import { SET_FEATURE, RESET_FEATURE } from '../CONSTANTS/types';
import Log from '../utilComponents/Log';
import { addAlertThunk } from './errorActions';
import { ProjectRecordService } from '../_services/GeotrakService';

const projectRecordService = new ProjectRecordService();

export const setFeature = (selectedFeature) => ({
    type: SET_FEATURE,
    payload: selectedFeature,
});

export const resetFeature = () => ({
    type: RESET_FEATURE,
});

export const fetchFeatureThunk = (dataSourceId, recordId) => async (dispatch) => {
    try {
        const body = { dataSourceId };

        const selectedFeature = await projectRecordService.getProjectRecord(recordId, body);

        if (selectedFeature) {
            dispatch(setFeature(selectedFeature));
        } else {
            dispatch(addAlertThunk('danger', 'Error getting selected feature', 'The selected record was not returned.'));
        }
    } catch (err) {
        Log.error(err);
        Log.dump();
        dispatch(addAlertThunk('danger', 'Error selecting feature', err.message || err));
    }
};
