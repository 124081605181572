import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { NotificationDomain } from '../../../../_services/NotificationService/Models';
import useDeleteNotificationDomain from '../../../../_services/NotificationService/hooks/domain/useDeleteNotificationDomain';
import '../notifications.css';

const deleteDomainButton = ({ notificationDomain }) => {
    const {
        deleteNotificationDomain, error, isLoading, isSuccessful,
    } = useDeleteNotificationDomain();

    if (!notificationDomain.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/notifications/${notificationDomain.notificationId}/domains`} />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="delete-notification-domain-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-notification-domain-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteNotificationDomain(notificationDomain)}
            />
        </div>
    );
};

export default deleteDomainButton;

deleteDomainButton.propTypes = {
    notificationDomain: PropTypes.instanceOf(NotificationDomain),
};

deleteDomainButton.defaultProps = {
    notificationDomain: new NotificationDomain(),
};
