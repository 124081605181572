import { useEffect, useState } from 'react';
import useGetGeocoderJob from '../../../_services/EtlService/hooks/useGetGeocoderJob';
import GeocoderJob from '../../../_services/EtlService/Models/GeocoderJob';
import { GeocoderTaskService } from '../../../_services/EtlService';

const useGeocoderJobForm = (id) => {
    const [values, setValues] = useState({
        id: null,
        taskId: '',
        database: '',
        schema: '',
        table: '',
        primaryKeyColumn: '',
    });
    const [tasks, setTasks] = useState([]);
    const geocoderTaskService = new GeocoderTaskService();
    const currentGeocoderJob = id ? useGetGeocoderJob(id) : new GeocoderJob();
    const { geocoderJob } = currentGeocoderJob;
    let error = null;

    const isValid = () => [values.database, values.schema, values.table, values.taskId].every((value) => value !== '');

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    const getTasks = async () => {
        try {
            const results = await geocoderTaskService.getTasks();
            setTasks(results);
        } catch (err) {
            error = err;
        }
    };

    useEffect(() => {
        getTasks();
    }, []);

    useEffect(() => {
        if (geocoderJob && geocoderJob.id) {
            setValues({
                id: geocoderJob.id,
                taskId: geocoderJob.taskId,
                database: geocoderJob.database,
                schema: geocoderJob.schema,
                table: geocoderJob.table,
                primaryKeyColumn: geocoderJob.primaryKeyColumn,
            });
        }
    }, [geocoderJob]);

    return {
        isLoading: currentGeocoderJob.isLoading,
        error: currentGeocoderJob.error || error,
        onFormUpdate,
        isValid,
        tasks,
        values,
    };
};

export default useGeocoderJobForm;
