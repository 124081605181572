import PropTypes from 'prop-types';
import { MapConfig } from '../Models';

class IUpdateMapRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(map) {
        if (!map) {
            throw Error('map is required');
        }
        if (!(map instanceof MapConfig)) {
            throw Error('Unexpected type for map');
        }

        const request = new IUpdateMapRequest();
        request.id = map.id;
        request.data = map;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateMapRequest;

IUpdateMapRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(MapConfig),
};
