import { useEffect, useState } from 'react';
import useGetDocumentType from '../../../_services/GeotrakService/hooks/useGetDocumentType';
import { DocumentType } from '../../../_services/GeotrakService/Models';

const useDocumentTypeForm = (id) => {
    let documentType = new DocumentType();
    let isLoading = false;
    if (id) {
        const result = useGetDocumentType(id);
        documentType = result.documentType;
        isLoading = result.isLoading;
    }
    const [values, setValues] = useState({
        id: null,
        name: '',
    });

    const isValid = () => values.name !== '' && values.name;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                id: documentType.id || null,
                name: documentType.name || '',
            });
        }
    }, [documentType]);

    return {
        values, isValid, onFormUpdate, isLoading,
    };
};

export default useDocumentTypeForm;
