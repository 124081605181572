class ICheckRequestsRequest {
    constructor() {
        this.permissions = null;
        this.role = null;
        this.filters = [];
        this.page = 1;
        this.subset = null;
        this.order = null;
    }

    static load(user, filters, page, subset, role, order) {
        if (!user.CheckRequest) {
            throw Error('permissions are required');
        }
        if (!filters) {
            throw Error('filters are required');
        }
        if (!page) {
            throw Error('page is required');
        }
        if (!subset) {
            throw Error('subset is required');
        }

        const request = new ICheckRequestsRequest();
        request.permissions = user.CheckRequest;
        request.subset = subset;
        request.role = role;
        request.filters = filters;
        request.page = page;
        request.order = order;
        request.validate();
        return request;
    }

    validate() {
        if (!this.permissions) {
            throw Error('permissions are required');
        }
        if (!this.filters) {
            throw Error('filters are required');
        }
        if (!this.page) {
            throw Error('page is required');
        }
        if (!this.subset) {
            throw Error('subset is required');
        }
    }
}

export default ICheckRequestsRequest;
