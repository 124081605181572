import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deliverToFields from '../../CONSTANTS/deliverToFields';
import { camelCaseToSpaced, snakeCaseToCamelCase } from '../../helpers/utilities';
import UploadFileManager from '../../components/CheckRequest/UploadFileManager';
import FileList from '../../components/CheckRequest/FileList';
import CheckRequestActions from '../../components/CheckRequest/CheckRequestActions';
import { CheckRequestConfigService, CheckRequestService } from '../../_services';
import generateInputTag from '../../helpers/inputTagFactory';
import Loader from '../../utilComponents/LoaderSpinner';
import '../stylesheets/CheckRequestDetail.css';
import { isFieldRequired, isFieldVisible } from '../../components/CheckRequest/utils/formRules';
import Modal from '../../components/Modals/Modal';
import ErrorMessage from '../../utilComponents/ErrorMessage';

class CheckRequestDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requestForm: [],
            checkRequest: {},
            requestDetails: {},
            autopopulateDeliverTo: false,
            existingFiles: [],
            filesReady: false,
            minimumRequiredDocuments: 0,
            error: null,
        };

        this.CheckRequestConfigService = new CheckRequestConfigService();
        this.CheckRequestService = new CheckRequestService();
    }

  componentDidMount = () => {
      const { checkRequestId } = this.props;
      this.CheckRequestService.getCheckRequest(checkRequestId).then((checkRequest) => {
          this.getFileRequirements(checkRequest);
          this.getRequestForm(checkRequest);
      });
  }

  getFileRequirements = (checkRequest) => {
      const { summary: { ProjectTag, RequestType } } = checkRequest;

      this.CheckRequestConfigService
          .getRequiredDocumentCount(ProjectTag, RequestType)
          .then((minimumRequiredDocuments) => this.setState({ minimumRequiredDocuments }))
          .catch((err) => this.setState({ error: `Error getting minimum file requirements needed. ${err.message || err}` }));
  }

  getCheckRequestField = (checkRequest, fieldName) => Object.values(checkRequest).find(
      (data) => Object.prototype.hasOwnProperty.call(data, fieldName)
  )[fieldName]

  getRequestForm = (checkRequest) => {
      const { role } = this.props;
      const requestDetails = { ...checkRequest };
      if (role) {
          this.CheckRequestConfigService.getRequestFormFields(
              checkRequest.summary.ProjectTag,
              checkRequest.summary.RequestType,
              role
          ).then((fields) => {
              const requestForm = fields.map((field) => {
                  const updatedField = field;
                  updatedField.value = this.getCheckRequestField(checkRequest, updatedField.Name);
                  delete requestDetails[updatedField.Name];
                  return updatedField;
              });
              this.setState({ requestForm, checkRequest, requestDetails });
          }).catch((err) => {
              this.setState({ error: `Error getting request form. ${err.message || err}` });
          });
      } else {
          this.setState({ requestDetails, checkRequest, requestForm: [] });
      }
  }

  handleSelectedDeliverTo = async (deliverToType) => {
      const { requestForm } = this.state;
      const deliverTo = await this.CheckRequestConfigService.getDeliverToOffice(deliverToType);
      let autopopulateDeliverTo;
      // autopopulate fields based on lookup info
      if (deliverTo) {
          Object.keys(deliverTo).forEach((field) => {
              requestForm.filter((formField) => formField.Name === field)[0].value = deliverTo[field];
          });
          requestForm.filter((formField) => formField.Name === 'DeliverToCompany')[0].value = requestForm.filter((formField) => formField.Name === 'PayableTo')[0].value;
          autopopulateDeliverTo = true;
      } else {
          requestForm.filter((formField) => deliverToFields.includes(formField.Name)).forEach((field) => {
              const updatedField = field;
              updatedField.IsRequired = true;
          });
          requestForm.filter((formField) => formField.Name === 'DeliverToCompany')[0].value = requestForm.filter((formField) => formField.Name === 'PayableTo')[0].value;
          requestForm.filter((formField) => formField.Name === 'DeliverToAddress')[0].value = requestForm.filter((formField) => formField.Name === 'PayeeAddress')[0].value;
          requestForm.filter((formField) => formField.Name === 'DeliverToCity')[0].value = requestForm.filter((formField) => formField.Name === 'PayeeCity')[0].value;
          requestForm.filter((formField) => formField.Name === 'DeliverToState')[0].value = requestForm.filter((formField) => formField.Name === 'PayeeState')[0].value;
          requestForm.filter((formField) => formField.Name === 'DeliverToZip')[0].value = requestForm.filter((formField) => formField.Name === 'PayeeZip')[0].value;
          autopopulateDeliverTo = false;
      }
      requestForm.filter((formField) => formField.Name === 'DeliverToType')[0].value = deliverToType;
      this.setState({
          requestForm,
          autopopulateDeliverTo,
      });
  }

  handleFormChange = (e) => {
      const { requestForm } = this.state;
      const field = e.target.id;
      const fieldValue = e.target.value || null;
      if (field === 'DeliverToType') {
          this.handleSelectedDeliverTo(fieldValue);
      }
      const newRequestForm = requestForm.map((formField) => (formField.Name === field
          ? { ...formField, value: fieldValue }
          : formField));
      this.setState({ requestForm: newRequestForm });
  }

  renderTitle = () => {
      const { checkRequest } = this.state;
      if (Object.keys(checkRequest).length) {
          return `${checkRequest.summary.ProjectTag} ${checkRequest.summary.MarketTag || ''} -
      ${checkRequest.projectControls.ProjectSiteId} (# ${checkRequest.summary.CheckRequestId})`;
      }
      return null;
  }

  renderHeader = (headerName) => (!['cancellationReason', 'voidFields'].includes(headerName)
      ? camelCaseToSpaced(snakeCaseToCamelCase(headerName)) : null)

  renderDetails = () => {
      const { requestDetails } = this.state;

      return Object.entries(requestDetails).map((field) => (
          <div key={field[0]}>
              <h6 className="text-primary"><strong>{this.renderHeader(field[0])}</strong></h6>
              <div className="row">
                  {Object.keys(field[1]).map((fieldValue) => (
                      <div
                          key={fieldValue}
                          className="col-md-4"
                      >
                          <div>
                              <span className="fw-bold">
                                  {camelCaseToSpaced(snakeCaseToCamelCase(fieldValue)).concat(' :')}
                              </span>
                              <span className="ps-2">
                                  {field[1][fieldValue]}
                              </span>
                          </div>
                      </div>
                  ))}
              </div>
              <hr className="mt-2 mb-2" />
          </div>
      ));
  }

  renderForm = () => {
      const { requestForm, autopopulateDeliverTo } = this.state;
      const requiredFormFields = {};
      requestForm.filter((field) => field.IsRequired).forEach((field) => {
          requiredFormFields[field.Name] = field.value;
      });
      return requestForm.map((field) => {
          if (isFieldVisible(autopopulateDeliverTo, requiredFormFields, field)) {
              return (
                  <div key={field.Id} className="col-md-4 col-sm-6">
                      {generateInputTag({
                          id: field.Name,
                          key: field.Id,
                          type: (field.Options && field.Options.length) ? 'select' : field.Type,
                          options: field.Options.map((option) => option.Name),
                          required: isFieldRequired(requiredFormFields, field),
                          label: field.DisplayName,
                          value: field.value,
                          maxLength: field.MaxLength,
                          handler: this.handleFormChange,
                      })}
                  </div>
              );
          }
          return null;
      });
  }

  handleFileStatusUpdate = (filesReady, existingFiles) => {
      this.setState({ filesReady, existingFiles });
  }

  renderFileManagement = () => {
      const { allowFileUploads, allowFileDeletes, requireFileUploads } = this.props;
      const { checkRequest, existingFiles, minimumRequiredDocuments } = this.state;
      return (
          <>
              <FileList
                  checkRequest={checkRequest}
                  allowFileDeletes={allowFileDeletes}
                  minimumRequiredDocuments={minimumRequiredDocuments}
                  onFileStatusUpdate={this.handleFileStatusUpdate}
              />
              {allowFileUploads
        && (
            <div className="container">
                <UploadFileManager
                    checkRequest={checkRequest}
                    requestType={checkRequest.summary.RequestType}
                    minimumRequiredDocuments={minimumRequiredDocuments}
                    existingFiles={existingFiles}
                    requireFileUploads={requireFileUploads}
                    onFileStatusUpdate={this.handleFileStatusUpdate}
                />
            </div>
        )}
          </>
      );
  }

  render() {
      const {
          role, refreshQueues, onCancel, showVoidOptions, checkRequestId,
      } = this.props;
      const {
          requestForm, requestDetails, checkRequest, filesReady, error,
      } = this.state;
      if (checkRequest) {
          return (
              <Modal
                  showModal
                  header={this.renderTitle()}
                  onCancel={onCancel}
                  component={!Object.keys(requestDetails).length ? <Loader />
                      : (
                          <>
                              {error && (
                                  <ErrorMessage
                                      message={error}
                                      onDismiss={() => this.setState({ error: null })}
                                  />
                              )}
                              <div className="container">
                                  {this.renderDetails()}
                              </div>
                              <div>
                                  <div className="container">
                                      <div className="row">
                                          {this.renderForm()}
                                      </div>
                                  </div>
                              </div>
                              {this.renderFileManagement()}
                          </>
                      )}
                  footer={(
                      <CheckRequestActions
                          role={role}
                          checkRequest={checkRequest}
                          refreshQueues={refreshQueues}
                          onCancel={onCancel}
                          filesReady={filesReady}
                          checkRequestId={checkRequestId}
                          requestForm={requestForm}
                          showVoidOptions={showVoidOptions}
                      />
                  )}
              />
          );
      }
      return null;
  }
}

export default CheckRequestDetail;

CheckRequestDetail.propTypes = {
    checkRequestId: PropTypes.number,
    allowFileUploads: PropTypes.bool,
    requireFileUploads: PropTypes.bool,
    allowFileDeletes: PropTypes.bool,
    role: PropTypes.string,
    onCancel: PropTypes.func,
    refreshQueues: PropTypes.func,
    showVoidOptions: PropTypes.bool,
};

CheckRequestDetail.defaultProps = {
    checkRequestId: 0,
    allowFileUploads: false,
    allowFileDeletes: false,
    requireFileUploads: true,
    role: '',
    onCancel: () => null,
    refreshQueues: () => null,
    showVoidOptions: false,
};
