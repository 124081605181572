import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import ReactGA from 'react-ga';
import { UserService } from '../../../_services';

const applicationLink = ({ label, route, userRole }) => {
    const history = useHistory();
    const auth = new UserService();
    const user = auth.getCurrentUser();

    const handleClickNavItem = (evt) => {
        evt.preventDefault();
        const { dataset } = evt.target;
        ReactGA.event({
            category: 'Navbar Click',
            action: dataset.route,
        });
        history.push(dataset.route);
    };

    const checkPermissions = () => {
        if (!user || !user[userRole]) {
            return false;
        }
        if (!user[userRole].length) {
            return false;
        }
        return true;
    };

    if (checkPermissions()) {
        return (
            <li
                key={uuidv4()}
                className="nav-item"
            >
                <a role="button" className="nav-link text-white" data-route={route} onClick={handleClickNavItem} href={history.location.hash}>
                    {label}
                </a>
            </li>
        );
    }

    return null;
};

export default applicationLink;

applicationLink.propTypes = {
    label: PropTypes.string,
    userRole: PropTypes.string,
    route: PropTypes.string,
};

applicationLink.defaultProps = {
    label: '',
    route: '',
    userRole: '',
};
