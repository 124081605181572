import PropTypes from 'prop-types';
import WidgetType from './WidgetType';

export default class DataSourceWidget {
    constructor() {
        this.id = null;
        this.dataSourceId = null;
        this.widgetType = new WidgetType();
    }

    static load(response) {
        const dataSourceWidget = new DataSourceWidget();

        if (response) {
            dataSourceWidget.id = response.id;
            dataSourceWidget.dataSourceId = response.dataSourceId;
            dataSourceWidget.widgetType = WidgetType.load(response.widgetType);
        }

        return dataSourceWidget;
    }

    validate() {
        if (!this.widgetType.id) {
            throw Error('widgetTypeId is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        return {
            dataSourceId: this.dataSourceId,
            widgetTypeId: this.widgetType.id,
        };
    }
}

DataSourceWidget.propTypes = {
    id: PropTypes.string,
    dataSourceId: PropTypes.string,
};
