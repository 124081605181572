import { useEffect, useState } from 'react';
import useGetOffice from '../../../../_services/CheckRequestService/hooks/useGetOffice';
import { Office } from '../../../../_services/CheckRequestService/Models';

const useOfficeForm = (id) => {
    let office = new Office();
    let isLoading = false;
    if (id) {
        const result = useGetOffice(id);
        office = result.office;
        isLoading = result.isLoading;
    }
    const [values, setValues] = useState({
        id: null,
        name: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
    });

    const isValid = () => values.name !== '' && values.name;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                id: office.id || null,
                name: office.name || '',
                address: office.address || '',
                city: office.city || '',
                state: office.state || '',
                zipCode: office.zipCode || '',
            });
        }
    }, [office]);

    return {
        values, isValid, onFormUpdate, isLoading,
    };
};

export default useOfficeForm;
