import { useState, useEffect } from 'react';
import { NotificationUser } from '../../../_services/NotificationService/Models';
import useGetNotificationUser from '../../../_services/NotificationService/hooks/user/useGetNotificationUser';
import useGetUsers from '../../../_services/UserService/hooks/useGetUsers';

const useNotificationUsersForm = (id) => {
    const { isLoading, users } = useGetUsers();
    let notificationUser = new NotificationUser();

    if (id) {
        const result = useGetNotificationUser(id);
        notificationUser = result.notificationUser;
    }
    const [values, setValues] = useState({
        username: '',
    });

    const isValid = () => values.username !== '';

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                username: notificationUser.username || '',
            });
        }
    }, [notificationUser]);

    return {
        isLoading,
        isValid,
        onFormUpdate,
        values,
        users,
    };
};

export default useNotificationUsersForm;
