import PropTypes from 'prop-types';
import { FavoriteDataSource } from '../Models';

class ICreateFavoriteDataSourceRequest {
    constructor() {
        this.moduleRoleId = null;
        this.data = {};
    }

    static load(favoriteDataSource, moduleRoleId) {
        if (!favoriteDataSource) {
            throw Error('favorite data source is required');
        }
        if (!(favoriteDataSource instanceof FavoriteDataSource)) {
            throw Error('unexpected type for favorite data source');
        }
        if (!moduleRoleId) {
            throw Error('role id is required');
        }

        const request = new ICreateFavoriteDataSourceRequest();
        request.moduleRoleId = moduleRoleId;
        request.data = favoriteDataSource;
        request.validate();
        return request;
    }

    validate() {
        if (!this.data.dataSourceId) {
            throw Error('dataSource id is required');
        }
    }
}

export default ICreateFavoriteDataSourceRequest;

ICreateFavoriteDataSourceRequest.propTypes = {
    moduleRoleId: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
