import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class Summary {
    constructor(checkRequest) {
        this.CreatedOn = datetimes.toLocal(checkRequest.CreatedOn);
        this.CreatedBy = checkRequest.CreatedBy;
        this.ProjectTag = checkRequest.ProjectTag;
        this.CheckRequestId = checkRequest.CheckRequestId;
        this.RequestType = checkRequest.RequestType;
        this.MarketTag = checkRequest.MarketTag;
        this.Submarket = checkRequest.Submarket;
        this.FALocationCode = checkRequest.FALocationCode;
        this.NationalProgram = checkRequest.NationalProgram;
        this.JobName = checkRequest.JobName;
        this.JobNumber = checkRequest.JobNumber;
        this.PONo = checkRequest.PONo;
        this.DeptNo = checkRequest.DeptNo;
        this.TowerOwner = checkRequest.TowerOwner;
        this.Amount = checkRequest.Amount;
        this.PayableTo = checkRequest.PayableTo;
        this.PayingBVCorp = checkRequest.PayingBVCorp;
        this.PayeeAddress = checkRequest.PayeeAddress;
        this.PayeeCity = checkRequest.PayeeCity;
        this.PayeeState = checkRequest.PayeeState;
        this.PayeeZip = checkRequest.PayeeZip;
        this.ShippingMethod = checkRequest.ShippingMethod;
        this.DeliverToCity = checkRequest.DeliverToCity;
        this.DeliverToType = checkRequest.DeliverToType;
        this.DeliverToName = checkRequest.DeliverToName;
        this.DeliverToRequestor = checkRequest.DeliverToRequestor;
        this.DeliverToLocation = checkRequest.DeliverToLocation;
        this.DeliverToCompany = checkRequest.DeliverToCompany;
        this.DeliverToAddress = checkRequest.DeliverToAddress;
        this.DeliverToState = checkRequest.DeliverToState;
        this.DeliverToZip = checkRequest.DeliverToZip;
        this.DeliverToPhone = checkRequest.DeliverToPhone;
        this.BVWA = checkRequest.BVWA;
        this.SAQCON = checkRequest.SAQCON;
        this.Purpose = checkRequest.Purpose;
        this.FieldCheckReason = checkRequest.FieldCheckReason;
        this.RemittanceNotes = checkRequest.RemittanceNotes;
        this.InternalNotes = checkRequest.InternalNotes;
        this.RightOfWayFirms = checkRequest.RightOfWayFirms;
        this.CWPNo = checkRequest.CWPNo;
        this.MasterProject = checkRequest.MasterProject;
        this.ExpenseType = checkRequest.ExpenseType;
    }
}

Summary.propTypes = {
    CreatedOn: PropTypes.datetimes,
    CreatedBy: PropTypes.string,
    RequestorEmail: PropTypes.string,
    RequestorPhone: PropTypes.string,
    DeliverToRequestor: PropTypes.string,
    DeliverToLocation: PropTypes.string,
    ProjectTag: PropTypes.string,
    CheckRequestId: PropTypes.number,
    RequestType: PropTypes.string,
    MarketTag: PropTypes.string,
    Submarket: PropTypes.string,
    Id: PropTypes.string,
    FALocationCode: PropTypes.string,
    NationalProgram: PropTypes.string,
    JobName: PropTypes.string,
    JobNumber: PropTypes.string,
    PONo: PropTypes.string,
    TowerOwner: PropTypes.string,
    Amount: PropTypes.number,
    DeliverToType: PropTypes.string,
    PayableTo: PropTypes.string,
    DeliverToName: PropTypes.string,
    PayingBVCorp: PropTypes.string,
    PayeeAddress: PropTypes.string,
    DeliverToCompany: PropTypes.string,
    ShippingMethod: PropTypes.string,
    PayeeCity: PropTypes.string,
    DeliverToAddress: PropTypes.string,
    DeptNo: PropTypes.string,
    PayeeState: PropTypes.string,
    DeliverToCity: PropTypes.string,
    SAQCON: PropTypes.string,
    PayeeZip: PropTypes.string,
    DeliverToState: PropTypes.string,
    BVWA: PropTypes.string,
    DeliverToZip: PropTypes.string,
    DeliverToPhone: PropTypes.string,
    Purpose: PropTypes.string,
    FieldCheckReason: PropTypes.string,
    RemittanceNotes: PropTypes.string,
    InternalNotes: PropTypes.string,
    RightOfWayFirms: PropTypes.string,
    CWPNo: PropTypes.string,
    MasterProject: PropTypes.string,
    ExpenseType: PropTypes.string,
};
