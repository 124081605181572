import PropTypes from 'prop-types';
import { NotificationUser } from '../Models';

class ICreateNotificationUserRequest {
    constructor() {
        this.data = {};
        this.notificationId = null;
    }

    static load(notificationUser) {
        if (!notificationUser) {
            throw Error('notification user is required');
        }
        if (!(notificationUser instanceof NotificationUser)) {
            throw Error('Unexpected type for notification user');
        }

        const request = new ICreateNotificationUserRequest();
        request.data = notificationUser;
        request.notificationId = notificationUser.notificationId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.notificationId) {
            throw Error('notification id is required');
        }
    }
}

export default ICreateNotificationUserRequest;

ICreateNotificationUserRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
    notificationId: PropTypes.string,
};
