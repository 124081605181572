import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class ProjectControls {
    constructor(checkRequest) {
        this.ProjectControlsApprovedBy = checkRequest.ProjectControlsApprovedBy;
        this.ProjectControlsApprovedDate = datetimes.toLocal(checkRequest.ProjectControlsApprovedDate);
        this.ProjectControlsRejectedBy = checkRequest.ProjectControlsRejectedBy;
        this.ProjectControlsRejectedDate = datetimes.toLocal(checkRequest.ProjectControlsRejectedDate);
        this.ProjectControlsRejectedComments = checkRequest.ProjectControlsRejectedComments;
        this.ProjectNo = checkRequest.ProjectNo;
        this.ProjectSiteId = checkRequest.ProjectSiteId;
        this.TaskNo = checkRequest.TaskNo;
        this.BottomTaskNo = checkRequest.BottomTaskNo;
        this.WorkCode = checkRequest.WorkCode;
        this.CostCode = checkRequest.CostCode;
    }
}

ProjectControls.propTypes = {
    ProjectControlsApprovedBy: PropTypes.string,
    ProjectControlsApprovedDate: PropTypes.datetimes,
    ProjectControlsRejectedBy: PropTypes.string,
    ProjectControlsRejectedDate: PropTypes.datetimes,
    ProjectControlsRejectedComments: PropTypes.string,
    ProjectNo: PropTypes.string,
    ProjectSiteId: PropTypes.string,
    TaskNo: PropTypes.string,
    BottomTaskNo: PropTypes.string,
    WorkCode: PropTypes.string,
    CostCode: PropTypes.string,
};
