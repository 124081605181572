import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestActionService } from '../../../_services';
import { ButtonDanger } from '../../../utilComponents/styledComponents';
import generateInputTag from '../../../helpers/inputTagFactory';
import Modal from '../../Modals/Modal';
import ErrorMessage from '../../../utilComponents/ErrorMessage';

class Cancel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            comments: null,
            isWorking: false,
            error: null,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  cancelRequest = () => {
      this.setState({ isWorking: true });
      const {
          checkRequestId, refreshQueues,
      } = this.props;
      const { comments } = this.state;
      const form = {
          CancellationReason: comments,
      };
      this.CheckRequestActionService.cancelRequest(checkRequestId, form).then(() => {
          this.setState({
              showModal: false,
              isWorking: false,
          });
          refreshQueues();
      }).catch((err) => {
          this.setState({
              isWorking: false,
              error: err.message || 'An error occurred canceling request.',
          });
      });
  }

  handleCommentsChange = (e) => {
      this.setState({ comments: e.target.value });
  }

  render() {
      const {
          showModal, comments, isWorking, error,
      } = this.state;
      return (
          <>
              <ButtonDanger onClick={() => this.setState({ showModal: true })}>
                  Cancel Request
              </ButtonDanger>
              <Modal
                  showModal={showModal}
                  header="Cancel Request"
                  onCancel={() => this.setState({ showModal: false })}
                  component={generateInputTag({
                      type: 'input',
                      id: 'CancellationReason',
                      label: 'Cancellation Reason',
                      required: true,
                      handler: this.handleCommentsChange,
                      value: comments,
                  })}
                  footer={(
                      <>
                          {error && (
                              <div>
                                  <ErrorMessage message={error} onDismiss={() => this.setState({ error: null })} />
                              </div>
                          )}
                          <LoaderButton
                              danger
                              onClick={this.cancelRequest}
                              isLoading={isWorking}
                              disabled={!(comments && comments.length)}
                              text="Cancel Check Request"
                              loadingText="Cancelling…"
                          />
                      </>
                  )}
                  displayFullScreen={false}
              />
          </>
      );
  }
}

export default Cancel;

Cancel.propTypes = {
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
};

Cancel.defaultProps = {
    checkRequestId: 0,
    refreshQueues: () => null,
};
