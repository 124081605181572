import React from 'react';
import PropTypes from 'prop-types';
import LabelControl from './LabelControl';

const numberBox = function statelessFunctionComponentClass(props) {
    const style = {
        display: 'inline-block',
        marginBottom: '0',
    };

    const columnWidth = props.formField.width ? `col-sm-${props.formField.width}` : 'col-sm-12';
    const helpBlock = props.feedback ? 'has-error' : '';

    return (
        <div className={`form-group form-component-datecontrol ${helpBlock} ${columnWidth}`}>
            <LabelControl htmlFor={props.formField.fieldName} style={style}>
                {props.displayName}
            </LabelControl>
            <input
                type="number"
                className="form-control"
                id={props.formField.fieldName}
                readOnly={props.metaData.isReadOnly}
                name={props.formField.fieldName}
                placeholder={props.displayName}
                defaultValue={props.value}
                aria-describedby={`helpblock${props.formField.fieldName}`}
                step="any"
                required={props.formField.isRequired}
            />
            <div id={`helpblock${props.formField.fieldName}`} className="help-block">
                {props.feedback}
            </div>
        </div>
    );
};

export default numberBox;

numberBox.propTypes = {
    formField: PropTypes.shape({
        fieldBody: PropTypes.string.isRequired,
        fieldName: PropTypes.string.isRequired,
    }).isRequired,
    metaData: PropTypes.shape({
        isReadOnly: PropTypes.bool,
    }),
    displayName: PropTypes.string,
    value: PropTypes.number,
    feedback: PropTypes.string,
};
