import { useReducer } from 'react';
import RendererService from '../RendererService';
import ICreateRendererRequest from '../interfaces/ICreateRendererRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useCreateRenderer = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const createRenderer = async (rendererObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = ICreateRendererRequest.load(rendererObject);
            const result = await new RendererService().createRenderer(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        createRenderer, error, isLoading, isSuccessful, data,
    };
};

export default useCreateRenderer;
