import React, { useEffect, useState } from 'react';
import { ButtonContainer } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useNotificationDomainsForm from './useNotificationDomainsForm';
import { CreateDomain, DeleteDomain } from './Actions';
import { NotificationDomain } from '../../../_services/NotificationService/Models';
import useGetNotification from '../../../_services/NotificationService/hooks/useGetNotification';
import { DomainService } from '../../../_services/GeotrakService';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminNotificationsDomainsForm = ({ match }) => {
    const { params: { id, domainId } } = match;
    const [fields, setFields] = useState([]);
    const {
        values, onFormUpdate, isLoading, isValid,
    } = useNotificationDomainsForm(domainId);
    const { notification, isLoading: isLoadingNotification } = useGetNotification(id);

    const notificationDomain = new NotificationDomain();
    notificationDomain.name = values.name;
    notificationDomain.notificationId = id;
    notificationDomain.id = domainId;

    const getDomainFields = async () => {
        const domains = await new DomainService().getRawDomains(notification.database, notification.table);
        const fieldList = [];
        domains.forEach((domain) => {
            if (!fieldList.includes(domain.field)) {
                fieldList.push(domain.field);
            }
        });
        fieldList.sort();
        setFields(fieldList);
    };

    useEffect(() => {
        if (notification.id) {
            getDomainFields();
        }
    }, [notification]);

    if (isLoading || isLoadingNotification) {
        return <LoadingFullScreen id="notification-domain-form-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Notification Domains">
                <form>
                    <div className="mb-3">
                        <label htmlFor="select-domain" className="form-label">
                            Domain Field
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-domain"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                            disabled={!!domainId}
                        >
                            <option value="" disabled>--Select a domain field--</option>
                            {fields.map((field) => (
                                <option key={field} value={field}>{field}</option>
                            ))}
                        </select>
                    </div>
                    <ButtonContainer>
                        <CreateDomain notificationDomain={notificationDomain} isValid={isValid} />
                        <DeleteDomain notificationDomain={notificationDomain} />
                    </ButtonContainer>
                </form>
            </AdminContainer>
        </>
    );
};

export default adminNotificationsDomainsForm;
