import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestActionService } from '../../../_services';
import { ButtonDanger } from '../../../utilComponents/styledComponents';
import Modal from '../../Modals/Modal';
import ErrorMessage from '../../../utilComponents/ErrorMessage';

class RejectVoid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            comments: '',
            isWorking: false,
            error: null,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  rejectVoid = () => {
      this.setState({ isWorking: true });
      const { checkRequestId, refreshQueues } = this.props;
      const { comments } = this.state;
      const form = {
          VoidRejectedComments: comments || null,
      };
      this.CheckRequestActionService.rejectVoid(checkRequestId, form).then(() => {
          this.setState({
              showModal: false,
              isWorking: false,
          });
          refreshQueues();
      }).catch((err) => {
          this.setState({
              isWorking: false,
              error: err.message || 'An error occurred rejecting void.',
          });
      });
  }

  render() {
      const { showModal, isWorking, error } = this.state;
      return (
          <>
              <ButtonDanger onClick={() => this.setState({ showModal: true })}>
                  Reject Void
              </ButtonDanger>
              <Modal
                  showModal={showModal}
                  header="Reject Void"
                  onCancel={() => this.setState({ showModal: false })}
                  component={(
                      <form>
                          <label htmlFor="void-rejected-comments" className="form-label">
                              Rejection Comments
                          </label>
                          <input
                              id="void-rejected-comments"
                              onChange={(e) => this.setState({ comments: e.target.value })}
                          />
                      </form>
                  )}
                  footer={(
                      <>
                          {error && (
                              <div>
                                  <ErrorMessage message={error} onDismiss={() => this.setState({ error: null })} />
                              </div>
                          )}
                          <LoaderButton
                              danger
                              onClick={this.rejectVoid}
                              isLoading={isWorking}
                              text="Reject"
                              loadingText="Rejecting…"
                          />
                      </>
                  )}
                  displayFullScreen={false}
              />
          </>
      );
  }
}

export default RejectVoid;

RejectVoid.propTypes = {
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
};

RejectVoid.defaultProps = {
    checkRequestId: 0,
    refreshQueues: () => null,
};
