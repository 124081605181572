import moment from 'moment';
import 'moment-timezone';

const defaultOutputFormat = 'LLLL'; //  // Friday, March 5, 2021 11:07 AM
const defaultInputFormat = 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z';
const millisecondsPerSecond = 1000;

const datetimes = {

    // TODO Moley(04/14/2023) rename to toLocalStr
    /**
     * toLocal: converts date to localized string
     * @param {*} utcDateStr
     * @param {*} utcDateFormat
     * @param {*} outputFormat
     * @returns date string in the format supplied from outputFormat
     */
    toLocal(utcDateStr, utcDateFormat, outputFormat) {
        if (!utcDateStr) {
            return null;
        }

        const inFormat = utcDateFormat || defaultInputFormat;
        const outFormat = outputFormat || defaultOutputFormat;

        const zone = moment.tz.guess();
        const date = moment(utcDateStr, inFormat);

        return date.tz(zone).format(outFormat);
    },

    /**
     * toLocalDate
     * @param {*} utcDate
     * @param {*} utcDateFormat
     * @returns Date
     */
    toLocalDate(utcDate, utcDateFormat) {
        return new Date(datetimes.toLocal(utcDate, utcDateFormat));
    },

    /**
     * toUTC
     * @param {*} localDateTimeStr
     * @returns Object<Moment>
     */
    toUTC(localDateTimeStr) {
        return moment(new Date(localDateTimeStr)).utc();
    },

    /**
     * getDifferanceInSeconds
     * @param {*} fromDate
     * @param {*} thruDate
     * @returns number
     */
    getDifferanceInSeconds(fromDate, thruDate) {
        return (thruDate - fromDate) / millisecondsPerSecond;
    },
};

export default datetimes;
