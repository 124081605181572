import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestActionService } from '../../../_services';

class ApproveVoid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isWorking: false,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  approveVoid = () => {
      this.setState({ isWorking: true });
      const {
          checkRequestId, refreshQueues, onError,
      } = this.props;
      this.CheckRequestActionService.approveVoid(checkRequestId).then(() => {
          this.setState({ isWorking: false });
          refreshQueues();
      }).catch((err) => {
          this.setState({ isWorking: false });
          onError(err.message || 'An error occurred approving void.');
      });
  }

  render() {
      const { isWorking } = this.state;
      return (
          <LoaderButton
              onClick={this.approveVoid}
              isLoading={isWorking}
              text="Approve Void"
              loadingText="Approving..."
          />
      );
  }
}

export default ApproveVoid;

ApproveVoid.propTypes = {
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
    onError: PropTypes.func,
};

ApproveVoid.defaultProps = {
    checkRequestId: 0,
    refreshQueues: () => null,
    onError: () => null,
};
