import PropTypes from 'prop-types';

export default class Domain {
    constructor(field, value, database, table, id = null) {
        this.id = id;
        this.field = field;
        this.value = value;
        this.database = database;
        this.table = table;
    }

    static load(response) {
        return new Domain(
            response.field,
            response.value,
            response.databaseReference,
            response.tableReference,
            response.id
        );
    }

    validate() {
        if (!this.field || this.field === '') {
            throw Error('Field is required');
        }

        if (!this.value || this.value === '') {
            throw Error('Value is required');
        }

        if (!this.database || this.database === '') {
            throw Error('Database is required');
        }

        if (!this.table || this.table === '') {
            throw Error('Table is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            field: this.field,
            value: this.value,
            databaseReference: this.database,
            tableReference: this.table,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

Domain.propTypes = {
    id: PropTypes.string,
    field: PropTypes.string,
    value: PropTypes.string,
    database: PropTypes.string,
    table: PropTypes.string,
};
