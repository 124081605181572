import PropTypes from 'prop-types';

export default class PrimaryKey {
    constructor(key, value) {
        this.key = key;
        this.value = value;
    }

    static load(key, value) {
        if (!key) {
            throw Error('key is required');
        }
        if (!value) {
            throw Error('value is required');
        }

        return new PrimaryKey(key, value);
    }
}

PrimaryKey.propTypes = {
    key: PropTypes.string,
    value: PropTypes.string,
};
