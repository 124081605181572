import { useReducer } from 'react';
import DocumentTypeService from '../DocumentTypeService';
import ICreateDataSourceDocumentTypeRequest from '../interfaces/ICreateDataSourceDocumentTypeRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useCreateDataSourceDocumentType = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const createDataSourceDocumentType = async (dataSourceDocumentTypeObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = ICreateDataSourceDocumentTypeRequest.load(dataSourceDocumentTypeObject);
            const result = await new DocumentTypeService().createDataSourceDocumentType(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        createDataSourceDocumentType, error, isLoading, isSuccessful, data,
    };
};

export default useCreateDataSourceDocumentType;
