import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class APProcessing {
    constructor(checkRequest) {
        this.ApprovedBy = checkRequest.CheckCreatedBy;
        this.CheckCreatedDate = datetimes.toLocal(checkRequest.CheckCreatedDate);
        this.APProcessingRejectedBy = checkRequest.APProcessingRejectedBy;
        this.APProcessingRejectedDate = datetimes.toLocal(checkRequest.APProcessingRejectedDate);
        this.APProcessingRejectedComments = checkRequest.APProcessingRejectedComments;
        this.AdditionalEmailsToCopy = checkRequest.AdditionalEmailsToCopy;
        this.InvoiceNo = checkRequest.InvoiceNo;
        this.InvoiceSource = checkRequest.InvoiceSource;
        this.APProcessingRecordNo = checkRequest.APProcessingRecordNo;
        this.VendorNo = checkRequest.VendorNo;
        this.TrackingNo = checkRequest.TrackingNo;
        this.CheckNo = checkRequest.CheckNo;
        this.VendorSiteCode = checkRequest.VendorSiteCode;
        this.FedexCostToProject = checkRequest.FedexCostToProject;
        this.CheckIssuedDate = checkRequest.CheckIssuedDate;
        this.FedexCostToTask = checkRequest.FedexCostToTask;
        this.CheckStatus = checkRequest.CheckStatus;
        this.CheckClearedDate = checkRequest.CheckClearedDate;
        this.AttachInvoice = checkRequest.AttachInvoice;
    }
}

APProcessing.propTypes = {
    ApprovedBy: PropTypes.string,
    CheckCreatedDate: PropTypes.string,
    APProcessingRejectedBy: PropTypes.string,
    APProcessingRejectedDate: PropTypes.datetimes,
    APProcessingRejectedComments: PropTypes.string,
    AdditionalEmailsToCopy: PropTypes.string,
    InvoiceNo: PropTypes.string,
    InvoiceSource: PropTypes.string,
    APProcessingRecordNo: PropTypes.string,
    VendorNo: PropTypes.string,
    TrackingNo: PropTypes.string,
    ApprCheckNoovedBy: PropTypes.string,
    VendorSiteCode: PropTypes.string,
    FedexCostToProject: PropTypes.string,
    CheckIssuedDate: PropTypes.string,
    FedexCostToTask: PropTypes.string,
    CheckStatus: PropTypes.string,
    CheckClearedDate: PropTypes.datetimes,
    AttachInvoice: PropTypes.string,
};
