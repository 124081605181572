import BaseCheckRequestService from './BaseCheckRequestService';

export default class CheckRequestActionService extends BaseCheckRequestService {
    async cancelRequest(checkRequestId, form) {
        const body = {
            checkRequestId,
            data: form,
        };
        return this.patch('/check-request-actions/cancel-request', body);
    }

    async approveRequest(role, checkRequestId, form) {
        const body = {
            checkRequestId,
            data: form,
        };
        return this.patch(`/check-request-actions/approve-request/role/${role}`, body);
    }

    async rejectRequest(role, checkRequestId, form, comments) {
        const body = {
            checkRequestId,
            comments,
            data: form,
        };
        return this.patch(`/check-request-actions/reject-request/role/${role}`, body);
    }

    async updateRequest(checkRequestId, form) {
        const body = {
            checkRequestId,
            data: form,
        };
        return this.patch('/check-request-actions/update-request', body);
    }

    async resubmitRequest(checkRequestId, form) {
        const body = {
            checkRequestId,
            data: form,
        };
        return this.patch('/check-request-actions/resubmit-request', body);
    }

    async submitRequest(form) {
        const body = {
            data: form,
        };
        return this.post('/check-request-actions/submit-request', body);
    }

    async requestVoid(checkRequestId, form) {
        const body = {
            checkRequestId,
            data: form,
        };
        return this.patch('/check-request-actions/request-void', body);
    }

    async approveVoid(checkRequestId) {
        const body = {
            checkRequestId,
        };
        return this.patch('/check-request-actions/approve-void', body);
    }

    async rejectVoid(checkRequestId, form) {
        const body = {
            checkRequestId,
            data: form,
        };
        return this.patch('/check-request-actions/reject-void', body);
    }

    async deleteRequest(checkRequestId) {
        const body = {
            checkRequestId,
        };
        return this.patch('/check-request-actions/delete-request', body);
    }

    async createCheck(checkRequestId, form) {
        const body = {
            checkRequestId,
            data: form,
        };
        return this.patch('/check-request-actions/create-check', body, true);
    }
}
