import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import App from './App';
import Log from './utilComponents/Log';
import './index.css';
import './calcite-web.css';
import store from './store';
import { UserService } from './_services';

const history = createBrowserHistory();
const auth = new UserService();

Log.trace(`You are running this application in ${process.env.NODE_ENV} mode.`);
Log.trace(`log level set to ${process.env.REACT_APP_LOG_LEVEL}.`);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_SITE_TAG);
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.set({
    userId: auth.getCurrentUserId(),
});

/* page changed event */
history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

/* eslint-disable react/jsx-filename-extension */
ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);
