import PropTypes from 'prop-types';
import { DataSourceDocumentType } from '../Models';

class ICreateDataSourceDocumentTypeRequest {
    constructor() {
        this.data = {};
        this.dataSourceId = null;
    }

    static load(dataSourceDocumentType) {
        if (!dataSourceDocumentType) {
            throw Error('document type is required');
        }
        if (!(dataSourceDocumentType instanceof DataSourceDocumentType)) {
            throw Error('Unexpected data type for data source document type');
        }
        if (!dataSourceDocumentType.dataSourceId) {
            throw Error('dataSourceId is required');
        }

        const request = new ICreateDataSourceDocumentTypeRequest();
        request.data = dataSourceDocumentType;
        request.dataSourceId = dataSourceDocumentType.dataSourceId;
        return request;
    }
}

export default ICreateDataSourceDocumentTypeRequest;

ICreateDataSourceDocumentTypeRequest.propTypes = {
    data: PropTypes.instanceOf(DataSourceDocumentType),
    dataSourceId: PropTypes.string,
};
