import PropTypes from 'prop-types';
import { Project } from '../Models';

class ICreateProjectRequest {
    constructor() {
        this.data = {};
    }

    static load(project) {
        if (!project) {
            throw Error('project is required');
        }
        if (!(project instanceof Project)) {
            throw Error('Unexpected type for project');
        }

        const request = new ICreateProjectRequest();
        request.data = project;
        return request;
    }
}

export default ICreateProjectRequest;

ICreateProjectRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
