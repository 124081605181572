import PropTypes from 'prop-types';

export default class Autopopulation {
    constructor() {
        this.id = null;
        this.condition = null;
        this.field = null;
        this.value = null;
        this.database = null;
        this.table = null;
    }

    static load(response) {
        const autopopulation = new Autopopulation();

        if (response) {
            autopopulation.id = response.id;
            autopopulation.condition = response.condition;
            autopopulation.field = response.field;
            autopopulation.value = response.value;
            autopopulation.database = response.database;
            autopopulation.table = response.table;
        }

        return autopopulation;
    }

    loadSqlEntity() {
        const sqlEntity = {
            condition: this.condition,
            field: this.field,
            value: this.value,
            database: this.database,
            table: this.table,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

Autopopulation.propTypes = {
    id: PropTypes.string,
    condition: PropTypes.string,
    field: PropTypes.string,
    value: PropTypes.string,
    database: PropTypes.database,
    table: PropTypes.table,
};
