import { useEffect, useReducer } from 'react';
import { DataSource } from '../Models';
import DataSourceService from '../DataSourceService';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';

const useGetDataSource = (id) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new DataSource() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const result = await new DataSourceService().getDataSource(id);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, [id]);

    return { dataSource: data, error, isLoading };
};

export default useGetDataSource;
