import React, { useState } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { Autopopulation } from '../../../../_services/GeotrakService/Models';
import useDeleteAutopopulation from '../../../../_services/GeotrakService/hooks/useDeleteAutopopulation';
import '../autopopulations.css';
import Modal from '../../../../components/Modals/Modal';
import { ButtonDanger } from '../../../../utilComponents/styledComponents';

const deleteButton = ({ autopopulation }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {
        deleteAutopopulation, error, isLoading, isSuccessful,
    } = useDeleteAutopopulation();

    if (!autopopulation.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/autopopulations" />;
    }

    return (
        <div className="autopopulation-toolbar autopopulation-button">
            <Modal
                id="autopopulation-delete-modal"
                showModal={showConfirmation}
                header="Wait!"
                onCancel={() => setShowConfirmation(false)}
                component={
                    <h5 className="text-center">Are you sure you want to delete this job?</h5>
                }
                footer={
                    <ButtonDanger onClick={() => deleteAutopopulation(autopopulation)}>Delete</ButtonDanger>
                }
                displayFullScreen={false}
            />
            {error && <div className="text-danger autopopulation-error" id="delete-autopopulation-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-autopopulation-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => setShowConfirmation(true)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    autopopulation: PropTypes.instanceOf(Autopopulation),
};

deleteButton.defaultProps = {
    autopopulation: new Autopopulation(),
};
