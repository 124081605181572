import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { Market } from '../../../_services/GeotrakService/Models';
import { Create, Update, Delete } from './Actions';
import useMarketForm from './useMarketForm';
import './markets.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminMarketsForm = ({ match }) => {
    const {
        values, isValid, onFormUpdate, isLoading,
    } = useMarketForm(match.params.id || null);

    if (isLoading) {
        return <LoadingFullScreen id="market-form-loader" />;
    }

    const market = new Market();
    market.id = values.id;
    market.name = values.name;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Market">
                <form>
                    <div className="mb-3">
                        <label htmlFor="market-name" className="form-label">
                            Market Name
                        </label>
                        <input
                            type="text"
                            id="market-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                </form>
                <div className="market-toolbar">
                    <Create market={market} isValid={isValid} />
                    <Update market={market} isValid={isValid} />
                    <Delete market={market} isValid={isValid} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminMarketsForm;
