import { useEffect, useState } from 'react';
import useGetRole from '../../../_services/GeotrakService/hooks/useGetRole';
import useGetModule from '../../../_services/GeotrakService/hooks/useGetModule';
import { Role } from '../../../_services/GeotrakService/Models';

const useRoleForm = (roleId, moduleId) => {
    const { module } = useGetModule(moduleId);
    let role = new Role();
    let isLoading = false;
    if (roleId) {
        const result = useGetRole(roleId);
        role = result.role;
        isLoading = result.isLoading;
    }
    const [values, setValues] = useState({
        id: null,
        name: '',
        alias: '',
    });

    const isValid = () => values.name !== '' && values.name && values.alias !== '' && values.alias;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (roleId) {
            setValues({
                id: role.id || null,
                name: role.name || '',
                alias: role.alias || '',
            });
        }
    }, [role]);

    return {
        values, isValid, onFormUpdate, module, isLoading,
    };
};

export default useRoleForm;
