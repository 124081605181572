import PropTypes from 'prop-types';
import { Renderer } from '../Models';

class IUpdateRendererRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(renderer) {
        if (!renderer) {
            throw Error('renderer is required');
        }
        if (!(renderer instanceof Renderer)) {
            throw Error('Unexpected type for renderer');
        }

        const request = new IUpdateRendererRequest();
        request.id = renderer.id;
        request.data = renderer;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateRendererRequest;

IUpdateRendererRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(Renderer),
};
