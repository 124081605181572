import React from 'react';
import PropTypes from 'prop-types';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';

const ICON_SIZE = 20;
const validIcons = ({ validStep }) => (
    <span className={`${validStep ? 'text-success' : 'text-danger'} me-lg-1 align-text-bottom`} size={ICON_SIZE}>
        {(validStep ? IoIosCheckmarkCircleOutline() : IoIosCloseCircleOutline())}
    </span>
);

export default validIcons;

validIcons.propTypes = {
    validStep: PropTypes.bool,

};

validIcons.defaultProps = {
    validStep: false,

};
