import { useReducer } from 'react';
import UserService from '../UserService';
import IUpdateDatabasePasswordRequest from '../interfaces/IUpdateDatabasePasswordRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateDatabasePassword = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const updateDatabasePassword = async (newPassword) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateDatabasePasswordRequest.load(newPassword);
            await new UserService().updateDatabasePassword(request);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    const clearError = () => {
        dispatch({ type: ACTIONS.CLEAR_ERROR });
    };

    return {
        updateDatabasePassword, error, isLoading, isSuccessful, clearError,
    };
};

export default useUpdateDatabasePassword;
