import React from 'react';
import useRoleETLForm from './useRoleETLForm';
import EtlJob from '../../../_services/EtlService/Models/EtlJob';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetEtlJobTypes from '../../../_services/EtlService/hooks/useGetEtlJobTypes';
import { Create, Delete } from './RoleETLActions';

const adminRoleETLsForm = ({ match }) => {
    const { params: { etlId, roleId, moduleId } } = match;
    const {
        values, isLoading, onFormUpdate, isValid,
    } = useRoleETLForm(etlId || null, roleId);
    const { etlJobTypes } = useGetEtlJobTypes();

    if (isLoading) {
        return <LoadingFullScreen id="etl-form-loader" />;
    }

    const etlJob = new EtlJob();
    etlJob.id = values.id;
    etlJob.moduleRoleId = roleId;
    etlJob.jobType.id = values.jobTypeId;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="ETL">
                <form>
                    <div className="mb-3">
                        <label htmlFor="select-job-type" className="form-label mb-3">
                            Job Type
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-job-type"
                            value={values.jobTypeId}
                            onChange={(e) => onFormUpdate('jobTypeId', e.target.value)}
                        >
                            <option disabled value="">--Select a job type--</option>
                            {etlJobTypes.map((etlJobType) => (
                                <option key={etlJobType.id} value={etlJobType.id}>{etlJobType.name}</option>
                            ))}
                        </select>
                    </div>
                </form>
                <div className="etl-toolbar">
                    <Create etlJob={etlJob} moduleId={moduleId} isValid={isValid} />
                    <Delete etlJob={etlJob} moduleId={moduleId} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminRoleETLsForm;
