import PropTypes from 'prop-types';

export default class Field {
    constructor() {
        this.id = null;
        this.dataSourceId = null;
        this.name = null;
        this.alias = null;
        this.isEditable = false;
    }

    static load(response) {
        const field = new Field();

        if (field) {
            field.id = response.id;
            field.dataSourceId = response.dataSourceId;
            field.name = response.name;
            field.alias = response.alias;
            field.isEditable = response.isEditable;
        }

        return field;
    }
}

Field.propTypes = {
    id: PropTypes.string,
    dataSourceId: PropTypes.string,
    name: PropTypes.string,
    alias: PropTypes.string,
    isEditable: PropTypes.bool,
};
