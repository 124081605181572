import moment from 'moment';

export default class FilterGridExpression {
    constructor(column, filterTerm) {
        this.column = column;
        this.filterTerm = filterTerm;
        this.sqlExpression = null;
        this.isValid = true;

        this.logicalOperators = {
            NULL: 'null',
            NOT_NULL: 'not null',
            LESS_THAN_EQUAL_TO: '<=',
            GREATER_THAN_EQUAL_TO: '>=',
            LESS_THAN: '<',
            GREATER_THAN: '>',
            NOT_EQUAL: '<>',
        };

        this.formatSqlExpression();
    }

    formatSqlExpression() {
        switch (this.column.columnType) {
        case 'date':
            this.sqlExpression = this.formatDateExpression();
            break;
        case 'datetime':
            this.sqlExpression = this.formatDateExpression();
            break;
        case 'datetime2':
            this.sqlExpression = this.formatDateExpression();
            break;
        case 'int':
            this.sqlExpression = this.formatNumericExpression();
            break;
        case 'numeric':
            this.sqlExpression = this.formatNumericExpression();
            break;
        case 'decimal':
            this.sqlExpression = this.formatNumericExpression();
            break;
        case 'money':
            this.sqlExpression = this.formatNumericExpression();
            break;
        default:
            this.sqlExpression = this.formatStringExpression();
        }
    }

    formatDateExpression() {
        let operator;

        if (this.filterTerm.includes(this.logicalOperators.LESS_THAN_EQUAL_TO)) {
            operator = this.logicalOperators.LESS_THAN_EQUAL_TO;
        }

        if (this.filterTerm.includes(this.logicalOperators.GREATER_THAN_EQUAL_TO)) {
            operator = this.logicalOperators.GREATER_THAN_EQUAL_TO;
        }

        if (this.filterTerm.includes(this.logicalOperators.NOT_EQUAL)) {
            operator = this.logicalOperators.NOT_EQUAL;
        }

        if (this.filterTerm.includes(this.logicalOperators.LESS_THAN)) {
            operator = this.logicalOperators.LESS_THAN;
        }

        if (this.filterTerm.includes(this.logicalOperators.GREATER_THAN)) {
            operator = this.logicalOperators.GREATER_THAN;
        }

        if (operator) {
            this.filterTerm = this.filterTerm.replace(operator, '');
            if (!this.isValidDate()) {
                this.isValid = false;
                return null;
            }
            return `${this.column.key} ${operator} '${moment(this.filterTerm).format('YYYY-MM-DD')}'`;
        }

        if (this.isNullExpression()) {
            return this.formatNullExpression();
        }

        if (!this.isValidDate()) {
            this.isValid = false;
            return null;
        }

        return `(${this.column.key} = '${this.filterTerm}')`;
    }

    formatStringExpression() {
        this.filterTerm = this.filterTerm.toString().toLowerCase();
        if (!this.isValidString()) {
            this.isValid = false;
            return null;
        }

        if (this.isNullExpression()) {
            return this.formatNullExpression();
        }

        if (this.filterTerm.includes(this.logicalOperators.NOT_EQUAL)) {
            return `(${this.column.key} ${this.logicalOperators.NOT_EQUAL} '${this.filterTerm.replace(this.logicalOperators.NOT_EQUAL, '')}')`;
        }

        return `(${this.column.key} LIKE '%${this.filterTerm}%')`;
    }

    formatNumericExpression() {
        let operator;

        if (this.isNullExpression()) {
            return this.formatNullExpression();
        }

        if (this.filterTerm.includes(this.logicalOperators.NOT_EQUAL)) {
            operator = this.logicalOperators.NOT_EQUAL;
        }

        if (this.filterTerm.includes(this.logicalOperators.LESS_THAN_EQUAL_TO)) {
            operator = this.logicalOperators.LESS_THAN_EQUAL_TO;
        }

        if (this.filterTerm.includes(this.logicalOperators.GREATER_THAN_EQUAL_TO)) {
            operator = this.logicalOperators.GREATER_THAN_EQUAL_TO;
        }

        if (this.filterTerm.includes(this.logicalOperators.LESS_THAN)) {
            operator = this.logicalOperators.LESS_THAN;
        }

        if (this.filterTerm.includes(this.logicalOperators.GREATER_THAN)) {
            operator = this.logicalOperators.GREATER_THAN;
        }

        if (operator) {
            this.filterTerm = this.filterTerm.replace(operator, '');
            return `(${this.column.key} ${operator} ${this.filterTerm})`;
        }

        return `(${this.column.key} LIKE '%${this.filterTerm}%')`;
    }

    isValidDate() {
        return moment(this.filterTerm.replace(/\//g, '-'), ['M-DD-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-M-DD'], true).isValid();
    }

    isValidString() {
        return this.filterTerm !== '';
    }

    isNullExpression() {
        return this.filterTerm.toLowerCase() === this.logicalOperators.NULL
        || this.filterTerm.toLowerCase() === this.logicalOperators.NOT_NULL;
    }

    formatNullExpression() {
        if (this.filterTerm.toLowerCase() === this.logicalOperators.NULL) {
            return `(${this.column.key} IS NULL)`;
        }
        return `(${this.column.key} IS NOT NULL)`;
    }
}
