import PropTypes from 'prop-types';

export default class CoordinatePair {
    constructor(latitude, longitude) {
        this.latitude = latitude;
        this.longitude = longitude;

        this.minimumLatitude = -90;
        this.maximumLatitude = 90;
        this.minimumLongitude = -180;
        this.maximumLongitude = 180;
    }

    validate() {
        if (!Number(this.latitude)) {
            throw new Error('latitude must be a number');
        }

        if (!Number(this.longitude)) {
            throw new Error('longitude must be a number');
        }

        if (this.latitude > this.maximumLatitude || this.latitude < this.minimumLatitude) {
            throw new Error('latitude must be between -90 and 90 degrees');
        }

        if (this.longitude > this.maximumLongitude || this.longitude < this.minimumLongitude) {
            throw new Error('longitude must be between -180 and 180 degrees');
        }
    }
}

CoordinatePair.propTypes = {
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
