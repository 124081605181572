import { useEffect, useState } from 'react';
import { Module } from '../../../_services/GeotrakService/Models';
import useGetModule from '../../../_services/GeotrakService/hooks/useGetModule';
import useGetProjects from '../../../_services/GeotrakService/hooks/useGetProjects';
import useGetUnits from '../../../_services/GeotrakService/hooks/useGetUnits';
import useGetMarkets from '../../../_services/GeotrakService/hooks/useGetMarkets';

const useModuleForm = (id) => {
    const { projects } = useGetProjects();
    const [units] = useGetUnits();
    const { markets } = useGetMarkets();
    let module = new Module();
    let isLoading = false;
    if (id) {
        const result = useGetModule(id);
        module = result.module;
        isLoading = result.isLoading;
    }
    const [values, setValues] = useState({
        id: null,
        name: '',
        alias: '',
        projectId: '',
        unitTypeId: '',
        marketId: '',
    });

    const isValid = () => values.name !== '' && values.alias !== '' && values.projectId !== '' && values.unitTypeId !== '';

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                id: module.id || null,
                name: module.name || '',
                alias: module.alias || '',
                projectId: module.project.id || '',
                unitTypeId: module.unit.id || '',
                marketId: module.market.id || '',
            });
        }
    }, [module]);

    return {
        values, isValid, onFormUpdate, isLoading, projects, units, markets,
    };
};

export default useModuleForm;
