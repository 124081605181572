import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaBookmark } from 'react-icons/fa';
import './DataSourceBookmark.css';
import { DataSource, FavoriteDataSource } from '../../_services/GeotrakService/Models';
import { DataSourceService } from '../../_services/GeotrakService';
import ICreateFavoriteDataSourceRequest from '../../_services/GeotrakService/interfaces/ICreateFavoriteDataSourceRequest';

const dataSourceBookmark = ({
    favoriteDataSource, dataSource, moduleRoleId, onFavoriteSelected,
}) => {
    const [error, setError] = useState(null);
    const dataSourceService = new DataSourceService();

    const addFavorite = async () => {
        try {
            const favorite = new FavoriteDataSource();
            favorite.dataSourceId = dataSource.id;
            const request = ICreateFavoriteDataSourceRequest.load(favorite, moduleRoleId);
            const result = await dataSourceService.createFavoriteDataSource(request);
            onFavoriteSelected(result);
            setError(null);
        } catch (err) {
            setError(err);
        }
    };

    const removeFavorite = async () => {
        try {
            await dataSourceService.deleteFavoriteDataSource(favoriteDataSource.id);
            onFavoriteSelected(new FavoriteDataSource());
            setError(null);
        } catch (err) {
            setError(err);
        }
    };

    const toggleFavorite = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (favoriteDataSource.dataSourceId === dataSource.id) {
            removeFavorite();
        } else {
            addFavorite();
        }
    };

    return (
        <span>
            {error && <span className="text-danger">{`ERROR: ${error.message || 'Error Updating Favorite'}`}</span>}
            <span
                id="data-source-bookmark"
                onClick={toggleFavorite}
                role="button"
                tabIndex={0}
                aria-label="Favorite"
                onKeyDown={toggleFavorite}
            >
                <FaBookmark
                    size={15}
                    id="data-source-bookmark-icon"
                    className={favoriteDataSource.dataSourceId === dataSource.id ? 'data-source-bookmark-selected' : 'data-source-bookmark'}
                />
            </span>

        </span>
    );
};

export default dataSourceBookmark;

dataSourceBookmark.propTypes = {
    favoriteDataSource: PropTypes.instanceOf(FavoriteDataSource),
    dataSource: PropTypes.instanceOf(DataSource),
    moduleRoleId: PropTypes.string,
    onFavoriteSelected: PropTypes.func,
};

dataSourceBookmark.defaultProps = {
    favoriteDataSource: new FavoriteDataSource(),
    dataSource: new DataSource(),
    moduleRoleId: null,
    onFavoriteSelected: () => null,
};
