import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class EtlStatus {
    constructor() {
        this.lastUpdatedDate = null;
    }

    static load(response) {
        const etlStatus = new EtlStatus();

        if (response) {
            etlStatus.lastUpdatedDate = response.endedOn;
        }

        return etlStatus;
    }

    formatDate() {
        const utcObj = datetimes.toUTC(this.lastUpdatedDate);
        this.lastUpdatedDate = datetimes.toLocal(utcObj.format(), '', 'MM/DD/YYYY hh:mm a z');

        if (this.lastUpdatedDate === 'Invalid date') {
            throw new Error('The ETL status last updated date is invalid');
        }
    }
}

EtlStatus.propTypes = {
    lastUpdated: PropTypes.date,
};
