import BaseCheckRequestService from './BaseCheckRequestService';
import CheckRequestReport from './Models/CheckRequestReport';

export default class CheckReportService extends BaseCheckRequestService {
    getReport(report, queryParams = '') {
        return this.fetch(`/check-request-reports/report/${report}?${queryParams}`).then(
            (results) => results.map((checkreport) => new CheckRequestReport(checkreport))
        );
    }
}
