import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addAlertThunk } from '../../../actions/errorActions';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { DataSource, View } from '../../../_services/GeotrakService/Models';
import IUpdateUserViewFieldsRequest from '../../../_services/GeotrakService/interfaces/IUpdateUserViewFieldsRequest';
import { saveView } from '../../../actions/viewActions';
import { ViewService } from '../../../_services/GeotrakService';

const createButton = ({
    disabled, view, closeDrawer, dataSource,
}) => {
    const [isCreating, setIsCreating] = useState(false);
    const viewService = new ViewService();
    const dispatch = useDispatch();

    const createView = () => {
        setIsCreating(true);
        view.stripDataSourceQuery(dataSource);
        const request = IUpdateUserViewFieldsRequest.load(view, dataSource.id);
        viewService.updateUserViewFields(request).then((response) => {
            dispatch(saveView(response, dataSource));
            setIsCreating(false);
            closeDrawer();
        }).catch(() => {
            setIsCreating(false);
            dispatch(addAlertThunk('danger', 'Error Creating View', 'Make sure your view names and default statuses are unique'));
        });
    };

    return (
        <LoaderButton
            text="Save As New"
            isLoading={isCreating}
            loadingText="Saving..."
            onClick={createView}
            disabled={disabled}
        />
    );
};

export default createButton;

createButton.propTypes = {
    disabled: PropTypes.bool,
    view: PropTypes.instanceOf(View),
    closeDrawer: PropTypes.func,
    dataSource: PropTypes.instanceOf(DataSource),
};

createButton.defaultProps = {
    disabled: true,
    view: new View(),
    closeDrawer: () => null,
    dataSource: new DataSource(),
};
