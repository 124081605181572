import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addAlertThunk } from '../../../actions/errorActions';
import { CheckRequestActionService, CheckRequestService, DocumentService } from '../../../_services';
import LoaderButton from '../../../utilComponents/LoaderButton';
import Log from '../../../utilComponents/Log';
import AggregateError from '../../../errors/AggregateError';
import ValidationError from '../../../errors/ValidationError';
import IDownloadFileRequest from '../../../_services/DocumentService/interfaces/IDownloadFileRequest';
import { dismissAlerts } from '../../../utilComponents/AlertSystem';

class Create extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isWorking: false,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
        this.CheckRequestService = new CheckRequestService();
        this.documentService = new DocumentService();
    }

  displayAlert = (passed, title, message, error) => {
      const { addAlertAction } = this.props;
      if (passed) {
          addAlertAction('success', title, message);
      } else {
          addAlertAction('danger', title, error ?? message);
      }
  }

  download = (result) => {
      const { addAlertAction } = this.props;
      const {
          data: {
              upload: {
                  result: {
                      s3Key,
                      fileName,
                  },
              },
          },
      } = result;
      const request = IDownloadFileRequest.load(fileName, s3Key);
      return this.documentService.downloadFile(request)
          .then(() => {
              addAlertAction('success', 'Downloaded PDF ', fileName);
              return result;
          })
          .catch((err) => {
              addAlertAction('danger', 'Download PDF Failed', err.message);
              throw err;
          });
  }

  clearAlerts = (result) => {
      dismissAlerts();
      return result;
  }

  displayAndValidateAlerts = (result) => {
      const {
          data: {
              sendEmails,
          },
          results,
          rollback,
      } = result;
      Log.info(results);
      results.forEach((r) => this.displayAlert(r.passed, r.name, r.message));

      if (!sendEmails.passed && !rollback) {
          this.download(result);
          this.displayAlert(
              false,
              'Email Notification Failed',
              'The email failed for this request, but we processed the transaction anyway. '
                + 'The PDF is downloaded in case you want to email the notification manually.'
          );
      }
      if (rollback) {
          throw new ValidationError([{
              field: 'Create Check Request Aborted',
              error: 'Due to errors, we have cancelled this request.',
          }]);
      }
      return result;
  }

  create = () => {
      this.setState({ isWorking: true });
      const {
          addAlertAction, requestForm, checkRequestId, refreshQueues,
      } = this.props;

      addAlertAction(
          'warning',
          'Processing Your Create Check Request ...',
          'This should take a few seconds. Please do not leave this screen or refresh until complete.'
      );

      const form = {};
      requestForm.map((field) => {
          form[field.Name] = field.value;
          return field;
      });
      this.CheckRequestActionService
          .createCheck(checkRequestId, form)
          .then((result) => this.clearAlerts(result))
          .then((result) => this.displayAndValidateAlerts(result))
          .then((result) => {
              this.setState({ isWorking: false });
              if (result.success) {
                  return refreshQueues();
              }
              return {};
          })
          .catch((err) => {
              // this.clearAlerts();
              // TODO (moley) 2022-11-29 refactor this and move it into a reusable errorHandler
              this.setState({ isWorking: false });
              Log.error('error submitting check request');
              Log.error(err);
              if (err instanceof ValidationError) {
                  err.errors.forEach((e) => addAlertAction('danger', e.field, e.error));
              } else if (err instanceof AggregateError) {
                  err.errors.forEach((e) => {
                      Log.info(e);
                      addAlertAction('danger', e.type, e.error);
                  });
              } else {
                  addAlertAction('danger', err.type, err.message);
              }
          });
  }

  render() {
      const { isWorking } = this.state;
      return (
          <LoaderButton
              onClick={this.create}
              isLoading={isWorking}
              text="Create Check Request"
              loadingText="Creating..."
          />
      );
  }
}

const mapDispatchToProps = ({
    addAlertAction: addAlertThunk,
});

export default connect(null, mapDispatchToProps)(Create);

Create.propTypes = {
    addAlertAction: PropTypes.func,
    requestForm: PropTypes.instanceOf(Array),
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
};

Create.defaultProps = {
    addAlertAction: () => null,
    requestForm: [],
    checkRequestId: 0,
    refreshQueues: () => null,
};
