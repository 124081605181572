import PropTypes from 'prop-types';

export default class CancellationReason {
    constructor(checkRequest) {
        this.CancellationReason = checkRequest.CancellationReason;
    }
}

CancellationReason.propTypes = {
    CancellationReason: PropTypes.string,
};
