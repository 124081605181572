import PropTypes from 'prop-types';
import RequestFormRole from './RequestFormRole';
import Field from './Field';

export default class RequestFormRoleField {
    constructor(requestFormRole, field, isRequired, id = null) {
        this.requestFormRole = requestFormRole;
        this.field = field;
        this.isRequired = isRequired;
        this.id = id;
    }

    static load(response) {
        return new RequestFormRoleField(
            RequestFormRole.load(response.RequestFormRole),
            Field.load(response.Field),
            response.IsRequired,
            response.Id
        );
    }

    loadSqlEntity() {
        const sqlEntity = {
            RequestFormRoleId: this.requestFormRole.id,
            FieldId: this.field.id,
            IsRequired: this.isRequired,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }

    toggleIsRequired() {
        this.isRequired = !this.isRequired;
    }
}

RequestFormRoleField.propTypes = {
    requestFormRole: PropTypes.instanceOf(RequestFormRole),
    field: PropTypes.instanceOf(Field),
    isRequired: PropTypes.bool,
    id: PropTypes.number,
};
