import { useReducer } from 'react';
import ProjectService from '../ProjectService';
import IUpdateProjectRequest from '../interfaces/IUpdateProjectRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateProject = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const updateProject = async (projectObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateProjectRequest.load(projectObject);
            const result = await new ProjectService().updateProject(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateProject, error, isLoading, isSuccessful, data,
    };
};

export default useUpdateProject;
