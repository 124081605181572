import UserService from '../_services/UserService/UserService';
import { getEnvironment } from './utilities';

const flaskPort = 5000;
export const flaskEndpoint = getEnvironment(`https://api${process.env.REACT_APP_ENV_PREFIX}.geotrak.io`, flaskPort);

export const acquireFlaskToken = async () => {
    const us = new UserService();
    return us.getUserClaim('flask').key;
};

/**
 * Temporary. Custom header options for flask services.
 */
export const getFlaskHeaders = async () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${await acquireFlaskToken()}`,
});

export const handleFetchErrors = (response) => {
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
};
