import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import Pagination from 'react-js-pagination';
import { MdFileDownload } from 'react-icons/md';
import { Toolbar } from 'react-data-grid-addons';
import RecordPlaceholder from '../DataGrid/RecordPlaceholder';
import { ButtonPrimary } from '../../utilComponents/styledComponents';
import LoadingFullScreen from '../../utilComponents/LoadingFullScreen';
import { CheckRequestConfigService, CheckRequestDownloadService, CheckRequestService } from '../../_services/CheckRequestService';
import ICheckRequestsRequest from '../../_services/CheckRequestService/interfaces/ICheckRequestsRequest';
import IDownloadChecksRequest from '../../_services/CheckRequestService/interfaces/IDownloadChecksRequest';
import Column from '../DataGrid/Models/Column';
import CheckRequestDetail from '../../containers/CheckRequest/CheckRequestDetail';
import withCurrentUser from '../../utilComponents/hocs/withCurrentUser';
import CheckRequestSummary from '../../_services/CheckRequestService/Models/CheckRequestSummary';
import '../stylesheets/CheckRequestTable.css';
import ErrorMessage from '../../utilComponents/ErrorMessage';

const checkRequestConfigService = new CheckRequestConfigService();
const checkRequestService = new CheckRequestService();
const checkRequestDownloadService = new CheckRequestDownloadService();

const checkRequestTable = ({
    subset, role, refreshQueues, user, tableWidth,
}) => {
    const [columns, setColumns] = useState([]);
    const [checkRequests, setCheckRequests] = useState([]);
    const [pager, setPager] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [selectedCheckId, setSelectedCheckId] = useState(null);
    const [filterExpressions, setFilterExpressions] = useState([]);
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);

    const getFields = () => {
        checkRequestConfigService.getTableFields().then((results) => {
            const columnRecords = results.map((field) => {
                const column = new Column(field.key, field.name);
                if (field.name === 'Check Request Id') {
                    column.makeHyperlink((value) => setSelectedCheckId(value));
                }
                column.useDefaultWidth();
                return column;
            });
            setColumns(columnRecords);
        }).catch((err) => {
            setError(`Error Getting Data. ${err.message || err}`);
        });
    };

    const getData = (pageNumber = 1) => {
        const requestBody = ICheckRequestsRequest.load(user, filterExpressions, pageNumber, subset, role, order);
        checkRequestService.getCheckRequests(requestBody).then((results) => {
            const formattedResults = results.pageOfItems.map((result) => new CheckRequestSummary(result));
            setCheckRequests(formattedResults);
            setPager(results.pager);
            setActivePage(pageNumber);
            setIsLoading(false);
        }).catch((err) => {
            setError(`Error Getting Data. ${err.message || err}`);
        });
    };

    const download = async () => {
        const requestBody = IDownloadChecksRequest.load(user, filterExpressions, subset, role, order);
        checkRequestDownloadService.downloadRequests(requestBody).then().catch((err) => {
            setError(`Error Downloading Requests. ${err.message || err}`);
        });
    };

    const onFilterChange = (filterInput) => {
        const filterTerm = { [filterInput.column.key]: filterInput.filterTerm };
        const updatedFilterExpressions = filterExpressions.filter(
            (expression) => !Object.keys(expression).includes(filterInput.column.key)
        );
        const finalExpressions = filterTerm[filterInput.column.key] !== '' ? [...updatedFilterExpressions, filterTerm] : updatedFilterExpressions;
        setFilterExpressions(finalExpressions);
    };

    const onSort = (column, direction) => {
        setOrder(direction === 'NONE' ? null : [column, direction]);
    };

    const refresh = () => {
        setSelectedCheckId(null);
        refreshQueues();
        getData();
    };

    useEffect(() => {
        getFields();
        getData();
    }, [filterExpressions, order]);

    if (!isLoading) {
        return (
            <div id="check-request-rdg-parent">
                {error && (
                    <ErrorMessage
                        message={error}
                        onDismiss={() => setError(null)}
                    />
                )}
                {(pager && checkRequests.length > 0) && (
                    <span className="float-end d-flex">
                        {`Showing ${pager.startIndex + 1} to ${pager.endIndex + 1} of ${pager.totalItems} records`}
                    </span>
                )}
                <ReactDataGrid
                    className="check-request-data-grid"
                    columns={columns}
                    rowsCount={checkRequests.length}
                    rowGetter={(i) => checkRequests[i]}
                    minHeight={650}
                    emptyRowsView={RecordPlaceholder}
                    onAddFilter={onFilterChange}
                    minWidth={tableWidth}
                    onClearFilters={() => setFilterExpressions([])}
                    onGridSort={onSort}
                    toolbar={(
                        <Toolbar enableFilter>
                            <ButtonPrimary className="ms-2" onClick={download}>
                                <MdFileDownload size={20} />
                            </ButtonPrimary>
                        </Toolbar>
                    )}
                />
                <div className="col-md-12 card-footer pb-0 pt-3 text-center">
                    {pager && (
                        <Pagination
                            activePage={activePage}
                            totalItemsCount={pager.recordCount}
                            pageRangeDisplayed={10}
                            itemsCountPerPage={pager.pageSize}
                            minRows={0}
                            onChange={(pageNumber) => getData(pageNumber)}
                            id="pager"
                            itemClass="page-item"
                            linkClass="page-link"
                            className="bg-primary"
                        />
                    )}
                </div>
                {selectedCheckId && (
                    <CheckRequestDetail
                        checkRequestId={selectedCheckId}
                        allowEdits={role !== null}
                        allowFileDeletes={role === 'B'}
                        showVoidOptions={subset === 'Complete'}
                        allowFileUploads={subset !== 'In Progress'}
                        role={role}
                        refreshQueues={refresh}
                        onCancel={() => setSelectedCheckId(null)}
                    />
                )}
            </div>
        );
    }
    return <LoadingFullScreen />;
};

export default withCurrentUser(checkRequestTable);

checkRequestTable.propTypes = {
    refreshQueues: PropTypes.func,
    tableWidth: PropTypes.number,
};

checkRequestTable.defaultProps = {
    refreshQueues: () => null,
    tableWidth: null,
};
