import PropTypes from 'prop-types';
import { MapConfig } from '../Models';

class ICreateMapRequest {
    constructor() {
        this.data = {};
    }

    static load(map) {
        if (!map) {
            throw Error('map is required');
        }

        if (!(map instanceof MapConfig)) {
            throw Error('Unexpected type for map');
        }

        const request = new ICreateMapRequest();
        request.data = map;
        return request;
    }
}

export default ICreateMapRequest;

ICreateMapRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
