import { SET_ID_COLLECTION, CLEAR_ID_COLLECTION } from '../CONSTANTS/types';

export const setIdCollection = (selectedFeatureCollection) => ({
    type: SET_ID_COLLECTION,
    selectedFeatureCollection,
});

export const clearIdCollection = () => ({
    type: CLEAR_ID_COLLECTION,
});
