import PropTypes from 'prop-types';
import Module from './Module';

export default class Role {
    constructor(id, name, alias, module) {
        this.id = id;
        this.module = module;
        this.name = name;
        this.alias = alias;
    }

    static load(role) {
        return new Role(
            role.id,
            role.name,
            role.alias,
            Module.load(role.module)
        );
    }

    loadSqlEntity() {
        const sqlEntity = {
            moduleId: this.module.id,
            name: this.name,
            alias: this.alias,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }

    static loadFromGeoTrakKey(key, module) {
        return new Role(null, key.roleId, null, module);
    }
}

Role.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    alias: PropTypes.string,
    module: PropTypes.instanceOf(Module),
};
