import React, { Component } from 'react';
import { UserService } from '../../_services';

const withCurrentUser = (WrappedComponent) => {
    class CurrentUserComponent extends Component {
        constructor(props) {
            super(props);

            const userService = new UserService();
            this.user = userService.getCurrentUser();
        }

        render() {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <WrappedComponent {...this.props} user={this.user} />;
        }
    }

    return CurrentUserComponent;
};

export default withCurrentUser;
