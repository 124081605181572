import React from 'react';
import { ButtonContainer } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useNotificationFieldsForm from './useNotificationFieldsForm';
import { CreateField, DeleteField } from './Actions';
import { NotificationField } from '../../../_services/NotificationService/Models';
import useGetNotification from '../../../_services/NotificationService/hooks/useGetNotification';
import SelectField from '../../../utilComponents/SelectField';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminNotificationsFieldsForm = ({ match }) => {
    const { params: { id, fieldId } } = match;
    const {
        values, onFormUpdate, isLoading, isValid,
    } = useNotificationFieldsForm(fieldId);
    const { notification } = useGetNotification(id);

    if (isLoading) {
        return <LoadingFullScreen id="notification-field-form-loader" />;
    }

    const notificationField = new NotificationField();
    notificationField.name = values.name;
    notificationField.notificationId = id;
    notificationField.id = fieldId;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Notification Fields">
                <form>
                    <SelectField
                        database={notification.database}
                        table={notification.table}
                        selectedSchema={notification.schema}
                        selectedField={values.name}
                        onFieldSelected={(e) => onFormUpdate('name', e)}
                        disabled={!!fieldId}
                    />
                    <ButtonContainer>
                        <CreateField notificationField={notificationField} isValid={isValid} />
                        <DeleteField notificationField={notificationField} />
                    </ButtonContainer>
                </form>
            </AdminContainer>
        </>
    );
};

export default adminNotificationsFieldsForm;
