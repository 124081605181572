import { useEffect, useState } from 'react';
import useGetRenderer from '../../../_services/GeotrakService/hooks/useGetRenderer';

const useRendererForm = (dataSourceId) => {
    const { renderer, isLoading } = useGetRenderer(dataSourceId);
    const [values, setValues] = useState({
        dataSourceId,
        id: null,
        size: '',
        color: '#ffffff',
    });

    const isValid = () => Number.isInteger(parseInt(values.size, 10)) && values.color;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        setValues({
            dataSourceId,
            id: renderer.id,
            size: renderer.size || '',
            color: renderer.color || '#ffffff',
        });
    }, [renderer]);

    return {
        values, isValid, onFormUpdate, isLoading,
    };
};

export default useRendererForm;
