import { useReducer } from 'react';
import DocumentTypeService from '../DocumentTypeService';
import IUpdateDocumentTypeRequest from '../interfaces/IUpdateDocumentTypeRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateDocumentType = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const updateDocumentType = async (documentTypeObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateDocumentTypeRequest.load(documentTypeObject);
            const result = await new DocumentTypeService().updateDocumentType(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateDocumentType, error, isLoading, isSuccessful, data,
    };
};

export default useUpdateDocumentType;
