import { useEffect, useReducer } from 'react';
import { useApiReducer, ACTIONS } from '../../../../helpers/hooks/useApiReducer';
import NotificationUserService from '../../NotificationUserService';

const useGetNotificationUsers = (notificationId) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: [] });
    const { data, isLoading, error } = state;

    const getNotificationUsers = async () => {
        try {
            const results = await new NotificationUserService().getNotificationUsers(notificationId);
            dispatch({ type: ACTIONS.SUCCESS, data: results });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getNotificationUsers();
    }, []);

    return { notificationUsers: data, error, isLoading };
};

export default useGetNotificationUsers;
