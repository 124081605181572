import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestActionService } from '../../../_services';

class Approve extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isWorking: false,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  approve = () => {
      this.setState({ isWorking: true });
      const {
          role, requestForm, checkRequestId, refreshQueues, onError,
      } = this.props;
      const form = {};
      requestForm.map((field) => {
          form[field.Name] = field.value;
          return field;
      });
      this.CheckRequestActionService.approveRequest(role, checkRequestId, form).then(() => {
          this.setState({ isWorking: false });
          refreshQueues();
      }).catch((err) => {
          this.setState({ isWorking: false });
          onError(err.message || 'An error occurred approving request.');
      });
  }

  render() {
      const { isWorking } = this.state;
      const { isReadyToSubmit } = this.props;
      return (
          <LoaderButton
              onClick={this.approve}
              isLoading={isWorking}
              disabled={isReadyToSubmit()}
              text="Approve"
              loadingText="Approving…"
          />
      );
  }
}

export default Approve;

Approve.propTypes = {
    requestForm: PropTypes.instanceOf(Array),
    role: PropTypes.string,
    checkRequestId: PropTypes.number,
    isReadyToSubmit: PropTypes.func,
    refreshQueues: PropTypes.func,
    onError: PropTypes.func,
};

Approve.defaultProps = {
    requestForm: [],
    role: '',
    checkRequestId: 0,
    isReadyToSubmit: () => null,
    refreshQueues: () => null,
    onError: () => null,
};
