import BaseGeoTrakService from './BaseGeoTrakService';
import { Renderer } from './Models';

export default class RendererService extends BaseGeoTrakService {
    async getRenderer(request) {
        return this.fetch(`/renderers/data-source/${request.dataSourceId}`).then(
            (result) => Renderer.load(result)
        );
    }

    async createRenderer(request) {
        const { dataSourceId, data } = request;
        return this.post(`/renderers/data-source/${dataSourceId}`, data.loadSqlEntity());
    }

    async updateRenderer(request) {
        const { id, data } = request;
        return this.put(`/renderers/${id}`, data);
    }

    async deleteRenderer(id) {
        return this.delete(`/renderers/${id}`);
    }
}
