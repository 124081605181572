import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../utilComponents/LoaderButton';
import '../stylesheets/Search.css';
import GeocodeService from '../../_services/GISService/GeocodeService';
import IGeocodeRequest from '../../_services/GISService/interfaces/IGeocodeRequest';
import { ButtonContainer } from '../../utilComponents/styledComponents';

const searchLocation = ({ onMapReposition, setError }) => {
    const [location, setLocation] = useState('');
    const [isWorking, setWorking] = useState(false);
    const geocoder = new GeocodeService();

    const search = () => {
        setWorking(true);
        const request = IGeocodeRequest.load(location);
        geocoder.addressToLatLng(request).then((response) => {
            onMapReposition(response);
            setWorking(false);
        }).catch((err) => {
            setWorking(false);
            setError(err.message || err);
        });
    };

    return (
        <div className="container">
            <p className="instructions text-center">
                Enter an address or lat, long to search for location.
            </p>

            <div className="row justify-content-center col-md-8 offset-md-2 pb-2">
                <input
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="123 Sesame Street"
                />
            </div>
            <div className="row">
                <ButtonContainer>
                    <LoaderButton
                        isLoading={isWorking}
                        text="Search"
                        loadingText="Searching..."
                        onClick={search}
                    />
                </ButtonContainer>
            </div>
        </div>
    );
};

export default searchLocation;

searchLocation.propTypes = {
    onMapReposition: PropTypes.func,
};

searchLocation.defaultProps = {
    onMapReposition: () => null,
};
