import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { Renderer } from '../../../_services/GeotrakService/Models';
import { Create, Update, Delete } from './Actions';
import useRendererForm from './useRendererForm';
import './renderers.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminRenderers = ({ match }) => {
    const { params: { dataSourceId, moduleId, roleId } } = match;
    const {
        values, isValid, onFormUpdate, isLoading,
    } = useRendererForm(dataSourceId);

    if (isLoading) {
        return <LoadingFullScreen id="renderer-loader" />;
    }

    const renderer = new Renderer();
    renderer.dataSourceId = values.dataSourceId;
    renderer.size = values.size;
    renderer.color = values.color;
    renderer.id = values.id;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Renderer">
                <form>
                    <div className="mb-3">
                        <label htmlFor="select-renderer-size" className="form-label">
                            Size:
                        </label>
                        <select
                            id="select-renderer-size"
                            value={values.size}
                            onChange={(e) => onFormUpdate('size', e.target.value)}
                        >
                            <option value="">--Select a size--</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3 (standard)</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </select>
                    </div>
                    <div className="renderer-color-picker">
                        <label htmlFor="select-renderer-color" className="form-label">
                            Color:
                        </label>
                        <input
                            id="select-renderer-color"
                            type="color"
                            value={values.color}
                            onChange={(evt) => { onFormUpdate('color', evt.target.value); }}
                        />
                    </div>
                </form>
                <div className="renderer-toolbar">
                    <Create
                        renderer={renderer}
                        isValid={isValid}
                        moduleId={moduleId}
                        roleId={roleId}
                    />
                    <Update
                        renderer={renderer}
                        isValid={isValid}
                        moduleId={moduleId}
                        roleId={roleId}
                    />
                    <Delete
                        renderer={renderer}
                        moduleId={moduleId}
                        roleId={roleId}
                    />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminRenderers;
