import PropTypes from 'prop-types';

export default class FavoriteDataSource {
    constructor() {
        this.id = null;
        this.dataSourceId = null;
    }

    static load(response) {
        const favoriteDataSource = new FavoriteDataSource();

        if (response) {
            favoriteDataSource.id = response.id;
            favoriteDataSource.dataSourceId = response.dataSourceId;
        }

        return favoriteDataSource;
    }

    loadSqlEntity() {
        return {
            dataSourceId: this.dataSourceId,
        };
    }
}

FavoriteDataSource.propTypes = {
    id: PropTypes.string,
    dataSourceId: PropTypes.string,
};
