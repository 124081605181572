import PropTypes from 'prop-types';

export default class FormField {
    constructor(id, formId, fieldName, orderIndex, width) {
        this.id = id || '00000000-0000-0000-0000-000000000000';
        this.formId = formId;
        this.fieldName = fieldName;
        this.orderIndex = orderIndex;
        this.width = width;
    }
}

FormField.propTypes = {
    id: PropTypes.string,
    formId: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    orderIndex: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
};
