import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import Modal from '../../Modals/Modal';
import { ViewService } from '../../../_services/GeotrakService';
import { DataSource, View } from '../../../_services/GeotrakService/Models';
import IDeleteUserViewRequest from '../../../_services/GeotrakService/interfaces/IDeleteUserViewRequest';
import { addAlertThunk } from '../../../actions/errorActions';
import { setActiveDefaultViewThunk } from '../../../actions/viewActions';

const deleteButton = ({
    disabled, closeDrawer, view, dataSource,
}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const viewService = new ViewService();
    const dispatch = useDispatch();
    const deleteView = () => {
        setIsDeleting(true);
        const request = IDeleteUserViewRequest.load(dataSource.id, view);
        viewService.deleteUserView(request).then(() => {
            setIsDeleting(false);
            setShowConfirmation(false);
            closeDrawer();
            dispatch(setActiveDefaultViewThunk(dataSource));
            dispatch(addAlertThunk('success', 'Success!', 'View Deleted'));
        }).catch((err) => {
            setIsDeleting(false);
            setShowConfirmation(false);
            dispatch(addAlertThunk('danger', 'Error Deleting View', err || err.message));
        });
    };

    return (
        <>
            <LoaderButton
                danger
                text="Delete"
                isLoading={isDeleting}
                loadingText="Deleting..."
                disabled={disabled}
                onClick={() => setShowConfirmation(true)}
            />
            <Modal
                showModal={showConfirmation}
                header="Wait!"
                onCancel={() => setShowConfirmation(false)}
                component={
                    <h5 className="text-center">Are you sure you want to delete this view?</h5>
                }
                footer={(
                    <LoaderButton
                        danger
                        text="Delete"
                        loadingText="Deleting..."
                        isLoading={isDeleting}
                        onClick={deleteView}
                    />
                )}
                displayFullScreen={false}
            />
        </>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    disabled: PropTypes.bool,
    closeDrawer: PropTypes.func,
    view: PropTypes.instanceOf(View),
    dataSource: PropTypes.instanceOf(DataSource),
};

deleteButton.defaultProps = {
    disabled: true,
    closeDrawer: () => null,
    view: new View(),
    dataSource: new DataSource(),
};
