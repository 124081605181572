import PropTypes from 'prop-types';

export default class NotificationField {
    constructor() {
        this.id = null;
        this.name = null;
        this.notificationId = null;
    }

    static load(response) {
        const notificationField = new NotificationField();

        if (response) {
            notificationField.id = response.NotificationsGeoTrakFieldId;
            notificationField.name = response.FieldName;
            notificationField.notificationId = response.NotificationId;
        }

        return notificationField;
    }

    loadSqlEntity() {
        const sqlEntity = {
            FieldName: this.name,
            NotificationId: this.notificationId,
        };

        if (this.id) {
            sqlEntity.NotificationsGeoTrakFieldId = this.id;
        }

        return sqlEntity;
    }
}

NotificationField.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    notificationId: PropTypes.number,
};
