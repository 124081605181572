import { useEffect, useState } from 'react';
import useGetAutopopulation from '../../../_services/GeotrakService/hooks/useGetAutopopulation';
import { Autopopulation } from '../../../_services/GeotrakService/Models';

const useAutopopulationForm = (id) => {
    let autopopulation = new Autopopulation();
    let isLoading = false;

    if (id) {
        const result = useGetAutopopulation(id);
        autopopulation = result.autopopulation;
        isLoading = result.isLoading;
    }

    const [values, setValues] = useState({
        id: null,
        field: '',
        value: '',
        condition: '',
        database: '',
        table: '',
    });

    const isReferenceValid = () => values.database !== '' && values.table !== '' && values.field;

    const isConditionValid = () => values.condition !== '' && values.value !== '';

    const isValid = () => isReferenceValid() && isConditionValid();

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                id: autopopulation.id || null,
                field: autopopulation.field || '',
                value: autopopulation.value || '',
                condition: autopopulation.condition || '',
                database: autopopulation.database || '',
                table: autopopulation.table || '',
            });
        }
    }, [autopopulation]);

    return {
        values,
        isLoading,
        onFormUpdate,
        isValid,
    };
};

export default useAutopopulationForm;
