import BaseNotificationService from './BaseNotificationService';
import { NotificationUser } from './Models';

export default class NotificationUserService extends BaseNotificationService {
    async getNotificationUsers(notificationId) {
        return this.fetch(`/notification/${notificationId}/users`)
            .then((users) => users.map((user) => NotificationUser.load(user)));
    }

    async getNotificationUser(id) {
        return this.fetch(`/notification-users/${id}`).then((user) => NotificationUser.load(user));
    }

    async createNotificationUser(request) {
        const { notificationId, data } = request;
        return this.post(`/notification/${notificationId}/users`, data.loadSqlEntity())
            .then((user) => NotificationUser.load(user));
    }

    async deleteNotificationUser(id) {
        return this.delete(`/notification-users/${id}`);
    }
}
