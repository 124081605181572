import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { DataSourceDocumentType } from '../../../_services/GeotrakService/Models';
import useDataSourceDocumentTypeForm from './useDataSourceDocumentTypeForm';
import { Create, Delete } from './DataSourceDocumentTypeActions';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDataSourceDocumentTypesForm = ({ match }) => {
    const {
        params: {
            dataSourceId, documentTypeId, moduleId, roleId,
        },
    } = match;
    const {
        isLoading, isValid, values, documentTypes, onFormUpdate,
    } = useDataSourceDocumentTypeForm(dataSourceId, documentTypeId || null);

    if (isLoading) {
        return <LoadingFullScreen id="document-type-form-loader" />;
    }

    const dataSourceDocumentType = new DataSourceDocumentType();
    dataSourceDocumentType.documentType.id = values.documentTypeId;
    dataSourceDocumentType.dataSourceId = dataSourceId;

    if (documentTypeId) {
        dataSourceDocumentType.id = documentTypeId;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Data Source Document Type">
                <form>
                    <div className="mb-3">
                        <label htmlFor="document-type-name" className="form-label">
                            Name
                        </label>
                        <select
                            id="select-document-type"
                            disabled={dataSourceDocumentType.id !== null}
                            value={values.documentTypeId}
                            onChange={(e) => onFormUpdate('documentTypeId', parseInt(e.target.value, 10))}
                        >
                            <option disabled value="">--Select a document type--</option>
                            {documentTypes.map((documentType) => (
                                <option
                                    id="select-document-type-option"
                                    key={documentType.id}
                                    value={documentType.id}
                                >
                                    {documentType.name}
                                </option>
                            ))}
                        </select>
                    </div>

                </form>
                <div className="document-type-toolbar">
                    <Create
                        dataSourceDocumentType={dataSourceDocumentType}
                        isValid={isValid}
                        moduleId={moduleId}
                        roleId={roleId}
                    />
                    <Delete
                        dataSourceDocumentType={dataSourceDocumentType}
                        moduleId={moduleId}
                        roleId={roleId}
                    />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDataSourceDocumentTypesForm;
