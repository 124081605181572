import React from 'react';
import { useHistory } from 'react-router';
import SelectDatabase from '../../../utilComponents/SelectDatabase';
import SelectTable from '../../../utilComponents/SelectTable';
import SelectField from '../../../utilComponents/SelectField';
import SelectSchema from '../../../utilComponents/SelectSchema';
import useNotificationForm from './useNotificationForm';
import { Delete, Update, Create } from './Actions';
import { Notification } from '../../../_services/NotificationService/Models';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import NotificationQueryBuilder from './NotificationQueryBuilder';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import './notifications.css';

const adminNotificationsForm = ({ match }) => {
    const { params: { id } } = match;
    const {
        values, onFormUpdate, isLoading, isValid,
    } = useNotificationForm(id || null);
    const history = useHistory();

    if (isLoading) {
        return <LoadingFullScreen id="notification-form-loader" />;
    }

    const notification = new Notification();
    notification.id = values.id;
    notification.database = values.database;
    notification.schema = values.schema;
    notification.table = values.table;
    notification.uniqueIdField = values.uniqueIdField;
    notification.changeField = values.changeField;
    notification.template = values.template;
    notification.whereClause = values.whereClause;
    notification.isActive = values.isActive;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Notification">
                {notification.id && (
                    <div className="notification-option-toolbar">
                        <ButtonPrimary
                            id="configure-users-button"
                            className="notification-option-button"
                            onClick={() => history.push(`/admin/notifications/${notification.id}/users`)}
                        >
                            Configure Users
                        </ButtonPrimary>
                        <ButtonPrimary
                            id="configure-fields-button"
                            className="notification-option-button"
                            onClick={() => history.push(`/admin/notifications/${notification.id}/fields`)}
                        >
                            Configure Fields
                        </ButtonPrimary>
                        <ButtonPrimary
                            id="configure-domains-button"
                            className="notification-option-button"
                            onClick={() => history.push(`/admin/notifications/${notification.id}/domains`)}
                        >
                            Configure Domains
                        </ButtonPrimary>
                    </div>
                )}
                <form>
                    <SelectDatabase
                        selectedDatabase={values.database}
                        onDatabaseSelected={(e) => onFormUpdate('database', e)}
                    />
                    <SelectSchema
                        selectedDatabase={values.database}
                        selectedSchema={values.schema}
                        onSchemaSelected={(e) => onFormUpdate('schema', e)}
                    />
                    <SelectTable
                        selectedDatabase={values.database}
                        selectedTable={values.table}
                        selectedSchema={values.schema}
                        onTableSelected={(e) => onFormUpdate('table', e)}
                    />
                    <SelectField
                        label="Change Field Name"
                        database={values.database}
                        table={values.table}
                        selectedField={values.changeField}
                        selectedSchema={values.schema}
                        onFieldSelected={(e) => onFormUpdate('changeField', e)}
                    />
                    <SelectField
                        label="Unique ID Field Name"
                        database={values.database}
                        table={values.table}
                        selectedSchema={values.schema}
                        selectedField={values.uniqueIdField}
                        onFieldSelected={(e) => onFormUpdate('uniqueIdField', e)}
                    />
                    <div className="mb-3">
                        <label htmlFor="input-template" className="form-label">
                            Template
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            id="input-template"
                            value={values.template}
                            onChange={(e) => onFormUpdate('template', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-where" className="form-label">
                            Where Clause
                            {' '}
                            <span className="text-danger">*</span>
                            <NotificationQueryBuilder
                                database={values.database}
                                table={values.table}
                                schema={values.schema}
                                whereClause={values.whereClause}
                                onQueryUpdate={(e) => onFormUpdate('whereClause', e)}
                            />
                        </label>
                        <textarea
                            rows={5}
                            id="input-where"
                            value={values.whereClause}
                            onChange={(e) => onFormUpdate('whereClause', e.target.value)}
                        />
                    </div>
                    <div className="form-check mb-3">
                        <label htmlFor="input-active" className="form-check-label">
                            Active
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="input-active"
                                checked={values.isActive}
                                onChange={(e) => onFormUpdate('isActive', e.target.checked)}
                            />
                        </label>
                    </div>
                </form>
                <div className="notification-toolbar">
                    <Create notification={notification} isValid={isValid} />
                    <Update notification={notification} isValid={isValid} />
                    <Delete notification={notification} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminNotificationsForm;
