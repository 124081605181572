import React, { Component } from 'react';
import { connect } from 'react-redux';
import validateFileNames from '../../components/CheckRequest/utils/uploadUtils';
import { UserType, HistoryType } from '../../types';
import { CheckRequestActionService, CheckRequestConfigService, DocumentService } from '../../_services';
import SiteService from '../../_services/SiteService/SiteService';
import FormCard from './FormCard';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';
import InitialRequestForm from './InitialRequestForm';
import { validateEmails } from '../../helpers/utilities';
import withCurrentUser from '../../utilComponents/hocs/withCurrentUser';
import ErrorMessage from '../../utilComponents/ErrorMessage';
import IUploadCheckRequestFileRequest from '../../_services/DocumentService/interfaces/IUploadCheckRequestFileRequest';

class SubmitCheckRequest extends Component {
    constructor(props) {
        super(props);

        const { history } = this.props;
        const params = new URLSearchParams(history.location.search);
        this.state = {
            selectedProject: params.get('project') || null,
            selectedMarket: params.get('market') || null,
            selectedRecordId: params.get('recordId') || null,
            selectedRequestType: null,
            isLoading: false,
            requestStatus: 'pending',
            requestForm: {},
            requiredFormFields: {},
            fieldConfig: [],
            autopopulateDeliverTo: false,
            minimumRequiredDocuments: 0,
            error: null,
        };
        this.CheckRequestActionService = new CheckRequestActionService();
        this.CheckRequestConfigService = new CheckRequestConfigService();
        this.SiteService = new SiteService();
        this.documentService = new DocumentService();
    }

  handleStartRequest = (
      phase,
      project = null,
      market = null,
      recordId = null,
      requestType = null
  ) => {
      this.setState({
          requestStatus: phase,
          selectedProject: project,
          selectedMarket: market,
          selectedRecordId: recordId,
          selectedRequestType: requestType,
          requestForm: {},
          requiredFormFields: {},
      });
      if (phase === 'start') {
          this.addFormFields(project, requestType);
          this.getFileRequirements(project, requestType);
      }
  };

  getFileRequirements = (project, requestType) => {
      this.CheckRequestConfigService.getRequiredDocumentCount(project, requestType)
          .then((minimumRequiredDocuments) => {
              this.setState({ minimumRequiredDocuments });
          });
  };

  handleSelectedDeliverTo = async (deliverToType) => {
      const { requestForm, requiredFormFields } = this.state;
      const deliverTo = await this.CheckRequestConfigService.getDeliverToOffice(deliverToType);
      let autopopulateDeliverTo;
      // autopopulate fields based on lookup info
      if (deliverTo) {
          Object.keys(deliverTo).forEach((field) => {
              requestForm[field] = deliverTo[field];
              requiredFormFields[field] = deliverTo[field];
          });
          delete requiredFormFields.DeliverToPhone;
          delete requiredFormFields.DeliverToCompany;
          autopopulateDeliverTo = true;
      } else {
      // completely different kind of autopopulate...
          requestForm.DeliverToCompany = requestForm.PayableTo;
          requestForm.DeliverToAddress = requestForm.PayeeAddress;
          requestForm.DeliverToCity = requestForm.PayeeCity;
          requestForm.DeliverToState = requestForm.PayeeState;
          requestForm.DeliverToZip = requestForm.PayeeZip;
          requestForm.DeliverToPhone = null;
          requiredFormFields.DeliverToCompany = requestForm.PayableTo;
          requiredFormFields.DeliverToAddress = requestForm.PayeeAddress;
          requiredFormFields.DeliverToCity = requestForm.PayeeCity;
          requiredFormFields.DeliverToState = requestForm.PayeeState;
          requiredFormFields.DeliverToZip = requestForm.PayeeZip;
          requiredFormFields.DeliverToPhone = null;
          autopopulateDeliverTo = false;
      }
      requestForm.DeliverToType = deliverToType;
      requiredFormFields.DeliverToType = deliverToType;
      this.setState({
          requestForm,
          requiredFormFields,
          autopopulateDeliverTo,
      });
  };

  handleFormUpdate = (event) => {
      const { requestForm, requiredFormFields } = this.state;
      const formField = event.target.id;
      const formValue = event.target.value || null;
      if (formField === 'DeliverToType') {
          this.handleSelectedDeliverTo(formValue);
      } else {
          requestForm[formField] = formValue;
          requiredFormFields[formField] = formValue;
      }
      this.setState({ requestForm, requiredFormFields });
  };

  uploadCheckRequestFiles = async (checkRequest, files) => {
      try {
          const promises = files.map((file) => {
              const request = new IUploadCheckRequestFileRequest();
              request.load(checkRequest.CheckRequestId, checkRequest.ProjectSiteId, file);
              return this.documentService.upload(request);
          });
          await Promise.all(promises);
      } catch (err) {
          this.setState({ isLoading: false, error: `Error Uploading File. ${err.message || err}` });
      }
  };

  handleSubmitForm = async (files) => {
      const {
          requestForm,
          selectedRecordId,
          selectedProject,
          selectedMarket,
          selectedRequestType,
          error,
      } = this.state;
      const { user, history } = this.props;
      const deliverToFieldValues = {};
      const form = {
          RequestorEmail: user.emailAddress,
          RequestorPhone: user.phoneNumber,
          ProjectSiteId: selectedRecordId.trim(),
          ProjectTag: selectedProject,
          MarketTag: selectedMarket,
          RequestType: selectedRequestType,
          ...requestForm,
          ...deliverToFieldValues,
      };
      this.setState({ isLoading: true });
      let checkRequestResult = null;
      let errorData;
      if (requestForm.AdditionalEmailsToCopy && !validateEmails(requestForm.AdditionalEmailsToCopy)) {
          return this.setState({ isLoading: false, error: 'Emails entered are not valid. Please use correct format and separate each by a comma.' });
      }

      try {
          validateFileNames(files);

          checkRequestResult = await this.CheckRequestActionService.submitRequest(form);
          await this.uploadCheckRequestFiles(checkRequestResult, files);

          this.setState({ requestForm: {}, requiredFormFields: {}, isLoading: false });
          if (!error && !errorData) {
              history.push('/service-center/finance/submit-check-request/success');
          }
      } catch (err) {
          this.setState({ isLoading: false });
          const { type } = err;
          if (type && type === 'ValidationError') {
              err.errors.forEach((e) => {
                  errorData = `${errorData}. ${e.field}: ${e.error}`;
              });
              this.setState({ error: errorData });
          } else {
              this.setState({ error: `${err.message || err}` });
          }
      }

      return null;
  };

  getRequiredFields = (fields) => {
      const { requestForm } = this.state;
      const requiredFields = fields.filter((field) => field.IsRequired);
      const fieldObject = {};
      requiredFields.forEach((field) => {
          fieldObject[field.Name] = requestForm[field.requestKey];
      });
      return fieldObject;
  };

  addFormFields = (project, requestType) => {
      this.CheckRequestConfigService.getRequestFormFields(project, requestType, 'B').then((fieldConfig) => {
          const requiredFormFields = this.getRequiredFields(fieldConfig);
          this.setState({ requiredFormFields, fieldConfig });
      }).catch((err) => {
          this.setState({ isLoading: false, error: `Error getting request form. ${err.message || err}` });
      });
  };

  render() {
      const {
          selectedRequestType,
          selectedProject,
          selectedMarket,
          selectedRecordId,
          requestStatus,
          requestForm,
          requiredFormFields,
          isLoading,
          fieldConfig,
          autopopulateDeliverTo,
          minimumRequiredDocuments,
          error,
      } = this.state;
      const formattedProject = selectedProject
          ? { project: selectedProject, markets: selectedMarket ? [selectedMarket] : [] } : null;
      return (
          <div className="container-fluid">
              <Breadcrumbs crumbName="Create Check Request" />
              <div className="mt-2 px-5">
                  {requestStatus === 'pending' && (
                      <InitialRequestForm
                          handleStartRequest={this.handleStartRequest}
                          selectedProject={formattedProject}
                          selectedMarket={selectedMarket}
                          selectedRecordId={selectedRecordId}
                          id="initial-check-request-form"
                      />
                  )}
                  {requestStatus === 'start' && (
                      <>
                          <FormCard
                              id="check-request-form"
                              requestForm={requestForm}
                              minimumRequiredDocuments={minimumRequiredDocuments}
                              requiredFormFields={requiredFormFields}
                              selectedRequestType={selectedRequestType}
                              handleFormUpdate={this.handleFormUpdate}
                              handleSubmitForm={this.handleSubmitForm}
                              handleStartRequest={this.handleStartRequest}
                              isLoading={isLoading}
                              fieldConfig={fieldConfig}
                              autopopulateDeliverTo={autopopulateDeliverTo}
                              error={error}
                          />
                          {error && (
                              <ErrorMessage
                                  message={error}
                                  onDismiss={() => this.setState({ error: null })}
                              />
                          )}
                      </>
                  )}
              </div>
          </div>
      );
  }
}

const mapStateToProps = ({
    recordIds,
}) => ({
    recordIds,
});

export default withCurrentUser(connect(mapStateToProps, null)(
    SubmitCheckRequest
));

SubmitCheckRequest.propTypes = {
    user: UserType,
    history: HistoryType.isRequired,
};

SubmitCheckRequest.defaultProps = {
    user: {},
};
