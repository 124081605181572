import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import useUpdateDatabasePassword from '../../../../_services/UserService/hooks/useUpdateDatabasePassword';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import ErrorMessage from '../../../../utilComponents/ErrorMessage';
import withCurrentUser from '../../../../utilComponents/hocs/withCurrentUser';
import GeotrakNotificationManager from '../../../../helpers/GeotrakNotificationManager/GeotrakNotificationManager';

const updateButton = ({
    newPassword, isValid, user,
}) => {
    const {
        updateDatabasePassword, error, isLoading, isSuccessful, clearError,
    } = useUpdateDatabasePassword();
    const geotrakNotificationManager = new GeotrakNotificationManager();

    if (isSuccessful) {
        const { notifications } = geotrakNotificationManager;
        const passwordNotification = notifications.find(
            (notification) => notification.alertType.name === notification.alertTypes.DATABASE_PASSWORD.name
        );
        geotrakNotificationManager.dismissNotification(passwordNotification);
        return <Redirect to={`/user/${user.Id}/profile`} />;
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-end mb-2">
                <LoaderButton
                    text="Update"
                    loadingText="Updating..."
                    isLoading={isLoading}
                    disabled={!isValid() || isSuccessful}
                    onClick={() => updateDatabasePassword(newPassword)}
                />
            </div>
            {(error) && (
                <ErrorMessage message={error.message} onDismiss={() => clearError()} />
            )}
        </div>
    );
};

export default withCurrentUser(updateButton);

updateButton.propTypes = {
    newPassword: PropTypes.string,
    isValid: PropTypes.func,
    user: PropTypes.instanceOf(Object),
};

updateButton.defaultProps = {
    newPassword: '',
    isValid: () => null,
    user: {},
};
