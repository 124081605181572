import React, { Component } from 'react';
import {
    MdFormatListBulleted, MdEdit, MdFunctions, MdGridOn
} from 'react-icons/md';
import NavIconContainer from '../../utilComponents/NavIconContainer';
import { areObjectsEqual } from '../../helpers/utilities';
import { UserType } from '../../types';
import withCurrentUser from '../../utilComponents/hocs/withCurrentUser';

export class FinanceHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasAccess: false,
        };
    }

    componentDidMount() {
        const { user } = this.props;
        if (user && user.roles.find((role) => role.module.unit.Name === 'CheckRequest')) {
            this.updateAccessState();
        }
    }

    componentDidUpdate(prevProps) {
        const { user } = this.props;
        if (!areObjectsEqual(prevProps.user, user) && user && user.roles.find((role) => role.module.unit.Name === 'CheckRequest')) {
            this.updateAccessState();
        }
    }

    updateAccessState = () => {
        this.setState({
            hasAccess: true,
        });
    };

    render() {
        const { hasAccess } = this.state;
        return (
            <div className="col-md-12 d-flex flex-wrap justify-content-center">
                <NavIconContainer
                    link="/service-center/finance/submit-check-request"
                    icon={MdEdit}
                    isAccessible={hasAccess}
                    title="Create Check Request"
                />
                <NavIconContainer
                    link="/service-center/finance/manage-check-requests/#needs-review"
                    icon={MdFormatListBulleted}
                    isAccessible={hasAccess}
                    title="Manage Check Requests"
                />
                <NavIconContainer
                    link="/service-center/finance/report-check-requests"
                    icon={MdFunctions}
                    isAccessible={hasAccess}
                    title="Summarize Check Requests"
                />
                <NavIconContainer
                    link="/service-center/finance/check-request-reports"
                    icon={MdGridOn}
                    isAccessible={hasAccess}
                    title="Check Reports"
                />
            </div>
        );
    }
}

export default withCurrentUser(FinanceHome);

FinanceHome.propTypes = {
    user: UserType,
};

FinanceHome.defaultProps = {
    user: {},
};
