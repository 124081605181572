import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'react-data-grid-addons';
import { useHistory } from 'react-router';
import { UserService } from '../../_services';
import withCurrentUser from '../hocs/withCurrentUser';
import { UserType } from '../../types';
import {
    CreateCheck, FreezeColumn, ViewCheckReports, ViewMap
} from './Actions';
import useGetDataSource from '../../_services/GeotrakService/hooks/useGetDataSource';
import '../stylesheets/ContextMenu.css';

const { ContextMenu: DataGridMenu } = Menu;

const ContextMenu = ({
    columns, idx, onFreezeColumn, rowIdx, match, user, rows,
}) => {
    const history = useHistory();
    const [isRecord, setIsRecord] = useState(true);
    const userService = new UserService();
    const projects = userService.getCheckRequestProjects();
    const activeRole = user.roles.find((role) => role.id === match.params.moduleRoleId);
    const projectName = activeRole.module.project.name;
    const marketName = activeRole.module.market ? activeRole.module.market.name : null;
    const searchParameters = new URLSearchParams(history.location.search);
    const { dataSource } = useGetDataSource(searchParameters.get('data-source'));

    const userHasMarketAccess = (project) => (
        !project.markets.length
        || project.markets.includes(marketName)
        || marketName === null
    );
    const canCreateCheckRequest = projects.filter(
        (project) => project.project === projectName && userHasMarketAccess(project)
    ).length > 0;

    const determineMenuContents = (e) => {
        const childElements = Array.from(e.detail.target.children[0].children);
        const columnHeader = childElements.find((child) => child.id === 'column-lock-label');
        if (columnHeader) {
            setIsRecord(false);
        } else {
            setIsRecord(true);
        }
    };

    return (
        <DataGridMenu onShow={(e) => determineMenuContents(e)}>
            <FreezeColumn column={columns[idx]} onFreezeColumn={onFreezeColumn} />
            {isRecord && <ViewMap row={rows[rowIdx]} dataSource={dataSource} />}
            {(isRecord && canCreateCheckRequest) && (
                <>
                    <CreateCheck row={rows[rowIdx]} match={match} dataSource={dataSource} activeRole={activeRole} />
                    <ViewCheckReports row={rows[rowIdx]} dataSource={dataSource} />
                </>
            )}
        </DataGridMenu>
    );
};

ContextMenu.propTypes = {
    rowIdx: PropTypes.number,
    idx: PropTypes.number,
    onFreezeColumn: PropTypes.func,
    columns: PropTypes.instanceOf(Array),
    user: UserType,
    rows: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    match: PropTypes.instanceOf(Object),
};

ContextMenu.defaultProps = {
    rowIdx: 0,
    idx: 0,
    onFreezeColumn: () => null,
    columns: [],
    user: {},
    rows: [],
    match: null,
};

export default withCurrentUser(ContextMenu);
