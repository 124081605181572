import React from 'react';
import propTypes from 'prop-types';
import '../stylesheets/ButtonDanger.css';

const ButtonDanger = ({
    id, children, onClick, disabled,
}) => (
    <button id={id} className="btn btn-danger danger-button" type="button" onClick={onClick} disabled={disabled}>
        {children}
    </button>
);

export default ButtonDanger;

ButtonDanger.propTypes = {
    id: propTypes.string,
    children: propTypes.oneOfType([propTypes.instanceOf(Object), propTypes.string]),
    onClick: propTypes.func,
    disabled: propTypes.bool,
};

ButtonDanger.defaultProps = {
    id: '',
    children: null,
    onClick: () => null,
    disabled: false,
};
