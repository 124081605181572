import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import './DateControl.css';
import LabelControl from './LabelControl';
import Log from '../../../utilComponents/Log';

const VALID_DATE_FORMAT = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

class DateControl extends React.Component {
    constructor(props) {
        super(props);

        const { value } = props;
        this.state = {
            startDate: this.setValueFromString(value),
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            startDate: date,
        });
    }

  setColumnWidth = (width) => {
      if (!width) {
          return 'col-sm-12';
      }

      return `col-sm-${width}`;
  }

  setValueFromString = (str) => {
      if (!str) {
          return null;
      }

      try {
          if (VALID_DATE_FORMAT.test(str)) {
              const slots = str.split('-');
              const YEAR_IDX = 0; // no
              const MNTH_IDX = 1; // magic
              const DAYS_IDX = 2; // numbers
              const RADIX = 10;
              return new Date(slots[YEAR_IDX], Number.parseInt(slots[MNTH_IDX], RADIX) - 1, slots[DAYS_IDX]);
          }
          throw Error(`date control [${str}] must be in yyyy-mm-dd format`);
      } catch (e) {
          Log.error(`DateControl error converting ${str} to date`);
      }

      // something bad happened. return null
      return null;
  }

  render() {
      const style = {
          display: 'inline-block',
          marginBottom: '0',
      };
      const {
          formField: { width, fieldName }, metaData: { isReadOnly }, displayName, feedback,
      } = this.props;

      const helpBlock = feedback ? 'has-error' : '';
      const { startDate } = this.state;

      return (
          <div
              className={`form-group form-component-datecontrol ${helpBlock}
        ${this.setColumnWidth(width)}`}
          >
              <LabelControl htmlFor={fieldName} style={style}>
                  {displayName}
              </LabelControl>
              <div className="input-group">
                  <div className="form-control d-flex align-items-center">
                      <DatePicker
                          selected={startDate}
                          onChange={this.handleChange}
                          readOnly={isReadOnly}
                          id={fieldName}
                          name={fieldName}
                          aria-describedby={`helpblock${fieldName}`}
                      />
                      <FaCalendarAlt size={20} />
                  </div>
              </div>
              <div id={`helpblock${fieldName}`} className="help-block">
                  {feedback}
              </div>
          </div>
      );
  }
}

export default DateControl;

DateControl.propTypes = {
    formField: PropTypes.shape({
        fieldName: PropTypes.string,
        width: PropTypes.number,
    }),
    metaData: PropTypes.shape({
        isReadOnly: PropTypes.bool,
    }),
    displayName: PropTypes.string,
    value: PropTypes.string,
    feedback: PropTypes.string,
};

DateControl.defaultProps = {
    formField: {
        fieldName: '',
        width: 0,
    },
    metaData: {
        isReadOnly: false,
    },
    displayName: '',
    value: '',
    feedback: '',
};
