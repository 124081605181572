import React, { useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Data, Toolbar } from 'react-data-grid-addons';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetAutopopulations from '../../../_services/GeotrakService/hooks/useGetAutopopulations';
import columns from './config';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import './autopopulations.css';
import AdminContainer from '../AdminContainer';

const adminAutopopulations = () => {
    const selectors = Data.Selectors;
    const { autopopulations, isLoading } = useGetAutopopulations();
    const [filters, setFilters] = useState({});
    const history = useHistory();

    if (isLoading) {
        return <LoadingFullScreen id="autopopulation-loader" />;
    }

    const getRows = () => selectors.getRows({ rows: autopopulations, filters });

    const onFilterChange = (filter) => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        setFilters(newFilters);
    };

    return (
        <div className="admin-autopopulations">
            <Breadcrumbs />
            <AdminContainer title="Autopopulations">
                <ButtonContainer>
                    <ButtonPrimary id="new-autopopulation-button" onClick={() => history.push('/admin/autopopulations/new')}>
                        New Rule
                    </ButtonPrimary>
                </ButtonContainer>
                <ReactDataGrid
                    columns={columns}
                    toolbar={(<Toolbar enableFilter />)}
                    rowGetter={(i) => getRows()[i]}
                    rowsCount={getRows().length}
                    onAddFilter={onFilterChange}
                    onClearFilters={() => setFilters({})}
                    minHeight={500}
                />
            </AdminContainer>
        </div>
    );
};

export default adminAutopopulations;
