import BaseNodeService from '../BaseNodeService';
import GeocoderTask from './Models/GeocoderTask';

export default class GeocoderTaskService extends BaseNodeService {
    constructor() {
        const url = '/etl-service';
        const port = 3016;
        super(url, port);
    }

    async getTasks() {
        return this.fetch('/geocoder-tasks')
            .then((tasks) => tasks.map((task) => GeocoderTask.load(task))).catch((err) => err);
    }
}
