import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useCreateNotification from '../../../../_services/NotificationService/hooks/useCreateNotification';
import '../notifications.css';
import { Notification } from '../../../../_services/NotificationService/Models';

const createButton = ({ notification, isValid }) => {
    const {
        createNotification, error, isLoading, isSuccessful,
    } = useCreateNotification();

    if (notification.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/notifications" />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="create-notification-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-notification-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createNotification(notification)}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    notification: PropTypes.instanceOf(Notification),
    isValid: PropTypes.func,
};

createButton.defaultProps = {
    notification: new Notification(),
    isValid: () => null,
};
