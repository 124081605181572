import PropTypes from 'prop-types';
import { NotificationDomain } from '../Models';

class ICreateNotificationDomainRequest {
    constructor() {
        this.data = {};
        this.notificationId = null;
    }

    static load(notificationDomain) {
        if (!notificationDomain) {
            throw Error('notification domain is required');
        }
        if (!(notificationDomain instanceof NotificationDomain)) {
            throw Error('Unexpected type for notification domain');
        }

        const request = new ICreateNotificationDomainRequest();
        request.data = notificationDomain;
        request.notificationId = notificationDomain.notificationId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.notificationId) {
            throw Error('notification id is required');
        }
    }
}

export default ICreateNotificationDomainRequest;

ICreateNotificationDomainRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
    notificationId: PropTypes.string,
};
