import { useEffect, useState } from 'react';
import useGetWidgets from '../../../_services/GeotrakService/hooks/useGetWidgets';
import useGetDataSourceWidgets from '../../../_services/GeotrakService/hooks/useGetDataSourceWidgets';

const useDataSourceWidgetForm = (dataSourceId) => {
    const { widgets } = useGetWidgets();
    const { dataSourceWidgets, isLoading } = useGetDataSourceWidgets(dataSourceId);

    const [values, setValues] = useState({
        widgets: [],
    });

    useEffect(() => {
        setValues({
            widgets: dataSourceWidgets,
        });
    }, [dataSourceWidgets]);

    return {
        values,
        isLoading,
        widgets: widgets.filter((widget) => widget.isTableWidget),
    };
};

export default useDataSourceWidgetForm;
