import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { DataSourceDocumentType } from '../../../../_services/GeotrakService/Models';
import useCreateDataSourceDocumentType from '../../../../_services/GeotrakService/hooks/useCreateDataSourceDocumentType';
import '../documentTypes.css';

const createButton = ({
    dataSourceDocumentType, isValid, moduleId, roleId,
}) => {
    const {
        createDataSourceDocumentType, error, isLoading, isSuccessful,
    } = useCreateDataSourceDocumentType();

    if (dataSourceDocumentType.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSourceDocumentType.dataSourceId}/document-types`} />;
    }

    return (
        <div className="document-type-toolbar document-type-button">
            {error && <div className="text-danger document-type-error" id="create-document-type-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-document-type-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createDataSourceDocumentType(dataSourceDocumentType)}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    dataSourceDocumentType: PropTypes.instanceOf(DataSourceDocumentType),
    isValid: PropTypes.func,
    moduleId: PropTypes.string,
    roleId: PropTypes.string,
};

createButton.defaultProps = {
    dataSourceDocumentType: new DataSourceDocumentType(),
    isValid: () => null,
    moduleId: null,
    roleId: null,
};
