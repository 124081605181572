import React from 'react';
import PropTypes from 'prop-types';

const inputPrimary = (props) => {
    const {
        id, label, required,
        maxLength, type, handleChange,
        value, isReadOnly, ...rest
    } = props;

    const handleBlur = (e) => {
        const { target } = e;
        try {
            target.value = value.trim();
        } catch {
            // must not be a valid string. so skip the trim. no big deal.
        }
    };

    return (
        <div className="mb-3">
            <label className="fw-bold form-label" htmlFor={id}>{label}</label>
            <input
                className={`${required && !value && 'border border-danger'}`}
                disabled={isReadOnly}
                type={type}
                id={id}
                maxLength={maxLength}
                name={label}
                onChange={(e) => handleChange(e)}
                onBlur={(e) => handleBlur(e)}
                required={required}
                value={value || ''}
                readOnly={isReadOnly}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
            />
        </div>
    );
};

inputPrimary.propTypes = {
    id: PropTypes.string,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
    isReadOnly: PropTypes.bool,
};

inputPrimary.defaultProps = {
    id: '',
    handleChange: () => null,
    label: '',
    required: false,
    type: '',
    value: '',
    isReadOnly: false,
    maxLength: 50,
};

export default inputPrimary;
