import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { DomainService } from '../../../_services/GeotrakService';
import { Domain } from '../../../_services/GeotrakService/Models';
import ErrorMessage from '../../../utilComponents/ErrorMessage';
import Create from './Actions/Domains/CreateButton';
import Update from './Actions/Domains/UpdateButton';
import Delete from './Actions/Domains/DeleteButton';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import '../../stylesheets/AdminDomains.css';

const adminDomains = () => {
    const [domains, setDomains] = useState([]);
    const [addDomainValue, setAddDomainValue] = useState('');
    const [error, setError] = useState(null);
    const domainService = new DomainService();
    const params = useParams();
    const { table, field, database } = params;

    const getDomainValues = async () => {
        try {
            const domainList = await domainService.getRawDomains(database, table);
            let domainValues = [];
            if (field) {
                domainValues = domainList.filter((domain) => domain.field === field);
            }
            setDomains(domainValues);
        } catch (err) {
            setError(`An error occurred while getting fields. ${err.message || err}`);
        }
    };

    const isValid = (value) => value && !domains.some(
        (item) => value.trim().toLowerCase() === item.value.trim().toLowerCase()
    );

    useEffect(() => {
        getDomainValues();
    }, []);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Domains">
                <h5 className="text-primary-emphasis">
                    Field Name: &nbsp;
                    {field}
                </h5>
                <input
                    onChange={(e) => setAddDomainValue(e.target.value)}
                    value={addDomainValue}
                    placeholder="Enter new domain value..."
                    className="mb-1"
                />
                {addDomainValue && !isValid(addDomainValue) && (
                    <span className="text-warning">
                        Domain value already exists.
                    </span>
                )}
                <Create
                    field={field}
                    domain={new Domain(field, addDomainValue, database, table)}
                    isDisabled={!isValid(addDomainValue)}
                    onCreate={() => {
                        getDomainValues();
                        setAddDomainValue('');
                    }}
                />
                <div className="list-group admin-domain-list">
                    {domains.map((domain) => (
                        <div className="list-group-item list-group-item-action d-flex flex-row" key={`domain=${domain.id}`}>
                            <div className="d-flex flex-row-reverse">
                                <Update
                                    isDisabled={isValid}
                                    domain={domain}
                                    onUpdate={() => getDomainValues()}
                                />
                                <Delete
                                    isDisabled={isValid}
                                    domain={domain}
                                    onDelete={() => getDomainValues()}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {error && (<ErrorMessage message={error} onDismiss={() => setError(null)} />)}
            </AdminContainer>
        </>
    );
};

export default adminDomains;
