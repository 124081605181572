import PropTypes from 'prop-types';

export default class EtlJobType {
    constructor() {
        this.id = null;
        this.name = null;
        this.description = null;
        this.createdOn = null;
        this.createdBy = null;
        this.updatedOn = null;
        this.updatedBy = null;
    }

    static load(response) {
        const etlJobType = new EtlJobType();

        if (response) {
            etlJobType.id = response.id;
            etlJobType.name = response.displayName;
            etlJobType.description = response.description;
            etlJobType.createdOn = response.createdOn;
            etlJobType.createdBy = response.createdBy;
            etlJobType.updatedOn = response.updatedOn;
            etlJobType.updatedBy = response.updatedBy;
        }

        return etlJobType;
    }
}

EtlJobType.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    updatedOn: PropTypes.string,
    updatedBy: PropTypes.string,
};
