import React, { Component } from 'react';
import { BsX, BsFileText } from 'react-icons/bs';
import propTypes from 'prop-types';
import { CheckRequestService, DocumentService } from '../../_services';
import { SelectedCheckRequestType } from '../../types';
import LoaderButton from '../../utilComponents/LoaderButton';
import Modal from '../Modals/Modal';
import ErrorMessage from '../../utilComponents/ErrorMessage';
import IUploadCheckRequestFileRequest from '../../_services/DocumentService/interfaces/IUploadCheckRequestFileRequest';

const totalFileSizeUploadLimitBytes = 26214400;

class UploadFileManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            existingFiles: [],
            isUploading: false,
            showFileSizeWarning: false,
            error: null,
        };

        this.CheckRequestService = new CheckRequestService();
        this.documentService = new DocumentService();
    }

    componentDidMount = () => {
        const { existingFiles } = this.props;
        this.updateFileStatus(existingFiles, true);
    };

    componentDidUpdate = (prevProps) => {
        const { existingFiles, minimumRequiredDocuments } = this.props;
        if (prevProps.existingFiles !== existingFiles
            || minimumRequiredDocuments !== prevProps.minimumRequiredDocuments) {
            this.updateFileStatus(existingFiles, true);
        }
    };

    renderFileSizeWarning = () => {
        const { showFileSizeWarning } = this.state;

        if (showFileSizeWarning) {
            return (
                <div>
                    <Modal
                        showModal={showFileSizeWarning}
                        header="File(s) too large"
                        onCancel={() => {
                            this.setState({
                                showFileSizeWarning: false,
                                isUploading: false,
                            });
                        }}
                        component={(
                            <div className="text-center">
                                <span>
                                    The total size for all the files uploaded cannot exceed 25 MB.
                                    Please reach out to AP for directions on how to get the file. Thank you!
                                </span>
                            </div>
                        )}
                    />
                </div>
            );
        }
        return null;
    };

  updateFileStatus = (files, existingFiles = false) => {
      const { onFileStatusUpdate, minimumRequiredDocuments } = this.props;
      if (existingFiles) {
          this.setState({ existingFiles: files });
      } else {
          this.setState({ files });
      }
      this.setState({ isUploading: false });
      onFileStatusUpdate(files.length >= minimumRequiredDocuments, files);
  };

  handleFileUpload = async (e) => {
      const {
          checkRequest, onFileStatusUpdate, minimumRequiredDocuments,
      } = this.props;
      const { files } = this.state;
      const file = e.target.files[0];
      this.setState({ isUploading: true });
      let totalFilesSize = file.size;
      for (let i = 0; i < files.length; i += 1) {
          totalFilesSize += files[i].size;
      }

      // Check if total size of uploaded files is over limit
      const isOverTotalFileSizeUploadLimit = totalFilesSize > totalFileSizeUploadLimitBytes;
      if (isOverTotalFileSizeUploadLimit) {
          this.setState({ showFileSizeWarning: true });
          return;
      }

      files.push(e.target.files[0]);
      if (Object.keys(checkRequest).length) {
          try {
              const request = new IUploadCheckRequestFileRequest();
              request.load(
                  checkRequest.summary.CheckRequestId,
                  checkRequest.projectControls.ProjectSiteId,
                  file
              );
              await this.documentService.upload(request);
              this.updateFileStatus(files);
          } catch (err) {
              this.setState({ isUploading: false, error: `Error occurred on file uploading: ${err.message || err}` });
          }
      } else {
          this.setState({ isUploading: false });
          onFileStatusUpdate(files.length >= minimumRequiredDocuments, files);
      }
      this.setState({ files });
  };

  handleFileDelete = (deleteFile) => {
      const { files } = this.state;
      const { checkRequest } = this.props;
      try {
          if (Object.keys(checkRequest).length) {
              this.documentService.getByExternalKey(`${encodeURIComponent(checkRequest.projectControls.ProjectSiteId)}`).then((metadata) => {
                  const fileItems = metadata.map((item) => ({ ...item }));
                  const fileToDelete = fileItems.filter(
                      (file) => file.folder === checkRequest.summary.CheckRequestId.toString()
              && file.fileName === deleteFile.name
                  )[0];
                  this.documentService.deleteFile(fileToDelete.id);
              });
          }
          const updatedFiles = files.filter((file) => deleteFile !== file);
          this.setState({ files: updatedFiles }, () => this.updateFileStatus(updatedFiles));
      } catch (err) {
          this.setState({ isUploading: false, error: `Error occurred with file deletion: ${err.message || err}` });
      }
  };

  openUpload = () => {
      document.getElementById('inputFile').click();
  };

  renderUploader = () => {
      const {
          files, existingFiles, isUploading,
      } = this.state;
      const { requireFileUploads, minimumRequiredDocuments } = this.props;
      return (
          <div className="m-2">
              <input
                  type="file"
                  key="0"
                  id="inputFile"
                  className="form-control"
                  onChange={(e) => { this.handleFileUpload(e); }}
                  files="files"
                  hidden
                  accept=".pdf,.doc,.docx,.zip,.xls,.xlsx"
                  required={minimumRequiredDocuments > [...new Set([...files, ...existingFiles])].length
                  && requireFileUploads}
                  onClick={(event) => {
                      const { target } = event;
                      target.value = null;
                  }}
              />
              <LoaderButton
                  onClick={this.openUpload}
                  disabled={isUploading}
                  type="submit"
                  isLoading={isUploading}
                  text={isUploading ? 'Uploading' : 'Choose File'}
                  loadingText="Uploading…"
              />
          </div>
      );
  };

  renderFiles = () => {
      const { files } = this.state;
      return files.map((file) => (
          <div key={file.name} className="ms-1">
              <div className="row">
                  <div className="col-md-6 p-3 show-file d-flex flex-row justify-content-between">
                      <div>
                          <BsFileText size={20} className="me-2" />
                          <span>{file.name}</span>
                      </div>
                      <span
                          className="remove-file"
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => this.handleFileDelete(file)}
                          onClick={() => this.handleFileDelete(file)}
                      >
                          <BsX size={20} className="upload-file-manager-icons" />
                      </span>
                  </div>
              </div>
          </div>
      ));
  };

  render() {
      const { minimumRequiredDocuments } = this.props;
      const { error } = this.state;
      return (
          <div className="check-request-documents">
              {error && (
                  <ErrorMessage
                      message={error}
                      onDismiss={() => this.setState({ error: null })}
                  />
              )}
              { this.renderFileSizeWarning() }
              <div>
                  <h5 className="fw-bold">
                      Supporting Documentation
                      {' '}
                      <em>{minimumRequiredDocuments > 0 && `(${minimumRequiredDocuments} Files Required)`}</em>
                  </h5>
                  {this.renderUploader()}
                  {this.renderFiles()}
              </div>
          </div>
      );
  }
}

export default UploadFileManager;

UploadFileManager.propTypes = {
    onFileStatusUpdate: propTypes.func,
    requireFileUploads: propTypes.bool,
    checkRequest: SelectedCheckRequestType,
    existingFiles: propTypes.instanceOf(Array),
    minimumRequiredDocuments: propTypes.number,
};

UploadFileManager.defaultProps = {
    checkRequest: {},
    minimumRequiredDocuments: 0,
    onFileStatusUpdate: () => null,
    requireFileUploads: true,
    existingFiles: [],
};
