import React from 'react';
import { useHistory } from 'react-router';
import useUserForm from './useUserForm';
import {
    Create, Update, Delete, Reactivate
} from './Actions';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import './users.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminUsersForm = ({ match }) => {
    const { params: { id } } = match;
    const {
        values, isValid, onFormUpdate, roles, onRoleUpdate, formatPermissions, isLoading,
    } = useUserForm(id || null);
    const history = useHistory();

    const modules = [...new Set(roles.map((role) => role.module.unit.name))];

    const user = {
        Id: values.id || null,
        userid: values.username,
        emailAddress: values.emailAddress,
        FullName: values.name,
        phoneNumber: values.phoneNumber || null,
        Admin: values.adminPermission || null,
        dashboards: formatPermissions('Dashboards') || null,
        manager: formatPermissions('Manager') || null,
        landbase: formatPermissions('Landbase') || null,
        Construction: formatPermissions('Construction') || null,
        Structural: formatPermissions('Structural') || null,
        checkRequest: formatPermissions('CheckRequest') || null,
        IsDeleted: values.isDeleted,
    };

    if (isLoading) {
        return <LoadingFullScreen id="user-form-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="User">
                {user.Id && (
                    <div className="user-option-toolbar">
                        <ButtonPrimary
                            id="user-history-button"
                            className="user-option-button"
                            onClick={() => history.push(`/admin/users/${user.Id}/history`)}
                        >
                            View User History
                        </ButtonPrimary>
                    </div>
                )}
                <form>
                    <div className="mb-3">
                        <label htmlFor="input-user-username" className="form-label">
                            Username
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-user-username"
                            value={values.username}
                            onChange={(e) => onFormUpdate('username', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-user-email" className="form-label">
                            Email Address
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-user-email"
                            value={values.emailAddress}
                            onChange={(e) => onFormUpdate('emailAddress', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-user-name" className="form-label">
                            Full Name
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-user-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-user-phone" className="form-label">
                            Phone Number
                        </label>
                        <input
                            type="text"
                            id="input-user-phone"
                            value={values.phoneNumber}
                            onChange={(e) => onFormUpdate('phoneNumber', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="select-admin" className="form-label">
                            Admin Permissions
                        </label>
                        <select
                            id="select-admin"
                            value={values.adminPermission}
                            onChange={(e) => onFormUpdate('adminPermission', e.target.value)}
                        >
                            <option value="">--Select a permission level--</option>
                            <option value="USER">User</option>
                            <option value="IT">IT</option>
                            <option value="DEV">Dev</option>
                        </select>
                    </div>
                    <div id="module-accordion" className="accordion accordion-flush mb-3">
                        {modules.map((module) => (
                            <div key={module}>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id={`${module}-header`}>
                                        <button
                                            className="accordion-button user-module-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#${module}-collapse`}
                                            aria-expanded="true"
                                            aria-controls={`${module}-collapse`}
                                        >
                                            {module}
                                        </button>
                                    </h5>
                                    <div
                                        id={`${module}-collapse`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby={`${module}-header`}
                                        data-bs-parent="module-accordion"
                                    >
                                        <div className="accordion-body user-module-container">
                                            {roles.filter(
                                                (role) => role.module.unit.name === module
                                            ).map((role) => (
                                                <div className="form-check mb-3" key={role.id}>
                                                    <label htmlFor={`user-role-${role.id}`} className="form-check-label">
                                                        {`${role.name} : ${role.alias}`}
                                                        <input
                                                            type="checkbox"
                                                            id={`user-role-${role.id}`}
                                                            className="form-check-input"
                                                            onChange={() => onRoleUpdate(role.id)}
                                                            checked={values.roles.includes(role.id)}
                                                        />
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </form>
                <div className="user-toolbar">
                    <Create isValid={isValid} user={user} />
                    <Update isValid={isValid} user={user} />
                    <Delete user={user} />
                    <Reactivate isValid={isValid} user={user} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminUsersForm;
