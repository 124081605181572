import { editData } from '../../helpers/tools';
import { Field, BulkFieldResults, FieldResult } from './Models';
import {
    DomainService, FieldService as AppFieldService,
    RoleService, DataSourceService, DatabaseService
} from '../GeotrakService';
import IGetFieldsRequest from '../GeotrakService/interfaces/IGetFieldsRequest';

const convertPromiseArrayToFieldArray = (fullfillments) => {
    const [fields, dataTypes, lookupValues] = fullfillments;

    return fields.map((field) => {
        const fieldCopy = { ...field };
        fieldCopy.dataType = dataTypes[field.name];
        fieldCopy.lookupValues = lookupValues[field.name];
        return fieldCopy;
    });
};

const sortFields = (fields) => fields.sort((a, b) => {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    if (x < y) {
        return -1;
    }
    if (x > y) {
        return 1;
    }
    return 0;
});

export default class FieldService {
    // Initializing important variables
    constructor(dataSource) {
        this.dataSource = dataSource;

        this.appFieldService = new AppFieldService();
        this.domainService = new DomainService();
        this.roleService = new RoleService();
        this.dataSourceService = new DataSourceService();
        this.databaseService = new DatabaseService();
    }

    /**
   * update
   * @method
   * @summary bulk updates a list of fields
   * @param {PrimaryKey} pk - THe unique identifier for the selected row
   * @param {Field[]} fields - all the fields to update
   */

    update(pkField, pkValue, fields) {
        return editData(
            this.dataSource.databaseReference,
            this.dataSource.tableReference,
            pkField,
            pkValue,
            fields
        ).then((results) => {
            const bulkResults = new BulkFieldResults();

            results.data.forEach((field) => {
                const result = FieldResult.load(field.status === 'success', field);
                bulkResults.push(result);
            });
            return bulkResults;
        });
    }

    /**
   * getFields
   * @method
   * @summary gets roles by module name
   * @param {DataSource} dataSource
   * @return {Array.<Field>} the fields
   */
    getFields(dataSource) {
        const promises = [];
        promises.push(this.getRoleFields(dataSource));
        promises.push(this.getDataTypes(dataSource));
        promises.push(this.getLookupValues(dataSource));

        return Promise.all(promises).then((results) => convertPromiseArrayToFieldArray(results));
    }

    /**
   * _getLookupValues
   * @method
   * @summary gets dropdown values
   * @param {DataSource} dataSource
   * @return {Array.<LookupValue>} the fields
   */
    getLookupValues(dataSource) {
        return this.domainService.getDomainValues(dataSource.databaseReference, dataSource.tableReference)
            .then((response) => {
                if (Object.keys(response).length) {
                    return response;
                }
                return [];
            }).catch(() => []);
    }

    /**
   * _getFields
   * @method
   * @summary gets fields by dataSource
   * @param {DataSource} dataSource
   * @return {Array.<Field>} the fields
   */
    async getRoleFields(dataSource) {
        return this.appFieldService.getFields(dataSource.id).then((fields) => {
            const sorted = sortFields(fields);
            return sorted.map((m) => Field.load(m));
        }).catch(() => []);
    }

    /**
   * _getDataTypes
   * @method
   * @summary get a list of data types.v
   * @param {Layer} layer - array of field names
   * @return {Array.<DataType>} list of datatypes
   */
    getDataTypes(dataSource) {
        const request = IGetFieldsRequest.load(dataSource.databaseReference, dataSource.tableReference);
        return this.databaseService.getFields(request)
            .then((response) => response)
            .catch(() => {});
    }
}
