import React from 'react';
import { useHistory } from 'react-router';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { Market, Module } from '../../../_services/GeotrakService/Models';
import useModuleForm from './useModuleForm';
import { Create, Update, Delete } from './Actions';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminModulesForm = ({ match }) => {
    const {
        values, isValid, onFormUpdate, isLoading, projects, units, markets,
    } = useModuleForm(match.params.id || null);
    const history = useHistory();

    if (isLoading) {
        return <LoadingFullScreen id="module-form-loader" />;
    }

    const module = new Module();
    module.id = values.id;
    module.name = values.name;
    module.alias = values.alias;
    module.project = projects.find((project) => project.id === values.projectId);
    module.unit = units.find((unit) => unit.id === values.unitTypeId);
    module.market = markets.find((market) => market.id === values.marketId) || new Market();

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Module">
                <div className="module-toolbar">
                    {match.params.id && (
                        <ButtonPrimary id="configure-roles-button" onClick={() => history.push(`/admin/modules/${match.params.id}/roles`)}>
                            Configure Roles
                        </ButtonPrimary>
                    )}
                </div>
                <form>
                    <div className="mb-3">
                        <label htmlFor="select-unit" className="form-label">
                            Unit
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-unit"
                            value={values.unitTypeId}
                            onChange={(e) => onFormUpdate('unitTypeId', e.target.value)}
                        >
                            <option disabled value="">--Select a unit--</option>
                            {units.map((unit) => (
                                <option id="select-unit-option" key={unit.id} value={unit.id}>{unit.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="select-project" className="form-label mb-3">
                            Project
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-project"
                            value={values.projectId}
                            onChange={(e) => onFormUpdate('projectId', e.target.value)}
                        >
                            <option disabled value="">--Select a project--</option>
                            {projects.map((project) => (
                                <option id="select-project-option" key={project.id} value={project.id}>{project.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="select-market" className="form-label">
                            Market
                        </label>
                        <select
                            id="select-market"
                            value={values.marketId}
                            onChange={(e) => onFormUpdate('marketId', e.target.value || '')}
                        >
                            <option value="">--Select a market--</option>
                            {markets.map((market) => (
                                <option id="select-market-option" key={market.id} value={market.id}>{market.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="module-name" className="form-label">
                            Module Name
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="module-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="module-alias" className="form-label">
                            Title
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="module-alias"
                            value={values.alias}
                            onChange={(e) => onFormUpdate('alias', e.target.value)}
                        />
                    </div>
                </form>
                <div className="module-toolbar">
                    <Create module={module} isValid={isValid} />
                    <Update module={module} isValid={isValid} />
                    <Delete module={module} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminModulesForm;
