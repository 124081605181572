import React from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import './checkRequest.css';

const success = () => (
    <div className="container-fluid">
        <div className="col-md-12 text-center request-form-success-container">
            <BsCheckCircle size="100" className="request-form-valid" />
            <h4>Success!  Your request form is configured.</h4>
        </div>
    </div>
);
export default success;
