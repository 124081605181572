import PropTypes from 'prop-types';
import DataType from './DataType';
import LookupValue from './LookupValue';

export default class Field {
    constructor(name, displayName, value, isReadOnly, dataType, lookupValues, feedback) {
        this.name = name;
        this.displayName = displayName;
        this.value = value;
        this.isReadOnly = isReadOnly;
        this.dataType = dataType;
        this.lookupValues = lookupValues;
        this.feedback = feedback;
    }

    static load(response) {
        return new Field(response.name, response.alias, null, !response.isEditable);
    }

    static copy(field) {
        if (!field) {
            return new Field();
        }
        return new Field(
            field.name,
            field.displayName,
            field.value,
            field.isReadOnly,
            field.dataType,
            field.lookupValues,
            field.feedback
        );
    }

    setValue(value) {
        if (typeof value === 'string' && value.trim() === '') {
            this.value = null;
        } else {
            this.value = value;
        }
    }
}

Field.propTypes = {
    name: PropTypes.string,
    displayName: PropTypes.string,
    isReadOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
    dataType: PropTypes.instanceOf(DataType),
    lookupValues: PropTypes.arrayOf(PropTypes.instanceOf(LookupValue)),
    feedback: PropTypes.string,
};
