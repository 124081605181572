import PropTypes from 'prop-types';

class IGeocodeRequest {
    constructor() {
        this.data = {
            address: '',
        };
    }

    static load(address) {
        if (!address) {
            throw Error('address is required');
        }
        const request = new IGeocodeRequest();
        request.data.address = address;
        return request;
    }
}

export default IGeocodeRequest;

IGeocodeRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
