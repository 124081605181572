import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    MdAddCircle, MdDelete, MdLock, MdLockOpen
} from 'react-icons/md';
import { CommentApplicationService, CommentTypeService } from '../../../_services/CommentService';
import {
    ButtonContainer, ButtonDanger, ButtonPrimary
} from '../../../utilComponents/styledComponents';
import { addAlertThunk } from '../../../actions/errorActions';
import '../../stylesheets/AdminComments.css';
import NewCommentApplication from './NewCommentApplication';
import AdminContainer from '../AdminContainer';
import IUpdateApplicationCommentTypeRequest from '../../../_services/CommentService/interfaces/IUpdateApplicationCommentTypeRequest';
import ICreateApplicationCommentTypeRequest from '../../../_services/CommentService/interfaces/ICreateApplicationCommentTypeRequest';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';

const appTiers = [null, 'system', 'module', 'application', 'role', 'layer', 'column'];

class AdminComments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applicationOptions: {},
            commentTypeOptions: [],
            showCommentTypeOptions: false,
            applicationCommentTypes: [],
            selectedCommentType: null,
            selectedApplication: null,
            selectedApplicationTier: null,
            newCommentType: '',
        };

        this.CommentApplicationService = new CommentApplicationService();
        this.CommentTypeService = new CommentTypeService();
    }

    componentDidMount = () => {
        this.getRootApplication();
        this.getCommentTypeOptions();
    };

    getRootApplication = () => {
        const { addAlertAction } = this.props;
        this.CommentApplicationService.getChildApplications().then((applications) => {
            this.formatOptions(applications);
        }).catch((err) => {
            addAlertAction('danger', 'Error Getting App Options', err.message || err);
        });
    };

    getCommentTypeOptions = () => {
        const { addAlertAction } = this.props;
        this.CommentTypeService.getCommentTypeOptions().then((commentTypeOptions) => {
            this.setState({ commentTypeOptions });
        }).catch((err) => {
            addAlertAction('danger', 'Error Getting Comment Types', err.message || err);
        });
    };

    formatOptions = (rawOptions) => {
        const { applicationOptions } = this.state;
        let applicationType;
        const options = rawOptions.sort((a, b) => a.AppValue.localeCompare(b.AppValue)).map((option) => {
            applicationType = option.AppKey;
            return option;
        });
        applicationOptions[applicationType] = options;
        this.setState({ applicationOptions });
    };

    getChildApplications = (app, applicationId) => {
        const { addAlertAction } = this.props;
        if (applicationId) {
            this.CommentApplicationService.getChildApplications(applicationId).then((childApplications) => {
                this.setState({
                    selectedApplication: applicationId,
                    selectedApplicationTier: app,
                });
                if (childApplications.length) {
                    this.formatOptions(childApplications);
                } else {
                    this.getApplicationCommentTypes(applicationId);
                }
            }).catch((err) => {
                addAlertAction('danger', 'Error Getting Child Applications', err.message || err);
            });
        }
    };

    getApplicationCommentTypes = (applicationId) => {
        const { addAlertAction } = this.props;
        this.CommentTypeService.getApplicationCommentTypes(applicationId).then((commentTypes) => {
            this.setState({
                applicationCommentTypes: commentTypes,
                showCommentTypeOptions: true,
            });
        }).catch((err) => {
            addAlertAction('danger', 'Error Getting Application Comment Types', err.message || err);
        });
    };

    renderSelectors = () => {
        const { applicationOptions, selectedApplication, selectedApplicationTier } = this.state;
        const apps = Object.keys(applicationOptions);
        return apps.map((app) => (
            <form key={app}>
                <div className="mb-3">
                    <label htmlFor={`select-${app}`} className="form-label">
                        {`Select ${app}.`}
                    </label>
                    <select
                        onChange={(e) => this.getChildApplications(app, e.target.value)}
                        defaultValue=""
                        id={`select-${app}`}
                    >
                        <option disabled value="">{`Choose a ${app}`}</option>
                        {applicationOptions[app].map((applicationOption) => (
                            <option value={applicationOption.Id} key={applicationOption.Id}>
                                {applicationOption.AppValue}
                            </option>
                        ))}
                    </select>
                </div>
                <ButtonContainer>
                    {app === appTiers[appTiers.indexOf(selectedApplicationTier) + 1]
                        && (
                            <NewCommentApplication
                                appTier={app}
                                parentAppId={selectedApplication}
                                updateApplicationOptions={this.updateApplicationOptions}
                            />
                        )}
                </ButtonContainer>
            </form>
        ));
    };

    updateApplicationOptions = (newApplication) => {
        const { applicationOptions } = this.state;
        if (applicationOptions[newApplication.AppKey]) {
            applicationOptions[newApplication.AppKey].push(newApplication);
        } else {
            applicationOptions[newApplication.AppKey] = [];
            applicationOptions[newApplication.AppKey].push(newApplication);
        }
        this.setState({ applicationOptions });
        this.getChildApplications(newApplication.AppKey, newApplication.Id);
    };

    toggleEditableType = (commentTypeId, isEditable) => {
        const { applicationCommentTypes } = this.state;
        const { addAlertAction } = this.props;

        const selectedApplicationCommentType = applicationCommentTypes.find(
            (app) => app.commentTypeId === commentTypeId
        );

        selectedApplicationCommentType.isEditable = isEditable;

        const request = IUpdateApplicationCommentTypeRequest.load(selectedApplicationCommentType);

        this.CommentTypeService.updateApplicationCommentType(request).then((updatedCommentType) => {
            const commentType = applicationCommentTypes.findIndex((obj) => obj.commentTypeId === commentTypeId);
            applicationCommentTypes[commentType].isEditable = updatedCommentType.isEditable;
            this.setState({
                applicationCommentTypes,
            });
        }).catch((err) => {
            addAlertAction('danger', 'Error Updating Comment Type', err.message || err);
        });
    };

    renderApplicationCommentTypes = () => {
        const { applicationCommentTypes } = this.state;
        return applicationCommentTypes.map((type) => (
            <button
                key={type.commentTypeId}
                className="list-group-item list-group-item-action vertical-center"
                type="button"
            >
                <MdDelete
                    size={20}
                    className="comment-type-button"
                    onClick={() => this.deleteCommentTypeFromApplication(type.commentTypeId)}
                />
                {type.isEditable
                    ? <MdLockOpen size={20} className="comment-type-button" onClick={() => this.toggleEditableType(type.commentTypeId, false)} />
                    : <MdLock size={20} className="comment-type-button" onClick={() => this.toggleEditableType(type.commentTypeId, true)} />}
                {type.name}
            </button>
        ));
    };

    deleteCommentTypeFromApplication = (commentTypeId) => {
        const { selectedApplication, applicationCommentTypes } = this.state;
        const { addAlertAction } = this.props;
        const body = {
            applicationId: selectedApplication,
            commentTypeId,
        };
        this.CommentTypeService.deleteApplicationCommentType(body).then(() => {
            const updatedApplicationCommentTypes = applicationCommentTypes.filter(
                (type) => type.commentTypeId !== commentTypeId
            );
            this.setState({
                applicationCommentTypes: updatedApplicationCommentTypes,
            });
            addAlertAction('success', 'Success!', 'Comment Type Removed');
        }).catch((err) => {
            addAlertAction('danger', 'Error Deleting Comment Type', err.message || err);
        });
    };

    addCommentTypeToApplication = () => {
        const {
            selectedCommentType, selectedApplication, applicationCommentTypes, commentTypeOptions,
        } = this.state;
        const { addAlertAction } = this.props;

        const body = {
            applicationId: selectedApplication,
            commentTypeId: selectedCommentType,
        };

        const request = ICreateApplicationCommentTypeRequest.load(body);

        this.CommentTypeService.postApplicationCommentType(request).then((newApplicationCommentType) => {
            const addedCommentType = commentTypeOptions.filter((type) => type.id === selectedCommentType)[0];
            const applicationCommentType = newApplicationCommentType;

            applicationCommentType.name = addedCommentType.name;

            this.setState({
                applicationCommentTypes: [applicationCommentType, ...applicationCommentTypes],
                selectedCommentType: null,
            });
            addAlertAction('success', 'Success!', 'Comment Type Added');
        }).catch((err) => {
            addAlertAction('danger', 'Error Adding Comment Type', err.message || err);
        });
    };

    isInputValid = () => {
        const { selectedCommentType, newCommentType, commentTypeOptions } = this.state;
        const commentTypes = commentTypeOptions.map((type) => type.name);
        if (selectedCommentType === 'Choose a comment type to add') {
            return false;
        }
        if (!selectedCommentType && !newCommentType) {
            return false;
        }
        return !(selectedCommentType === '' && commentTypes.includes(newCommentType));
    };

    createNewCommentType = () => {
        const { newCommentType, commentTypeOptions } = this.state;
        const { addAlertAction } = this.props;
        this.CommentTypeService.postCommentType({ name: newCommentType }).then((commentType) => {
            this.setState({
                newCommentType: '',
                commentTypeOptions: [commentType, ...commentTypeOptions],
                selectedCommentType: commentType.id,
            });
            this.addCommentTypeToApplication();
        }).catch((err) => {
            addAlertAction('danger', 'Error Creating Comment Type', err.message || err);
        });
    };

    renderCommentTypeSelector = () => {
        const { commentTypeOptions, applicationCommentTypes, newCommentType } = this.state;
        const applicationTypes = applicationCommentTypes.map((type) => type.name);

        return (
            <div className="d-flex justify-content-center">
                <select defaultValue="" onChange={(e) => this.setState({ selectedCommentType: e.target.value })}>
                    <option value="" disabled>Choose a comment type to add</option>
                    <option value="">Create new</option>
                    {commentTypeOptions.filter(
                        (type) => !applicationTypes.includes(type.name)
                    ).map((commentTypeOption) => (
                        <option value={commentTypeOption.id} key={commentTypeOption.id}>
                            {commentTypeOption.name}
                        </option>
                    ))}
                </select>
                <ButtonPrimary
                    onClick={newCommentType.length > 0
                        ? this.createNewCommentType : this.addCommentTypeToApplication}
                    disabled={!this.isInputValid()}
                >
                    <MdAddCircle size={20} />
                </ButtonPrimary>
            </div>
        );
    };

    resetForm = () => {
        this.setState({
            applicationOptions: {},
            selectedApplication: null,
            showCommentTypeOptions: false,
            applicationCommentTypes: [],
        });
        this.getRootApplication();
    };

    render() {
        const {
            showCommentTypeOptions, selectedCommentType, newCommentType, selectedApplication, selectedApplicationTier,
        } = this.state;
        const nextAppTier = appTiers[appTiers.indexOf(selectedApplicationTier) + 1];
        return (
            <div className="admin-comments">
                <Breadcrumbs />
                <AdminContainer title="Configure Comment Types">
                    <div className="row">
                        <div className="col-md-3 offset-md-1">
                            {this.renderSelectors()}
                            <ButtonContainer>
                                {selectedApplication && <ButtonDanger onClick={this.resetForm}>Reset</ButtonDanger>}
                                {(showCommentTypeOptions && nextAppTier)
                            && (
                                <NewCommentApplication
                                    appTier={nextAppTier}
                                    parentAppId={selectedApplication}
                                    updateApplicationOptions={this.updateApplicationOptions}
                                />
                            )}
                            </ButtonContainer>
                        </div>
                        <div className="col-md-6 offset-md-1">
                            {showCommentTypeOptions && this.renderCommentTypeSelector()}
                            {selectedCommentType === ''
                            && (
                                <input
                                    name="createCommentType"
                                    value={newCommentType}
                                    onChange={(e) => this.setState({ newCommentType: e.target.value })}
                                />
                            )}
                            <div className="list-group mt-2">
                                {this.renderApplicationCommentTypes()}
                            </div>
                        </div>
                    </div>
                </AdminContainer>
            </div>
        );
    }
}

const mapDispatchToProps = ({
    addAlertAction: addAlertThunk,
});

export default connect(null, mapDispatchToProps)(AdminComments);

AdminComments.propTypes = {
    addAlertAction: PropTypes.func,
};

AdminComments.defaultProps = {
    addAlertAction: () => null,
};
