import React, { Component } from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import ReactDataGrid from 'react-data-grid';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';
import { ButtonPrimary } from '../../utilComponents/styledComponents';
import Loading from '../../utilComponents/LoaderSpinner';
import { CheckRequestReportService, CheckRequestDownloadService } from '../../_services/CheckRequestService';
import './ReportView.css';
import RecordPlaceholder from '../../components/DataGrid/RecordPlaceholder';
import ErrorMessage from '../../utilComponents/ErrorMessage';
import SuccessMessage from '../../utilComponents/SuccessMessage';

class ReportView extends Component {
    constructor(props) {
        super(props);
        const { location: { search } } = this.props;
        this.state = {
            isLoading: false,
            columns: [],
            checkReport: [],
            searchTerm: '',
            reportType: new window.URLSearchParams(search).get('type'),
            success: null,
            error: null,
        };
        this.CheckRequestReportService = new CheckRequestReportService();
        this.CheckRequestDownloadService = new CheckRequestDownloadService();
    }

    componentDidMount = () => {
        const { location: { search } } = this.props;
        const searchId = new window.URLSearchParams(search).get('recordId');

        if (searchId) {
            this.setState({ searchTerm: searchId }, () => { this.getReport(); });
        }
    }

    setColumns = (fields) => fields.map((fieldName) => ({
        key: fieldName,
        name: fieldName,
        resizable: true,
    }));

    handleInput = (e) => {
        const searchTerm = e.target.value;
        this.setState({ searchTerm });
    };

    getReport = () => {
        const { searchTerm, reportType } = this.state;
        const params = {};
        params[reportType] = searchTerm;
        const urlParams = querystring.stringify(params);
        this.setState({ isLoading: true });
        this.CheckRequestReportService.getReport(reportType, urlParams).then((results) => {
            this.setState({
                checkReport: results,
                columns: results.length ? this.setColumns(Object.keys(results[0])) : [],
                isLoading: false,
            });
        }).catch((err) => {
            this.setState({
                error: `Could not load report for input: ${searchTerm}. ${err.message || err}`,
                isLoading: false,
                checkReport: [],
            });
        });
    };

    downloadReport = async () => {
        const { searchTerm, reportType } = this.state;
        this.setState({ isLoading: true });
        try {
            const params = {};
            params[reportType] = searchTerm;
            const urlParams = querystring.stringify(params);
            await this.CheckRequestDownloadService.getReportDownload(reportType, urlParams);
            this.setState({ isLoading: false });
            this.setState({ success: 'Report downloaded successfully.' });
        } catch (err) {
            this.setState({
                error: `Could not download report for search term: ${searchTerm}. ${err.message || err}`,
                isLoading: false,
            });
        }
    };

    getRows = (index) => {
        const { checkReport } = this.state;
        return checkReport[index];
    };

    render() {
        const {
            checkReport, isLoading, columns, searchTerm, error, success,
        } = this.state;
        return (
            <div className="container-fluid">
                <Breadcrumbs crumbName="Site Report" />
                <h1>Check Request Report</h1>
                <div className="row justify-content-between mb-1">
                    <div className="d-flex col-7 align-items-center">
                        <input
                            className="mt-0"
                            placeholder="Search for report"
                            onChange={this.handleInput}
                            value={searchTerm}
                        />
                        <ButtonPrimary onClick={this.getReport}>
                            Submit
                        </ButtonPrimary>
                    </div>
                    <div className="col-1 me-5">
                        <ButtonPrimary onClick={this.downloadReport}>
                            Download Report
                        </ButtonPrimary>
                    </div>
                    {error && (
                        <ErrorMessage
                            message={error}
                            onDismiss={() => this.setState({ error: null })}
                        />
                    )}
                    {success && (
                        <SuccessMessage
                            message={success}
                            onDismiss={() => this.setState({ success: null })}
                        />
                    )}
                </div>
                <div className="checkrequest-report-grid">
                    {isLoading ? (
                        <div className="checkrequest-report-loading">
                            <Loading message="Loading Data..." />
                        </div>
                    )
                        : (
                            <ReactDataGrid
                                columns={columns}
                                rowsCount={checkReport.length}
                                rowGetter={this.getRows}
                                minHeight={650}
                                emptyRowsView={RecordPlaceholder}
                            />
                        )}
                </div>
            </div>
        );
    }
}

export default ReportView;

ReportView.propTypes = {
    location: PropTypes.instanceOf(Object),
};

ReportView.defaultProps = {
    location: {
        search: '',
    },
};
