import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { DataSourceDocumentType } from '../../../../_services/GeotrakService/Models';
import useDeleteDataSourceDocumentType from '../../../../_services/GeotrakService/hooks/useDeleteDataSourceDocumentType';
import '../documentTypes.css';

const deleteButton = ({ dataSourceDocumentType, moduleId, roleId }) => {
    const {
        deleteDataSourceDocumentType, error, isLoading, isSuccessful,
    } = useDeleteDataSourceDocumentType();

    if (!dataSourceDocumentType.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSourceDocumentType.dataSourceId}/document-types`} />;
    }

    return (
        <div className="document-type-toolbar document-type-button">
            {error && <div className="text-danger document-type-error" id="delete-document-type-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-document-type-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteDataSourceDocumentType(dataSourceDocumentType)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    dataSourceDocumentType: PropTypes.instanceOf(DataSourceDocumentType),
    moduleId: PropTypes.string,
    roleId: PropTypes.string,
};

deleteButton.defaultProps = {
    dataSourceDocumentType: new DataSourceDocumentType(),
    moduleId: null,
    roleId: null,
};
