import BaseCheckRequestService from './BaseCheckRequestService';

export default class CheckRequestConfigService extends BaseCheckRequestService {
    async getRequestFormFields(project, requestType, role) {
        return this.fetch(`/check-request-config/form-fields/project/${project}/type/${requestType}?&role=${role}`);
    }

    async getRoleActions(role) {
        return this.fetch(`/check-request-config/actions?&role=${role}`);
    }

    async getVoidActions(checkRequestId) {
        return this.fetch(`/void-actions/${checkRequestId}`)
            .catch((err) => Promise.reject(err));
    }

    async getQueues() {
        return this.fetch('/check-request-config/queues');
    }

    async getRequestAdmin() {
        return this.fetch('/check-request-config/admin-requester');
    }

    async getDeliverToOffice(deliverTo) {
        return this.fetch(`/check-request-config/deliver-to-office/${deliverTo}`);
    }

    async getRequestTypes(projectTag) {
        return this.fetch(`/check-request-config/request-types/projectTag/${projectTag}`)
            .catch((err) => Promise.reject(err));
    }

    async getTableFields() {
        return this.fetch('/check-request-config/table-fields');
    }

    async getRequiredDocumentCount(project, requestType) {
        return this.fetch(`/check-request-config/required-documents/project/${project}/type/${requestType}`);
    }
}
