import PropTypes from 'prop-types';
import { NotificationField } from '../Models';

class ICreateNotificationFieldRequest {
    constructor() {
        this.data = {};
        this.notificationId = null;
    }

    static load(notificationField) {
        if (!notificationField) {
            throw Error('notification field is required');
        }
        if (!(notificationField instanceof NotificationField)) {
            throw Error('Unexpected type for notification field');
        }

        const request = new ICreateNotificationFieldRequest();
        request.data = notificationField;
        request.notificationId = notificationField.notificationId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.notificationId) {
            throw Error('notification id is required');
        }
    }
}

export default ICreateNotificationFieldRequest;

ICreateNotificationFieldRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
    notificationId: PropTypes.string,
};
