import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';
import CheckRequestTable from '../../components/CheckRequest/CheckRequestTable';

const archive = () => {
    const [tableWidth, setTableWidth] = useState(window.innerWidth);
    const ref = useRef(null);

    useEffect(() => {
        setTableWidth(ref.current ? ref.current.offsetWidth : window.innerWidth);
    }, [ref.current]);

    return (
        <>
            <div className="bg-light">
                <Breadcrumbs crumbName="Archived Check Requests" />
                <div className="container-fluid">
                    <h1 className="text-center">Archived Check Requests</h1>
                    <div className="md-col-12" ref={ref}>
                        <CheckRequestTable subset="Complete" tableWidth={tableWidth} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default archive;
