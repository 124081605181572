import { SET_QUERY, RESET_QUERY } from '../CONSTANTS/types';
import { buildQuery } from '../components/QueryBuilder/query-helpers';

export const setQuery = (dataSource, view, searchQuery) => ({
    type: SET_QUERY,
    activeQuery: buildQuery(dataSource, view, searchQuery),
});

export const resetQuery = () => ({
    type: RESET_QUERY,
});
