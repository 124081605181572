import PropTypes from 'prop-types';
import DocumentType from './DocumentType';

export default class DataSourceDocumentType {
    constructor() {
        this.id = null;
        this.dataSourceId = null;
        this.documentType = new DocumentType();
    }

    static load(response) {
        const dataSourceDocumentType = new DataSourceDocumentType();

        if (response) {
            dataSourceDocumentType.id = response.id;
            dataSourceDocumentType.dataSourceId = response.dataSourceId;
            dataSourceDocumentType.documentType = DocumentType.load(response.documentType);
        }

        return dataSourceDocumentType;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('dataSourceId is required');
        }
        if (!this.documentType.id) {
            throw Error('documentTypeId is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            dataSourceId: this.dataSourceId,
            documentTypeId: this.documentType.id,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

DataSourceDocumentType.propTypes = {
    id: PropTypes.number,
    dataSourceId: PropTypes.string,
    documentType: PropTypes.instanceOf(DocumentType),
};
