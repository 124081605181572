import { useEffect, useState } from 'react';
import { DataSource } from '../../../_services/GeotrakService/Models';
import useGetDataSource from '../../../_services/GeotrakService/hooks/useGetDataSource';
import useGetRole from '../../../_services/GeotrakService/hooks/useGetRole';

const useDataSourceForm = (id, roleId) => {
    const { role } = useGetRole(roleId);
    let dataSource = new DataSource();
    let isLoading = false;

    if (id) {
        const result = useGetDataSource(id);
        dataSource = result.dataSource;
        isLoading = result.isLoading;
    }

    const [values, setValues] = useState({
        id: null,
        databaseReference: '',
        schemaReference: '',
        tableReference: '',
        name: '',
        alias: '',
        searchFieldName: '',
        searchFieldAlias: '',
        category: '',
        dataType: '',
        serviceType: '',
        uniqueIdField: '',
        isSecure: false,
        isMapDefaultOn: false,
        isInitialDataSource: false,
        isHiddenOnMap: false,
        isHiddenOnData: false,
        url: '',
        query: '',
    });

    const isDataReferenceValid = () => values.databaseReference !== '' && values.schemaReference !== '' && values.tableReference !== '';

    const isLabelValid = () => values.name !== '' && values.alias !== '';

    const isClassificationValid = () => values.category !== '' && values.dataType !== '';

    const isSearchFieldValid = () => values.uniqueIdField !== '' && values.searchFieldName !== '' && values.searchFieldAlias !== '';

    const isValid = () => isDataReferenceValid() && isLabelValid() && isClassificationValid() && isSearchFieldValid();

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    const useStringPlaceholder = (value) => value || '';

    useEffect(() => {
        if (id) {
            setValues({
                id: dataSource.id || null,
                databaseReference: useStringPlaceholder(dataSource.databaseReference),
                schemaReference: useStringPlaceholder(dataSource.schemaReference),
                tableReference: useStringPlaceholder(dataSource.tableReference),
                name: useStringPlaceholder(dataSource.name),
                alias: useStringPlaceholder(dataSource.alias),
                searchFieldName: useStringPlaceholder(dataSource.searchFieldName),
                searchFieldAlias: useStringPlaceholder(dataSource.searchFieldAlias),
                category: useStringPlaceholder(dataSource.category),
                dataType: useStringPlaceholder(dataSource.dataType),
                serviceType: useStringPlaceholder(dataSource.serviceType),
                uniqueIdField: useStringPlaceholder(dataSource.uniqueIdField),
                isSecure: dataSource.isSecure || false,
                isMapDefaultOn: dataSource.isMapDefaultOn || false,
                isInitialDataSource: dataSource.isInitialDataSource || false,
                isHiddenOnMap: dataSource.isHiddenOnMap || false,
                isHiddenOnData: dataSource.isHiddenOnData || false,
                url: useStringPlaceholder(dataSource.url),
                query: useStringPlaceholder(dataSource.query),
            });
        }
    }, [dataSource, role]);

    return {
        values,
        isLoading,
        onFormUpdate,
        isValid,
        role,
    };
};

export default useDataSourceForm;
