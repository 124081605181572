import React from 'react';
import PropTypes from 'prop-types';
import { MdLock } from 'react-icons/md';
import './stylesheets/ColumnLock.css';

const columnLock = ({ text, isEditable }) => (
    <div>
        {!isEditable && <MdLock className="align-text-top column-lock" />}
        {text && <span id="column-lock-label">{text}</span>}
    </div>
);

columnLock.propTypes = {
    text: PropTypes.string,
    onContextMenu: PropTypes.func,
    isEditable: PropTypes.bool,
};

columnLock.defaultProps = {
    text: '',
    onContextMenu: () => null,
    isEditable: false,
};

export default columnLock;
