import { useReducer } from 'react';
import MapService from '../MapService';
import IUpdateMapRequest from '../interfaces/IUpdateMapRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateMap = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const updateMap = async (mapObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateMapRequest.load(mapObject);
            const result = await new MapService().updateMap(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateMap, error, isLoading, isSuccessful, data,
    };
};

export default useUpdateMap;
