import PropTypes from 'prop-types';

class IDownloadFileRequest {
    constructor() {
        this.fileName = null;
        this.s3Key = null;
    }

    static load(fileName, s3Key) {
        if (!fileName) {
            throw Error('file name is required');
        }
        if (!s3Key) {
            throw Error('s3 key is required');
        }

        const request = new IDownloadFileRequest();
        request.fileName = fileName;
        request.s3Key = s3Key;
        return request;
    }
}

export default IDownloadFileRequest;

IDownloadFileRequest.propTypes = {
    fileName: PropTypes.string,
    s3Key: PropTypes.string,
};
