import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../../utilComponents/LoadingFullScreen';
import useGetOffices from '../../../../_services/CheckRequestService/hooks/useGetOffices';
import Breadcrumbs from '../../../../utilComponents/Breadcrumbs';
import AdminContainer from '../../AdminContainer';
import './offices.css';

const adminCheckRequestOffices = () => {
    const history = useHistory();
    const { offices, isLoading } = useGetOffices();

    if (isLoading) {
        return <LoadingFullScreen id="office-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Offices">
                <ButtonContainer>
                    <ButtonPrimary id="new-office-button" onClick={() => history.push('/admin/check-request/check-request-offices/new')}>
                        New Office
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group office-container">
                    {offices.map((office) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action"
                            key={office.id}
                            onClick={() => history.push(`/admin/check-request/check-request-offices/${office.id}`)}
                        >
                            <span id="office-name">{office.name}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminCheckRequestOffices;
