import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import DocumentTypeService from '../DocumentTypeService';

const useDeleteDataSourceDocumentType = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteDataSourceDocumentType = async (dataSourceDocumentType) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new DocumentTypeService().deleteDataSourceDocumentType(dataSourceDocumentType.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteDataSourceDocumentType, error, isLoading, isSuccessful,
    };
};

export default useDeleteDataSourceDocumentType;
