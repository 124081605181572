import moment from 'moment';
import BaseCheckRequestService from './BaseCheckRequestService';

export default class CheckRequestDownloadService extends BaseCheckRequestService {
    async downloadRequests(body) {
        try {
            return this.download('/download',
                `CheckRequests ${moment().format('MM-DD-YYYY')}.xlsx`,
                { method: 'POST', body: JSON.stringify(body) });
        } catch (err) {
            return Promise.reject(err);
        }
    }

    getReportDownload(report, queryParams = '') {
        return this.download(
            `/download/reports/${report}?${queryParams}`,
            `report-${report}.xlsx`,
            { cacheBuster: true }
        );
    }
}
