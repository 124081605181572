export const filter = (predicate) => (arr) => arr.filter(predicate);
export const map = (fn) => (arr) => arr.map(fn);
export const reduce = (fn, initial) => (arr) => arr.reduce(fn, initial);
export const sort = (fn) => (arr) => arr.slice().sort(fn);
export const forEach = (fn) => (arr) => arr.forEach(fn);
export const apply = (arg, fn) => fn(arg);
export const pipe = (...fns) => (arg) => reduce(apply, arg)(fns);
export const has = (prop) => (obj) => Object.prototype.hasOwnProperty.call(obj, prop);
export const identity = (val) => val;
export const pluck = (prop) => (obj) => obj[prop];
export const sortBy = (prop) => (a, b) => {
    if (b[prop] > a[prop]) {
        return -1;
    }
    if (a[prop] > b[prop]) {
        return 1;
    }
    return 0;
};
export const keys = (obj) => Object.keys(obj);
export const includes = (obj) => (arr) => arr.includes(obj);
export const find = (fn) => (arr) => arr.find(fn);
export const propEq = (prop, val) => (obj) => obj[prop] === val;
export const not = (fn) => (arg) => !fn(arg);
