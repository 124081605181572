import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MapConfig } from '../_services/GeotrakService/Models';
import './stylesheets/MapContextMenu.css';

class MapContextMenu extends PureComponent {
  componentDidMount = () => {
      const { closeMenu } = this.props;
      document.addEventListener('click', closeMenu);
  };

  componentWillUnmount = () => {
      const { closeMenu } = this.props;
      document.removeEventListener('click', closeMenu);
  };

  render() {
      const {
          top, left, hasConfirm, confirmLocation, cancelMoving, startMovingLocation, visible, mapConfig,
      } = this.props;

      if (visible) {
          return (
              <div
                  style={{ top, left }}
                  className="map-context-menu position-absolute"
              >
                  {hasConfirm && (
                      <div className="d-flex flex-column">
                          <button
                              onClick={confirmLocation}
                              type="button"
                              className="btn"
                          >
                              Confirm
                          </button>
                          <button
                              onClick={cancelMoving}
                              type="button"
                              className="btn"
                          >
                              Cancel
                          </button>
                      </div>
                  )}
                  {mapConfig.isSpatialAdmin && !hasConfirm && (
                      <div>
                          <button
                              onClick={startMovingLocation}
                              type="button"
                              className="btn"
                          >
                              Move Location
                          </button>
                      </div>
                  )}
              </div>
          );
      }
      return (
          null
      );
  }
}

export default MapContextMenu;

MapContextMenu.propTypes = {
    left: PropTypes.number,
    top: PropTypes.number,
    closeMenu: PropTypes.func,
    startMovingLocation: PropTypes.func,
    cancelMoving: PropTypes.func,
    confirmLocation: PropTypes.func,
    hasConfirm: PropTypes.bool,
    visible: PropTypes.bool,
    mapConfig: PropTypes.instanceOf(MapConfig),
};

MapContextMenu.defaultProps = {
    startMovingLocation: () => null,
    confirmLocation: () => null,
    cancelMoving: () => null,
    hasConfirm: false,
    closeMenu: () => null,
    top: 0,
    left: 0,
    visible: false,
    mapConfig: new MapConfig(),
};
