import React from 'react';
import './stylesheets/NotFound.css';

const expiredToken = () => (
    <div className="NotFound">
        <h3>Your token has expired. Please re-login to continue using GeoTRAK.</h3>
    </div>
);

export default expiredToken;
