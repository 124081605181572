import { useReducer } from 'react';
import NotificationFieldService from '../../NotificationFieldService';
import ICreateNotificationFieldRequest from '../../interfaces/ICreateNotificationFieldRequest';
import { ACTIONS, useApiReducer } from '../../../../helpers/hooks/useApiReducer';

const useCreateNotificationField = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const createNotificationField = async (notificationFieldObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = ICreateNotificationFieldRequest.load(notificationFieldObject);
            const result = await new NotificationFieldService().createNotificationField(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        createNotificationField, error, isLoading, isSuccessful, data,
    };
};

export default useCreateNotificationField;
