import PropTypes from 'prop-types';

class IUpdateDatabasePasswordRequest {
    constructor() {
        this.data = {
            newPassword: null,
        };
    }

    static load(newPassword) {
        if (!newPassword) {
            throw Error('new password is required');
        }

        const request = new IUpdateDatabasePasswordRequest();

        request.data.newPassword = newPassword;

        return request;
    }
}

export default IUpdateDatabasePasswordRequest;

IUpdateDatabasePasswordRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
