import PropTypes from 'prop-types';
import { FavoriteView } from '../Models';

class ICreateFavoriteViewRequest {
    constructor() {
        this.dataSourceId = null;
        this.data = {};
    }

    static load(favoriteView) {
        if (!favoriteView) {
            throw Error('favorite view is required');
        }
        if (!(favoriteView instanceof FavoriteView)) {
            throw Error('unexpected type for favorite view');
        }

        const request = new ICreateFavoriteViewRequest();
        request.dataSourceId = favoriteView.dataSourceId;
        request.data = favoriteView;
        request.validate();
        return request;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('dataSourceId is required');
        }
        if (!this.data.viewName) {
            throw Error('viewName is required');
        }
    }
}

export default ICreateFavoriteViewRequest;

ICreateFavoriteViewRequest.propTypes = {
    dataSourceId: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
