import { useEffect, useState } from 'react';
import useGetNotification from '../../../_services/NotificationService/hooks/useGetNotification';
import { Notification } from '../../../_services/NotificationService/Models';

const useNotificationForm = (id) => {
    let notification = new Notification();
    let isLoading = false;

    if (id) {
        const result = useGetNotification(id);
        notification = result.notification;
        isLoading = result.isLoading;
    }

    const [values, setValues] = useState({
        id: null,
        database: '',
        schema: '',
        table: '',
        changeField: '',
        uniqueIdField: '',
        template: '',
        whereClause: '',
        isActive: false,
    });

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    const isDataReferenceValid = () => values.database !== '' && values.schema !== '' && values.table !== '';

    const isFieldReferenceValid = () => values.changeField !== '' && values.uniqueIdField !== '';

    const isValid = () => isDataReferenceValid() && isFieldReferenceValid() && values.template !== '' && values.whereClause !== '';

    useEffect(() => {
        if (id) {
            setValues({
                id: notification.id || null,
                database: notification.database || '',
                schema: notification.schema || '',
                table: notification.table || '',
                changeField: notification.changeField || '',
                uniqueIdField: notification.uniqueIdField || '',
                template: notification.template || '',
                whereClause: notification.whereClause || '',
                isActive: notification.isActive || false,
            });
        }
    }, [notification]);

    return {
        values,
        isLoading,
        onFormUpdate,
        isValid,
    };
};

export default useNotificationForm;
