import PropTypes from 'prop-types';

export default class GeotrakKey {
    constructor(
        id,
        moduleName,
        projectName,
        roleId,
        roleAppReference,
        layerId,
        layerDatabaseReference,
        layerTableReference
    ) {
        this.id = id || '00000000-0000-0000-0000-000000000000';
        this.moduleName = moduleName;
        this.projectName = projectName;
        this.roleId = roleId;
        this.roleAppReference = roleAppReference;
        this.layerName = layerId;
        this.layerDatabaseReference = layerDatabaseReference;
        this.layerTableReference = layerTableReference;
    }
}

GeotrakKey.propTypes = {
    id: PropTypes.string,
    moduleName: PropTypes.string,
    projectName: PropTypes.string,
    roleId: PropTypes.string,
    roleAppReference: PropTypes.string,
    layerName: PropTypes.string,
    layerDatabaseReference: PropTypes.layerDatabaseReference,
    layerTableReference: PropTypes.layerTableReference,
};
