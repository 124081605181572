import PropTypes from 'prop-types';
import { Market } from '../Models';

class ICreateMarketRequest {
    constructor() {
        this.data = {};
    }

    static load(market) {
        if (!market) {
            throw Error('market is required');
        }
        if (!(market instanceof Market)) {
            throw Error('Unexpected type for market');
        }

        const request = new ICreateMarketRequest();
        request.data = market;
        return request;
    }
}

export default ICreateMarketRequest;

ICreateMarketRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
