import React from 'react';
import PropTypes from 'prop-types';

const moduleDescription = ({ activeRole }) => {
    if (activeRole) {
        return (
            <div className="card rounded-0 m-2">
                <div className="card-body">
                    <div>
                        <span><strong>Application: </strong></span>
                        <span>{activeRole.module.name}</span>
                    </div>
                    <div>
                        <span><strong>Role: </strong></span>
                        <span>{activeRole.name}</span>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export default moduleDescription;

moduleDescription.propTypes = {
    activeRole: PropTypes.instanceOf(Object),
};

moduleDescription.defaultProps = {
    activeRole: null,
};
