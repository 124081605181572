import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useUpdateMap from '../../../../_services/GeotrakService/hooks/useUpdateMap';
import { MapConfig } from '../../../../_services/GeotrakService/Models';
import '../maps.css';

const updateButton = ({ map, isValid, moduleId }) => {
    const {
        updateMap, error, isLoading, isSuccessful,
    } = useUpdateMap();

    if (!map.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${map.moduleRoleId}`} />;
    }

    return (
        <div className="map-toolbar map-button">
            {error && <div className="text-danger map-error" id="update-map-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-map-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateMap(map)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    map: PropTypes.instanceOf(MapConfig),
    isValid: PropTypes.func,
    moduleId: PropTypes.string,
};

updateButton.defaultProps = {
    map: new MapConfig(),
    isValid: () => null,
    moduleId: null,
};
