import React, { Component } from 'react';
import propTypes from 'prop-types';
import Loading from '../../utilComponents/LoaderSpinner';
import generateInputTag from '../../helpers/inputTagFactory';
import { ButtonCharcoal } from '../../utilComponents/styledComponents';
import UploadFileManager from '../../components/CheckRequest/UploadFileManager';
import LoaderButton from '../../utilComponents/LoaderButton';
import { isNullOrEmpty } from '../../helpers/stringUtils';
import { isFieldRequired, isFieldVisible } from '../../components/CheckRequest/utils/formRules';

export default class FormCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filesReady: false,
            files: [],
        };
    }

  renderInputs = () => {
      const {
          fieldConfig, handleFormUpdate, requestForm, autopopulateDeliverTo, requiredFormFields,
      } = this.props;
      return fieldConfig.map((field) => {
          const hasFieldOptions = field.Options && field.Options.length;
          const options = {
              id: field.Name,
              maxLength: field.MaxLength,
              label: field.DisplayName,
              handler: handleFormUpdate,
              required: isFieldRequired(requiredFormFields, field),
              value: requestForm[field.Name],
              type: hasFieldOptions ? 'select' : field.Type,
              options: hasFieldOptions ? field.Options.map((option) => option.Name) : [],
          };

          if (!isFieldRequired(requiredFormFields, field)) {
              delete requiredFormFields[field.Name];
          }

          // handle conditionally rendered fields
          if (isFieldVisible(autopopulateDeliverTo, requiredFormFields, field)) {
              return (
                  <div className="col-md-3 col-sm-6">
                      {generateInputTag(options)}
                  </div>
              );
          }

          return null;
      });
  };

  isFormValid = () => {
      const { requiredFormFields, minimumRequiredDocuments, error } = this.props;
      const { filesReady, files } = this.state;
      if (!requiredFormFields || files.length < minimumRequiredDocuments || error) {
          return false;
      }

      return isNullOrEmpty(requiredFormFields) && filesReady;
  };

  handleFileStatusUpdate = (filesReady, files) => {
      this.setState({ filesReady, files });
  };

  render() {
      const {
          handleStartRequest,
          handleSubmitForm,
          isLoading,
          fieldConfig,
          minimumRequiredDocuments,
          error,
      } = this.props;
      const { files } = this.state;
      const loading = !error ? <Loading /> : null;

      return (
          <div id="check-request-form-card" className="pb-5">
              <div className="d-flex justify-content-between">
                  <h3 className="fw-bold">Create New Check Request</h3>
                  <ButtonCharcoal
                      onClick={() => handleStartRequest('pending')}
                      id="check-request-back-button"
                  >
                      Back
                  </ButtonCharcoal>
              </div>
              {fieldConfig && (
                  <div>
                      <div className="row" id="check-request-form-container">
                          {fieldConfig.length ? this.renderInputs() : loading}
                      </div>
                      {fieldConfig.length > 0 && (
                          <div className="row">
                              <div className="ms-3">
                                  <UploadFileManager
                                      minimumRequiredDocuments={minimumRequiredDocuments}
                                      onFileStatusUpdate={this.handleFileStatusUpdate}
                                  />
                              </div>
                              <div className="text-center">
                                  <LoaderButton
                                      disabled={!this.isFormValid()}
                                      onClick={() => handleSubmitForm(files)}
                                      isLoading={isLoading}
                                      text="Submit Check Request"
                                      loadingText="Submitting…"
                                      id="submitCheckRequest"
                                  />
                              </div>
                          </div>
                      )}
                  </div>
              )}
          </div>
      );
  }
}

FormCard.propTypes = {
    minimumRequiredDocuments: propTypes.number,
    handleFormUpdate: propTypes.func,
    requestForm: propTypes.instanceOf(Object),
    handleSubmitForm: propTypes.func,
    isLoading: propTypes.bool.isRequired,
    handleStartRequest: propTypes.func,
    autopopulateDeliverTo: propTypes.bool,
    fieldConfig: propTypes.instanceOf(Array),
    requiredFormFields: propTypes.instanceOf(Object),
    error: propTypes.string,
};

FormCard.defaultProps = {
    minimumRequiredDocuments: 0,
    requestForm: {},
    fieldConfig: [],
    autopopulateDeliverTo: false,
    handleFormUpdate: () => null,
    handleSubmitForm: () => null,
    handleStartRequest: () => null,
    requiredFormFields: {},
    error: null,
};
