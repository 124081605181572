import PropTypes from 'prop-types';
import { Notification } from '../Models';

class ICreateNotificationRequest {
    constructor() {
        this.data = {};
    }

    static load(notification) {
        if (!notification) {
            throw Error('notification is required');
        }
        if (!(notification instanceof Notification)) {
            throw Error('Unexpected type for notification');
        }

        const request = new ICreateNotificationRequest();
        request.data = notification;
        return request;
    }
}

export default ICreateNotificationRequest;

ICreateNotificationRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
