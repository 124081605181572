import PropTypes from 'prop-types';
import { Autopopulation } from '../Models';

class IUpdateAutopopulationRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(autopopulation) {
        if (!autopopulation) {
            throw Error('autopopulation is required');
        }
        if (!(autopopulation instanceof Autopopulation)) {
            throw Error('Unexpected type for autopopulation');
        }

        const request = new IUpdateAutopopulationRequest();
        request.id = autopopulation.id;
        request.data = autopopulation;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateAutopopulationRequest;

IUpdateAutopopulationRequest.propTypes = {
    id: PropTypes.string,
    data: PropTypes.instanceOf(Autopopulation),
};
