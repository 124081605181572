import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import DocumentTypeService from '../DocumentTypeService';

const useDeleteDocumentType = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteDocumentType = async (documentType) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new DocumentTypeService().deleteDocumentType(documentType.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteDocumentType, error, isLoading, isSuccessful,
    };
};

export default useDeleteDocumentType;
