import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatabaseService } from '../_services/GeotrakService';
import IGetTablesRequest from '../_services/GeotrakService/interfaces/IGetTablesRequest';

const selectTable = ({
    selectedDatabase, selectedTable, onTableSelected, selectedSchema,
}) => {
    const [tables, setTables] = useState([]);
    const [database, setDatabase] = useState(selectedDatabase || '');
    const [value, setValue] = useState(selectedTable || '');
    const [error, setError] = useState(null);
    const databaseService = new DatabaseService();

    const getTables = async () => {
        try {
            const request = IGetTablesRequest.load(selectedDatabase, selectedSchema);
            const results = await databaseService.getTables(request);
            setTables(results);
        } catch (err) {
            setError(err);
        }
    };

    const onTableChanged = (e) => {
        setValue(e);
        onTableSelected(e);
    };

    useEffect(() => {
        if (selectedDatabase && selectedSchema) {
            setDatabase(selectedDatabase);
            getTables();
            if (database !== selectedDatabase) {
                setValue('');
            } else {
                setValue(selectedTable);
            }
        }
        if (!database) {
            setValue(selectedTable || '');
        }
    }, [selectedDatabase, selectedTable, selectedSchema]);

    if (error) {
        return (
            <div id="select-table-error" className="text-danger">{`ERROR: ${error.message || 'An error occurred.'}`}</div>
        );
    }

    return (
        <div className="mb-3">
            <label htmlFor="select-table" className="form-label">
                Table
                {' '}
                <span className="text-danger">*</span>
            </label>
            <select
                id="select-table"
                value={value}
                onChange={(e) => onTableChanged(e.target.value)}
            >
                <option disabled value="">--Select a table--</option>
                {tables.map((table) => (
                    <option value={table} id="select-table-option" key={table}>{table}</option>
                ))}
            </select>
        </div>
    );
};

export default selectTable;

selectTable.propTypes = {
    selectedDatabase: PropTypes.string,
    selectedTable: PropTypes.string,
    onTableSelected: PropTypes.func,
    selectedSchema: PropTypes.string,
};

selectTable.defaultProps = {
    selectedDatabase: null,
    selectedTable: null,
    onTableSelected: () => null,
    selectedSchema: 'GeoMRE',
};
