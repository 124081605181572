import PropTypes from 'prop-types';
import { ModuleWidget } from '../Models';

class ICreateModuleWidgetRequest {
    constructor() {
        this.data = {};
        this.moduleRoleId = null;
    }

    static load(moduleWidget) {
        if (!moduleWidget) {
            throw Error('widget is required');
        }
        if (!(moduleWidget instanceof ModuleWidget)) {
            throw Error('Unexpected data type for widget');
        }

        const request = new ICreateModuleWidgetRequest();
        request.data = moduleWidget;
        request.moduleRoleId = moduleWidget.moduleRoleId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.moduleRoleId) {
            throw Error('role id is required');
        }
    }
}

export default ICreateModuleWidgetRequest;

ICreateModuleWidgetRequest.propTypes = {
    data: PropTypes.instanceOf(ModuleWidget),
    moduleRoleId: PropTypes.string,
};
