import {
    flaskEndpoint,
    acquireFlaskToken
} from './APIUtils';
import putRequest from './HTTPRequests';
import UserService from '../_services/UserService/UserService';

export const editData = (database, table, pk, pkValue, fields) => putRequest(`/data/${database}/${table}`, {
    userid: new UserService().getCurrentUserId(),
    pk,
    id: pkValue,
    field: fields,
});

export const uploadFile = async (file, dataSourceId) => {
    const data = new FormData();
    data.append('file', file);
    data.append('dataSourceId', dataSourceId);
    data.append('userId', new UserService().getCurrentUserId());
    return fetch(`${flaskEndpoint}/tools/upload/excel`, {
        method: 'POST',
        async: true,
        crossDomain: true,
        headers: {
            Authorization: `Bearer ${await acquireFlaskToken()}`,
        },
        processData: false,
        mimeType: 'multipart/form-data',
        body: data,
    });
};
