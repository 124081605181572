import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckRequestActionService } from '../../../_services';
import LoaderButton from '../../../utilComponents/LoaderButton';

class Finalize extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isWorking: false,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  finalize = () => {
      this.setState({ isWorking: true });
      const {
          requestForm, checkRequestId, refreshQueues, onError,
      } = this.props;
      const form = {};
      requestForm.map((field) => {
          form[field.Name] = field.value;
          return field;
      });
      form.CurrentStage = null;
      this.CheckRequestActionService.updateRequest(checkRequestId, form).then(() => {
          this.setState({ isWorking: false });
          refreshQueues();
      }).catch((err) => {
          this.setState({ isWorking: false });
          onError(err.message || 'An error occurred finalizing request.');
      });
  }

  render() {
      const { isWorking } = this.state;
      const { isReadyToSubmit } = this.props;
      return (
          <LoaderButton
              onClick={this.finalize}
              isLoading={isWorking}
              disabled={isReadyToSubmit()}
              text="Finalize"
              loadingText="Finalizing..."
          />
      );
  }
}

export default Finalize;

Finalize.propTypes = {
    requestForm: PropTypes.instanceOf(Array),
    checkRequestId: PropTypes.number,
    isReadyToSubmit: PropTypes.func,
    refreshQueues: PropTypes.func,
    onError: PropTypes.func,
};

Finalize.defaultProps = {
    requestForm: [],
    checkRequestId: 0,
    isReadyToSubmit: () => null,
    refreshQueues: () => null,
    onError: () => null,
};
