import PropTypes from 'prop-types';

export default class Office {
    constructor(name, address, city, state, zipCode, id = null) {
        this.name = name;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.id = id;
    }

    static load(response) {
        return new Office(
            response.Name,
            response.Address,
            response.City,
            response.State,
            response.PostalCode,
            response.Id
        );
    }

    validate() {
        if (!this.name || this.name === '') {
            throw Error('Name is required');
        }

        if (!this.address || this.address === '') {
            throw Error('Address is required');
        }

        if (!this.city || this.city === '') {
            throw Error('City is required');
        }

        if (!this.zipCode || this.zipCode === '') {
            throw Error('Zipcode is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            Name: this.name,
            Address: this.address,
            City: this.city,
            State: this.state,
            PostalCode: this.zipCode,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

Office.propTypes = {
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    id: PropTypes.number,
};
