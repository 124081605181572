import React from 'react';
import withWizard from '../../../utilComponents/hocs/withWizard';
import AppPicker from './AppPicker';
import DataSourcePicker from './DataSourcePicker';
import ViewPicker from './ViewPicker';
import ViewDetails from './ViewDetails';
import ViewFields from './ViewFields';
import Success from './Success';

const steps = {
    SET_APP: AppPicker,
    SET_DATA_SOURCE: DataSourcePicker,
    SET_VIEW: ViewPicker,
    SET_VIEW_DETAILS: ViewDetails,
    SET_VIEW_FIELDS: ViewFields,
};

const viewBuilder = () => {
    const isRootView = (viewName) => viewName === 'All';

    const ViewWizard = withWizard(steps, Success, { isRootView });
    return (
        <div className="container">
            <div className="col-md-12">
                <ViewWizard />
            </div>
        </div>
    );
};

export default viewBuilder;
