import PropTypes from 'prop-types';
import { Module } from '../Models';

class ICreateModuleRequest {
    constructor() {
        this.data = {};
    }

    static load(module) {
        if (!module) {
            throw Error('module is required');
        }
        if (!(module instanceof Module)) {
            throw Error('Unexpected type for module');
        }

        const request = new ICreateModuleRequest();
        request.data = module;
        request.validate();
        return request;
    }

    validate() {
        if (!this.data.project.id) {
            throw Error('projectId is required');
        }
        if (!this.data.unit.id) {
            throw Error('unitId is required');
        }
    }
}

export default ICreateModuleRequest;

ICreateModuleRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
