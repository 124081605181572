import PropTypes from 'prop-types';
import { DataSource } from '../Models';

class IUpdateDataSourceRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(dataSource) {
        if (!dataSource) {
            throw Error('dataSource is required');
        }
        if (!(dataSource instanceof DataSource)) {
            throw Error('Unexpected type for dataSource');
        }

        const request = new IUpdateDataSourceRequest();
        request.id = dataSource.id;
        request.data = dataSource;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateDataSourceRequest;

IUpdateDataSourceRequest.propTypes = {
    id: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
