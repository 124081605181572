import { useEffect, useReducer } from 'react';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';
import AutopopulateService from '../AutopopulateService';

const useGetAutopopulations = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: [] });
    const { data, isLoading, error } = state;

    const getAutopopulations = async () => {
        try {
            const results = await new AutopopulateService().getAutopopulations();
            dispatch({ type: ACTIONS.SUCCESS, data: results });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getAutopopulations();
    }, []);

    return { autopopulations: data, error, isLoading };
};

export default useGetAutopopulations;
