import React from 'react';
import PropTypes from 'prop-types';
import '../stylesheets/ButtonSuccess.css';

export const ButtonSuccess = ({
    onClick, children,
}) => (
    <button
        type="button"
        className="btn btn-success success-button"
        onClick={onClick}
    >
        {children}
    </button>
);

ButtonSuccess.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

ButtonSuccess.defaultProps = {
    onClick: () => null,
    children: null,
};

export default ButtonSuccess;
