import BaseGeoTrakService from './BaseGeoTrakService';
import { Role } from './Models';

const NO_SUFFIX_LENGTH = 2;

export default class RoleService extends BaseGeoTrakService {
    getRolesByModuleProjectEndsWith(moduleName, projectName, endsWith) {
        if (!moduleName || !projectName || !endsWith) {
            return null;
        }

        return this.getRoles().then((roles) => {
            const projectRoles = roles.filter(
                (role) => role.module.project.name === projectName && role.module.unit.name === moduleName
            );
            // convert them all to make them consistent
            const consistentRoles = projectRoles.map((role) => {
                const slots = role.name.split('-');
                if (slots.length === NO_SUFFIX_LENGTH) {
                    const noDash = role;
                    noDash.name += '-*';
                    return noDash;
                }
                return role;
            });

            return consistentRoles.filter((f) => f.name.endsWith(`-${endsWith}`));
        });
    }

    async getRoles() {
        return this.fetch('/roles').then((roles) => roles.map((role) => Role.load(role)));
    }

    async getRole(id) {
        return this.fetch(`/roles/${id}`).then((role) => Role.load(role));
    }

    async createRole(request) {
        return this.post('/roles', request.data.loadSqlEntity()).then((role) => Role.load(role));
    }

    async updateRole(request) {
        const { id, data } = request;
        return this.put(`/roles/${id}`, data.loadSqlEntity()).then((role) => Role.load(role));
    }

    async deleteRole(id) {
        return this.delete(`/roles/${id}`);
    }
}
