const deliverToFields = [
    'DeliverToCompany',
    'DeliverToAddress',
    'DeliverToCity',
    'DeliverToState',
    'DeliverToZip',
    'DeliverToPhone',
];

export default deliverToFields;
