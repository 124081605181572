import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetRoles from '../../../_services/GeotrakService/hooks/useGetRoles';
import './roles.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminRoles = ({ match }) => {
    const history = useHistory();
    const { roles, isLoading } = useGetRoles();
    const moduleRoles = roles.filter((role) => role.module.id === match.params.moduleId);

    if (isLoading) {
        return <LoadingFullScreen id="role-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Roles">
                <ButtonContainer>
                    <ButtonPrimary id="new-role-button" onClick={() => history.push(`/admin/modules/${match.params.moduleId}/roles/new`)}>
                        New Role
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group">
                    {moduleRoles.map((role) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action text-start"
                            id={role.name}
                            key={role.id}
                            onClick={() => history.push(`/admin/modules/${match.params.moduleId}/roles/${role.id}`)}
                        >
                            <span id="role-name">{`${role.name} : ${role.alias}`}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminRoles;
