import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

export default class SelectPrimary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            required: false,
            formValue: '',
        };
    }

    componentDidMount() {
        const { required } = this.props;
        this.setState({ required });
    }

    handleChange = (e) => {
        const { required, handleChange } = this.props;
        const { value } = e.target;
        if (required && !value) {
            this.setState({ formValue: value, required: true });
        } else {
            this.setState({ formValue: value, required: false });
        }
        return handleChange(e);
    };

    render() {
        const {
            label, placeholder, options, value, id, isReadOnly,
        } = this.props;
        const { required, formValue } = this.state;
        const defaultNoMarket = options.length === 1 && options[0];
        return (
            <div className="mb-3">
                {label ? <label htmlFor={id} className="fw-bold form-label">{label}</label> : null}
                <select
                    id={id}
                    className={`${required && !value && 'border border-danger'}`}
                    required={required}
                    placeholder={placeholder}
                    onChange={(e) => this.handleChange(e)}
                    value={defaultNoMarket || value || formValue}
                    disabled={isReadOnly}
                >
                    <option disabled value="">
                        {placeholder}
                    </option>
                    {options.sort().map((option) => (
                        <option key={uuidv4()} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

SelectPrimary.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
    value: PropTypes.string,
};

SelectPrimary.defaultProps = {
    value: '',
    options: [],
    isReadOnly: false,
};
