import BaseNodeService from '../BaseNodeService';
import CoordinatePair from '../../helpers/spatialUtils/CoordinatePair';

export default class GeocodeService extends BaseNodeService {
    constructor() {
        const url = '/gis-service';
        const port = 3007;
        super(url, port);

        this.route = '/geocode';
    }

    addressToLatLng(request) {
        return this.post(`${this.route}/addresstolatlng`, request.data)
            .then((result) => {
                if (!Array.isArray(result) || result.length < 1) {
                    return Promise.reject(new Error('no results found'));
                }
                return new CoordinatePair(result[0].geometry.location.lat, result[0].geometry.location.lng);
            });
    }

    latLngToAddress(request) {
        return this.post(`${this.route}/latlngtoaddress`, request.data)
            .then((result) => {
                if (!Array.isArray(result) || result.length < 1) {
                    return Promise.reject(new Error('no results found'));
                }
                return result[0].formatted_address;
            });
    }
}
