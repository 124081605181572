import React from 'react';
import PropTypes from 'prop-types';

const rowRenderer = ({ renderBaseRow, row, ...canvasProps }) => (
    <div key={row.SiteId} className={row.isSelected ? 'selected' : ''}>
        {renderBaseRow({ ...canvasProps, row })}
    </div>
);

rowRenderer.propTypes = {
    renderBaseRow: PropTypes.func,
    row: PropTypes.instanceOf(Object),
};

rowRenderer.defaultProps = {
    renderBaseRow: () => null,
    row: PropTypes.instanceOf(Object),
};

export default rowRenderer;
