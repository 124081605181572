import BaseCommentService from './BaseCommentService';

export default class CommentApplicationService extends BaseCommentService {
    async getApplicationId(params) {
        return this.fetch(`/application?${params}`);
    }

    async postApplication(body) {
        return this.post('/application', body);
    }

    async getChildApplications(applicationId) {
        const appId = applicationId ? `?application=${applicationId}` : '';
        return this.fetch(`/application/children${appId}`);
    }
}
