import PropTypes from 'prop-types';
import RequestForm from './RequestForm';
import RequestRole from './RequestRole';

export default class RequestFormRole {
    constructor(requestForm, requestRole, order, id = null) {
        this.requestForm = requestForm;
        this.requestRole = requestRole;
        this.order = order;
        this.id = id;
    }

    static load(response) {
        return new RequestFormRole(
            RequestForm.load(response.RequestForm),
            RequestRole.load(response.RequestRole),
            response.Order,
            response.Id
        );
    }

    validate() {
        if (!this.order) {
            throw Error('Field order is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            RequestFormId: this.requestForm.id,
            RequestRoleId: this.requestRole.id,
            Order: this.order,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

RequestFormRole.propTypes = {
    requestForm: PropTypes.instanceOf(RequestForm),
    requestRole: PropTypes.instanceOf(RequestRole),
    order: PropTypes.number,
    id: PropTypes.number,
};
