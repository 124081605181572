import { useReducer } from 'react';
import AutopopulateService from '../AutopopulateService';
import IUpdateAutopopulationRequest from '../interfaces/IUpdateAutopopulationRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateAutopopulation = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const updateAutopopulation = async (autopopulationObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateAutopopulationRequest.load(autopopulationObject);
            const result = await new AutopopulateService().updateAutopopulation(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateAutopopulation, error, isLoading, isSuccessful, data,
    };
};

export default useUpdateAutopopulation;
