import BaseNodeService from '../BaseNodeService';
import SiteKey from './interfaces/SiteKey';

export default class SiteService extends BaseNodeService {
    constructor() {
        const url = '/site-service';
        const port = 3005;
        super(url, port);
    }

    async getAvailableD3Extracts(dataSource) {
        const siteKey = SiteKey.load(dataSource);
        const url = `/extract/project/${siteKey.project}`;
        return this.fetch(url);
    }

    downloadD3Extract(siteId, templateId, filename) {
        const url = `/turf/sites/${siteId}/extract/${templateId}`;
        return this.download(url, filename);
    }
}
