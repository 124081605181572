import { CLOSE_WIDGETS, OPEN_WIDGET, TOGGLE_WIDGET } from '../CONSTANTS/types';

export const toggleWidget = (widget) => ({
    type: TOGGLE_WIDGET,
    widget,
});

export const openWidget = (widget) => ({
    type: OPEN_WIDGET,
    widget,
});

export const closeWidgets = () => ({
    type: CLOSE_WIDGETS,
});
