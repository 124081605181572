import { useState, useEffect } from 'react';
import useGetNotificationDomain from '../../../_services/NotificationService/hooks/domain/useGetNotificationDomain';
import { NotificationDomain } from '../../../_services/NotificationService/Models';

const useNotificationDomainsForm = (id) => {
    let isLoading = false;
    let notificationDomain = new NotificationDomain();

    if (id) {
        const result = useGetNotificationDomain(id);
        notificationDomain = result.notificationDomain;
        isLoading = result.isLoading;
    }

    const [values, setValues] = useState({
        name: '',
    });

    const isValid = () => values.name !== '';

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                name: notificationDomain.name || '',
            });
        }
    }, [notificationDomain]);

    return {
        isLoading,
        isValid,
        onFormUpdate,
        values,
    };
};

export default useNotificationDomainsForm;
