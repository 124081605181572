import React from 'react';
import PropTypes from 'prop-types';

const unsupportedFormFieldControl = function statelessFunctionComponentClass(props) {
    const style = {
        display: 'inline-block',
        marginBottom: '0',
    };

    const { formField: { width, fieldName } } = props;
    const columnWidth = width ? `col-sm-${width}` : 'col-sm-12';

    return (
        <div
            className={`form-group form-component-datecontrol has-warning ${columnWidth}`}
            key={fieldName}
        >
            <label htmlFor={fieldName} style={style} className="form-label">
                {fieldName}
            </label>
            <input
                type="text"
                className="form-control"
                id={fieldName}
                name={fieldName}
                readOnly
            />
            <span id={`helpBlock${fieldName}`} className="help-block">
                Metadata or Schema does not exist
            </span>
        </div>
    );
};

export default unsupportedFormFieldControl;

unsupportedFormFieldControl.propTypes = {
    formField: PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
};
