import React from 'react';
import { useHistory } from 'react-router-dom';
import GeotrakNotificationManager from '../../helpers/GeotrakNotificationManager/GeotrakNotificationManager';

const notificationList = () => {
    const history = useHistory();
    const geotrakNotificationManager = new GeotrakNotificationManager();
    const { notifications } = geotrakNotificationManager;
    const hasNotifications = notifications.length > 0;

    const onNotificationClick = (notification) => {
        if (notification.navigation) {
            history.push(notification.navigation);
        }
        geotrakNotificationManager.dismissNotification(notification);
    };

    return (
        <div className="text-start m-2 overflow-y-auto w-100">
            <h5>
                Notifications
                {hasNotifications && (
                    <span className=" ms-2 badge bg-danger text-white">
                        {notifications.length}
                    </span>
                )}
            </h5>
            {!hasNotifications && (
                <div className="card rounded-0">
                    <div className="card-body bg-light">
                        <div className="fst-italic text-center">
                            You have no new notifications.
                        </div>
                    </div>
                </div>
            )}
            {notifications.map((notification) => (
                <button
                    className={`d-block text-center text-wrap alert alert-${notification.alertLevel} border border-info`}
                    onClick={() => onNotificationClick(notification)}
                    type="button"
                    key={notification.id}
                >
                    {notification.message}
                </button>
            ))}
        </div>
    );
};

export default notificationList;
