import PropTypes from 'prop-types';

export default class S3File {
    constructor() {
        this.id = null;
        this.databaseName = null;
        this.tableName = null;
        this.externalKeyColumnName = null;
        this.externalKey = null;
        this.s3Key = null;
        this.s3Bucket = null;
        this.documentType = null;
        this.folder = null;
        this.fileName = null;
        this.createdOn = null;
        this.createdBy = null;
        this.compressedFilename = null;
        this.s3ValidatedOn = null;
        this.updatedOn = null;
        this.updatedBy = null;
    }

    static load(response) {
        const s3File = new S3File();

        if (response) {
            s3File.id = response.id;
            s3File.databaseName = response.databaseName;
            s3File.tableName = response.tableName;
            s3File.externalKeyColumnName = response.externalKeyColumnName;
            s3File.externalKey = response.externalKey;
            s3File.s3Key = response.s3Key;
            s3File.s3Bucket = response.s3Bucket;
            s3File.documentType = response.documentType;
            s3File.folder = response.folder;
            s3File.fileName = response.fileName;
            s3File.createdOn = response.createdOn;
            s3File.createdBy = response.createdBy;
            s3File.compressedFilename = response.compressedFilename;
            s3File.s3ValidatedOn = response.s3ValidatedOn;
            s3File.updatedBy = response.updatedBy;
            s3File.updatedOn = response.updatedOn;
        }

        return s3File;
    }
}

S3File.propTypes = {
    id: PropTypes.string,
    databaseName: PropTypes.string,
    tableName: PropTypes.string,
    externalKeyColumnName: PropTypes.string,
    externalKey: PropTypes.string,
    s3Key: PropTypes.string,
    s3Bucket: PropTypes.string,
    documentType: PropTypes.string,
    folder: PropTypes.string,
    fileName: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    compressedFilename: PropTypes.string,
    s3ValidatedOn: PropTypes.string,
    updatedOn: PropTypes.string,
    updatedBy: PropTypes.string,
};
