import React, { Component } from 'react';
import update from 'immutability-helper';
import { DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
import DragCard from './DragCard';
import { areObjectsEqual } from '../helpers/utilities';
import './stylesheets/DragContainer.css';

class DragContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { cards: props.list };
    }

  componentDidUpdate = (prevProps) => {
      const { list } = this.props;
      if (!areObjectsEqual(prevProps.list, list)) {
          this.updateList();
      }
  };

  updateList = () => {
      const { list } = this.props;
      this.setState({ cards: list });
  }

  pushCard(card) {
      this.setState((previousState) => update(previousState, {
          cards: {
              $push: [card],
          },
      }));
  }

  removeCard(index) {
      const { removedFrom, id } = this.props;
      const { cards } = this.state;
      removedFrom([id, cards[index]]);
  }

  moveCard(dragIndex, hoverIndex) {
      const { cardOrder } = this.props;
      const { cards } = this.state;
      const dragCard = cards[dragIndex];
      const updated = update(this.state, {
          cards: {
              $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
          },
      });
      this.setState(() => ({ cards: updated.cards }), () => {
          cardOrder(updated.cards);
      });
  }

  render() {
      const { cards } = this.state;
      const {
          canDrop, isOver, connectDropTarget, uniqueIdField, id,
      } = this.props;
      const isActive = canDrop && isOver;

      return connectDropTarget(
          <div className={`drag-container ${isActive && 'active'}`}>
              {cards.map((card, i) => (
                  <DragCard
                      key={card.id}
                      index={i}
                      uniqueIdField={uniqueIdField}
                      listId={id}
                      card={card}
                      removeCard={(index) => this.removeCard(index)}
                      moveCard={(dragIndex, hoverIndex) => this.moveCard(dragIndex, hoverIndex)}
                  />
              ))}
          </div>
      );
  }
}

const cardTarget = {
    drop(props, monitor, component) {
        const { id } = props;
        const sourceObj = monitor.getItem();
        if (id !== sourceObj.listId) {
            component.pushCard(sourceObj.card);
        }
        return {
            listId: id,
        };
    },
};

export default DropTarget('CARD', cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
}))(DragContainer);

DragContainer.propTypes = {
    canDrop: PropTypes.bool,
    cardOrder: PropTypes.func,
    connectDropTarget: PropTypes.func,
    id: PropTypes.string,
    isOver: PropTypes.bool,
    list: PropTypes.instanceOf(Array),
    removedFrom: PropTypes.func,
    uniqueIdField: PropTypes.string,
};

DragContainer.defaultProps = {
    canDrop: false,
    cardOrder: () => null,
    connectDropTarget: () => null,
    list: [],
    isOver: false,
    removedFrom: () => null,
    uniqueIdField: null,
    id: '',
};
