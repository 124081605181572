import { useReducer } from 'react';
import RendererService from '../RendererService';
import IUpdateRendererRequest from '../interfaces/IUpdateRendererRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateRenderer = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const updateRenderer = async (rendererObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateRendererRequest.load(rendererObject);
            const result = await new RendererService().updateRenderer(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateRenderer, error, isLoading, isSuccessful, data,
    };
};

export default useUpdateRenderer;
