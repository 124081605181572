import { combineReducers } from 'redux';
import ActiveConfigurationReducer from './ActiveConfigurationReducer';
import WidgetReducer from './WidgetReducer';
import TableDatareducer from './TableDataReducer';
import ConfigReducer from './ConfigReducer';
import AlertsReducer from './AlertsReducer';
import PageReducer from './PageReducer';
import SelectedFeatureCollectionReducer from './SelectedFeatureCollectionReducer';
import SelectedFeatureReducer from './SelectedFeatureReducer';
import DrawControllerReducer from './DrawControllerReducer';
import DrawCompleteControllerReducer from './DrawCompleteControllerReducer';
import mapUIReducer from './MapReducer';
import DataGridFilterReducer from './DataColumnsFilterReducer';
import DataRefreshReducer from './DataRefreshReducer';

const rootReducer = combineReducers({
    activeConfiguration: ActiveConfigurationReducer,
    alerts: AlertsReducer,
    tableData: TableDatareducer,
    selectedFeatureCollection: SelectedFeatureCollectionReducer,
    selectedFeature: SelectedFeatureReducer,
    drawController: DrawControllerReducer,
    drawCompleteController: DrawCompleteControllerReducer,
    widgets: WidgetReducer,
    page: PageReducer,
    config: ConfigReducer,
    mapUI: mapUIReducer,
    dataColumnsFilter: DataGridFilterReducer,
    refreshTableData: DataRefreshReducer,
});

export default rootReducer;
