import PropTypes from 'prop-types';
import { Domain } from '../Models';

class ICreateDomainRequest {
    constructor() {
        this.data = {};
    }

    static load(domain) {
        if (!domain) {
            throw Error('domain is required');
        }

        if (!(domain instanceof Domain)) {
            throw Error('Unexpected type for domain');
        }

        const request = new ICreateDomainRequest();
        request.data = domain;
        return request;
    }
}

export default ICreateDomainRequest;

ICreateDomainRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
