module.exports = {
    INFORMATIONAL: {
        CONTINUE: 100,
    },
    SUCCESS: {
        OK: 200,
        CREATED: 201,
        NO_CONTENT: 204,
    },
    REDIRECTION: {
        MULTIPLE_CHOICES: 300,
        MOVED_PERMANENTLY: 301,
        NOT_MODIFIED: 304,
    },
    CLIENT_ERROR: {
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        CONFLICT: 409,
        IM_A_TEAPOT: 418,
    },
    SERVER_ERROR: {
        INTERNAL_SERVER_ERROR: 500,
        NOT_IMPLEMENTED: 501,
    },
};
