import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useDeleteMarket from '../../../../_services/GeotrakService/hooks/useDeleteMarket';
import { Market } from '../../../../_services/GeotrakService/Models';
import '../markets.css';

const deleteButton = ({ market }) => {
    const {
        deleteMarket, error, isLoading, isSuccessful,
    } = useDeleteMarket();

    if (!market.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/markets" />;
    }

    return (
        <div className="market-toolbar market-button">
            {error && <div className="text-danger market-error" id="delete-market-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-market-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteMarket(market)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    market: PropTypes.instanceOf(Market),
};

deleteButton.defaultProps = {
    market: new Market(),
};
