import React, { useState, useEffect } from 'react';
import { ButtonPrimary, ButtonContainer } from '../../../utilComponents/styledComponents';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import RequestBuilder from './RequestBuilder';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const checkRequestAdminService = new CheckRequestAdminService();

const adminCheckRequestRequests = () => {
    const [requestForms, setRequestForms] = useState([]);
    const [requestFormId, setRequestFormId] = useState(null);
    const [newRequestForm, setNewRequestForm] = useState(false);
    const [error, setError] = useState(null);

    const getRequestForms = () => {
        checkRequestAdminService.getRequestForms().then((result) => {
            setRequestForms(result);
        }).catch((err) => {
            setError(`Error Getting Forms. ${err.message || err}`);
        });
    };

    const renderProjectRequestForms = (project) => requestForms.filter(
        (requestForm) => requestForm.project.name === project
    ).map((requestForm) => (
        <button
            className="list-group-item list-group-item-action text-start"
            type="button"
            key={requestForm.id}
            onClick={() => setRequestFormId(requestForm.id)}
        >
            {requestForm.requestType.alias}
        </button>
    ));

    const renderProjects = () => {
        const projects = [...new Set(requestForms.map((requestForm) => requestForm.project.name))];
        return projects.map((project) => (
            <div key={project}>
                <h3 className="Admin">{project}</h3>
                <div className="list-group">
                    {renderProjectRequestForms(project)}
                </div>
            </div>
        ));
    };

    useEffect(() => {
        getRequestForms();
    }, []);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Check Request Forms">
                {(newRequestForm || requestFormId)
                    ? <RequestBuilder requestFormId={requestFormId} />
                    : (
                        <>
                            <ButtonContainer
                                error={error}
                                onErrorDismiss={() => setError(null)}
                            >
                                <ButtonPrimary onClick={() => setNewRequestForm(true)}>
                                    Create New
                                </ButtonPrimary>
                            </ButtonContainer>
                            {renderProjects()}
                        </>
                    )}
            </AdminContainer>
        </>
    );
};

export default adminCheckRequestRequests;
