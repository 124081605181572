import PropTypes from 'prop-types';

export default class ViewField {
    constructor(name, alias, isEditable, order) {
        this.name = name;
        this.alias = alias;
        this.isEditable = isEditable;
        this.order = order;
    }

    static load(response) {
        return new ViewField(
            response.FieldName,
            response.FieldAlias,
            response.IsEditable,
            response.FieldOrder
        );
    }
}

ViewField.propTypes = {
    name: PropTypes.string,
    alias: PropTypes.string,
    isEdditable: PropTypes.bool,
    order: PropTypes.number,
};
