import React, { useState } from 'react';
import { MdWarning } from 'react-icons/md';
import { ButtonSuccess, ButtonDanger } from '../../../../utilComponents/styledComponents';
import NotificationService from '../../../../_services/NotificationService/NotificationService';
import '../notifications.css';

const toggleActivationButton = ({ row }) => {
    const [notification, setNotification] = useState(row);
    const [error, setError] = useState(null);
    const notificationService = new NotificationService();

    const toggleStatus = async () => {
        try {
            const result = await notificationService.toggleNotificationStatus(notification.id);
            setNotification(result);
        } catch (err) {
            setError(err);
        }
    };

    const Button = notification.isActive ? ButtonDanger : ButtonSuccess;
    return (
        <div className="d-grid">
            <Button
                bsSize="small"
                id="toggle-notification-button"
                onClick={toggleStatus}
            >
                <span className="notification-view-edit-button-contents">

                    {(!error && notification.isActive) && 'Deactivate'}
                    {(!error && !notification.isActive) && 'Activate'}
                    {error && (
                        <>
                            <MdWarning size={15} className="notification-view-edit-button-icon" />
                            Error
                        </>
                    )}
                </span>
            </Button>
        </div>
    );
};

export default toggleActivationButton;
