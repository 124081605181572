import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AlertList } from 'react-bs-notifier';
import PropTypes from 'prop-types';
import { removeAlertThunk } from '../../actions/errorActions';
import './AlertSystem.css';

// todo: (moley) 2022-11-29 refactor the redux version so timeout can be passed
//                          as a prop, instead of hacky hard-coding
const ALERT_TYPE_SUCCESS = 'success';
const ALERT_TIMEOUT_FREEZE = 0;
const ALERT_TIMEOUT_FADE_AWAY = 2500;

class AlertSystem extends Component {
  onAlertDismissed = (alert) => {
      const { removeAlertAction } = this.props;
      removeAlertAction(alert.id);
  };

  render() {
      const { alerts } = this.props;
      const nonSuccess = alerts.find((f) => f.type !== ALERT_TYPE_SUCCESS);
      const timeout = nonSuccess ? ALERT_TIMEOUT_FREEZE : ALERT_TIMEOUT_FADE_AWAY;
      return (
          <AlertList
              id="alerts-list"
              position="top-right"
              alerts={alerts}
              timeout={timeout}
              onDismiss={this.onAlertDismissed}
          />
      );
  }
}

const mapStateToProps = ({ alerts }) => ({
    alerts,
});

const mapDispatchToProps = {
    removeAlertAction: removeAlertThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertSystem);

AlertSystem.propTypes = {
    removeAlertAction: PropTypes.func,
    alerts: PropTypes.instanceOf(Array),
};

AlertSystem.defaultProps = {
    removeAlertAction: () => null,
    alerts: [],
};
