import PropTypes from 'prop-types';

export default class DataType {
    constructor(type, length) {
        this.length = length;
        this.type = type;
    }

    static load(responseObj) {
        return new DataType(responseObj.type, responseObj.length);
    }
}

DataType.propTypes = {
    length: PropTypes.number,
    type: PropTypes.string,
};
