import { View } from './Actions';

const config = [
    {
        key: 'id',
        name: 'Id',
        editable: false,
        width: 100,
        resizable: true,
        filterable: true,
    },
    {
        key: 'condition',
        name: 'Condition',
        editable: false,
        filterable: true,
        resizable: true,
    },
    {
        key: 'field',
        name: 'Field',
        editable: false,
        width: 150,
        filterable: true,
        resizable: true,
    },
    {
        key: 'value',
        name: 'Value',
        editable: false,
        width: 400,
        filterable: true,
        resizable: true,
    },
    {
        key: 'database',
        name: 'Database',
        editable: false,
        width: 150,
        filterable: true,
        resizable: true,
    },
    {
        key: 'table',
        name: 'Table',
        editable: false,
        width: 150,
        filterable: true,
        resizable: true,
    },
    {
        key: 'Edit',
        name: 'Edit',
        editable: false,
        width: 120,
        resizable: true,
        formatter: View,
    },
];

export default config;
