import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdDelete, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ViewService } from '../../../_services/GeotrakService';
import { ButtonContainer, ButtonDanger, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import LoaderButton from '../../../utilComponents/LoaderButton';
import IUpdateSystemViewFieldsRequest from '../../../_services/GeotrakService/interfaces/IUpdateSystemViewFieldsRequest';
import ViewBuilderSummary from './ViewBuilderSummary';
import './views.css';
import ViewImport from './ViewImport';
import IGetViewRequest from '../../../_services/GeotrakService/interfaces/IGetViewRequest';
import FieldAdder from './FieldAdder';

const viewFields = ({ jobProperties, previousStep, nextStep }) => {
    const {
        application, role, dataSource, view, uniqueIdField, dataSources,
    } = jobProperties;
    const [fields, setFields] = useState([]);
    const [fieldPickList, setFieldPickList] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(false);
    const viewService = new ViewService();

    const getFieldPickList = () => {
        const selectedDataSource = dataSources.find((source) => source.name === dataSource);
        const request = IGetViewRequest.load(selectedDataSource.id);
        return viewService.getSystemView(request).then(
            (viewRecord) => viewRecord.fields
        ).catch((err) => {
            setError(`Error Getting Field Options. ${err.message || err}`);
            return [];
        });
    };

    const addIdField = (fieldList, pickList) => {
        const idFields = fieldList.filter((field) => field.name.toLowerCase() === uniqueIdField.toLowerCase());
        if (idFields.length > 0) {
            setFields(fieldList);
        } else {
            const idField = pickList.filter(
                (field) => field.name.toLowerCase() === uniqueIdField.toLowerCase()
            )[0];
            setFields([idField, ...fieldList]);
        }
    };

    const getViewFields = () => {
        const selectedDataSource = dataSources.find((source) => source.name === dataSource);
        const request = IGetViewRequest.load(selectedDataSource.id, view.name);
        viewService.getSystemView(request).then(async (viewRecord) => {
            const pickList = await getFieldPickList();
            if (Array.isArray(pickList)) {
                setFieldPickList([...pickList].sort((a, b) => ((a.alias > b.alias) ? 1 : -1)));
                addIdField(viewRecord.fields, pickList);
            }
        }).catch((err) => {
            setError(`Error Getting View Fields. ${err.message || err}`);
        });
    };

    const reorderFields = (result) => {
        if (result.destination) {
            const fieldList = Array.from(fields);
            const [removed] = fieldList.splice(result.source.index, 1);
            fieldList.splice(result.destination.index, 0, removed);
            setFields(fieldList);
        }
    };

    const addField = (fieldAdded) => {
        const addedField = fieldPickList.filter((field) => field.name === fieldAdded)[0];
        setFields([...fields, addedField]);
    };

    const deleteField = (field) => {
        const fieldList = Array.from(fields);
        const newFieldList = fieldList.filter((fieldObject) => fieldObject !== field);
        setFields(newFieldList);
    };

    const saveFields = () => {
        setIsSaving(true);
        view.loadFields(fields);
        const selectedDataSource = dataSources.find((source) => source.name === dataSource);
        const request = IUpdateSystemViewFieldsRequest.load(view, selectedDataSource.id);
        viewService.updateSystemViewFields(request).then(() => {
            setIsSaving(false);
            nextStep();
        }).catch((err) => {
            setIsSaving(false);
            setError(`Error Updating View. ${err.message || err}`);
        });
    };

    const getAvailableFields = () => fieldPickList.filter(
        ({ name: pickName }) => !fields.some(({ name: fieldName }) => fieldName === pickName)
    );

    const isFirstField = (index) => index === 0;

    const isLastField = (index) => index === fields.length - 1;

    const isUniqueIdField = (field) => field.name.toLowerCase() === uniqueIdField.toLowerCase();

    const availableFields = getAvailableFields();

    const clearFields = () => {
        const newFieldList = fields.filter((field) => isUniqueIdField(field));
        setFields(newFieldList);
    };

    const canDelete = (field) => !view.isRootView() && !isUniqueIdField(field);

    const updateEditPermissions = (importFields) => importFields.map((field) => {
        const updatedField = { ...field };
        const pickerField = fieldPickList.find((pickField) => pickField.name === field.name);
        if (pickerField) {
            updatedField.isEditable = pickerField.isEditable;
        }
        return updatedField;
    });

    const onFieldImport = (importedFields) => {
        const validFieldNames = fieldPickList.map((field) => field.name.toLowerCase());
        const validFieldAliases = fieldPickList.map((field) => field.alias.toLowerCase());
        const validFields = importedFields.filter(
            (field) => validFieldNames.includes(field.name.toLowerCase())
            && validFieldAliases.includes(field.alias.toLowerCase())
        );
        const updatedFields = updateEditPermissions(validFields);
        addIdField(updatedFields, fieldPickList);
        setFields(updatedFields);
    };

    const renderFields = () => fields.map((field, idx) => (
        <Draggable key={field.name} draggableId={field.name} index={idx}>
            {(provided, snapshot) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <div className={`row list-group-item view-list-dragging-${snapshot.isDragging}`} key={field.name}>
                        {canDelete(field) && <div className="col-md-1"><MdDelete size={20} className="view-list-button" onClick={() => deleteField(field)} /></div>}
                        <div className={`col-md-11 ${canDelete(field) ? '' : 'offset-md-1'} d-flex align-items-center`}>
                            <div className="col-md-11 d-flex justify-content-start text-start">
                                {field.alias}
                            </div>
                            <div className="col-md-1 d-flex flex-column">
                                {!isFirstField(idx) && (
                                    <MdKeyboardArrowUp
                                        size={20}
                                        className="view-list-button"
                                        onClick={() => reorderFields({
                                            destination: { index: idx - 1 }, source: { index: idx },
                                        })}
                                    />
                                )}
                                {!isLastField(idx) && (
                                    <MdKeyboardArrowDown
                                        size={20}
                                        className="view-list-button"
                                        onClick={() => reorderFields({
                                            destination: { index: idx + 1 }, source: { index: idx },
                                        })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    ));

    const renderFieldForm = () => (
        <ButtonContainer>
            {(availableFields.length > 0 && !view.isRootView()) && (
                <FieldAdder
                    fields={availableFields}
                    addField={addField}
                />
            )}
            {!view.isRootView() && <ButtonDanger onClick={clearFields}>Clear Fields</ButtonDanger>}
            {!view.isRootView() && <ViewImport onFieldImport={onFieldImport} />}
        </ButtonContainer>
    );

    useEffect(() => {
        getViewFields();
    }, []);

    return (
        <div className="col-md-8 offset-md-2 text-center">
            <h2 className="Admin">Fields</h2>
            <ViewBuilderSummary application={application} role={role} dataSource={dataSource} view={view.name} />
            {renderFieldForm()}
            <DragDropContext onDragEnd={reorderFields}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            className="list-group view-list"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {fields.length > 0 ? renderFields() : <LoadingFullScreen />}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <ButtonContainer
                className="mt-2"
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <LoaderButton
                    onClick={saveFields}
                    disabled={fields.length < 1}
                    isLoading={isSaving}
                    text="Finish"
                    loadingText="Saving..."
                />
                <ButtonPrimary onClick={previousStep}>
                    Back
                </ButtonPrimary>
            </ButtonContainer>
        </div>
    );
};

export default viewFields;

viewFields.propTypes = {
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    jobProperties: PropTypes.instanceOf(Object),
};

viewFields.defaultProps = {
    nextStep: () => null,
    previousStep: () => null,
    jobProperties: {
        view: {},
        application: '',
        role: '',
        dataSource: '',
        uniqueIdField: '',
    },
};
