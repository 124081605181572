import BaseGeoTrakService from './BaseGeoTrakService';
import { MapConfig } from './Models';

export default class MapService extends BaseGeoTrakService {
    async getMap(id) {
        return this.fetch(`/maps/${id}`).then((map) => MapConfig.load(map));
    }

    async getModuleMap(moduleRoleId) {
        return this.fetch(`/module-roles/${moduleRoleId}/map`).then((map) => MapConfig.load(map));
    }

    async createMap(request) {
        return this.post('/maps', request.data.loadSqlEntity());
    }

    async updateMap(request) {
        const { id, data } = request;
        return this.put(`/maps/${id}`, data.loadSqlEntity());
    }

    async deleteMap(id) {
        return this.delete(`/maps/${id}`);
    }
}
