import QueryParser from 'react-two-way-querybuilder/dist/helpers/QueryParser';
import { DataSource, View } from '../../_services/GeotrakService/Models';

export const supportedOperators = [
    { operator: '=', label: '=' },
    { operator: '<>', label: '<>' },
    { operator: '<', label: '<' },
    { operator: '>', label: '>' },
    { operator: '>=', label: '>=' },
    { operator: '<=', label: '<=' },
    { operator: 'is null', label: 'Null' },
    { operator: 'is not null', label: 'Not Null' },
];

export const supportedCombinators = [
    { combinator: 'Choose Operator', label: '--Choose Operator--' },
    { combinator: ' AND ', label: 'And' },
    { combinator: ' OR ', label: 'Or' },
];

export const setCombinatorValues = (query) => {
    if (!query) {
        return;
    }

    const matched = query.match(/( OR | AND | NOT )/g);
    const viewCombinators = matched && Array.from(matched.every((v) => v === matched[0]) ? matched : new Set(matched));
    const customSelects = document.querySelectorAll('.condition > .custom-select');
    if (viewCombinators && customSelects) {
        customSelects.forEach((value, i) => {
            const currentSelector = value;
            currentSelector.value = viewCombinators[i];
        });
    }
};

/*
 * Original implementation https://github.com/Lefortov/react-two-way-querybuilder/blob/ca37d37c875566f68099a0553219d3e3b56288b9/src/helpers/QueryParser.js#L60
 */
export const getTokensArray = (query, inputCombinators, inputOperators) => {
    const combinatorsIndexes = QueryParser.getCombinatorsIndexes(query, inputCombinators);
    const tokens = [];
    let token = '';
    for (let i = 0, { length } = query; i < length; i += 1) {
        const combinatorIndexes = combinatorsIndexes.find((x) => x.start === i);
        if (combinatorIndexes) {
            const combinator = query.substring(combinatorIndexes.start, combinatorIndexes.end - 1);
            token = QueryParser.pushTokenIfNotEmpty(token, tokens, inputOperators);
            tokens.push(combinator);
            i = combinatorIndexes.end - 1;
        } else if (query[i] === '(' || query[i] === ')') {
            token = QueryParser.pushTokenIfNotEmpty(token, tokens, inputOperators);
            tokens.push(query[i]);
        } else {
            token += query[i];
        }
    }
    return tokens;
};

export const updateQueryBuilderFieldOptions = (fieldOptions, formattedQuery) => {
    try {
        const queryBuilderOptions = [...fieldOptions];
        const queryParsedToData = QueryParser.parseToData(formattedQuery || '()', {
            query: formattedQuery,
            operators: supportedOperators,
            combinators: supportedCombinators,
        });
        if (queryParsedToData) {
            const { rules } = queryParsedToData;
            rules.forEach((rule) => {
                queryBuilderOptions.forEach((field, ii) => {
                    if (rule.field.toUpperCase() === field.name.toUpperCase()) {
                        queryBuilderOptions[ii].name = rule.field;
                    }
                });
                rules.pop();
            });
        }
        return queryBuilderOptions;
    } catch (err) {
        return [];
    }
};

const extractViewQuery = (view) => {
    // temporarily need both of these statements until all views arrays can be replaced with new View object
    if (view instanceof View) {
        return view.query;
    }
    if (view && view.length) {
        const firstField = view[0];
        return (firstField.Query);
    }
    return null;
};

export const buildQuery = (dataSource, view, searchQuery = null) => {
    let dataSourceQuery;
    if (dataSource instanceof DataSource) {
        dataSourceQuery = dataSource.query;
    } else {
        dataSourceQuery = dataSource.Query;
    }
    const viewQuery = extractViewQuery(view);
    let finalQuery;
    if (searchQuery) {
        finalQuery = (!dataSourceQuery || searchQuery.includes(dataSourceQuery)) ? searchQuery : `${dataSourceQuery} AND ${searchQuery}`;
    } else if (dataSourceQuery && viewQuery) {
        finalQuery = `${dataSourceQuery} AND ${viewQuery}`;
    } else {
        finalQuery = dataSourceQuery || viewQuery;
    }
    return finalQuery;
};

export const validateQuery = (query) => {
    try {
        if (!query) {
            return true;
        }
        const combinators = supportedCombinators.slice(1);
        QueryParser.parseToData(query, { query, combinators, operators: supportedOperators });
        return true;
    } catch (error) {
        return false;
    }
};
