import React from 'react';
import PropTypes from 'prop-types';
import '../stylesheets/ButtonPrimary.css';

export const ButtonPrimary = ({
    title, onClick, disabled, className, children, type,
}) => (
    <button
        type={type === 'submit' ? 'submit' : 'button'}
        className={`btn btn-default text-white primary-button ${className}`}
        disabled={disabled}
        onClick={onClick}
        title={title}
    >
        {children}
    </button>
);

ButtonPrimary.propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button']),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

ButtonPrimary.defaultProps = {
    title: '',
    type: 'button',
    onClick: () => null,
    disabled: false,
    className: '',
    children: null,
};

export default ButtonPrimary;
