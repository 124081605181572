import PropTypes from 'prop-types';
import { Office } from '../Models';

class ICreateOfficeRequest {
    constructor() {
        this.data = {};
    }

    static load(office) {
        if (!office) {
            throw Error('office is required');
        }
        if (!(office instanceof Office)) {
            throw Error('Unexpected type for office');
        }

        const request = new ICreateOfficeRequest();
        request.data = office;
        return request;
    }
}

export default ICreateOfficeRequest;

ICreateOfficeRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
