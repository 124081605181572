import BaseNotificationService from './BaseNotificationService';
import { NotificationDomain } from './Models';

export default class NotificationDomainService extends BaseNotificationService {
    async getNotificationDomains(notificationId) {
        return this.fetch(`/notification/${notificationId}/domains`)
            .then((domains) => domains.map((domain) => NotificationDomain.load(domain)));
    }

    async getNotificationDomain(id) {
        return this.fetch(`/notification-domains/${id}`).then((domain) => NotificationDomain.load(domain));
    }

    async createNotificationDomain(request) {
        const { notificationId, data } = request;
        return this.post(`/notification/${notificationId}/domains`, data.loadSqlEntity())
            .then((domain) => NotificationDomain.load(domain));
    }

    async deleteNotificationDomain(id) {
        return this.delete(`/notification-domains/${id}`);
    }
}
