import BaseGeoTrakService from './BaseGeoTrakService';
import { Autopopulation } from './Models';

export default class AutopopulateService extends BaseGeoTrakService {
    async getAutopopulations() {
        return this.fetch('/autopopulate').then((autopopulations) => autopopulations.map((autopopulation) => Autopopulation.load(autopopulation)));
    }

    async getAutopopulation(id) {
        return this.fetch(`/autopopulate/${id}`).then((autopopulation) => Autopopulation.load(autopopulation));
    }

    async createAutopopulation(request) {
        return this.post('/autopopulate', request.data.loadSqlEntity());
    }

    async updateAutopopulation(request) {
        const { id, data } = request;
        return this.put(`/autopopulate/${id}`, data);
    }

    async deleteAutopopulation(id) {
        return this.delete(`/autopopulate/${id}`);
    }
}
