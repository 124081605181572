import PropTypes from 'prop-types';
import FieldResult from './FieldResult';

export default class BulkFieldResults {
    constructor() {
        this.results = [];
        this.errors = [];
        this.totalRecords = null;
        this.totalSuccesses = null;
        this.totalErrors = null;
        this.hasErrors = false;
    }

    validate() {
        this.totalSuccesses = this.results.length;
        this.totalErrors = this.errors.length;
        this.totalRecords = this.totalSuccesses + this.totalErrors;
        this.hasErrors = this.totalErrors > 0;
    }

    push(fieldResult) {
        if (fieldResult.isSuccess) {
            this.results.push(fieldResult);
        } else {
            this.errors.push(fieldResult);
        }
        this.validate();
    }
}

BulkFieldResults.propTypes = {
    result: PropTypes.number.isRequired,
    successes: PropTypes.arrayOf(PropTypes.instanceOf(FieldResult)),
    errors: PropTypes.arrayOf(PropTypes.instanceOf(FieldResult)),
};
