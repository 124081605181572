import BaseGeoTrakService from './BaseGeoTrakService';
import { Market } from './Models';

export default class MarketService extends BaseGeoTrakService {
    async getMarkets() {
        return this.fetch('/markets').then((markets) => markets.map((market) => Market.load(market)));
    }

    async getMarket(id) {
        return this.fetch(`/markets/${id}`).then((market) => Market.load(market));
    }

    async createMarket(request) {
        return this.post('/markets', request.data.loadSqlEntity());
    }

    async updateMarket(request) {
        const { id, data } = request;
        return this.put(`/markets/${id}`, data);
    }

    async deleteMarket(id) {
        return this.delete(`/markets/${id}`);
    }
}
