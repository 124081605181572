import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetModules from '../../../_services/GeotrakService/hooks/useGetModules';
import './modules.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminModules = () => {
    const history = useHistory();
    const { modules, isLoading } = useGetModules();
    const units = [...new Set(modules.map((module) => module.unit.name))];

    if (isLoading) {
        return <LoadingFullScreen id="module-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Modules">
                <ButtonContainer>
                    <ButtonPrimary id="new-module-button" onClick={() => history.push('/admin/modules/new')}>
                        New Module
                    </ButtonPrimary>
                </ButtonContainer>
                <div id="module-accordion" className="accordion accordion-flush mb-5">
                    {units.map((unit) => (
                        <div key={unit}>
                            <div className="accordion-item">
                                <h5 className="accordion-header" id={`${unit}-header`}>
                                    <button
                                        className="accordion-button admin-module-accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#${unit}-collapse`}
                                        aria-expanded="true"
                                        aria-controls={`${unit}-collapse`}
                                    >
                                        {unit}
                                    </button>
                                </h5>
                                <div
                                    id={`${unit}-collapse`}
                                    className="accordion-collapse collapse list-group"
                                    aria-labelledby={`${unit}-header`}
                                    data-bs-parent="module-accordion"
                                >
                                    {modules.filter((module) => module.unit.name === unit).map((module) => (
                                        <button
                                            type="button"
                                            className="list-group-item list-group-item-action text-start"
                                            id={module.name}
                                            key={module.id}
                                            onClick={() => history.push(`/admin/modules/${module.id}`)}
                                        >
                                            <span id="module-name">{module.name}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminModules;
