import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import ErrorMessage from '../../../utilComponents/ErrorMessage';
import AdminContainer from '../AdminContainer';
import { DatabaseService } from '../../../_services/GeotrakService';
import IGetFieldsRequest from '../../../_services/GeotrakService/interfaces/IGetFieldsRequest';

const tableFields = ({ match }) => {
    const history = useHistory();
    const [fields, setFields] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { table, schema, database } = match.params;
    const databaseService = new DatabaseService();

    const getFields = async () => {
        try {
            const request = IGetFieldsRequest.load(database, table, schema);
            const result = await databaseService.getFields(request);
            const fieldList = Object.keys(result);

            fieldList.sort();
            setFields(fieldList);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(`An error occurred while getting fields. ${err.message || err}`);
        }
    };

    useEffect(() => {
        getFields();
    }, []);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Table Fields">
                {isLoading && (<LoadingFullScreen />)}
                {error && <ErrorMessage message={error} onDismiss={() => setError(null)} />}
                <div className="list-group tables-list">
                    {fields.map((field) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action text-start"
                            key={field}
                            onClick={
                                () => history.push(`/admin/databases/${database}/schemas/${schema}/tables/${table}/fields/${field}/domains`)
                            }
                        >
                            {field}
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default tableFields;
