import { useEffect, useState } from 'react';
import useGetProject from '../../../_services/GeotrakService/hooks/useGetProject';
import { Project } from '../../../_services/GeotrakService/Models';

const useProjectForm = (id) => {
    let project = new Project();
    let isLoading = false;
    if (id) {
        const result = useGetProject(id);
        project = result.project;
        isLoading = result.isLoading;
    }
    const [values, setValues] = useState({
        id: null,
        name: '',
    });

    const isValid = () => values.name !== '' && values.name;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                id: project.id || null,
                name: project.name || '',
            });
        }
    }, [project]);

    return {
        values, isValid, onFormUpdate, isLoading,
    };
};

export default useProjectForm;
