import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { NotificationUser } from '../../../../_services/NotificationService/Models';
import useDeleteNotificationUser from '../../../../_services/NotificationService/hooks/user/useDeleteNotificationUser';
import '../notifications.css';

const deleteUserButton = ({ notificationUser }) => {
    const {
        deleteNotificationUser, error, isLoading, isSuccessful,
    } = useDeleteNotificationUser();

    if (!notificationUser.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/notifications/${notificationUser.notificationId}/users`} />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="delete-notification-user-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-notification-user-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteNotificationUser(notificationUser)}
            />
        </div>
    );
};

export default deleteUserButton;

deleteUserButton.propTypes = {
    notificationUser: PropTypes.instanceOf(NotificationUser),
};

deleteUserButton.defaultProps = {
    notificationUser: new NotificationUser(),
};
