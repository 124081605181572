import PropTypes from 'prop-types';

class ICreateSchemaRequest {
    constructor() {
        this.database = null;
        this.data = {};
    }

    static load(database, schema) {
        if (!database) {
            throw Error('database is required');
        }

        const request = new ICreateSchemaRequest();
        request.database = database;
        request.data.schema = schema;
        return request;
    }
}

export default ICreateSchemaRequest;

ICreateSchemaRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
    database: PropTypes.string,
};
