import PropTypes from 'prop-types';

class ICreateApplicationCommentTypeRequest {
    constructor() {
        this.data = {};
    }

    static load(applicationCommentType) {
        if (!applicationCommentType) {
            throw Error('ApplicationCommentType is required');
        }

        const request = new ICreateApplicationCommentTypeRequest();
        request.data = applicationCommentType;

        request.validate();
        return request;
    }

    validate() {
        if (!this.data.applicationId) {
            throw Error('applicationId is required');
        }

        if (!this.data.commentTypeId) {
            throw Error('applicationId is required');
        }
    }
}

export default ICreateApplicationCommentTypeRequest;

ICreateApplicationCommentTypeRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
