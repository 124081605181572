import {
    RESET_TABLE_DATA,
    LOAD_TABLE_DATA_PENDING,
    LOAD_TABLE_DATA_SUCCESS,
    LOAD_TABLE_DATA_ERROR
} from '../CONSTANTS/types';
import { addAlertThunk } from './errorActions';
import Log from '../utilComponents/Log';
import { clearIdCollection } from './idActions';
import { ProjectRecordService } from '../_services/GeotrakService';

const projectRecordService = new ProjectRecordService();

export const resetTableData = () => ({
    type: RESET_TABLE_DATA,
});

export const loadDataTablePending = () => ({
    type: LOAD_TABLE_DATA_PENDING,
});

export const loadDataTableSuccess = (tableData, recordCount) => ({
    type: LOAD_TABLE_DATA_SUCCESS,
    tableData,
    recordCount,
});

export const loadDataTableError = (error, errorLevel, message) => (dispatch) => {
    Log.warn(error);
    dispatch(addAlertThunk(errorLevel, message, error.message || error));
    dispatch({ type: LOAD_TABLE_DATA_ERROR });
};

export const fetchDataThunk = (paginationPage, payload) => async (dispatch) => {
    dispatch(clearIdCollection());

    try {
        const records = await projectRecordService.getProjectRecords(paginationPage, payload);
        dispatch(loadDataTableSuccess(records.data, records.recordCount));
    } catch (err) {
        dispatch(loadDataTableError(err, 'danger', 'Error fetching data'));
    }
};
