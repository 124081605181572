import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import AdminContainer from '../AdminContainer';
import '../../stylesheets/AdminTables.css';
import { DatabaseService } from '../../../_services/GeotrakService';
import IGetTablesRequest from '../../../_services/GeotrakService/interfaces/IGetTablesRequest';

const adminTables = ({ match }) => {
    const history = useHistory();
    const { schema, database } = match.params;
    const [existingTables, setExistingTables] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const databaseService = new DatabaseService();

    const getTables = async () => {
        try {
            const request = IGetTablesRequest.load(database, schema);
            const tables = await databaseService.getTables(request);
            setExistingTables(tables);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(`An error occurred while getting existing tables. ${err.message || err}`);
        }
    };

    useEffect(() => {
        getTables();
    }, []);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Tables">
                {isLoading && (
                    <LoadingFullScreen />
                )}
                <ButtonContainer
                    error={error}
                    onErrorDismiss={() => setError(null)}
                >
                    <ButtonPrimary id="new-table-button" onClick={() => history.push(`/admin/databases/${database}/schemas/${schema}/tables/new`)}>
                        New Table
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group tables-list">
                    {existingTables.map((table) => (
                        <button
                            type="button"
                            id="database-name"
                            className="list-group-item list-group-item-action text-left"
                            key={table}
                            onClick={() => history.push(`/admin/databases/${database}/schemas/${schema}/tables/${table}/fields`)}
                        >
                            {table}
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminTables;
