import Log from '../../utilComponents/Log';
import returnCodes from '../../CONSTANTS/returnCodes';

const LOCALHOST = 'http://localhost:56425';
const ENV_LOCAL = 'local';
const ENV_DEV = '-dev';
const CONTENT_TYPE_APPLICATION_JSON = 'application/json';

export default class BaseFormService {
    constructor(clientId, secret) {
        if (!clientId || !secret) {
            this.logout();
            return;
        }
        // prettier-ignore
        this.credentials = {
            userName: clientId,
            password: secret,
            grant_type: 'password',
        };

        this.domain = this.buildApiBaseUri();
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.login = this.login.bind(this);
    }

    buildApiBaseUri = () => {
        // normally developers will not be running the forms-service locally,
        // so if react is being run locally, we will just use forms-dev instead.
        //
        // BUT if you want to run the local forms-service then set this
        // constant to true
        const reallyUseLocalHost = false;

        let env = process.env.REACT_APP_ENV_PREFIX;
        if (env === ENV_LOCAL) {
            if (reallyUseLocalHost) {
                return LOCALHOST;
            }
            env = ENV_DEV;
        }

        return `https://forms${env}.geotrak.io`;
    }

    static checkStatus(response) {
        if (response.status >= returnCodes.SUCCESS.OK
            && response.status < returnCodes.REDIRECTION.MULTIPLE_CHOICES) {
            // Success status lies between 200 to 300
            return response;
        }
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    }

    login() {
        return fetch(`${this.domain}/token`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
            body: Object.keys(this.credentials)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(this.credentials[key])}`)
                .join('&'),
        })
            .then((response) => response.json());
    }

    fetch(url, options) {
        return this.login().then((token) => {
            Log.trace(token);
            // performs api calls sending the required authentication headers
            const headers = {
                Accept: CONTENT_TYPE_APPLICATION_JSON,
                'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
                Authorization: `Bearer ${token.access_token}`,
            };

            return fetch(url, {
                headers,
                options,
            })
                .then(BaseFormService.checkStatus)
                .then((response) => response.json())
                .then((response) => Promise.resolve(response));
        });
    }

    post(url, data) {
        return this.login().then((token) => {
            Log.trace(token);
            // performs api calls sending the required authentication headers
            const headers = {
                Accept: CONTENT_TYPE_APPLICATION_JSON,
                'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
                Authorization: `Bearer ${token.access_token}`,
            };

            const options = {
                headers,
                method: 'POST',
                body: JSON.stringify(data),
            };

            return fetch(url, options)
                .then(BaseFormService.checkStatus)
                .then((response) => response.json())
                .then((response) => Promise.resolve(response));
        });
    }

    delete(url) {
        return this.login().then((token) => {
            Log.trace(token);
            // performs api calls sending the required authentication headers
            const headers = {
                Accept: CONTENT_TYPE_APPLICATION_JSON,
                'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
                Authorization: `Bearer ${token.access_token}`,
            };

            const options = {
                method: 'DELETE',
                headers,
            };

            return fetch(url, options)
                .then(BaseFormService.checkStatus)
                .then((response) => Promise.resolve(response));
        });
    }
}
