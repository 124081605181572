import React from 'react';
import PropTypes from 'prop-types';
import { MdStop, MdCheckBoxOutlineBlank } from 'react-icons/md';
import styles from './WidthControl.module.css';

const QUARTER_SCREEN_SIZE = 3;
const THIRD_SCREEN_SIZE = 4;
const HALF_SCREEN_SIZE = 6;
const THREE_QUARTER_SCREEN_SIZE = 8;
const FULL_SCREEN_SIZE = 12;

class WidthControl extends React.Component {
    constructor(props) {
        super(props);

        const { 'data-field': dataField } = this.props;
        const validWidths = [
            QUARTER_SCREEN_SIZE,
            THIRD_SCREEN_SIZE,
            HALF_SCREEN_SIZE,
            THREE_QUARTER_SCREEN_SIZE,
            FULL_SCREEN_SIZE,
        ];
        this.state = {
            validWidths,
            selectedWidth: dataField.width,
            fieldName: dataField.fieldName,
        };
    }

  clickHandler = (event) => {
      const { onClick } = this.props;
      event.preventDefault();
      const selectedWidth = event.currentTarget.dataset.width;
      const { fieldName } = this.state;

      this.setState({ selectedWidth });
      onClick(event, {
          fieldName,
          width: selectedWidth,
      });
  };

  getIcon = (width) => {
      const { selectedWidth, validWidths } = this.state;
      const selected = selectedWidth || validWidths[0];
      if (width > selected) {
          return <MdCheckBoxOutlineBlank />;
      }
      return <MdStop />;
  };

  createControls = () => {
      const { validWidths } = this.state;

      return validWidths.map((width) => (
          <span
              key={width}
              data-width={width}
              aria-hidden="true"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...this.props}
              onClick={this.clickHandler}
          >
              {this.getIcon(width)}
          </span>
      ));
  };

  render() {
      return <div className={styles.widthControlContainer}>{this.createControls()}</div>;
  }
}

export default WidthControl;

WidthControl.propTypes = {
    onClick: PropTypes.func.isRequired,
    'data-field': PropTypes.instanceOf(Object).isRequired,
};
