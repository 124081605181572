import React from 'react';
import SelectDatabase from '../../../utilComponents/SelectDatabase';
import SelectTable from '../../../utilComponents/SelectTable';
import SelectField from '../../../utilComponents/SelectField';
import SelectSchema from '../../../utilComponents/SelectSchema';
import useGeocoderJobForm from './useGeocoderJobForm';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import GeocoderJob from '../../../_services/EtlService/Models/GeocoderJob';
import { Create, Update, Delete } from './Actions';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import './etljobs.css';

const adminETLGeocoderJobForm = ({ match }) => {
    const { params: { id } } = match;
    const {
        values, tasks, onFormUpdate, isLoading, error, isValid,
    } = useGeocoderJobForm(id);
    const geocoderJob = new GeocoderJob();

    if (error) {
        return (
            <div className="text-danger">
                <span>
                    <strong>There was an error the job form: </strong>
                    {error.message || error}
                </span>
            </div>
        );
    }

    if (isLoading) {
        return <LoadingFullScreen id="geocoder-form-loader" />;
    }

    if (values) {
        geocoderJob.id = values.id;
        geocoderJob.taskId = values.taskId;
        geocoderJob.database = values.database;
        geocoderJob.schema = values.schema;
        geocoderJob.table = values.table;
        geocoderJob.primaryKeyColumn = values.primaryKeyColumn;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Geocoder Job">
                {!error && (
                    <form>
                        <SelectDatabase
                            selectedDatabase={geocoderJob.database}
                            onDatabaseSelected={(e) => onFormUpdate('database', e)}
                        />
                        <SelectSchema
                            selectedDatabase={geocoderJob.database}
                            selectedSchema={geocoderJob.schema}
                            onSchemaSelected={(e) => onFormUpdate('schema', e)}
                        />
                        <SelectTable
                            selectedDatabase={geocoderJob.database}
                            selectedTable={geocoderJob.table}
                            selectedSchema={geocoderJob.schema}
                            onTableSelected={(e) => onFormUpdate('table', e)}
                        />
                        <SelectField
                            label="Field Name"
                            database={values.database}
                            table={values.table}
                            selectedField={geocoderJob.primaryKeyColumn}
                            onFieldSelected={(e) => onFormUpdate('primaryKeyColumn', e)}
                        />
                        <div className="mb-3">
                            <label htmlFor="select-task" className="form-label">
                                Task Type
                                {' '}
                                <span className="text-danger">*</span>
                            </label>
                            <select
                                id="select-task"
                                value={geocoderJob.taskId}
                                onChange={(e) => onFormUpdate('taskId', e.target.value)}
                            >
                                <option disabled value="">--Select a task--</option>
                                {tasks && tasks.map((task) => (
                                    <option id="select-task-option" value={task.id} key={task.id}>{task.name}</option>
                                ))}
                            </select>
                        </div>
                    </form>
                )}
                <div className="etl-job-toolbar">
                    <Create isValid={isValid} job={geocoderJob} />
                    <Update isValid={isValid} job={geocoderJob} />
                    <Delete isValid={isValid} jobId={geocoderJob.id} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminETLGeocoderJobForm;
