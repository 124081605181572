import PropTypes from 'prop-types';
import GeocoderJob from '../Models/GeocoderJob';

class IUpdateGeocoderJob {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(geocoder) {
        if (!geocoder) {
            throw Error('geocoderJob is required');
        }
        if (!(geocoder instanceof GeocoderJob)) {
            throw Error('Unexpected type for geocoderJob');
        }

        const request = new IUpdateGeocoderJob();
        request.id = geocoder.id;
        request.data = geocoder;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateGeocoderJob;

IUpdateGeocoderJob.propTypes = {
    id: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
