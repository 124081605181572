import React from 'react';
import { Link } from 'react-router-dom';
import {
    MdLock,
    MdSupervisorAccount,
    MdViewList,
    MdStore,
    MdFilterNone
} from 'react-icons/md';
import { UserService } from '../../../_services';
import '../../stylesheets/AdminCheckRequest.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';

const adminCheckRequest = () => {
    const auth = new UserService();

    const renderLink = (route, Icon, adminTypes, label) => {
        const userHasPermission = auth.hasAdminPermission(adminTypes);
        return (
            <div className="col-sm">
                <Link to={`/admin/${route}`} className="link-style">
                    <div className="render-link">
                        {userHasPermission ? (
                            <h1>
                                <Icon size={100} />
                            </h1>
                        ) : (
                            <h1>
                                <MdLock size={100} className="text-danger" />
                            </h1>
                        )}
                        <p className="text-center text-dark h5">{label}</p>
                    </div>
                </Link>
            </div>
        );
    };

    return (
        <div className="col-md-12">
            <Breadcrumbs />
            <div className="col-md-10 offset-md-1 d-flex flex-wrap mt-3 admin-check-request">
                {renderLink('check-request/check-request-fields', MdViewList, ['IT', 'DEV'], 'Form Fields')}

                {renderLink('check-request/check-request-admin-requester', MdSupervisorAccount, ['IT', 'DEV'], 'Admin Requester')}

                {renderLink('check-request/check-request-offices', MdStore, ['IT', 'DEV'], 'Offices')}

                {renderLink('check-request/check-request-requests', MdFilterNone, ['IT', 'DEV'], 'Requests')}

            </div>
        </div>
    );
};

export default adminCheckRequest;
