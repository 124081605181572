import React, { Component } from 'react';
import propTypes from 'prop-types';
import { CheckRequestService, UserService } from '../../_services';
import { CheckRequestDownloadService } from '../../_services/CheckRequestService';
import { ButtonContainer, ButtonPrimary } from '../../utilComponents/styledComponents';
import withCurrentUser from '../../utilComponents/hocs/withCurrentUser';

class APDailySummary extends Component {
    constructor(props) {
        super(props);

        this.CheckRequestService = new CheckRequestService();
        this.UserService = new UserService();
        this.CheckRequestDownloadService = new CheckRequestDownloadService();
    }

  downloadAPDailySummary = async () => {
      const { onError } = this.props;
      try {
          await this.CheckRequestDownloadService.getReportDownload('ap-summary');
      } catch (err) {
          onError(`Error generating AP Daily Summary. ${err.message || err}`);
      }
  }

  render() {
      if (this.UserService.hasCheckRequestAdminPermissions()) {
          return (
              <ButtonContainer>
                  <ButtonPrimary onClick={this.downloadAPDailySummary}>
                      AP Daily Summary
                  </ButtonPrimary>
              </ButtonContainer>
          );
      }

      return null;
  }
}

export default withCurrentUser(APDailySummary);

APDailySummary.propTypes = {
    onError: propTypes.func,
};

APDailySummary.defaultProps = {
    onError: () => null,
};
