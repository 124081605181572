import React from 'react';
import { MdThumbUp } from 'react-icons/md';
import NavIconContainer from '../../utilComponents/NavIconContainer';

const procurementHome = () => (
    <div className="col-md-12 d-flex flex-wrap justify-content-center">
        <NavIconContainer
            link="/procurementCSR/P-CSR/data"
            icon={MdThumbUp}
            title="Contract Status Report"
        />
    </div>
);

export default procurementHome;
