import React from 'react';
import useDatabasePasswordForm from './useDatabasePasswordForm';
import Update from './Actions/UpdateButton';
import InputPassword from '../../../utilComponents/InputPassword';
import ValidIcons from '../../../utilComponents/ValidIcons';

const databasePasswordForm = () => {
    const {
        values, isValid, passwordsMatch, onFormUpdate, hasRequiredCharacters, hasRequiredLength,
    } = useDatabasePasswordForm();

    return (
        <div className="container text-center">
            <h1 className="text-center mt-3 mb-3">Change Database Password</h1>
            <p className="text-center">
                If you have any problems, please contact a system administrator for assistance.
            </p>
            <form className="col-md-6 offset-md-3">
                <div className="mb-3">
                    <label htmlFor="new-password" className="form-label">
                        New Password
                    </label>
                    <InputPassword
                        id="new-password"
                        handleChange={(value) => onFormUpdate('newPassword', value)}
                        password={values.newPassword}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="confirm-new-password" className="form-label">
                        Confirm New Password
                    </label>
                    <InputPassword
                        id="confirm-new-password"
                        handleChange={(value) => onFormUpdate('confirmNewPassword', value)}
                        password={values.confirmNewPassword}
                    />
                </div>
                <div className="text-start">
                    <ValidIcons validStep={(passwordsMatch() && values.confirmNewPassword)} />
                    <span className={`${passwordsMatch() && values.confirmNewPassword ? 'text-success' : 'text-danger'}`}>New passwords must match.</span>
                </div>
                <div className="text-start">
                    <ValidIcons validStep={hasRequiredCharacters()} />
                    <span className={`${hasRequiredCharacters() ? 'text-success' : 'text-danger'}`}>Passwords must have at least one number and special character, ! @ # $ % ^ & * .</span>
                </div>
                <div className="text-start">
                    <ValidIcons validStep={hasRequiredLength()} />
                    <span className={`${hasRequiredLength() ? 'text-success' : 'text-danger'}`}>Passwords must have eight characters or more.</span>
                </div>
                <div className="container-fluid mt-3">
                    <Update
                        isValid={isValid}
                        newPassword={values.newPassword}
                    />
                </div>
            </form>
        </div>
    );
};

export default databasePasswordForm;
