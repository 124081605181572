import { RESET_ACTIVE_MODULE, GET_PAGE_RECEIVED } from '../CONSTANTS/types';
import { DataSourceService } from '../_services/GeotrakService';
import { addAlertThunk } from './errorActions';
import { resetActiveViewThunk } from './viewActions';

export const resetActiveModule = {
    type: RESET_ACTIVE_MODULE,
};

const changePageReceived = (page) => ({
    type: GET_PAGE_RECEIVED,
    page,
});

export const activateModuleThunk = (loadedDataSourceId, history) => async (dispatch) => {
    try {
        const defaultDataSource = await new DataSourceService().getDataSource(loadedDataSourceId);
        dispatch(resetActiveViewThunk(defaultDataSource));
        history.push(`${history.location.pathname}?data-source=${defaultDataSource.id}`);
    } catch (err) {
        dispatch(addAlertThunk('danger', 'Error Loading Module', err));
    }
};

export const changePageThunk = (page) => (dispatch) => dispatch(changePageReceived(page));
