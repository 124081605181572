export default class IFilterGrid {
    constructor(defaultQuery, filters) {
        this.defaultQuery = defaultQuery;
        this.filters = filters;
    }

    buildFilterQuery() {
        const validFilters = this.filters.filter((filter) => filter.isValid);
        const expressions = validFilters.map((filter) => filter.sqlExpression);
        return expressions.join(' AND ');
    }

    buildQuery() {
        if (this.filters.length > 0) {
            if (this.defaultQuery) {
                return `${this.defaultQuery} AND ${this.buildFilterQuery()}`;
            }
            return this.buildFilterQuery();
        }
        return this.defaultQuery;
    }
}
