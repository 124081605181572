import PropTypes from 'prop-types';

export default class ApplicationCommentType {
    constructor() {
        this.id = null;
        this.applicationId = null;
        this.commentTypeId = null;
        this.isEditable = true;
        this.name = null;
    }

    static load(response) {
        const appCommentType = new ApplicationCommentType();

        if (response) {
            appCommentType.id = response.Id;
            appCommentType.applicationId = response.ApplicationId;
            appCommentType.commentTypeId = response.CommentTypeId;
            appCommentType.isEditable = response.IsEditable;
            appCommentType.name = response.Name;
        }

        return appCommentType;
    }

    validate() {
        if (!this.id) {
            throw Error('ApplicationCommentTypeId is required');
        }

        if (!this.commentTypeId) {
            throw Error('commentTypeId is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const entity = {
            CommentTypeId: this.commentTypeId,
            IsEditable: this.isEditable,
            Name: this.name,
        };

        if (this.id) {
            entity.Id = this.id;
        }

        return entity;
    }
}

ApplicationCommentType.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    applicationId: PropTypes.string,
    commentTypeId: PropTypes.string,
    isEditable: PropTypes.bool,
};
