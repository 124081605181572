import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import EtlJob from '../../../../_services/EtlService/Models/EtlJob';
import useDeleteEtlJob from '../../../../_services/EtlService/hooks/useDeleteEtlJob';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import ErrorMessage from '../../../../utilComponents/ErrorMessage';

const deleteButton = ({ etlJob, moduleId }) => {
    const {
        deleteEtlJob, error, isLoading, isSuccessful,
    } = useDeleteEtlJob();

    if (!etlJob.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${etlJob.moduleRoleId}/etls`} />;
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-end mb-2">
                <LoaderButton
                    danger
                    id="delete-etl-button"
                    text="Delete"
                    loadingText="Deleting..."
                    isLoading={isLoading}
                    onClick={() => deleteEtlJob(etlJob)}
                />
            </div>
            {error && <ErrorMessage message={error.message || error} />}
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    etlJob: PropTypes.instanceOf(EtlJob),
    moduleId: PropTypes.string,
};

deleteButton.defaultProps = {
    etlJob: new EtlJob(),
    moduleId: null,
};
