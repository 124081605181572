import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdDelete } from 'react-icons/md';
import { ButtonPrimary, ButtonContainer, ButtonDanger } from '../../../utilComponents/styledComponents';
import Modal from '../../../components/Modals/Modal';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { RequestFormNotification } from '../../../_services/CheckRequestService/Models';
import AdminContainer from '../AdminContainer';
import './checkRequest.css';

const checkRequestAdminService = new CheckRequestAdminService();

const notificationPicker = ({ jobProperties: { requestFormId }, nextStep }) => {
    const [requestForm, setRequestForm] = useState({});
    const [requestFormNotifications, setRequestFormNotifications] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [editNotification, setEditNotification] = useState(null);
    const [newNotification, setNewNotification] = useState('');
    const [error, setError] = useState(null);

    const getRequestFormNotifications = () => {
        checkRequestAdminService.getRequestFormNotifications(requestFormId).then((result) => {
            setRequestFormNotifications(result);
        }).catch((err) => {
            setError(`Error Getting Notifications. ${err.message || err}`);
        });
    };

    const getRequestForm = () => {
        checkRequestAdminService.getRequestForm(requestFormId).then((result) => {
            setRequestForm(result);
        }).catch((err) => {
            setError(`Error Getting Request Form. ${err.message || err}`);
        });
    };

    const createNotification = () => {
        setIsAdding(true);
        const newRequestNotification = new RequestFormNotification(requestForm, true, newNotification);
        checkRequestAdminService.createRequestFormNotification(newRequestNotification).then(() => {
            setIsAdding(false);
            getRequestFormNotifications();
        }).catch((err) => {
            setIsAdding(false);
            setError(`Error Creating Notification. ${err.message || err}`);
        });
    };

    const confirmDelete = (e) => {
        setShowConfirmation(true);
        setEditNotification(e);
    };

    const cancelDelete = () => {
        setShowConfirmation(false);
    };

    const deleteSelectedNotification = () => {
        checkRequestAdminService.deleteRequestFormNotification(editNotification.id).then(() => {
            setEditNotification(null);
            setShowConfirmation(false);
            getRequestFormNotifications();
        }).catch((err) => {
            setError(`Error Deleting Notification. ${err.message || err}`);
        });
    };

    useEffect(() => {
        getRequestFormNotifications();
        if (requestFormId) {
            getRequestForm();
        }
    }, []);

    const renderNotificationList = () => requestFormNotifications.map((notification) => (
        <div key={`notification-${notification.id}`} className="list-group-item list-group-item-action d-flex flex-row align-items-center">
            <MdDelete
                size={20}
                className="cr-notification-delete-button"
                onClick={() => confirmDelete(notification)}
            />
            <span>
                {notification.email}
            </span>
        </div>
    ));

    return (
        <AdminContainer title="Request Form Notifications">
            <form>
                <div className="mb-3">
                    <label htmlFor="notification-email" className="form-label">
                        Email
                        {' '}
                        <span className="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        placeholder="New Email"
                        id="notification-email"
                        value={newNotification}
                        onChange={(e) => setNewNotification(e.target.value)}
                    />
                </div>
                <ButtonContainer>
                    <LoaderButton
                        type="submit"
                        isLoading={isAdding}
                        text="Add"
                        loadingText="Adding..."
                        onClick={createNotification}
                        disabled={newNotification === ''}
                    />
                </ButtonContainer>
            </form>
            <div className="list-group">
                {renderNotificationList()}
            </div>
            <ButtonContainer
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <ButtonPrimary onClick={() => nextStep()}>Finish</ButtonPrimary>
            </ButtonContainer>
            <Modal
                showModal={showConfirmation}
                header="Wait!"
                onCancel={cancelDelete}
                component={
                    <h5 className="text-center">Are you sure you want to delete this notification?</h5>
                }
                footer={
                    <ButtonDanger onClick={deleteSelectedNotification}>Delete</ButtonDanger>
                }
                displayFullScreen={false}
            />
        </AdminContainer>
    );
};

export default notificationPicker;

notificationPicker.propTypes = {
    jobProperties: PropTypes.instanceOf(Object),
    nextStep: PropTypes.func,
};

notificationPicker.defaultProps = {
    jobProperties: {
        requestFormId: null,
    },
    nextStep: () => null,
};
