import BaseNodeService from '../BaseNodeService';
import EtlStatus from './Models/EtlStatus';
import EtlJobType from './Models/EtlJobType';
import EtlJob from './Models/EtlJob';

export default class EtlService extends BaseNodeService {
    constructor() {
        const url = '/etl-service';
        const port = 3016;
        super(url, port);
    }

    async getEtlStatus(moduleRoleId) {
        return this.fetch(`/${moduleRoleId}/etl`).then((status) => EtlStatus.load(status));
    }

    async getEtlJobTypes() {
        return this.fetch('/job-types').then((jobTypes) => jobTypes.map((jobType) => EtlJobType.load(jobType)));
    }

    async deleteEtlJob(id) {
        return this.delete(`/etls/${id}`);
    }

    async getEtlJobs(moduleRoleId) {
        return this.fetch(`/${moduleRoleId}/etls`).then((etlJobs) => etlJobs.map((etlJob) => EtlJob.load(etlJob)));
    }

    async createEtlJob(request) {
        const { moduleRoleId, data } = request;
        return this.post(`/${moduleRoleId}/etls`, data).then((etlJob) => EtlJob.load(etlJob));
    }

    async getEtlJob(id) {
        return this.fetch(`/etls/${id}`).then((etlJob) => EtlJob.load(etlJob));
    }
}
