import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { ButtonDanger } from '../../../utilComponents/styledComponents';
import { CheckRequestActionService } from '../../../_services';
import Modal from '../../Modals/Modal';
import ErrorMessage from '../../../utilComponents/ErrorMessage';

class Reject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            comments: null,
            isWorking: false,
            error: null,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  reject = () => {
      this.setState({ isWorking: true });
      const {
          role, checkRequestId, refreshQueues, requestForm,
      } = this.props;
      const { comments } = this.state;
      const form = {};
      requestForm.map((field) => {
          form[field.Name] = field.value;
          return field;
      });
      this.CheckRequestActionService.rejectRequest(role, checkRequestId, form, comments).then(() => {
          this.setState({
              showModal: false,
              isWorking: false,
          });
          refreshQueues();
      }).catch((err) => {
          this.setState({
              error: err.message || 'An error occurred rejecting request.',
              isWorking: false,
          });
      });
  }

  render() {
      const { showModal, isWorking, error } = this.state;
      return (
          <>
              <ButtonDanger onClick={() => this.setState({ showModal: true })}>
                  Reject
              </ButtonDanger>
              <Modal
                  showModal={showModal}
                  header="Reject Request"
                  onCancel={() => this.setState({ showModal: false })}
                  component={(
                      <form>
                          <label htmlFor="rejection-comments" className="form-label">
                              Rejection Comments
                          </label>
                          <input
                              id="rejection-comments"
                              onChange={(e) => this.setState({ comments: e.target.value })}
                          />
                      </form>
                  )}
                  footer={(
                      <>
                          {error && (
                              <div>
                                  <ErrorMessage message={error} onDismiss={() => this.setState({ error: null })} />
                              </div>
                          )}
                          <LoaderButton
                              danger
                              onClick={this.reject}
                              isLoading={isWorking}
                              text="Reject"
                              loadingText="Rejecting…"
                          />
                      </>
                  )}
                  displayFullScreen={false}
              />
          </>
      );
  }
}

export default Reject;

Reject.propTypes = {
    requestForm: PropTypes.instanceOf(Array),
    role: PropTypes.string,
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
};
Reject.defaultProps = {
    requestForm: [],
    role: '',
    checkRequestId: 0,
    refreshQueues: () => null,
};
