/* eslint react/forbid-prop-types: "off" */
import PropTypes from 'prop-types';

export default class FieldResult {
    constructor(isSuccess, field, message, value) {
        this.isSuccess = isSuccess;
        this.field = field;
        this.message = message;
        this.value = value;
    }

    static load(isSuccess, data) {
        FieldResult.validate(data);
        return new FieldResult(isSuccess, data.field, data.message, data.value);
    }

    static validate(data) {
        if (!Object.prototype.hasOwnProperty.call(data, 'field')) {
            throw Error('field is missing');
        }

        if (!Object.prototype.hasOwnProperty.call(data, 'message')) {
            throw Error('message is missing');
        }

        if (!Object.prototype.hasOwnProperty.call(data, 'value')) {
            throw Error('value is missing');
        }
    }
}

FieldResult.propTypes = {
    isSuccess: PropTypes.bool.isRequired,
    field: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
};
