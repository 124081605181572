import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import Create from './Actions/Database/CreateButton';
import useDatabaseForm from './useDatabaseForm';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDatabasesForm = () => {
    const {
        values, isValid, onFormUpdate, isLoading, databases,
    } = useDatabaseForm();

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Database">
                {isLoading && (
                    <LoadingFullScreen />
                )}
                <form>
                    <div className="mb-3">
                        <label htmlFor="input-database-name" className="form-label">
                            Database Name
                        </label>
                        <input
                            type="text"
                            id="input-database-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                </form>
                <div className="d-flex justify-content-end">
                    <Create database={values.name} isValid={isValid} databases={databases} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDatabasesForm;
