import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Menu } from 'react-data-grid-addons';
import { DataSource } from '../../../_services/GeotrakService/Models';

const { MenuItem } = Menu;

const viewCheckReports = ({ row, dataSource }) => {
    const history = useHistory();

    const onViewCheckReports = async () => {
        const { searchFieldName } = dataSource;
        const recordId = row[searchFieldName];
        const params = { recordId };
        const urlParams = new URLSearchParams(params);

        history.push(`/service-center/finance/check-request-reports/report?type=site&${urlParams}`);
    };

    return (
        <MenuItem onClick={onViewCheckReports}>
            View Check Request Reports
        </MenuItem>
    );
};

viewCheckReports.propTypes = {
    row: PropTypes.instanceOf(Object),
    dataSource: PropTypes.instanceOf(DataSource),
};

viewCheckReports.defaultProps = {
    row: null,
    dataSource: new DataSource(),
};

export default viewCheckReports;
