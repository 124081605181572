import React from 'react';
import { useHistory } from 'react-router';
import { ButtonPrimary } from '../../../../utilComponents/styledComponents';
import '../autopopulations.css';

const viewButton = ({ row }) => {
    const history = useHistory();
    return (
        <div className="d-grid">
            <ButtonPrimary
                className="autopopulation-view-edit-button"
                id="view-autopopulation-button"
                onClick={() => history.push(`/admin/autopopulations/${row.id}`)}
            >
                Edit
            </ButtonPrimary>
        </div>
    );
};

export default viewButton;
