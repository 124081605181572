import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../../utilComponents/LoaderButton';
import useUpdateOffice from '../../../../../_services/CheckRequestService/hooks/useUpdateOffice';
import { Office } from '../../../../../_services/CheckRequestService/Models';
import '../offices.css';

const updateButton = ({ office, isValid }) => {
    const {
        updateOffice, error, isLoading, isSuccessful,
    } = useUpdateOffice();

    if (!office.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/check-request/check-request-offices" />;
    }

    return (
        <div className="office-toolbar office-button">
            {error && <div className="text-danger office-error" id="update-office-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-office-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateOffice(office)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    office: PropTypes.instanceOf(Office),
    isValid: PropTypes.func,
};

updateButton.defaultProps = {
    office: new Office(),
    isValid: () => null,
};
