import PropTypes from 'prop-types';

class IGetViewRequest {
    constructor() {
        this.dataSourceId = null;
        this.params = {
            viewName: 'All',
        };
    }

    static load(dataSourceId, viewName) {
        if (!dataSourceId) {
            throw Error('dataSourceId is required');
        }

        const request = new IGetViewRequest();
        request.dataSourceId = dataSourceId;
        if (viewName) {
            request.params.viewName = viewName;
        }

        request.validate();
        return request;
    }

    validate() {
        if (!this.params.viewName) {
            throw Error('viewName is required');
        }
    }
}

export default IGetViewRequest;

IGetViewRequest.propTypes = {
    dataSourceId: PropTypes.string,
    params: PropTypes.instanceOf(Object),
};
