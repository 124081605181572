import React, { useEffect, useState } from 'react';
import { MdInsertDriveFile } from 'react-icons/md';
import { useHistory } from 'react-router';
import datetimes from '../../../helpers/datetimehelper';
import useSortableData from '../../../helpers/hooks/useSortableData';
import { LogService } from '../../../_services';
import SortableHeader from '../../../utilComponents/SortableHeader';
import AdminLogsError from './AdminLogsError';
import './logs.css';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminLogsFileList = ({ match }) => {
    const { params: { directory } } = match;
    const logService = new LogService();
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { items, requestSort, sortConfig } = useSortableData(files);
    const history = useHistory();

    const getFolderContents = async () => {
        try {
            const results = await logService.getLogFiles(`${logService.rootDirectory}/${directory}`);
            setFiles(results);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    };

    useEffect(() => {
        getFolderContents();
    }, []);

    if (isLoading) {
        return <LoadingFullScreen id="log-file-list-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Logs">
                <h4 className="text-center">{directory}</h4>
                <div className="row">
                    {items.length < 1 && (
                        <div className="card bg-light rounded-0 text-center">
                            <div className="card-body">
                                <h6>No files found.</h6>
                            </div>
                        </div>
                    )}
                    {items.length > 0 && (
                        <>
                            <div className="col-md-6">
                                <SortableHeader
                                    onSort={requestSort}
                                    sortKey="name"
                                    sortConfig={sortConfig}
                                    title="File Name"
                                />
                            </div>
                            <div className="col-md-3 text-center logs-right-header">
                                <SortableHeader
                                    onSort={requestSort}
                                    sortKey="size"
                                    sortConfig={sortConfig}
                                    title="Size"
                                />
                            </div>
                            <div className="col-md-3 text-center logs-right-header">
                                <SortableHeader
                                    onSort={requestSort}
                                    sortKey="modifiedOn"
                                    sortConfig={sortConfig}
                                    title="Date Modified"
                                />
                            </div>
                        </>
                    )}
                    <AdminLogsError error={error} id="logs-folder-error" />
                    <div className="list-group">
                        {items.map((file) => (
                            <button
                                type="button"
                                className="list-group-item list-group-item-action logs-folder"
                                key={file.name}
                                onClick={() => history.push(`/admin/logs/${directory}/${file.name}`)}
                            >
                                <div className="col-md-6">
                                    <span className="logs-folder-title">
                                        <MdInsertDriveFile size={20} />
                                        <span id="log-file-name" className="logs-folder-name">{file.name}</span>
                                    </span>
                                </div>
                                <div className="col-md-3 text-center">
                                    <span>{`${file.size} bytes`}</span>
                                </div>
                                <div className="col-md-3 text-center">
                                    <span>
                                        {datetimes.toLocal(
                                            datetimes.toUTC(file.modifiedOn).format(), '', 'MM/DD/YYYY HH:mm a z'
                                        )}
                                    </span>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </AdminContainer>
        </>
    );
};

export default adminLogsFileList;
