import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useMapForm from './useMapForm';
import {
    Create, Update, Delete, ToggleWidget
} from './Actions';
import './maps.css';
import { MapConfig } from '../../../_services/GeotrakService/Models';
import CoordinatePair from '../../../helpers/spatialUtils/CoordinatePair';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminMapsForm = ({ match }) => {
    const { params: { roleId, moduleId } } = match;
    const {
        values, isValid, onFormUpdate, isLoading, widgets,
    } = useMapForm(roleId);

    if (isLoading) {
        return <LoadingFullScreen id="map-form-loader" />;
    }

    const map = new MapConfig();
    map.zoom = values.zoom;
    map.center = new CoordinatePair(values.centerLatitude, values.centerLongitude);
    map.moduleRoleId = roleId;
    map.isSpatialAdmin = values.isSpatialAdmin;
    map.id = values.id;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Map">
                <form>
                    <div className="mb-3">
                        <label htmlFor="select-zoom-level" className="form-label">
                            Zoom Level
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-zoom-level"
                            value={values.zoom}
                            onChange={(e) => onFormUpdate('zoom', parseInt(e.target.value, 10))}
                        >
                            <option disabled value="">--Select a zoom level--</option>
                            <option value={1}>1 (farthest out)</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12 (closest in)</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-center-latitude" className="form-label">
                            Center Latitude
                            {' '}
                            <span className="text-danger">*</span>

                        </label>
                        <input
                            id="input-center-latitude"
                            type="number"
                            min="-90"
                            max="90"
                            step="0.0001"
                            value={values.centerLatitude}
                            onChange={(e) => onFormUpdate('centerLatitude', e.target.value)}
                        />
                        <span className="text-warning">Values must be between -90 and 90</span>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-center-longitude" className="form-label mb-3">
                            Center Longitude
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            id="input-center-longitude"
                            type="number"
                            min="-180"
                            max="180"
                            step="0.0001"
                            value={values.centerLongitude}
                            onChange={(e) => onFormUpdate('centerLongitude', e.target.value)}
                        />
                        <span className="text-warning">Values must be between -180 and 180</span>
                    </div>
                    <div className="form-check mb-3">
                        <label htmlFor="check-spatial-admin" className="form-check-label">
                            Users in this role should be able to relocate points
                            <input
                                type="checkbox"
                                id="check-spatial-admin"
                                className="form-check-input"
                                checked={values.isSpatialAdmin}
                                onChange={(e) => onFormUpdate('isSpatialAdmin', e.target.checked)}
                            />
                        </label>
                    </div>
                    <h4 className="text-center">Widgets</h4>
                    {widgets.map((widget) => {
                        const selectedWidgets = values.widgets.filter(
                            (widgetValue) => widgetValue.widgetType.name === widget.name
                        );
                        return (
                            <ToggleWidget
                                id="check-widget"
                                key={widget.id}
                                widget={widget}
                                moduleRoleId={roleId}
                                isIncluded={selectedWidgets.length > 0}
                            />
                        );
                    })}
                </form>
                <div className="col-md-12 map-toolbar">
                    <Create map={map} isValid={isValid} moduleId={moduleId} />
                    <Update map={map} isValid={isValid} moduleId={moduleId} />
                    <Delete map={map} moduleId={moduleId} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminMapsForm;
