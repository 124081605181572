import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './stylesheets/RecordCount.css';

const RecordCount = () => {
    const tableDataRecordCount = useSelector((state) => state.tableData.recordCount);
    const selectedFeatureCollection = useSelector((state) => state.selectedFeatureCollection);
    const [totalRecords, setTotalRecordCount] = useState(0);

    const displayRows = () => (`${selectedFeatureCollection.length} of ${parseFloat(
        totalRecords
    ).toLocaleString('en')} Selected`);

    useEffect(() => {
        setTotalRecordCount(tableDataRecordCount);
    }, [tableDataRecordCount]);

    return (
        <div className="float-end">
            <span id="record-count-label" className="record-count">{displayRows()}</span>
        </div>
    );
};

export default RecordCount;
