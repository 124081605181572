import BaseNotificationService from './BaseNotificationService';
import Notification from './Models/Notification';

export default class NotificationService extends BaseNotificationService {
    async getNotifications() {
        return this.fetch('/notifications')
            .then((notifications) => notifications.map((notification) => Notification.load(notification)));
    }

    async getNotification(id) {
        return this.fetch(`/notification/${id}`).then((notification) => Notification.load(notification));
    }

    async toggleNotificationStatus(id) {
        return this.patch(`/notification/${id}`).then((notification) => Notification.load(notification));
    }

    async createNotification(request) {
        const { data } = request;
        return this.post('/notification', data.loadSqlEntity())
            .then((notification) => Notification.load(notification));
    }

    async updateNotification(request) {
        const { id, data } = request;
        return this.put(`/notification/${id}`, data.loadSqlEntity())
            .then((notification) => Notification.load(notification));
    }

    async deleteNotification(id) {
        return this.delete(`/notification/${id}`);
    }
}
