import React, { useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Data, Toolbar } from 'react-data-grid-addons';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetNotifications from '../../../_services/NotificationService/hooks/useGetNotifications';
import columns from './config';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import '../../stylesheets/AdminNotifications.css';

const adminNotifications = () => {
    const selectors = Data.Selectors;
    const { notifications, isLoading } = useGetNotifications();
    const [filters, setFilters] = useState({});
    const history = useHistory();

    if (isLoading) {
        return <LoadingFullScreen id="notification-loader" />;
    }

    const getRows = () => selectors.getRows({ rows: notifications, filters });

    const onFilterChange = (filter) => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        setFilters(newFilters);
    };

    return (
        <div className="col-md-12">
            <Breadcrumbs />
            <h1 className="text-center">Notifications</h1>
            <ButtonContainer>
                <ButtonPrimary id="new-notification-button" onClick={() => history.push('/admin/notifications/new')}>
                    New Notification
                </ButtonPrimary>
            </ButtonContainer>
            <div className="admin-notifications">
                <ReactDataGrid
                    columns={columns}
                    toolbar={(<Toolbar enableFilter />)}
                    rowGetter={(i) => getRows()[i]}
                    rowsCount={getRows().length}
                    onAddFilter={onFilterChange}
                    onClearFilters={() => setFilters({})}
                    minHeight={500}
                />
            </div>
        </div>
    );
};

export default adminNotifications;
