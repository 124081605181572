import PropTypes from 'prop-types';
import { View } from '../Models';

class IDeleteUserViewRequest {
    constructor() {
        this.dataSourceId = null;
        this.viewName = null;
    }

    static load(dataSourceId, view) {
        if (!view) {
            throw Error('view is required');
        }
        if (!(view instanceof View)) {
            throw Error('Unexpected type for view');
        }
        if (!dataSourceId) {
            throw Error('dataSource id is required');
        }

        const request = new IDeleteUserViewRequest();
        request.dataSourceId = dataSourceId;
        request.viewName = view.name;
        request.validate();
        return request;
    }

    validate() {
        if (!this.viewName) {
            throw Error('viewName is required');
        }
        if (!this.dataSourceId) {
            throw Error('dataSource id is required');
        }
    }
}

export default IDeleteUserViewRequest;

IDeleteUserViewRequest.propTypes = {
    dataSourceId: PropTypes.string,
    viewName: PropTypes.string,
};
