import { useEffect, useReducer } from 'react';
import IGetRendererRequest from '../interfaces/IGetRendererRequest';
import { Renderer } from '../Models';
import RendererService from '../RendererService';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';

const useGetRenderer = (dataSourceId) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new Renderer() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const request = IGetRendererRequest.load(dataSourceId);
            const result = await new RendererService().getRenderer(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, []);

    return { renderer: data, error, isLoading };
};

export default useGetRenderer;
