class ICreateProjectRecordsRequest {
    constructor() {
        this.dataSourceId = null;
        this.values = null;
    }

    static load(dataSourceId, userInputs) {
        if (!dataSourceId) {
            throw Error('dataSource is required');
        }
        if (!userInputs) {
            throw Error('userInputs is required');
        }

        const request = new ICreateProjectRecordsRequest();
        request.dataSourceId = dataSourceId;
        request.values = userInputs;
        request.validate();
        return request;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('dataSource is required');
        }
        if (!Array.isArray(this.values)) {
            throw Error('input must be an array');
        }
        if (Array.isArray(this.values) && !this.values.length) {
            throw Error('input array must have at least one value');
        }
    }
}

export default ICreateProjectRecordsRequest;
