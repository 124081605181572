import BaseGeoTrakService from './BaseGeoTrakService';
import { Module } from './Models';

export default class ModuleService extends BaseGeoTrakService {
    async getModules() {
        return this.fetch('/modules').then((modules) => modules.map((module) => Module.load(module)));
    }

    async getModule(id) {
        return this.fetch(`/modules/${id}`).then((module) => Module.load(module));
    }

    async createModule(request) {
        return this.post('/modules', request.data.loadSqlEntity()).then((module) => Module.load(module));
    }

    async updateModule(request) {
        const { id, data } = request;
        return this.put(`/modules/${id}`, data.loadSqlEntity()).then((module) => Module.load(module));
    }

    async deleteModule(id) {
        return this.delete(`/modules/${id}`);
    }
}
