/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ServiceHome from './ServiceHome';
import ProcurementHome from '../Procurement/ProcurementHome';
import FinanceHomePage from '../Finance/FinanceHome';
import SubmitCheckRequest from '../CheckRequest/SubmitCheckRequest';
import ManageCheckRequests from '../CheckRequest/ManageCheckRequests';
import ConfirmationPage from '../CheckRequest/ConfirmationPage';
import ArchivedCheckRequests from '../CheckRequest/Archive';
import { UserType } from '../../types';
import Log from '../../utilComponents/Log';
import withDirectAccess from '../../utilComponents/hocs/withDirectAccess';
import Reports from '../CheckRequest/Reports';
import ReportView from '../CheckRequest/ReportView';

const hasCheckRequestAccess = (props) => {
    const { user } = props;
    return user && user.roles.find((role) => role.module.unit.Name === 'CheckRequest');
};

// eslint-disable-next-line react/display-name, react/prop-types
const makePrivateRoute = (compareFn) => ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(otherProps) => (compareFn(rest) ? <Component {...otherProps} /> : <Redirect to="/unauthorized" />)}
    />
);

const serviceCenterRouter = (props) => {
    const { user } = props;
    const PrivateRouteCheckRequest = makePrivateRoute(hasCheckRequestAccess);
    const RedirectSuccess = withDirectAccess(
        ConfirmationPage,
        '/service-center/finance/submit-check-request'
    );

    if (!user || !user.Id) {
        Log.info('props user is undefined');
        return null;
    }

    return (
        <Switch>
            <Route path="/service-center/home" component={ServiceHome} />
            <Route path="/service-center/procurement" exact component={ProcurementHome} />
            <Route path="/service-center/finance" exact component={FinanceHomePage} />
            <PrivateRouteCheckRequest
                path="/service-center/finance/submit-check-request"
                exact
                component={SubmitCheckRequest}
                user={user}
            />
            <PrivateRouteCheckRequest
                path="/service-center/finance/manage-check-requests"
                exact
                component={ManageCheckRequests}
                user={user}
            />
            <PrivateRouteCheckRequest
                path="/service-center/finance/report-check-requests"
                component={ArchivedCheckRequests}
                user={user}
            />
            <PrivateRouteCheckRequest
                path="/service-center/finance/submit-check-request/success"
                exact
                component={RedirectSuccess}
                user={user}
            />
            <PrivateRouteCheckRequest
                path="/service-center/finance/check-request-reports"
                exact
                component={Reports}
                user={user}
            />
            <PrivateRouteCheckRequest
                path="/service-center/finance/check-request-reports/report"
                exact
                component={ReportView}
                user={user}
            />
        </Switch>
    );
};

serviceCenterRouter.propTypes = {
    user: UserType,
};

serviceCenterRouter.defaultProps = {
    user: {},
};

export default serviceCenterRouter;
