import React from 'react';
import './stylesheets/NotFound.css';

const unauthorized = () => (
    <div className="NotFound">
        <h3>You do not have permission to access the requested resource.</h3>
    </div>
);

export default unauthorized;
