import PropTypes from 'prop-types';

class IGetFavoriteViewsRequest {
    constructor() {
        this.dataSourceId = null;
    }

    static load(dataSourceId) {
        if (!dataSourceId) {
            throw Error('dataSourceId is required');
        }

        const request = new IGetFavoriteViewsRequest();
        request.dataSourceId = dataSourceId;
        return request;
    }
}

export default IGetFavoriteViewsRequest;

IGetFavoriteViewsRequest.propTypes = {
    dataSourceId: PropTypes.string,
};
