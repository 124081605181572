import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserService } from '../../../_services';
import LogoutLink from '../UserMenuItems/LogoutLink';
import ModuleDescription from '../UserMenuItems/ModuleDescription';
import NotificationList from '../../../utilComponents/Notifications/NotificationList';
import GeotrakNotificationManager from '../../../helpers/GeotrakNotificationManager/GeotrakNotificationManager';

const ROLE_ID_INDEX = 4;

const userMenu = ({ onLogout, history }) => {
    const auth = new UserService();
    const user = auth.getCurrentUser();
    const activeRole = user.roles.find((role) => role.id === history.location.pathname.split('/')[ROLE_ID_INDEX]);
    const geotrakNotificationManager = new GeotrakNotificationManager();

    return (
        <ul className="navbar-nav">
            <li className="nav-item">
                <a
                    className="nav-link dropdown-toggle text-white"
                    aria-expanded="false"
                    id="user-nav-dropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    href="/#"
                >
                    <span className="position-relative">
                        {activeRole ? `${user.FullName} (${activeRole.module.alias})` : `${user.FullName}`}
                        {geotrakNotificationManager.notifications.length > 0 && (
                            <span className="position-absolute ms-3 bottom-200 translate-middle badge rounded-pill bg-danger">
                                {geotrakNotificationManager.notifications.length}
                            </span>
                        )}
                    </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end shadow" aria-labelledby="user-nav-dropdown">
                    <li>
                        <ModuleDescription activeRole={activeRole} />
                    </li>
                    <li className="border border-default">
                        <NotificationList />
                    </li>
                    <li className="drop-down-item">
                        <nav className="navbar-nav navbar-text m-2">
                            <Link to={`/user/${user.Id}/profile`}>My Profile</Link>
                        </nav>
                    </li>
                    <LogoutLink onLogout={onLogout} />
                </ul>
            </li>
        </ul>
    );
};

export default userMenu;

userMenu.propTypes = {
    onLogout: PropTypes.func,
    history: PropTypes.instanceOf(Object),
};

userMenu.defaultProps = {
    onLogout: () => null,
    history: {},
};
