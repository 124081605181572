import querystring from 'querystring';
import BaseGeoTrakService from './BaseGeoTrakService';
import { FavoriteView, View } from './Models';

export default class ViewService extends BaseGeoTrakService {
    async getView(request) {
        const { params, dataSourceId } = request;
        const urlParams = querystring.stringify(params);
        return this.fetch(`/data-sources/${dataSourceId}/views?${urlParams}`).then((view) => View.load(view));
    }

    async getSystemView(request) {
        const { params, dataSourceId } = request;
        const urlParams = querystring.stringify(params);
        return this.fetch(`/data-sources/${dataSourceId}/views/system?${urlParams}`).then((view) => View.load(view));
    }

    async getViews(request) {
        const { dataSourceId } = request;
        return this.fetch(`/data-sources/${dataSourceId}/view-list`).then(
            (views) => views.map((view) => View.load(view))
        );
    }

    async getSystemViews(request) {
        const { dataSourceId } = request;
        return this.fetch(`/data-sources/${dataSourceId}/view-list/system`).then((views) => views.map((view) => View.load(view)));
    }

    async updateViewDetails(body) {
        return this.patch('/views', body);
    }

    async updateUserViewFields(body) {
        return this.put('/user-views/fields', body).then((view) => View.load(view));
    }

    async updateSystemViewFields(body) {
        return this.put('/system-views/fields', body).then((view) => View.load(view));
    }

    async deleteUserView(request) {
        return this.delete('/user-views', request);
    }

    async deleteSystemView(request) {
        return this.delete('/system-views', request);
    }

    async getFavoriteViews(request) {
        const { dataSourceId } = request;
        return this.fetch(`/user-favorites/data-sources/${dataSourceId}/views`)
            .then((favoriteViews) => favoriteViews.map((favoriteView) => FavoriteView.load(favoriteView)));
    }

    async createFavoriteView(request) {
        const { dataSourceId, data } = request;
        return this.post(`/user-favorites/data-sources/${dataSourceId}/views`, data)
            .then((favoriteView) => FavoriteView.load(favoriteView));
    }

    async deleteFavoriteView(id) {
        return this.delete(`/user-favorites/views/${id}`);
    }
}
