import { SET_FEATURE, RESET_FEATURE } from '../CONSTANTS/types';

export default (state = {}, action = { type: '', payload: {} }) => {
    switch (action.type) {
    case SET_FEATURE:
        return action.payload;
    case RESET_FEATURE:
        return {};
    default:
        return state;
    }
};
