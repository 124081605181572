import PropTypes from 'prop-types';
import { Module } from '../Models';

class IUpdateModuleRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(module) {
        if (!module) {
            throw Error('module is required');
        }
        if (!(module instanceof Module)) {
            throw Error('Unexpected type for module');
        }

        const request = new IUpdateModuleRequest();
        request.id = module.id;
        request.data = module;
        request.validate();
        return request;
    }

    validate() {
        if (!this.data.project.id) {
            throw Error('projectId is required');
        }
        if (!this.data.unit.id) {
            throw Error('unitId is required');
        }
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateModuleRequest;

IUpdateModuleRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(Object),
};
