import PropTypes from 'prop-types';

export default class Notification {
    constructor() {
        this.id = null;
        this.database = null;
        this.schema = null;
        this.table = null;
        this.uniqueIdField = null;
        this.changeField = null;
        this.template = null;
        this.whereClause = null;
        this.isActive = false;
    }

    static load(response) {
        const notification = new Notification();

        if (response) {
            notification.id = response.NotificationId;
            notification.database = response.DatabaseName;
            notification.schema = response.SchemaName;
            notification.table = response.TableName;
            notification.uniqueIdField = response.UniqueIdFieldName;
            notification.changeField = response.ChangeFieldName;
            notification.template = response.Template;
            notification.whereClause = response.WhereClause;
            notification.isActive = response.IsActive;
        }

        return notification;
    }

    loadSqlEntity() {
        const sqlEntity = {
            DatabaseName: this.database,
            SchemaName: this.schema,
            TableName: this.table,
            UniqueIdFieldName: this.uniqueIdField,
            ChangeFieldName: this.changeField,
            Template: this.template,
            WhereClause: this.whereClause,
            IsActive: this.isActive,
        };

        if (this.id) {
            sqlEntity.NotificationId = this.id;
        }

        return sqlEntity;
    }
}

Notification.propTypes = {
    id: PropTypes.string,
    database: PropTypes.string,
    schema: PropTypes.string,
    table: PropTypes.string,
    uniqueIdField: PropTypes.string,
    changeField: PropTypes.string,
    template: PropTypes.string,
    whereClause: PropTypes.string,
    isActive: PropTypes.bool,
};
