import BaseGeoTrakService from './BaseGeoTrakService';
import { DataSource, FavoriteDataSource } from './Models';

export default class DataSourceService extends BaseGeoTrakService {
    async getDataSources(moduleRoleId) {
        return this.fetch(`/module-roles/${moduleRoleId}/data-sources`)
            .then((dataSources) => dataSources.map((dataSource) => DataSource.load(dataSource)));
    }

    async getDataSource(id) {
        return this.fetch(`/data-sources/${id}`).then((dataSource) => DataSource.load(dataSource));
    }

    async createDataSource(request) {
        const { moduleRoleId, data } = request;
        return this.post(`/module-roles/${moduleRoleId}/data-sources`, data.loadSqlEntity())
            .then((dataSource) => DataSource.load(dataSource));
    }

    async updateDataSource(request) {
        const { id, data } = request;
        return this.put(`/data-sources/${id}`, data.loadSqlEntity())
            .then((dataSource) => DataSource.load(dataSource));
    }

    async deleteDataSource(id) {
        return this.delete(`/data-sources/${id}`);
    }

    async getFavoriteDataSource(request) {
        const { moduleRoleId } = request;
        return this.fetch(`/user-favorites/module-roles/${moduleRoleId}/data-sources`)
            .then((favoriteDataSource) => FavoriteDataSource.load(favoriteDataSource));
    }

    async createFavoriteDataSource(request) {
        const { moduleRoleId, data } = request;
        return this.post(`/user-favorites/module-roles/${moduleRoleId}/data-sources`, data.loadSqlEntity())
            .then((favoriteDataSource) => FavoriteDataSource.load(favoriteDataSource));
    }

    async deleteFavoriteDataSource(id) {
        return this.delete(`/user-favorites/data-sources/${id}`);
    }
}
