export default class AggregateError extends Error {
    constructor(errors, message) {
        super(message);

        this.errors = errors;
    }

    static load(errors, message) {
        const result = new AggregateError([], message);
        errors.forEach((e) => {
            if (e.code && !e.type) {
                // its a socket error!
                result.errors.push(new Error(e.code));
                return;
            }
            result.errors.push(e);
        });
        return result;
    }
}
