import { SET_ACTIVE_SCHEMA } from '../CONSTANTS/types';
import { addAlertThunk } from './errorActions';
import TableBuilder from '../helpers/TableBuilder';
import { DomainService, DatabaseService } from '../_services/GeotrakService';
import IGetFieldsRequest from '../_services/GeotrakService/interfaces/IGetFieldsRequest';

const setActiveSchema = (activeSchema) => ({
    type: SET_ACTIVE_SCHEMA,
    activeSchema,
});

const getDomains = (dataSource) => {
    const domainService = new DomainService();
    return domainService.getDomainValues(dataSource.databaseReference, dataSource.tableReference);
};

const fetchSchemaThunk = (dataSource, viewFields) => async (dispatch) => {
    try {
        const request = IGetFieldsRequest.load(
            dataSource.databaseReference,
            dataSource.tableReference,
            dataSource.schemaReference
        );
        const schema = await new DatabaseService().getFields(request);
        const domains = await getDomains(dataSource);
        const tableBuilder = new TableBuilder(viewFields, schema, domains);
        const tableSchema = tableBuilder.getColumns();
        return dispatch(setActiveSchema(tableSchema));
    } catch (err) {
        return dispatch(addAlertThunk('danger', 'Error fetching schema', err.message || err));
    }
};

export default fetchSchemaThunk;
