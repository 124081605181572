import PropTypes from 'prop-types';

export default class CheckRequestSummary {
    constructor(summary) {
        this.CheckRequestId = summary['Check Request Id'];
        this.ProjectSiteId = summary['Project Site Id'];
        this.RequestType = summary['Request Type'];
        this.NationalProgram = summary['National Program'];
        this.Purpose = summary.Purpose;
        this.PayableTo = summary['Payable To'];
        this.CurrentStage = summary['Current Stage'];
        this.ProjectTag = summary['Project Tag'];
        this.MarketTag = summary['Market Tag'];
        this.Amount = summary.Amount;
        this.CheckNo = summary['Check No'];
        this.AttachInvoice = summary['Attach Invoice'];
        this.CheckStatus = summary['Check Status'];
        this.CheckClearedUTC = summary['Check Cleared Date'];
        this.CreatedByUserName = summary['Created By'];
    }
}

CheckRequestSummary.propTypes = {
    'Check Request Id': PropTypes.string,
    'Project Site Id': PropTypes.string,
    'Request Type': PropTypes.string,
    'National Program': PropTypes.string,
    Purpose: PropTypes.string,
    'Payable To': PropTypes.string,
    'Current Stage': PropTypes.string,
    'Project Tag': PropTypes.string,
    'Market Tag': PropTypes.string,
    Amount: PropTypes.string,
    'Check No': PropTypes.string,
    'Attach Invoice': PropTypes.string,
    'Check Status': PropTypes.string,
    'Check Cleared Date': PropTypes.datetimes,
    'Created By': PropTypes.string,
};
