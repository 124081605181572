import PropTypes from 'prop-types';
import RequestType from './RequestType';
import Project from './Project';

export default class RequestForm {
    constructor(project, requestType, requiredDocuments, id = null) {
        this.project = project;
        this.requestType = requestType;
        this.requiredDocuments = requiredDocuments;
        this.id = id;
    }

    static load(response) {
        return new RequestForm(
            Project.load(response.Project),
            RequestType.load(response.RequestType),
            response.MinimumRequiredDocuments,
            response.Id
        );
    }

    validate() {
        if (!this.project.name || this.project.name === '') {
            throw Error('Project is required');
        }

        if (!this.requestType.id) {
            throw Error('Associated request type is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            ProjectTag: this.project.name,
            RequestTypeId: this.requestType.id,
            MinimumRequiredDocuments: this.requiredDocuments,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

RequestForm.propTypes = {
    project: PropTypes.instanceOf(Project),
    requestType: PropTypes.instanceOf(RequestType),
    requiredDocuments: PropTypes.number,
    id: PropTypes.number,
};
