import decode from 'jwt-decode';
import { createWriteStream } from 'streamsaver';
import Log from '../utilComponents/Log';
import GeotrakCookies from '../helpers/GeotrakCookies';
import { ExpiredTokenError, NoContentError, ValidationError } from '../errors';
import AggregateError from '../errors/AggregateError';
import FeatureFlagService from './FeatureFlagService/FeatureFlagService';
import ContentTypes from './contentTypes';
import datetimes from '../helpers/datetimehelper';
import { getEnvironment } from '../helpers/utilities';
import returnCodes from '../CONSTANTS/returnCodes';

const cookies = new GeotrakCookies();
const EXPIRATION_MINUTES = 5;
const NO_CONTENT_SUCCESS_RESPONSE = 204;

export default class BaseNodeService {
    // Initializing important variables
    constructor(service, port) {
        const authEndpoint = `$https://ms${process.env.REACT_APP_ENV_PREFIX}.geotrak.io/authentication-service`;
        // prettier-ignore
        const authPort = 3004;
        this.domain = getEnvironment(`https://ms${process.env.REACT_APP_ENV_PREFIX}.geotrak.io${service}`, port, service)
        || getEnvironment(authEndpoint, authPort, '/authentication-service');
        this.fetch = this.fetch.bind(this);
        this.login = this.login.bind(this);
        this.featureFlags = new FeatureFlagService();
        this.tokenCookieKey = 'ms_token';
        this.accessTokenSecret = null;
    }

    getCurrentUrl = () => {
        try {
            return window.href.location;
        } catch (e) {
            Log.error('error getting current url');
            Log.dump();
            return null;
        }
    }

    refresh() {
        try {
            window.location.href = this.getCurrentUrl();
        } catch (e) {
            Log.error('error refreshing page');
            throw e;
        }
    }

    async buildHeaders(contentType = ContentTypes.ApplicationJson) {
        if (!await this.loggedIn()) {
            Log.dump();
            throw new ExpiredTokenError('user not logged in');
        }

        const headers = {
            Accept: ContentTypes.ApplicationJson,
            'Content-Type': contentType,
            Authorization: `Bearer ${await this.getTokenFromCookie()}`,
        };

        if (contentType === ContentTypes.MultipartFormData) {
            /* by deleting this header, fetch will autocalc the boundary of the
               uploaded file */
            delete headers['Content-Type'];
        }

        return headers;
    }

    buildUri(route) {
        if (!route) {
            return this.domain;
        }

        // clean up the route
        let formattedRoute = route.trim();

        if (!route.startsWith('/')) {
            formattedRoute = `/${formattedRoute}`;
        }

        return `${this.domain}${formattedRoute}`;
    }

    async login(credentials) {
        if (await this.loggedIn()) {
            return this.getTokenFromCookie();
        }
        return fetch(`${this.domain}/token`, {
            headers: {
                'Content-Type': ContentTypes.ApplicationXWWWFormUrlencoded,
            },
            method: 'POST',
            body: Object.keys(credentials)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(credentials[key])}`)
                .join('&'),
        })
            .then(BaseNodeService.checkStatus)
            .then((response) => response.json())
            .then((data) => {
                this.accessTokenSecret = data.access_token_secret;
                cookies.setSecret(this.accessTokenSecret);
                this.setToken(data); // Setting the token in localStorage
                return data;
            });
    }

    async loggedIn() {
        const token = await this.getWebToken();
        if (token == null || token === '{}' || token === 'undefined') {
            return false;
        }
        return !this.isTokenExpired(token);
    }

    isTokenExpired(token) {
        let decoded = null;
        let isExpired = false;

        // decode it.
        try {
            decoded = decode(token);
        } catch (err) {
            // maybe it's not encoeded.  lets try and read a property.
            if (Object.prototype.hasOwnProperty.call(token, '.expires')
                && Object.prototype.hasOwnProperty.call(token, 'access_token')) {
                decoded = token;
            }
        }

        if (!decoded || !Object.prototype.hasOwnProperty.call(decoded, '.expires')) {
            return true;
        }
        const now = Date.now();
        const tokenTime = Date.parse(decoded['.expires']);
        Log.debug(`mstoken expires in ${datetimes.getDifferanceInSeconds(now, tokenTime)} seconds`);

        if (tokenTime > now) {
            // Checking if token is expired. N
            isExpired = false;
        } else {
            isExpired = true;
        }

        if (isExpired) {
            Log.debug(`removing expired token from ${window.location.hostname}`);
            cookies.set(this.tokenCookieKey, '');
            cookies.remove(this.tokenCookieKey);
            const removed = cookies.get(this.tokenCookieKey) === null;
            Log.debug(`removed=${removed}`);
            this.refresh();
        }

        return isExpired;
    }

    setToken(token) {
        cookies.set(this.tokenCookieKey, JSON.stringify(token));
    }

    getWebToken() {
        const token = cookies.get(this.tokenCookieKey);
        if (!token) {
            return null;
        }
        return token;
    }

    getClaim(name) {
        Log.debug(`getting claim ${name}`);
        const token = cookies.get(this.tokenCookieKey);
        if (!token) {
            return null;
        }
        const d = cookies.decrypt(token.access_token, this.accessTokenSecret);
        Log.debug(d.claims);
        return d.claims[name];
    }

    async getTokenFromCookie() {
        const token = await this.getWebToken();
        this.accessTokenSecret = token.access_token_secret;
        return token.access_token;
    }

    logout() {
        cookies.destroy();
        return cookies.remove(this.tokenCookieKey);
    }

    clearCookies = () => {
        cookies.removeAll();
    }

    async fetch(route, options) {
        const headers = await this.buildHeaders();
        const uri = this.buildUri(route);
        return fetch(uri, {
            headers,
            options,
        })
            .then(BaseNodeService.checkStatus)
            .then((response) => response.json())
            .catch((err) => {
                Log.error('fetch error');
                Log.error(err);
                throw err;
            });
    }

    async get(route, useCache) {
        if (useCache) {
            const cache = BaseNodeService.getCache(route);
            if (cache) {
                return cache;
            }
        }

        const headers = await this.buildHeaders();
        return fetch(this.buildUri(route), {
            method: 'GET',
            headers,
        })
            .then(BaseNodeService.checkStatus)
            .then((response) => {
                if (useCache) {
                    BaseNodeService.setCache(route, response);
                }
                return response.json();
            });
    }

    async download(route, providedFileName, options) {
        const headers = await this.buildHeaders();
        const url = BaseNodeService.addCacheBuster(this.buildUri(route), options);
        return fetch(url, { ...options, headers })
            .then(BaseNodeService.checkStatus)
            .then((resp) => {
                // specific status checks for downloads
                const { status } = resp;
                if (status === returnCodes.SUCCESS.NO_CONTENT) {
                    throw new NoContentError('Downloaded was successful, but there is no data.');
                }
                return resp;
            })
            .then((fetchRes) => {
                let fileName = providedFileName;
                if (!providedFileName) {
                    fileName = fetchRes.headers.get('Content-Disposition').split('filename=').pop();
                }
                const fileStream = createWriteStream(fileName);
                const readableStream = fetchRes.body;

                // (Safari may have pipeTo but it's useless without the WritableStream)
                if (window.WritableStream && readableStream.pipeTo) {
                    return readableStream
                        .pipeTo(fileStream)
                        .then(() => Log.trace(`done piping file ${fileName}`));
                }

                // Write (pipe) manually
                const writer = fileStream.getWriter();
                const reader = readableStream.getReader();
                const pump = () => reader.read()
                    .then((res) => (res.done ? writer.close() : writer.write(res.value).then(pump)));
                return pump();
            });
    }

    async patch(route, data) {
        const headers = await this.buildHeaders();
        const options = {
            headers,
            method: 'PATCH',
            body: JSON.stringify(data),
        };

        return fetch(this.buildUri(route), options)
            .then(BaseNodeService.checkStatus)
            .then((response) => {
                if (response.status === NO_CONTENT_SUCCESS_RESPONSE) {
                    return response;
                }
                return response.json();
            });
    }

    async put(route, data) {
        const headers = await this.buildHeaders();
        const options = {
            headers,
            method: 'PUT',
            body: JSON.stringify(data),
        };

        return fetch(this.buildUri(route), options)
            .then(BaseNodeService.checkStatus)
            .then((response) => {
                if (response.status === NO_CONTENT_SUCCESS_RESPONSE) {
                    return response;
                }
                return response.json();
            });
    }

    async post(route, data) {
        const headers = await this.buildHeaders();
        const options = {
            headers,
            method: 'POST',
            body: JSON.stringify(data),
        };

        return fetch(this.buildUri(route), options)
            .then(BaseNodeService.checkStatus)
            .then((response) => response.json());
    }

    async delete(route, data = null) {
        const headers = await this.buildHeaders();
        const options = {
            method: 'DELETE',
            headers,
        };

        if (data) {
            options.body = JSON.stringify(data);
        }

        return fetch(this.buildUri(route), options)
            .then(BaseNodeService.checkStatus);
    }

    async uploadFile(route, formData) {
        const headers = await this.buildHeaders(ContentTypes.MultipartFormData);
        const options = {
            headers,
            method: 'POST',
            body: formData,
        };
        return fetch(this.buildUri(route), options)
            .then(BaseNodeService.checkStatus)
            .then((response) => response.blob());
    }

    async postJson(route, data) {
        const headers = await this.buildHeaders();
        const options = {
            headers,
            method: 'POST',
            body: JSON.stringify(data),
        };

        return fetch(this.buildUri(route), options)
            .then(this.checkStatus)
            .then((response) => response.json());
    }

    static deleteCache(url) {
        localStorage.removeItem(url);
    }

    // passing null clears all cache
    static clearCache(contains) {
        if (!contains) {
            localStorage.clear();
            return;
        }

        Object.keys(localStorage).forEach((url) => {
            if (url.includes(contains)) {
                this.deleteCache(url);
            }
        });
    }

    static addCacheBuster(url, options) {
        if (!options) {
            return url;
        }

        const { cacheBuster } = options;

        // no cachebuster option. return url as-is
        if (!cacheBuster) {
            return url;
        }

        // already has a cachebuster qs parameter. return url as-is
        const hasCacheBuster = /[?|&|]cb=/;
        if (hasCacheBuster.test(url)) {
            return url;
        }

        // generate the cache buster
        const cb = new Date().getTime();

        // if url ends with question mark, just append the cache buster
        if (url.endsWith('?')) {
            return `${url}cb=${cb}`;
        }

        // url already has query string parameters, add the cb key value pair
        const hasQueryString = /[?=]/;
        if (hasQueryString.test(url)) {
            return `${url}&cb=${cb}`;
        }

        // ends with a slash.  swap out for ?cb=21432
        if (url.endsWith('/')) {
            return `${url.slice(0, -1)}?cb=${cb}`;
        }

        return `${url}?cb=${cb}`;
    }

    static getCache(url, validate) {
        const cache = localStorage.getItem(url);
        if (cache) {
            try {
                if (validate) {
                    return JSON.parse(cache);
                }
                return JSON.parse(cache).data;
            } catch {
                return null;
            }
        }
        return null;
    }

    static setCache(url, response) {
        const expiresOn = new Date();
        expiresOn.setMinutes(expiresOn.getMinutes() + EXPIRATION_MINUTES);

        const cache = {
            type: 'fetch_cache',
            data: response,
            expiresOn,
        };

        return localStorage.setItem(url, JSON.stringify(cache));
    }

    static expireCache() {
        for (let i = 0; i < localStorage.length; i += 1) {
            const item = BaseNodeService.getCache(localStorage.key(i), true);
            if (item && item.expiresOn) {
                const cacheDate = new Date(item.expiresOn);
                const now = new Date();
                if (cacheDate < now) {
                    localStorage.removeItem(localStorage.key(i));
                }
            }
        }
    }

    static async checkStatus(response) {
        if (response.status >= returnCodes.SUCCESS.OK && response.status < returnCodes.REDIRECTION.MULTIPLE_CHOICES) {
            return response;
        }
        const body = await response.json();

        if (response.status >= returnCodes.SERVER_ERROR.INTERNAL_SERVER_ERROR) {
            throw new Error(`${response.statusText}: ${body.message}`);
        }
        if (body.type === 'ValidationError') {
            throw new ValidationError(body.errors, body.type);
        }
        if (body.type === 'ProcessChainError') { // TODO: NOT CURRENTLY UTILIZED
            throw AggregateError.load(body.errors, body.type);
        }
        throw new Error(response.statusText);
    }
}
