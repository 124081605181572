export default class IntersectionManager {
    constructor(layers, selectionLayer, boundingBox) {
        this.layers = layers;
        this.selectionLayer = selectionLayer;
        this.boundingBox = boundingBox;

        this.viablePoints = this.getPointsInSelectionLayer();
        this.selectedPoints = this.intersect();
    }

    intersect() {
        return this.viablePoints.filter((point) => this.isPointInBoundingBox(point));
    }

    getSelectedPointIds = () => this.selectedPoints.map((point) => point.options.id)

    isPointInBoundingBox(point) {
        const { _bounds: bounds } = this.boundingBox;
        const { _southWest: southWestCorner, _northEast: northEastCorner } = bounds;
        const { _latlng: pointCoordinates } = point;

        return southWestCorner.lng <= pointCoordinates.lng
        && northEastCorner.lng >= pointCoordinates.lng
        && southWestCorner.lat <= pointCoordinates.lat
        && northEastCorner.lat >= pointCoordinates.lat;
    }

    getPointsInSelectionLayer() {
        let markerCollection = [];
        Object.keys(this.layers).forEach((layer) => {
            const markers = this.getMarkersInGroup(this.layers[layer]);
            markerCollection = [...markerCollection, ...markers];
        });
        return markerCollection.filter((marker) => marker.options.layer === this.selectionLayer);
    }

    getMarkersInGroup(group) {
        let markerCollection = [];
        const { _markers: markers, _childClusters: childClusters, _latlng: latlng } = group;
        if (childClusters && childClusters.length) {
            childClusters.forEach((child) => {
                const groupMarkers = this.getMarkersInGroup(child);
                markerCollection = [...markerCollection, ...groupMarkers];
            });
        }
        if (markers && markers.length) {
            markerCollection = [...markerCollection, ...markers];
        }
        if (!markers && !childClusters && latlng) {
            markerCollection = [...markerCollection, ...[group]];
        }

        return markerCollection;
    }
}
