import PropTypes from 'prop-types';

export default class Option {
    constructor(name, fieldId, id = null) {
        this.name = name;
        this.fieldId = fieldId;
        this.id = id;
    }

    static load(response) {
        return new Option(
            response.Name,
            response.FieldId,
            response.Id
        );
    }

    validate() {
        if (!this.name || this.email === '') {
            throw Error('Name is required');
        }

        if (!this.fieldId) {
            throw Error('Associated field is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            Name: this.name,
            FieldId: this.fieldId,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

Option.propTypes = {
    name: PropTypes.string,
    fieldId: PropTypes.number,
    id: PropTypes.number,
};
