import { useEffect, useReducer } from 'react';
import { NotificationField } from '../../Models';
import NotificationFieldService from '../../NotificationFieldService';
import { useApiReducer, ACTIONS } from '../../../../helpers/hooks/useApiReducer';

const useGetNotificationField = (id) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new NotificationField() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const result = await new NotificationFieldService().getNotificationField(id);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, []);

    return { notificationField: data, error, isLoading };
};

export default useGetNotificationField;
