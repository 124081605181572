import { useState, useEffect } from 'react';
import useGetRoles from '../../../_services/GeotrakService/hooks/useGetRoles';
import useGetUsers from '../../../_services/UserService/hooks/useGetUsers';

const useUserForm = (id) => {
    const { roles, isLoading: isLoadingRoles } = useGetRoles();

    let user = {};
    let isLoading = false;

    if (id) {
        const result = useGetUsers();
        user = result.users.find((userObject) => userObject.Id === id);
        isLoading = result.isLoading;
    }

    const [values, setValues] = useState({
        id: null,
        username: '',
        emailAddress: '',
        name: '',
        phoneNumber: '',
        adminPermission: '',
        roles: [],
        isDeleted: false,
    });

    const isValid = () => values.name !== '' && values.username !== '' && values.emailAddress !== '';

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    const onRoleUpdate = (value) => {
        if (values.roles.includes(value)) {
            setValues({ ...values, roles: [...values.roles.filter((role) => role !== value)] });
        } else {
            setValues({ ...values, roles: [...values.roles, value] });
        }
    };

    const formatPermissions = (module) => {
        const roleList = [];
        values.roles.forEach((role) => {
            const selectedRole = roles.find((roleObject) => roleObject.id === role);
            if (selectedRole.module.unit.name === module) {
                roleList.push(selectedRole.name);
            }
        });
        return roleList.join(',');
    };

    const convertRoleStringToRoleIds = (roleNames, unit) => {
        if (roleNames) {
            const roleList = roleNames.split(',').map((roleName) => {
                const foundRole = roles.find((role) => role.module.unit.name === unit && roleName === role.name);
                if (foundRole) {
                    return foundRole;
                }
                return null;
            });
            return roleList.filter((role) => role).map((roleObject) => roleObject.id);
        }
        return [];
    };

    const unformatPermissions = () => {
        const landbaseRoles = convertRoleStringToRoleIds(user.landbase, 'Landbase');
        const checkRequestRoles = convertRoleStringToRoleIds(user.CheckRequest, 'CheckRequest');
        const constructionRoles = convertRoleStringToRoleIds(user.Construction, 'Construction');
        const managerRoles = convertRoleStringToRoleIds(user.manager, 'Manager');
        const dashboardRoles = convertRoleStringToRoleIds(user.dashboards, 'Dashboards');
        const structuralRoles = convertRoleStringToRoleIds(user.Structural, 'Structural');
        return [
            ...landbaseRoles,
            ...checkRequestRoles,
            ...constructionRoles,
            ...managerRoles,
            ...dashboardRoles,
            ...structuralRoles,
        ];
    };

    const formatFullName = (fullName) => fullName.trimStart().trimRight().split(' ').reverse()
        .join(' ')
        .replace(',', '');

    useEffect(() => {
        if (id && user && !isLoadingRoles) {
            setValues({
                id: user.Id || null,
                username: user.userid || '',
                emailAddress: user.emailAddress || '',
                name: formatFullName(user.FullName) || '',
                phoneNumber: user.phoneNumber || '',
                adminPermission: user.Admin || '',
                roles: unformatPermissions() || [],
                isDeleted: user.IsDeleted || false,
            });
        }
    }, [user, isLoadingRoles]);

    return {
        values,
        onFormUpdate,
        onRoleUpdate,
        isValid,
        roles,
        formatPermissions,
        isLoading,
    };
};

export default useUserForm;
