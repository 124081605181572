import { useEffect, useReducer } from 'react';
import { Autopopulation } from '../Models';
import AutopopulateService from '../AutopopulateService';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';

const useGetAutopopulation = (id) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new Autopopulation() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const result = await new AutopopulateService().getAutopopulation(id);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, []);

    return { autopopulation: data, error, isLoading };
};

export default useGetAutopopulation;
