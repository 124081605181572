import { useState, useMemo } from 'react';
import { sortBy } from '../functionals';

const useSortableData = (items, config = { key: null, direction: null }) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
        const sortableItems = [...items];
        if (sortConfig.key) {
            if (sortConfig.direction === 'ascending') {
                return sortableItems.sort(sortBy(sortConfig.key));
            }
            sortableItems.sort(sortBy(sortConfig.key));
            return sortableItems.reverse();
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig
        && sortConfig.key === key
        && sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
