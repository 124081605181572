import { useEffect, useState } from 'react';
import useGetModuleMap from '../../../_services/GeotrakService/hooks/useGetModuleMap';
import useGetModuleWidgets from '../../../_services/GeotrakService/hooks/useGetModuleWidgets';
import useGetWidgets from '../../../_services/GeotrakService/hooks/useGetWidgets';

const useMapForm = (moduleRoleId) => {
    const { map, isLoading: isMapLoading } = useGetModuleMap(moduleRoleId);
    const { moduleWidgets, isLoading: isWidgetListLoading } = useGetModuleWidgets(moduleRoleId);
    const { widgets } = useGetWidgets();

    const [values, setValues] = useState({
        id: null,
        centerLatitude: '',
        centerLongitude: '',
        zoom: '',
        isSpatialAdmin: false,
        widgets: [],
    });
    const DEFAULT_LATITUDE = 45.0000;
    const DEFAULT_LONGITUDE = -82.0000;

    const isValid = () => values.zoom;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        setValues({
            id: map.id,
            centerLatitude: map.center.latitude || DEFAULT_LATITUDE,
            centerLongitude: map.center.longitude || DEFAULT_LONGITUDE,
            zoom: map.zoom || '',
            isSpatialAdmin: map.isSpatialAdmin,
            widgets: moduleWidgets,
        });
    }, [map, moduleWidgets]);

    return {
        values,
        isValid,
        onFormUpdate,
        isLoading: isMapLoading || isWidgetListLoading,
        widgets: widgets.filter((widget) => widget.isMapWidget && !widget.isTableWidget),
    };
};

export default useMapForm;
