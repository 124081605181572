import PropTypes from 'prop-types';

export default class GeocoderJob {
    constructor() {
        this.taskId = '';
        this.taskType = '';
        this.database = '';
        this.schema = '';
        this.table = '';
        this.primaryKeyColumn = '';
        this.createdBy = '';
        this.id = '';
    }

    static load(response) {
        if (response) {
            const geocoderJob = new GeocoderJob();
            geocoderJob.taskId = response.geocoderTask.id;
            geocoderJob.taskType = response.geocoderTask.name;
            geocoderJob.database = response.databaseName;
            geocoderJob.schema = response.schemaName;
            geocoderJob.table = response.tableName;
            geocoderJob.primaryKeyColumn = response.pkColumnName;
            geocoderJob.createdBy = response.createdBy;
            geocoderJob.id = response.id;
            return geocoderJob;
        }
        return null;
    }

    validate() {
        if (!this.taskId) {
            throw Error('TaskId is required');
        }

        if (!this.database) {
            throw Error('DatabaseName is required');
        }

        if (!this.schema) {
            throw Error('SchemaName is required');
        }

        if (!this.table) {
            throw Error('TableName is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const entity = {
            taskId: this.taskId,
            databaseName: this.database,
            schemaName: this.schema,
            tableName: this.table,
            pkColumnName: this.primaryKeyColumn,
        };

        if (this.id) {
            entity.id = this.id;
        }

        return entity;
    }
}

GeocoderJob.propTypes = {
    id: PropTypes.string,
    taskId: PropTypes.string,
    database: PropTypes.string,
    schema: PropTypes.string,
    table: PropTypes.string,
    primaryKeyColumn: PropTypes.string,
};
