import PropTypes from 'prop-types';
import { Role } from '../Models';

class IUpdateRoleRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(role) {
        if (!role) {
            throw Error('role is required');
        }
        if (!(role instanceof Role)) {
            throw Error('Unexpected type for role');
        }

        const request = new IUpdateRoleRequest();
        request.id = role.id;
        request.data = role;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateRoleRequest;

IUpdateRoleRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(Object),
};
