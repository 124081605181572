import Notification from './Notification';

const PASSWORD_CLOSE_TO_EXPIRED_DAYS = 14;
const PASSWORD_REALLY_CLOSE_TO_EXPIRED_DAYS = 7;

export default class PasswordChangeNotification extends Notification {
    static load(user) {
        const notification = new Notification();

        if (!user) {
            throw new Error('user must be provided');
        }

        if (!user.DaysUntilExpiration) {
            notification.alertLevel = notification.alertLevels.NONE;
        }

        if (user.DaysUntilExpiration > PASSWORD_REALLY_CLOSE_TO_EXPIRED_DAYS
            && user.DaysUntilExpiration <= PASSWORD_CLOSE_TO_EXPIRED_DAYS) {
            notification.alertLevel = notification.alertLevels.WARNING;
        }

        if (user.DaysUntilExpiration && user.DaysUntilExpiration <= PASSWORD_REALLY_CLOSE_TO_EXPIRED_DAYS) {
            notification.alertLevel = notification.alertLevels.DANGER;
        }

        if (user.DaysUntilExpiration > PASSWORD_CLOSE_TO_EXPIRED_DAYS) {
            notification.alertLevel = notification.alertLevels.NONE;
        }

        notification.message = user.DaysUntilExpiration ? `Your database password will expire in ${user.DaysUntilExpiration} days.  Click to change it.` : null;
        notification.navigation = `/user/${user.Id}/change-database-password`;
        notification.alertType = notification.alertTypes.DATABASE_PASSWORD;

        if (notification.alertLevel === notification.alertLevels.NONE) {
            return null;
        }

        return notification;
    }
}
