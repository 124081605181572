import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class VoidFields {
    constructor(checkRequest) {
        this.VoidSubmittedBy = checkRequest.VoidSubmittedBy;
        this.VoidSubmittedDate = datetimes.toLocal(checkRequest.VoidSubmittedDate);
        this.ReasonForVoid = checkRequest.ReasonForVoid;
        this.VoidComments = checkRequest.VoidComments;
        this.VoidApprovedBy = checkRequest.VoidApprovedBy;
        this.VoidApprovedDate = datetimes.toLocal(checkRequest.VoidApprovedDate);
        this.VoidRejectedBy = checkRequest.VoidRejectedBy;
        this.VoidRejectedDate = datetimes.toLocal(checkRequest.VoidRejectedDate);
        this.VoidRejectedComments = checkRequest.VoidRejectedComments;
    }
}

VoidFields.propTypes = {
    VoidSubmittedBy: PropTypes.string,
    VoidSubmittedDate: PropTypes.datetimes,
    ReasonForVoid: PropTypes.string,
    VoidComments: PropTypes.string,
    VoidApprovedBy: PropTypes.string,
    VoidApprovedDate: PropTypes.datetimes,
    VoidRejectedBy: PropTypes.string,
    VoidRejectedDate: PropTypes.datetimes,
    VoidRejectedComments: PropTypes.string,
};
