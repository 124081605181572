import {
    ADD_ALERT, REMOVE_ALERT
} from '../CONSTANTS/types';

const addAlert = (newAlert) => ({
    type: ADD_ALERT,
    payload: newAlert,
});

const removeAlert = (id) => ({
    type: REMOVE_ALERT,
    payload: id,
});

const convertMessageToString = (message) => {
    if (!message || typeof message === 'string') {
        return message;
    }

    if (typeof message === 'object' && 'message' in message) {
        return message.message;
    }

    try {
        return JSON.stringify(message);
    } catch {
        return 'unknown alert';
    }
};

export const addAlertThunk = (type, headline, message) => (dispatch) => {
    if (type) {
        const alert = {
            type,
            headline,
            id: new Date().getTime(),
            message: convertMessageToString(message),
        };
        dispatch(addAlert(alert));
    }
};

export const removeAlertThunk = (id) => (dispatch) => dispatch(removeAlert(id));
