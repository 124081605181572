import PropTypes from 'prop-types';
import { Domain } from '../Models';

class IUpdateDomainRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(domain) {
        if (!domain) {
            throw Error('domain is required');
        }
        if (!(domain instanceof Domain)) {
            throw Error('Unexpected type for domain');
        }

        const request = new IUpdateDomainRequest();
        request.id = domain.id;
        request.data = domain;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateDomainRequest;

IUpdateDomainRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(Domain),
};
