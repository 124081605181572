import React, { Component } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import '../stylesheets/Wizard.css';

const withWizard = (steps, SuccessComponent, initialJobProperties) => {
    class WizardComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                currentStepIndex: 0,
                wizardSteps: Object.keys(steps),
                jobProperties: initialJobProperties || {},
            };

            this.totalPercent = 100;
        }

        triggerNextStep = (e) => {
            const { currentStepIndex, jobProperties } = this.state;
            const nextStepIndex = currentStepIndex + 1;
            this.setState({
                jobProperties: e ? { ...jobProperties, ...e } : jobProperties,
                currentStepIndex: nextStepIndex,
            });
        }

        triggerPreviousStep = () => {
            const { currentStepIndex } = this.state;
            const previousStepIndex = currentStepIndex - 1;
            if (previousStepIndex >= 0) {
                this.setState({ currentStepIndex: previousStepIndex });
            }
        }

        skipNextStep = (e) => {
            const { currentStepIndex } = this.state;
            this.setState({ currentStepIndex: currentStepIndex + 1 });
            this.triggerNextStep(e);
        }

        cancelJob = () => {
            this.setState({ currentStepIndex: 0, jobProperties: {} });
        }

        renderActiveStep = () => {
            const { currentStepIndex, wizardSteps, jobProperties } = this.state;
            return wizardSteps.map((step) => {
                if (step === wizardSteps[currentStepIndex]) {
                    const WizardStep = steps[step];
                    return (
                        <WizardStep
                            key={`wizard-${step}`}
                            nextStep={(e) => this.triggerNextStep(e)}
                            previousStep={() => this.triggerPreviousStep()}
                            skipNextStep={(e) => this.skipNextStep(e)}
                            cancelJob={() => this.cancelJob()}
                            jobProperties={jobProperties}
                        />
                    );
                }
                return null;
            });
        }

        renderProgressBar = () => {
            const { currentStepIndex, wizardSteps } = this.state;
            return (
                <div className="text-center wizard-progress-container">
                    <div className="progress">
                        <div
                            className="progress-bar wizard-progress"
                            role="progressbar"
                            aria-valuenow="60"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${((currentStepIndex + 1) / wizardSteps.length) * this.totalPercent}%` }}
                        >
                            {`Step ${currentStepIndex + 1} of ${wizardSteps.length}`}
                        </div>
                    </div>
                </div>
            );
        }

        renderSuccessPage = () => {
            const { jobProperties } = this.state;
            if (SuccessComponent) {
                return (<SuccessComponent jobProperties={jobProperties} />);
            }
            return (
                <div className="col-md-12 text-center wizard-success-container">
                    <BsCheckCircle size="100" className="wizard-success" />
                    Success! Job complete.
                </div>
            );
        }

        render() {
            const { currentStepIndex, wizardSteps } = this.state;
            return (
                <>
                    {(currentStepIndex < wizardSteps.length) && this.renderProgressBar()}
                    {this.renderActiveStep()}
                    {(currentStepIndex === wizardSteps.length) && this.renderSuccessPage()}
                </>
            );
        }
    }

    return WizardComponent;
};

export default withWizard;
