import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../../helpers/hooks/useApiReducer';
import NotificationDomainService from '../../NotificationDomainService';

const useDeleteNotificationDomain = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteNotificationDomain = async (notificationDomain) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new NotificationDomainService().deleteNotificationDomain(notificationDomain.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteNotificationDomain, error, isLoading, isSuccessful,
    };
};

export default useDeleteNotificationDomain;
