import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetDatabases from '../../../_services/GeotrakService/hooks/useGetDatabases';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import '../../stylesheets/AdminDatabases.css';

const adminDatabases = () => {
    const history = useHistory();
    const { databases, isLoading, responseError } = useGetDatabases();
    const [error, setError] = useState(responseError);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Databases">
                {isLoading && (
                    <LoadingFullScreen />
                )}
                <ButtonContainer
                    error={error}
                    onErrorDismiss={() => setError(null)}
                >
                    <ButtonPrimary id="new-database-button" onClick={() => history.push('/admin/databases/new')}>
                        New Database
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group align-items-left databases-list-container">
                    {databases.map((database) => (
                        <button
                            type="button"
                            className="list-group-item text-left"
                            key={database}
                            onClick={() => history.push(`/admin/databases/${database}/schemas`)}
                        >
                            {database}
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDatabases;
