import { useReducer } from 'react';
import NotificationDomainService from '../../NotificationDomainService';
import ICreateNotificationDomainRequest from '../../interfaces/ICreateNotificationDomainRequest';
import { ACTIONS, useApiReducer } from '../../../../helpers/hooks/useApiReducer';

const useCreateNotificationDomain = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const createNotificationDomain = async (notificationDomainObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = ICreateNotificationDomainRequest.load(notificationDomainObject);
            const result = await new NotificationDomainService().createNotificationDomain(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        createNotificationDomain, error, isLoading, isSuccessful, data,
    };
};

export default useCreateNotificationDomain;
