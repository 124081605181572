import { useReducer } from 'react';
import CheckRequestAdminService from '../CheckRequestAdminService';
import IUpdateOfficeRequest from '../interfaces/IUpdateOfficeRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateOffice = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const updateOffice = async (officeObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateOfficeRequest.load(officeObject);
            const result = await new CheckRequestAdminService().updateOffice(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateOffice, error, isLoading, isSuccessful, data,
    };
};

export default useUpdateOffice;
