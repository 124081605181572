import React, { useState, useEffect } from 'react';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import FieldOptions from './FieldOptions';
import { Field } from '../../../_services/CheckRequestService/Models';
import './checkRequest.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';

const checkRequestAdminService = new CheckRequestAdminService();

const adminCheckRequestFields = () => {
    const [fields, setFields] = useState([]);
    const [selectedFieldId, setField] = useState(null);
    const [fieldName, setFieldName] = useState('');
    const [fieldAlias, setFieldAlias] = useState('');
    const [showNewField, setNewField] = useState(false);
    const [error, setError] = useState(null);
    const [isSaving, setSaving] = useState(false);

    const getFields = async () => {
        try {
            const fieldsResult = await checkRequestAdminService.getFields();
            setFields(fieldsResult);
            setError(null);
        } catch (err) {
            setError(err.message || err);
        }
    };

    const selectField = (field) => {
        setField(field.id);
        setFieldName(field.name);
        setFieldAlias(field.alias);
    };

    const createField = async (newField) => {
        try {
            await checkRequestAdminService.createField(newField);
            setSaving(false);
            setNewField(false);
            setField(null);
            getFields();
            setError(null);
        } catch (err) {
            setSaving(false);
            setError(err.message || err);
        }
    };

    const updateField = async (newField) => {
        try {
            await checkRequestAdminService.updateField(newField);
            getFields();
            setSaving(false);
            setField(null);
        } catch (err) {
            setSaving(false);
            setError(err.message || err);
        }
    };

    const submitField = () => {
        setSaving(true);
        const newField = new Field(fieldName, fieldAlias, [], selectedFieldId);
        if (selectedFieldId) {
            updateField(newField);
        } else {
            createField(newField);
        }
    };

    const showNewFieldForm = () => {
        setNewField(true);
        setFieldName('');
        setFieldAlias('');
        setField(null);
    };

    const isValid = () => fieldName !== '' && fieldAlias !== '';

    useEffect(() => {
        getFields();
    }, []);

    const renderFieldForm = () => (
        <form>
            <h4 className="text-center">Field</h4>
            <div className="mb-3">
                <label htmlFor="field-name" className="form-label">
                    Name
                </label>
                <input
                    type="text"
                    id="field-name"
                    value={fieldName}
                    placeholder="Name"
                    disabled={selectedFieldId !== null}
                    onChange={(e) => setFieldName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="field-alias" className="form-label">
                    Alias
                </label>
                <input
                    type="text"
                    id="field-alias"
                    value={fieldAlias}
                    placeholder="Alias"
                    onChange={(e) => setFieldAlias(e.target.value)}
                />
            </div>
            <ButtonContainer
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <LoaderButton
                    type="submit"
                    text="Save"
                    loadingText="Saving..."
                    isLoading={isSaving}
                    disabled={!isValid()}
                    onClick={submitField}
                />
            </ButtonContainer>
        </form>
    );

    const renderFields = () => fields.sort((a, b) => ((a.name > b.name) ? 1 : -1)).map((field) => (
        <button type="button" className="list-group-item list-group-item-action text-start" key={field.id} onClick={() => selectField(field)}>
            {field.alias}
        </button>
    ));

    return (
        <div className="col-md-12">
            <Breadcrumbs />
            <h2 className="Admin text-center">Form Fields</h2>
            <div className="col-md-10 offset-md-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <ButtonContainer>
                                <ButtonPrimary onClick={showNewFieldForm}>
                                    New Field
                                </ButtonPrimary>
                            </ButtonContainer>
                            <div className="list-group cr-form-fields-container">
                                {renderFields()}
                            </div>
                        </div>
                        <div className="col-md-6">
                            {(showNewField || selectedFieldId)
                                ? renderFieldForm()
                                : <h4 className="Admin text-center">Select a field to edit.</h4>}
                            {selectedFieldId && <FieldOptions fieldId={selectedFieldId} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default adminCheckRequestFields;
