import React, { Component } from 'react';
import { HistoryType } from '../../types';
// HoC for pages that cannot be directly navigated to, ie success pages.
// Forces redirect to provided page if tried to navigate via the address bar
// or a hyperlink.

const withDirectAccess = (WrappedComponent, redirectPath) => {
    class RedirectComponent extends Component {
        constructor(props) {
            super(props);

            const { history } = this.props;
            if (history.action === 'POP') {
                history.push(redirectPath);
            }
        }

        render() {
            return <WrappedComponent />;
        }
    }

    RedirectComponent.propTypes = {
        history: HistoryType.isRequired,
    };

    return RedirectComponent;
};

export default withDirectAccess;
