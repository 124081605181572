import BaseNodeService from '../BaseNodeService';
import GeotrakCookies from '../../helpers/GeotrakCookies';
import Log from '../../utilComponents/Log';
import UserHistory from './models/UserHistory';
import ICreateUserHistoryRequest from './interfaces/ICreateUserHistoryRequest';
import PasswordChangeNotification from '../../helpers/GeotrakNotificationManager/Models/PasswordChangeNotification';
import GeotrakNotificationManager from '../../helpers/GeotrakNotificationManager/GeotrakNotificationManager';

const cookies = new GeotrakCookies();

export default class UserService {
    // Initializing important variables
    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        const url = '/user-service';
        const port = 3003;
        this.service = new BaseNodeService(url, port);

        if (!this.isAuthenticated()) {
            this.logout();
        }
    }

    getUserClaim(claimName) {
        return this.service.getClaim(claimName);
    }

    async isLoggedIn() {
        return this.service.loggedIn();
    }

    async getUser(userid) {
        if (!userid) {
            return Promise.reject(new Error('Invalid User'));
        }

        if (this.currentUser) {
            if (this.currentUser.userId !== userid.toUpperCase()) {
                localStorage.removeItem('user');
            } else {
                return Promise.resolve(this.currentUser);
            }
        }

        return this.service.fetch(`/username/${userid}`, {
            method: 'GET',
        }).then((user) => {
            this.currentUser = user;
            localStorage.setItem('user', JSON.stringify(user));
            this.getUserNotifications();
            return this.currentUser;
        });
    }

    async getUserNotifications() {
        const notificationManager = new GeotrakNotificationManager();
        const databasePasswordNotification = PasswordChangeNotification.load(this.currentUser);
        notificationManager.createNotification(databasePasswordNotification);
    }

    getCurrentUser() {
        const locallyStoredUser = localStorage.getItem('user');
        const storedUserObj = JSON.parse(locallyStoredUser);
        if (this.currentUser) {
            if (locallyStoredUser === JSON.stringify(this.currentUser)) {
                return this.currentUser;
            }
            return storedUserObj;
        }
        return storedUserObj;
    }

    unassignCurrentUser() {
        if (this.currentUser) {
            this.currentUser = null;
        }

        localStorage.removeItem('user');
    }

    getCurrentUserId() {
        const user = this.getCurrentUser();

        if (!user) {
            return null;
        }

        return user.userid;
    }

    logout() {
        this.unassignCurrentUser();
        this.service.logout();
    }

    isAuthenticated() {
        this.currentUser = this.getCurrentUser();
        let isAuth = false;
        if (cookies.get('ms_token') && this.currentUser != null
        ) {
            isAuth = true;
        }
        return isAuth;
    }

    hasAppReferenceRole(moduleRoleId) {
        const user = this.getCurrentUser();
        if (!user || !moduleRoleId) {
            return false;
        }
        return user.roles.filter((e) => e.id === moduleRoleId).length > 0;
    }

    hasAdminPermission(arr) {
        return arr.includes(this.currentUser.Admin);
    }

    hasDatabasePermissions() {
        const user = this.getCurrentUser();

        if (!user) {
            return false;
        }

        if (!Object.prototype.hasOwnProperty.call(user, 'SqlLoginId')) {
            return false;
        }

        return !!user.SqlLoginId;
    }

    getCheckRequestProjects() {
        const projects = [];
        const checkRequestRoles = this.currentUser.roles.filter((role) => role.module.unit.Name === 'CheckRequest');
        checkRequestRoles.forEach((role) => {
            const isProjectAdded = projects.find((project) => project.project === role.module.project.name);
            if (!isProjectAdded) {
                projects.push({ project: role.module.project.name });
            }
        });
        return projects.map((p) => {
            const project = { ...p };
            const projectRoles = checkRequestRoles.filter((role) => role.module.project.name === project.project);
            const markets = [];
            projectRoles.forEach((role) => {
                if (role.module.market) {
                    markets.push(...role.module.market.name.split(','));
                }
            });
            project.markets = markets;
            return project;
        });
    }

    hasCheckRequestAdminPermissions() {
        const roleSuffix = -2;
        const checkRequestRoles = this.currentUser.roles.filter((role) => role.module.unit.Name === 'CheckRequest');
        if (checkRequestRoles.length > 0) {
            const adminRole = checkRequestRoles.find((role) => role.name.slice(roleSuffix) === '-A');
            if (adminRole) {
                return true;
            }
        }
        return false;
    }

    getMicroServiceToken(user, password) {
        return this.service.login({
            password,
            userid: user,
            grant_type: 'password',
        });
    }

    login(user, password) {
        this.logout();
        return this.getMicroServiceToken(user, password)
            .then(() => this.getUser(user));
    }

    async insertHistory(url) {
        if (!this.isAuthenticated()) {
            const msg = 'no user is authenticated to add history';
            Log.info(msg);
            return Promise.reject(msg);
        }
        const user = this.getCurrentUser();
        const userid = user.Id;
        const history = new UserHistory();
        history.userId = userid;
        history.url = url;
        const request = ICreateUserHistoryRequest.load(history);
        return this.service.post('/history/', request.data.loadSqlEntity());
    }

    async getHistoryById(id) {
        return this.service.fetch(`/history/${id}`, {
            method: 'GET',
        }).then((response) => response.map((history) => UserHistory.load(history)));
    }

    async updateDatabasePassword(request) {
        return this.service.put('/database/password', request.data);
    }

    async getUsers() {
        return this.service.fetch('/', {
            method: 'GET',
        }).then((response) => response);
    }

    async deleteUser(id) {
        return this.service.delete(`/${id}`);
    }

    async createUser(user) {
        return this.service.post('/', user);
    }

    async modifyUser(id, user) {
        return this.service.put(`/${id}`, user);
    }
}
