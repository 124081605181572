import { useEffect, useReducer } from 'react';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';
import DatabaseService from '../DatabaseService';

const useGetDatabases = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: [] });
    const { data, isLoading, error } = state;

    const getDatabases = async () => {
        try {
            const results = await new DatabaseService().getDatabases();
            dispatch({ type: ACTIONS.SUCCESS, data: results });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getDatabases();
    }, []);

    return { databases: data, error, isLoading };
};

export default useGetDatabases;
