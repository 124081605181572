import React, { useState, useEffect } from 'react';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestAdminService, CheckRequestConfigService } from '../../../_services/CheckRequestService';
import { ButtonContainer } from '../../../utilComponents/styledComponents';
import { Admin } from '../../../_services/CheckRequestService/Models';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import SuccessMessage from '../../../utilComponents/SuccessMessage';

const checkRequestAdminService = new CheckRequestAdminService();
const checkRequestConfigService = new CheckRequestConfigService();

const adminCheckRequestRequester = () => {
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [adminId, setAdminId] = useState(null);
    const [isWorking, setWorking] = useState(false);

    const updateAdminRequester = () => {
        setWorking(true);
        const newAdmin = new Admin(email, location, adminId);
        checkRequestAdminService.updateRequestAdmin(newAdmin).then(() => {
            setWorking(false);
            setSuccess('Admin updated');
        }).catch((err) => {
            setWorking(false);
            setError(`Error Updating Admin. ${err.message || err}`);
        });
    };

    useEffect(() => {
        checkRequestConfigService.getRequestAdmin().then((requestAdmin) => {
            setEmail(requestAdmin.Email);
            setLocation(requestAdmin.Location);
            setAdminId(requestAdmin.Id);
        }).catch((err) => {
            setError(`Error Getting Admin. ${err.message || err}`);
        });
    }, []);

    const isValid = () => email !== '' && location !== '';

    return (
        <>
            <Breadcrumbs />
            <div className="col-md-8 offset-md-2">
                <h2 className="Admin text-center">Admin Requester</h2>
                <form>
                    <div className="mb-3">
                        <label htmlFor="requester-email" className="form-label">
                            Email
                        </label>
                        <input
                            type="text"
                            id="requester-email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="requester-location" className="form-label">
                            Location
                        </label>
                        <input
                            type="text"
                            id="requester-location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Location"
                        />
                    </div>
                    <ButtonContainer
                        error={error}
                        onErrorDismiss={() => setError(null)}
                    >
                        <LoaderButton
                            text="Save"
                            type="submit"
                            loadingText="Saving..."
                            isLoading={isWorking}
                            disabled={!isValid()}
                            onClick={updateAdminRequester}
                        />
                    </ButtonContainer>
                    {success && (
                        <SuccessMessage
                            message={success}
                            onDismiss={() => setSuccess(null)}
                        />
                    )}
                </form>
            </div>
        </>
    );
};

export default adminCheckRequestRequester;
