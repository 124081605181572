import PropTypes from 'prop-types';

export default class RequestRole {
    constructor(name, alias, code, permissions = {}, id = null) {
        this.name = name;
        this.alias = alias;
        this.code = code;
        this.id = id;
        this.permissions = {
            canApprove: permissions.canApprove || false,
            canReject: permissions.canReject || false,
            canCreate: permissions.canCreate || false,
            canGenerate: permissions.canGenerate || false,
            canAcknowledge: permissions.canAcknowledge || false,
            canFinalize: permissions.canFinalize || false,
            canResubmit: permissions.canResubmit || false,
            canSave: permissions.canSave || false,
            canCancel: permissions.canCancel || false,
        };
    }

    static load(response) {
        return new RequestRole(
            response.Name,
            response.Label,
            response.Code,
            {
                canApprove: response.CanApprove,
                canReject: response.CanReject,
                canCreate: response.CanCreate,
                canGenerate: response.CanGenerate,
                canAcknowledge: response.CanAcknowledge,
                canFinalize: response.CanFinalize,
                canResubmit: response.CanResubmit,
                canSave: response.CanSave,
                canCancel: response.CanCancel,
            },
            response.Id
        );
    }

    validate() {
        if (!this.name || this.name === '') {
            throw Error('Name is required');
        }

        if (!this.alias || this.alias === '') {
            throw Error('Alias is required');
        }

        if (!this.code || this.code === '') {
            throw Error('Code is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            Name: this.name,
            Label: this.alias,
            Code: this.code,
            CanApprove: this.permissions.canApprove,
            CanReject: this.permissions.canReject,
            CanCreate: this.permissions.canCreate,
            CanGenerate: this.permissions.canGenerate,
            CanAcknowledge: this.permissions.canAcknowledge,
            CanFinalize: this.permissions.canFinalize,
            CanResubmit: this.permissions.canResubmit,
            CanSave: this.permissions.canSave,
            CanCancel: this.permissions.canCancel,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

RequestRole.propTypes = {
    name: PropTypes.string,
    alias: PropTypes.string,
    code: PropTypes.string,
    permissions: PropTypes.instanceOf(Object),
    id: PropTypes.number,
};
