import React, { memo } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { CircleMarker } from 'react-leaflet';
import useGetRenderer from '../_services/GeotrakService/hooks/useGetRenderer';
import { DataSource } from '../_services/GeotrakService/Models';

const markerCluster = ({
    dataSource, data, selectedSiteId, handleMarkerSelection, showContextMenu,
}) => {
    const { renderer } = useGetRenderer(dataSource.id);
    const selectedSizeIncrease = 3;
    const selectedColor = '#f00';

    const renderPoints = () => Object.values(data[dataSource.name]).map((point) => (
        <CircleMarker
            center={[point.Latitude, point.Longitude]}
            key={`${dataSource.name}-${point.SiteId}}`}
            radius={selectedSiteId === point.SiteId ? renderer.size + selectedSizeIncrease : renderer.size}
            color={selectedSiteId === point.SiteId ? selectedColor : renderer.color}
            fill
            fillOpacity={1}
            id={point.SiteId}
            layer={dataSource.name}
            eventHandlers={{
                click: handleMarkerSelection,
                contextmenu: (event) => showContextMenu(
                    [event.containerPoint.x, event.containerPoint.y],
                    [point.Latitude, point.Longitude],
                    dataSource.name,
                    point.SiteId
                ),
            }}
        />
    ));

    if (renderer.color) {
        return (
            <MarkerClusterGroup
                key={dataSource.name}
                iconCreateFunction={(cluster) => {
                    const count = cluster.getChildCount();
                    return new L.DivIcon({
                        html: `<div style="background-color: ${renderer.color}"><span style="color: white">${count}</span></div>`,
                        className: 'marker-cluster',
                    });
                }}
                showCoverageOnHover={false}
                maxClusterRadius={100}
                chunkedLoading
            >
                {renderPoints()}
            </MarkerClusterGroup>
        );
    }

    return null;
};

export default memo(markerCluster);

markerCluster.propTypes = {
    dataSource: PropTypes.instanceOf(DataSource),
    data: PropTypes.instanceOf(Object),
    selectedSiteId: PropTypes.number,
    handleMarkerSelection: PropTypes.func,
    showContextMenu: PropTypes.func,
};

markerCluster.defaultProps = {
    dataSource: new DataSource(),
    data: {},
    selectedSiteId: 0,
    handleMarkerSelection: () => null,
    showContextMenu: () => null,
};
