import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../utilComponents/ErrorMessage';
import GeoTRAKLogo from '../icons/logo.svg';
import LoaderButton from '../utilComponents/LoaderButton';
import { UserService } from '../_services';
import InputPassword from '../utilComponents/InputPassword';
import './stylesheets/Login.css';

const login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isWorking, setIsWorking] = useState(false);
    const [isShaking, setIsShaking] = useState(false);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const history = useHistory();

    useEffect(() => {
        try {
            if (!submitted) {
                // No need to cleanup state if we aren't firing async calls.
                return undefined;
            }

            let isSubscribed = true;

            setIsWorking(true);
            setIsShaking(false);

            const auth = new UserService();
            auth
                .login(username, password)
                .then(() => {
                    history.push('/');
                })
                .catch((err) => {
                    if (!isSubscribed) {
                        return null;
                    }

                    setError(err.message);
                    setIsWorking(false);
                    setIsShaking(true);
                    return null;
                }).finally(() => {
                    if (!isSubscribed) {
                        return null;
                    }

                    setSubmitted(false);
                    return null;
                });

            return () => {
                isSubscribed = false;
            };
        } catch (err) {
            setError(err.message);
            setIsWorking(false);
            setIsShaking(true);
            return null;
        }
    }, [submitted, username, password, history]);

    const onSubmit = useCallback((event) => {
        setSubmitted(true);
        event.preventDefault();
        return [];
    }, []);

    return (
        <div className="container text-center">
            <div className="col-md-8 offset-md-2 col-lg-4 offset-lg-4">
                <img src={GeoTRAKLogo} alt="GeoTRAK Logo" />
                <div className={isShaking ? 'invalid-login' : ''}>
                    <form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">
                                Username
                            </label>
                            <input
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus
                                id="username"
                                type="text"
                                autoComplete="geotrak-username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Password
                            </label>
                            <InputPassword
                                id="password"
                                handleChange={(value) => setPassword(value)}
                                password={password}
                            />
                        </div>
                        <LoaderButton
                            disabled={!(username && password)}
                            type="submit"
                            isLoading={isWorking}
                            text="Login"
                            loadingText="Logging in…"
                            onClick={onSubmit}
                        />
                        {error && (
                            <div className="py-md-3">
                                <ErrorMessage onDismiss={() => setError(null)} message={error} />
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default login;
