import BaseGeoTrakService from './BaseGeoTrakService';
import { Project } from './Models';

export default class ProjectService extends BaseGeoTrakService {
    async getProjects() {
        return this.fetch('/projects').then((projects) => projects.map((project) => Project.load(project)));
    }

    async getProject(id) {
        return this.fetch(`/projects/${id}`).then((project) => Project.load(project));
    }

    async createProject(request) {
        return this.post('/projects', request.data.loadSqlEntity());
    }

    async updateProject(request) {
        const { id, data } = request;
        return this.put(`/projects/${id}`, data);
    }

    async deleteProject(id) {
        return this.delete(`/projects/${id}`);
    }
}
