import {
    SET_ACTIVE_VIEW,
    SET_ACTIVE_SCHEMA,
    SET_QUERY,
    RESET_QUERY
} from '../CONSTANTS/types';

const initialState = {
    query: '',
    view: 'All',
    schema: [],
    accountInfo: {
        account: null,
        error: null,
    },
};

const defaultAction = {
    type: '',
    activeView: '',
    activeQuery: '',
    activeSchema: [],
};

export default (state = initialState, action = defaultAction) => {
    switch (action.type) {
    case SET_ACTIVE_VIEW: {
        return {
            ...state,
            view: action.activeView,
        };
    }
    case SET_QUERY: {
        return {
            ...state,
            query: action.activeQuery,
        };
    }
    case RESET_QUERY: {
        return {
            ...state,
            query: '',
        };
    }
    case SET_ACTIVE_SCHEMA: {
        return {
            ...state,
            schema: action.activeSchema,
        };
    }
    default:
        return state;
    }
};
