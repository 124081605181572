import { useEffect, useState } from 'react';
import EtlJob from '../../../_services/EtlService/Models/EtlJob';
import useGetEtlJob from '../../../_services/EtlService/hooks/useGetEtlJob';

const useRoleETLForm = (id) => {
    let etlJob = new EtlJob();
    let isLoading = false;

    if (id) {
        const result = useGetEtlJob(id);
        etlJob = result.etlJob;
        isLoading = result.isLoading;
    }

    const [values, setValues] = useState({
        id: null,
        jobTypeId: '',
    });

    const isValid = () => values.jobTypeId !== '';

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                id: etlJob.id || null,
                jobTypeId: etlJob.jobType.id || '',
            });
        }
    }, [etlJob]);

    return {
        values,
        isLoading,
        onFormUpdate,
        isValid,
    };
};

export default useRoleETLForm;
