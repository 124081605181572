import BaseNodeService from '../BaseNodeService';
import GeocoderJob from './Models/GeocoderJob';

export default class GeocoderJobService extends BaseNodeService {
    constructor() {
        const url = '/etl-service';
        const port = 3016;
        super(url, port);
    }

    async getGeocoderJobs() {
        return this.fetch('/geocoder-jobs').then((jobs) => jobs.map((job) => GeocoderJob.load(job))).catch((err) => err);
    }

    async getGeocoderJob(id) {
        return this.fetch(`/geocoder-jobs/${id}`).then((job) => GeocoderJob.load(job)).catch((err) => err);
    }

    async createGeocoderJob(request) {
        const { data } = request;
        return this.post('/geocoder-jobs', data.loadSqlEntity());
    }

    async updateGeocoderJob(request) {
        const { data, id } = request;
        return this.put(`/geocoder-jobs/${id}`, data.loadSqlEntity());
    }

    async deleteGeocoderJob(id) {
        return this.delete(`/geocoder-jobs/${id}`);
    }
}
