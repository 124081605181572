import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { UserService } from '../../../../_services';
import '../users.css';

const updateButton = ({ user, isValid }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userService = new UserService();
    const history = useHistory();

    const updateUser = async () => {
        try {
            setIsLoading(true);
            await userService.modifyUser(user.Id, user);
            if (userService.getCurrentUser().userid === user.userid) {
                await userService.getUser(user.userid);
            }
            setIsLoading(false);
            setError(false);
            history.push('/admin/users');
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    if (!user.Id || user.IsDeleted) {
        return null;
    }

    return (
        <div className="user-toolbar user-button">
            {error && <div className="text-danger user-error" id="update-user-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-user-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={updateUser}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    user: PropTypes.instanceOf(Object),
    isValid: PropTypes.func,
};

updateButton.defaultProps = {
    user: {},
    isValid: () => null,
};
