import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import useCreateGeocoderJob from '../../../../_services/EtlService/hooks/useCreateGeocoderJob';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import GeocoderJob from '../../../../_services/EtlService/Models/GeocoderJob';
import '../etljobs.css';

const CreateButton = ({ job, isValid }) => {
    const {
        createJob, isLoading, isSuccessful, error,
    } = useCreateGeocoderJob();

    if (job.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/etls/geocoder-jobs" />;
    }

    return (
        <div className="etl-job-toolbar geocoder-job-button">
            {error && <div className="text-danger etl-geocoder-job-error" id="create-etl-geocoder-job-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-etl-geocoder-job-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createJob(job)}
            />
        </div>
    );
};

export default CreateButton;

CreateButton.propTypes = {
    job: PropTypes.instanceOf(GeocoderJob),
    isValid: PropTypes.func,
};

CreateButton.defaultProps = {
    job: new GeocoderJob(),
    isValid: () => null,
};
