import PropTypes from 'prop-types';
import CoordinatePair from '../../../helpers/spatialUtils/CoordinatePair';

export default class MapConfig {
    constructor() {
        this.id = null;
        this.moduleRoleId = null;
        this.center = new CoordinatePair(null, null);
        this.zoom = null;
        this.isSpatialAdmin = false;
    }

    static load(response) {
        const map = new MapConfig();

        if (response) {
            map.id = response.id;
            map.moduleRoleId = response.moduleRoleId;
            map.center = new CoordinatePair(response.centerLatitude, response.centerLongitude);
            map.zoom = response.zoom;
            map.isSpatialAdmin = response.isSpatialAdmin;

            map.center.validate();
        }

        return map;
    }

    loadSqlEntity() {
        this.center.validate();

        const sqlEntity = {
            moduleRoleId: this.moduleRoleId,
            centerLatitude: this.center.latitude,
            centerLongitude: this.center.longitude,
            zoom: this.zoom,
            isSpatialAdmin: this.isSpatialAdmin,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

MapConfig.propTypes = {
    id: PropTypes.number,
    center: PropTypes.instanceOf(CoordinatePair),
    moduleRoleId: PropTypes.number,
    zoom: PropTypes.number,
    isSpatialAdmin: PropTypes.bool,
};
