import { GET_ACTIVE_MODULE_RECEIVED, RESET_ACTIVE_MODULE } from '../CONSTANTS/types';

export default (state = {}, action = { type: '', config: {} }) => {
    switch (action.type) {
    case GET_ACTIVE_MODULE_RECEIVED:
        return {
            isLoaded: true,
        };
    case RESET_ACTIVE_MODULE:
        return {
            isLoaded: false,
        };
    default:
        return state;
    }
};
