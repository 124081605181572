import BaseGeoTrakService from './BaseGeoTrakService';

export class ProjectRecordService extends BaseGeoTrakService {
    async getProjectRecords(paginationPage, payload) {
        return this.post(`/project-records?page=${paginationPage}`, payload);
    }

    async getSpatialRecords(request) {
        return this.post('/project-records/spatial', request.data);
    }

    async createProjectRecords(body) {
        return this.put('/project-records', body);
    }

    async getProjectRecord(recordId, body) {
        if (!recordId) {
            // hiding these requests with malformed parameters... for now
            // this is symptomatic of something seriously wrong upstream that needs
            // to be addressed later. right now, it appeaers the table refresh is
            // broke, and removes the selected recordId.
            return Promise.resolve(null);
        }

        return this.post(`/project-records/${recordId}`, body)
            .then((result) => {
                if (Array.isArray(result)) {
                    return Promise.reject(new Error('expected a single record. got an array'));
                }
                return result;
            });
    }
}

export const projectRecordService = new ProjectRecordService();
