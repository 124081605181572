import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetDataSourceDocumentTypes from '../../../_services/GeotrakService/hooks/useGetDataSourceDocumentTypes';
import './documentTypes.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDataSourceDocumentTypes = ({ match }) => {
    const history = useHistory();
    const { params: { dataSourceId, moduleId, roleId } } = match;
    const { isLoading, dataSourceDocumentTypes } = useGetDataSourceDocumentTypes(dataSourceId);

    if (isLoading) {
        return <LoadingFullScreen id="document-type-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Data Source Document Types">
                <ButtonContainer>
                    <ButtonPrimary
                        id="new-document-type-button"
                        onClick={() => history.push(
                            `/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSourceId}/document-types/new`
                        )}
                    >
                        New Document Type
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group document-type-container">
                    {dataSourceDocumentTypes.map((documentType) => (
                        <button
                            type="button"
                            id={documentType.documentType.name}
                            key={documentType.id}
                            className="list-group-item list-group-item-action text-start"
                            onClick={() => history.push(
                                `/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSourceId}/document-types/${documentType.id}`
                            )}
                        >
                            <span id="document-type-name">{documentType.documentType.name}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDataSourceDocumentTypes;
