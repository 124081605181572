import React from 'react';
import propTypes from 'prop-types';
import './stylesheets/LoaderSpinner.css';

const loaderSpinner = ({ message }) => (
    <div className="text-center">
        <div className="spinner-border loading-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <div className="loading-spinner-message">{message}</div>
    </div>
);

export default loaderSpinner;

loaderSpinner.propTypes = {
    message: propTypes.string,
};

loaderSpinner.defaultProps = {
    message: '',
};
