import { useEffect, useState } from 'react';
import useGetMarket from '../../../_services/GeotrakService/hooks/useGetMarket';
import { Market } from '../../../_services/GeotrakService/Models';

const useMarketForm = (id) => {
    let market = new Market();
    let isLoading = false;
    if (id) {
        const result = useGetMarket(id);
        market = result.market;
        isLoading = result.isLoading;
    }
    const [values, setValues] = useState({
        id: null,
        name: '',
    });

    const isValid = () => values.name !== '' && values.name;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (id) {
            setValues({
                id: market.id || null,
                name: market.name || '',
            });
        }
    }, [market]);

    return {
        values, isValid, onFormUpdate, isLoading,
    };
};

export default useMarketForm;
