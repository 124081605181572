import React from 'react';
import PropTypes from 'prop-types';

const messageBanner = function statelessFunctionComponentClass(props) {
    if (!props.visible) {
        return null;
    }

    return <div className={`text-${props.level}`}>{props.message}</div>;
};

export default messageBanner;

messageBanner.propTypes = {
    message: PropTypes.string,
    level: PropTypes.string,
    visible: PropTypes.bool,
};
