import PropTypes from 'prop-types';
import UserHistory from '../models/UserHistory';

class ICreateUserHistoryRequest {
    constructor() {
        this.data = {};
    }

    static load(userHistory) {
        if (!userHistory) {
            throw Error('user history is required');
        }
        if (!(userHistory instanceof UserHistory)) {
            throw Error('Unexpected type for user history');
        }

        const request = new ICreateUserHistoryRequest();
        request.data = userHistory;
        return request;
    }
}

export default ICreateUserHistoryRequest;

ICreateUserHistoryRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
