import PropTypes from 'prop-types';
import React from 'react';
import { Editors, Menu } from 'react-data-grid-addons';
import ColumnLock from '../../../utilComponents/ColumnLock';
import DateEditor from '../../../utilComponents/DateEditor';
import DateFormatter from '../../../utilComponents/DateFormatter';
import {
    isValidDate,
    isValidString,
    isValidInteger,
    isValidFloat
} from '../../../helpers/utilities';
import HyperlinkFormatter from '../../../utilComponents/HyperlinkFormatter';

const { DropDownEditor } = Editors;
const { ContextMenuTrigger } = Menu;

const DEFAULT_COLUMN_WIDTH = 100;
const COLUMN_WIDTH_MULTIPLIER = 10;

export default class Column {
    constructor(key, name, columnType = null, columnLength = null, isEditable = false, dropdownValues = null) {
        this.stringFieldTypes = ['nvarchar', 'varchar'];
        this.dateFieldTypes = ['date', 'datetime', 'datetime2'];

        this.key = key;
        this.name = name;
        this.getColumnType(columnType);
        this.columnLength = columnLength;
        this.editable = isEditable;

        this.formatter = this.getFormatter();
        this.headerRenderer = this.getLock();
        this.hash = Date.now();
        this.editor = this.getEditor(dropdownValues);
        this.resizable = true;
        this.filterable = true;
        this.sortable = true;
        this.frozen = false;
        this.cellClass = null;
        this.dropdownValues = dropdownValues;
        this.width = this.getWidth();
    }

    getColumnType(columnType) {
        if (this.stringFieldTypes.includes(columnType)) {
            this.columnType = 'varchar';
        } else {
            this.columnType = columnType;
        }
    }

    getFormatter() {
        if (this.dateFieldTypes.includes(this.columnType)) {
            return DateFormatter;
        }
        return null;
    }

    getEditor(dropdownValues) {
        if (this.dateFieldTypes.includes(this.columnType) && this.editable) {
            return DateEditor;
        }
        if (dropdownValues && this.editable) {
            const options = [{ id: 'None', value: '' }, ...dropdownValues.map((value) => ({ id: value, value }))];
            return <DropDownEditor options={options} />;
        }
        return null;
    }

    getLock() {
        return (
            <ContextMenuTrigger>
                <ColumnLock text={this.name} isEditable={this.editable} />
            </ContextMenuTrigger>
        );
    }

    getWidth() {
        return Math.max(this.name.length * COLUMN_WIDTH_MULTIPLIER, DEFAULT_COLUMN_WIDTH);
    }

    useDefaultWidth() {
        this.width = null;
    }

    grayOutColumn() {
        this.cellClass = 'id-column';
    }

    freezeColumn() {
        this.frozen = true;
    }

    unfreezeColumn() {
        this.frozen = false;
    }

    validateInput(value) {
        if (this.dropdownValues) {
            this.validateDropdown(value);
        }
        if (this.columnType === 'varchar') {
            this.validateString(value);
        }

        if (this.columnType === 'date' && !isValidDate(value)) {
            throw new Error('Please input a date.');
        }

        if (this.columnType === 'int' && !isValidInteger(value)) {
            throw new Error('Please input a whole number.');
        }

        if (['numeric', 'decimal'].includes(this.columnType) && !isValidFloat(value)) {
            throw new Error('Please input a number.');
        }
    }

    validateDropdown(value) {
        if (!this.dropdownValues.includes(value) && value !== null && value !== '') {
            throw new Error('Please input a value from the dropdown list.');
        }
    }

    validateString(value) {
        if (!isValidString(this.columnLength, value)) {
            throw new Error(`Please keep input to ${this.columnLength.toString()} characters.`);
        }
    }

    makeHyperlink(onRecordSelection) {
        this.formatter = <HyperlinkFormatter onRecordSelection={onRecordSelection} />;
    }
}

Column.propTypes = {
    key: PropTypes.string,
    name: PropTypes.string,
    columnType: PropTypes.string,
    columnLength: PropTypes.number,
    editable: PropTypes.bool,
    filterable: PropTypes.bool,
    frozen: PropTypes.bool,
    sortable: PropTypes.bool,
    resizable: PropTypes.bool,
    width: PropTypes.number,
    hash: PropTypes.number,
    formatter: PropTypes.func,
    headerRenderer: PropTypes.func,
    editor: PropTypes.func,
    cellClass: PropTypes.string,
};
