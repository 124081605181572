import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataSourceWidget, WidgetType } from '../../../../_services/GeotrakService/Models';
import useDeleteDataSourceWidget from '../../../../_services/GeotrakService/hooks/useDeleteDataSourceWidget';
import useCreateDataSourceWidget from '../../../../_services/GeotrakService/hooks/useCreateDataSourceWidget';
import '../dataSources.css';

const toggleWidget = ({ isIncluded, widget, dataSourceId }) => {
    const [isChecked, setIsChecked] = useState(isIncluded);
    const { deleteDataSourceWidget, error: deleteError } = useDeleteDataSourceWidget();
    const { createDataSourceWidget, error: createError } = useCreateDataSourceWidget();

    const deleteWidget = () => {
        setIsChecked(false);
        deleteDataSourceWidget(dataSourceId, widget.id);
    };

    const addWidget = () => {
        const newWidget = new DataSourceWidget();
        newWidget.dataSourceId = dataSourceId;
        newWidget.widgetType = widget;
        createDataSourceWidget(newWidget);
        setIsChecked(true);
    };

    const toggleWidgetState = () => {
        if (isChecked) {
            deleteWidget();
        } else {
            addWidget();
        }
    };

    useEffect(() => {
        setIsChecked(isIncluded);
    }, [isIncluded]);

    return (
        <div className="form-check mb-3" key={widget.id}>
            <label
                htmlFor={`toggle-widget-${widget.name}`}
                className="form-check-label"
            >
                {widget.name}
                {' '}
                <span className="widget-description"><em>{`(${widget.description})`}</em></span>
                <input
                    type="checkbox"
                    id={`toggle-widget-${widget.name}`}
                    checked={isChecked}
                    className="form-check-input"
                    onChange={toggleWidgetState}
                />
                {deleteError && <div className="text-danger" id="delete-widget-error">{`ERROR: ${deleteError.message || 'An error occurred.'}`}</div>}
                {createError && <div className="text-danger" id="create-widget-error">{`ERROR: ${createError.message || 'An error occurred.'}`}</div>}
            </label>
        </div>
    );
};

export default toggleWidget;

toggleWidget.propTypes = {
    widget: PropTypes.instanceOf(WidgetType),
    dataSourceId: PropTypes.string,
    isIncluded: PropTypes.bool,
};

toggleWidget.defaultProps = {
    widget: new WidgetType(),
    dataSourceId: null,
    isIncluded: false,
};
