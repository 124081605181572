import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import { GeocoderJobService } from '../index';

const useDeleteGeocoderJob = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteGeocoderJob = async (jobId) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new GeocoderJobService().deleteGeocoderJob(jobId);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteGeocoderJob, error, isLoading, isSuccessful,
    };
};

export default useDeleteGeocoderJob;
