import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { ToggleWidget } from './Actions';
import useDataSourceWidgetForm from './useDataSourceWidgetForm';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDataSourceWidgetsForm = ({ match }) => {
    const { params: { dataSourceId } } = match;
    const { widgets, values, isLoading } = useDataSourceWidgetForm(dataSourceId);

    if (isLoading) {
        return <LoadingFullScreen id="data-source-widget-form-loader" />;
    }
    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Data Source Widgets">
                {widgets.map((widget) => {
                    const selectedWidgets = values.widgets.filter(
                        (widgetValue) => widgetValue.widgetType.name === widget.name
                    );
                    return (
                        <ToggleWidget
                            id="check-widget"
                            key={widget.id}
                            widget={widget}
                            dataSourceId={dataSourceId}
                            isIncluded={selectedWidgets.length > 0}
                        />
                    );
                })}
            </AdminContainer>
        </>
    );
};

export default adminDataSourceWidgetsForm;
