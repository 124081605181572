import BaseCheckRequestService from './BaseCheckRequestService';
import {
    Project, RequestType, RequestForm, Field, Option, Office, RequestRole,
    RequestFormRole, RequestFormRoleField, RequestFormNotification
} from './Models';

export default class CheckRequestAdminService extends BaseCheckRequestService {
    async getProjects() {
        return this.fetch('/projects').then(
            (projects) => projects.map((project) => Project.load(project))
        );
    }

    async getProject(projectId) {
        return this.fetch(`/project/${projectId}`).then(
            (project) => Project.load(project)
        );
    }

    async createProject(project) {
        return this.post('/project', project.loadSqlEntity());
    }

    async updateProject(project) {
        return this.put(`/project/${project.id}`, project.loadSqlEntity());
    }

    async deleteProject(project) {
        return this.delete(`/project/${project.id}`);
    }

    async getRequestTypes() {
        return this.fetch('/request-types').then(
            (requestTypes) => requestTypes.map((requestType) => RequestType.load(requestType))
        );
    }

    async getRequestType(requestTypeId) {
        return this.fetch(`/request-type/${requestTypeId}`).then(
            (requestType) => RequestType.load(requestType)
        );
    }

    async createRequestType(requestType) {
        return this.post('/request-type', requestType.loadSqlEntity());
    }

    async updateRequestType(requestType) {
        return this.put(`/request-type/${requestType.id}`, requestType.loadSqlEntity());
    }

    async deleteRequestType(requestType) {
        return this.delete(`/request-type/${requestType.id}`);
    }

    async getRequestForms() {
        return this.fetch('/request-forms').then(
            (requestForms) => requestForms.map((requestForm) => RequestForm.load(requestForm))
        );
    }

    async getRequestForm(requestFormId) {
        return this.fetch(`/request-form/${requestFormId}`).then(
            (requestForm) => RequestForm.load(requestForm)
        );
    }

    async createRequestForm(requestForm) {
        return this.post('/request-form', requestForm.loadSqlEntity()).then((result) => RequestForm.load(result));
    }

    async updateRequestForm(requestForm) {
        return this.put(`/request-form/${requestForm.id}`, requestForm.loadSqlEntity());
    }

    async deleteRequestForm(requestForm) {
        return this.delete(`/request-form/${requestForm.id}`);
    }

    async getRequestRoles() {
        return this.fetch('/request-roles').then(
            (requestRoles) => requestRoles.map((requestRole) => RequestRole.load(requestRole))
        );
    }

    async getRequestRole(requestRoleId) {
        return this.fetch(`/request-role/${requestRoleId}`).then((requestRole) => RequestRole.load(requestRole));
    }

    async createRequestRole(requestRole) {
        return this.post('/request-role', requestRole.loadSqlEntity());
    }

    async updateRequestRole(requestRole) {
        return this.put(`/request-role/${requestRole.id}`, requestRole.loadSqlEntity());
    }

    async deleteRequestRole(requestRoleId) {
        return this.delete(`/request-role/${requestRoleId}`);
    }

    async getRequestFormNotifications(requestFormId) {
        return this.fetch(`/request-form/${requestFormId}/request-form-notifications`).then(
            (requestFormNotifications) => requestFormNotifications.map(
                (requestFormNotification) => RequestFormNotification.load(requestFormNotification)
            )
        );
    }

    async getRequestFormNotification(requestFormNotificationId) {
        return this.fetch(`/request-form-notification/${requestFormNotificationId}`).then(
            (requestFormNotification) => RequestFormNotification.load(requestFormNotification)
        );
    }

    async createRequestFormNotification(requestFormNotification) {
        return this.post('/request-form-notification', requestFormNotification.loadSqlEntity());
    }

    async updateRequestFormNotification(requestFormNotification) {
        return this.put(`/request-form-notification/${requestFormNotification.id}`, requestFormNotification.loadSqlEntity());
    }

    async deleteRequestFormNotification(requestFormNotificationId) {
        return this.delete(`/request-form-notification/${requestFormNotificationId}`);
    }

    async getFields() {
        return this.fetch('/fields').then(
            (fields) => fields.map((field) => Field.load(field))
        );
    }

    async getField(fieldId) {
        return this.fetch(`/field/${fieldId}`).then((field) => Field.load(field));
    }

    async createField(field) {
        return this.post('/field', field.loadSqlEntity());
    }

    async updateField(field) {
        return this.put(`/field/${field.id}`, field.loadSqlEntity());
    }

    async deleteField(fieldId) {
        return this.delete(`/field/${fieldId}`);
    }

    async getOptions() {
        return this.fetch('/options').then(
            (options) => options.map((option) => Option.load(option))
        );
    }

    async getOption(optionId) {
        return this.fetch(`/option/${optionId}`).then((option) => Option.load(option));
    }

    async createOption(option) {
        return this.post('/option', option.loadSqlEntity());
    }

    async updateOption(option) {
        return this.put(`/option/${option.id}`, option.loadSqlEntity());
    }

    async deleteOption(optionId) {
        return this.delete(`/option/${optionId}`);
    }

    async getOffices() {
        return this.fetch('/offices').then(
            (offices) => offices.map((office) => Office.load(office))
        );
    }

    async getOffice(officeId) {
        return this.fetch(`/office/${officeId}`).then((office) => Office.load(office));
    }

    async createOffice(request) {
        return this.post('/office', request.data.loadSqlEntity());
    }

    async updateOffice(request) {
        const { id, data } = request;
        return this.put(`/office/${id}`, data.loadSqlEntity());
    }

    async deleteOffice(officeId) {
        return this.delete(`/office/${officeId}`);
    }

    async getRequestFormRoles(requestFormId) {
        return this.fetch(`/request-form/${requestFormId}/request-form-roles`).then(
            (requestFormRoles) => requestFormRoles.map((requestFormRole) => RequestFormRole.load(requestFormRole))
        );
    }

    async getRequestFormRole(requestFormRoleId) {
        return this.fetch(`/request-form-role/${requestFormRoleId}`).then(
            (requestFormRole) => RequestFormRole.load(requestFormRole)
        );
    }

    async createRequestFormRole(requestFormRole) {
        return this.post('/request-form-role', requestFormRole.loadSqlEntity());
    }

    async updateRequestFormRole(requestFormRole) {
        return this.put(`/request-form-role/${requestFormRole.id}`, requestFormRole.loadSqlEntity());
    }

    async deleteRequestFormRole(requestFormRoleId) {
        return this.delete(`/request-form-role/${requestFormRoleId}`);
    }

    async getRequestFormRoleFields(requestFormRoleId) {
        return this.fetch(`/request-form-role/${requestFormRoleId}/request-form-role-fields`).then(
            (requestFormRoleFields) => requestFormRoleFields.map(
                (requestFormRoleField) => RequestFormRoleField.load(requestFormRoleField)
            )
        );
    }

    async getRequestFormRoleField(requestFormRoleFieldId) {
        return this.fetch(`/request-form-role-field/${requestFormRoleFieldId}`).then(
            (requestFormRoleField) => RequestFormRoleField.load(requestFormRoleField)
        );
    }

    async createRequestFormRoleField(requestFormRoleField) {
        return this.post('/request-form-role-field', requestFormRoleField.loadSqlEntity());
    }

    async updateRequestFormRoleField(requestFormRoleField) {
        return this.put(`/request-form-role-field/${requestFormRoleField.id}`, requestFormRoleField.loadSqlEntity());
    }

    async deleteRequestFormRoleField(requestFormRoleFieldId) {
        return this.delete(`/request-form-role-field/${requestFormRoleFieldId}`);
    }

    async updateRequestAdmin(admin) {
        return this.put(`/admin/${admin.id}`, admin.loadSqlEntity());
    }
}
