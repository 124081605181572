import BaseGeoTrakService from './BaseGeoTrakService';
import { DocumentType, DataSourceDocumentType } from './Models';

export default class DocumentTypeService extends BaseGeoTrakService {
    async getDocumentTypes() {
        return this.fetch('/document-types').then((documentTypes) => documentTypes.map((documentType) => DocumentType.load(documentType)));
    }

    async getDocumentType(id) {
        return this.fetch(`/document-types/${id}`).then((documentType) => DocumentType.load(documentType));
    }

    async createDocumentType(request) {
        return this.post('/document-types', request.data.loadSqlEntity());
    }

    async updateDocumentType(request) {
        const { id, data } = request;
        return this.put(`/document-types/${id}`, data);
    }

    async deleteDocumentType(id) {
        return this.delete(`/document-types/${id}`);
    }

    async getDataSourceDocumentTypes(dataSourceId) {
        return this.fetch(`/document-types/data-source/dataSourceId/${dataSourceId}`).then((documentTypes) => documentTypes.map((documentType) => DataSourceDocumentType.load(documentType)));
    }

    async deleteDataSourceDocumentType(dataSourceDocumentTypeId) {
        return this.delete(`/document-types/data-source/${dataSourceDocumentTypeId}`);
    }

    async createDataSourceDocumentType(request) {
        return this.post(`/document-types/data-source/dataSourceId/${request.dataSourceId}`, request.data.loadSqlEntity());
    }
}
