import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatabaseService } from '../_services/GeotrakService';
import IGetFieldsRequest from '../_services/GeotrakService/interfaces/IGetFieldsRequest';

const selectField = ({
    label, database, table, selectedField, onFieldSelected, selectedSchema, disabled,
}) => {
    const [error, setError] = useState(null);
    const [value, setValue] = useState(selectedField || '');
    const [fields, setFields] = useState([]);
    const databaseService = new DatabaseService();

    const getFields = async () => {
        try {
            const request = IGetFieldsRequest.load(database, table, selectedSchema);
            const schema = await databaseService.getFields(request);
            const fieldList = Object.keys(schema);
            fieldList.sort();
            setFields(fieldList);
        } catch (err) {
            setError(err);
        }
    };

    const onFieldChanged = (e) => {
        setValue(e);
        onFieldSelected(e);
    };

    useEffect(() => {
        if (database && table) {
            getFields();
            setValue(selectedField || '');
        }
    }, [database, table, selectedField, selectedSchema]);

    if (error) {
        return (
            <div id="select-field-error" className="text-danger">{`ERROR: ${error.message || 'An error occurred.'}`}</div>
        );
    }

    return (
        <div className="mb-3">
            <label htmlFor="select-field" className="form-label">
                {label || 'Field'}
                {' '}
                <span className="text-danger">*</span>
            </label>
            <select
                id="select-field"
                value={value}
                disabled={disabled}
                onChange={(e) => onFieldChanged(e.target.value)}
            >
                <option disabled value="">--Select a field--</option>
                {fields.map((field) => (
                    <option id="select-field-option" value={field} key={field}>{field}</option>
                ))}
            </select>
        </div>
    );
};

export default selectField;

selectField.propTypes = {
    database: PropTypes.string,
    table: PropTypes.string,
    label: PropTypes.string,
    selectedField: PropTypes.string,
    onFieldSelected: PropTypes.func,
    selectedSchema: PropTypes.string,
    disabled: PropTypes.bool,
};

selectField.defaultProps = {
    database: null,
    table: null,
    label: null,
    selectedField: null,
    onFieldSelected: () => null,
    selectedSchema: 'GeoMRE',
    disabled: false,
};
