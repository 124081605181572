import { useState } from 'react';

const useDatabasePasswordForm = () => {
    const [values, setValues] = useState({
        newPassword: '',
        confirmNewPassword: '',
    });

    const MINIMUM_PW_LENGTH = 8;

    const passwordsMatch = () => values.newPassword === values.confirmNewPassword;

    const hasRequiredCharacters = () => {
        const rgx = /^(?=.{1,})(?=.+\d)(?=.+[a-zA-Z])(?=.+[!@#$%^&*]).+$/;
        return rgx.test(values.newPassword);
    };

    const hasRequiredLength = () => values.newPassword.length >= MINIMUM_PW_LENGTH;

    const isValid = () => values.newPassword !== ''
                        && values.confirmNewPassword !== ''
                        && passwordsMatch()
                        && hasRequiredCharacters()
                        && hasRequiredLength();

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    return {
        values, isValid, passwordsMatch, onFormUpdate, hasRequiredCharacters, hasRequiredLength,
    };
};

export default useDatabasePasswordForm;
