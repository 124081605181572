import { useEffect, useReducer } from 'react';
import { MapConfig } from '../Models';
import MapService from '../MapService';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';

const useGetModuleMap = (moduleRoleId) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new MapConfig() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const result = await new MapService().getModuleMap(moduleRoleId);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, []);

    return { map: data, error, isLoading };
};

export default useGetModuleMap;
