import { SET_TABLE_DATA_COLUMNS_FILTER, RESET_TABLE_DATA_COLUMNS_FILTER } from '../CONSTANTS/types';

export default (state = [], action = { type: '', payload: {} }) => {
    switch (action.type) {
    case SET_TABLE_DATA_COLUMNS_FILTER:
        return action.payload;
    case RESET_TABLE_DATA_COLUMNS_FILTER:
        return [];
    default:
        return state;
    }
};
