export const ACTIONS = {
    CALL: 'call',
    SUCCESS: 'success',
    ERROR: 'error',
    CLEAR_ERROR: 'clear error',
};

export const useApiReducer = (state, action) => {
    switch (action.type) {
    case ACTIONS.CALL: {
        return {
            ...state,
            error: null,
            isLoading: true,
            isSuccessful: false,
        };
    }
    case ACTIONS.SUCCESS: {
        return {
            ...state,
            error: null,
            isLoading: false,
            data: action.data,
            isSuccessful: true,
        };
    }
    case ACTIONS.ERROR: {
        return {
            ...state,
            isLoading: false,
            error: action.error,
            isSuccessful: false,
        };
    }
    case ACTIONS.CLEAR_ERROR: {
        return {
            ...state,
            isLoading: false,
            error: null,
            isSuccessful: false,
        };
    }
    default:
        return state;
    }
};
