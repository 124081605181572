import BaseGeoTrakService from './BaseGeoTrakService';
import { Domain } from './Models';

export default class DomainService extends BaseGeoTrakService {
    async getDomainValues(database, table) {
        return this.fetch(`/domains/${database}/${table}`);
    }

    async getRawDomains(database, table) {
        return this.fetch(`/domains/raw/${database}/${table}`).then(
            (domains) => domains.map((domain) => Domain.load(domain))
        );
    }

    async createDomainValue(request) {
        return this.post('/domains', request.data.loadSqlEntity());
    }

    async updateDomainValue(request) {
        const { id, data } = request;
        return this.put(`/domains/${id}`, data.loadSqlEntity());
    }

    async deleteDomainValue(id) {
        return this.delete(`/domains/${id}`);
    }
}
