import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useCreateMarket from '../../../../_services/GeotrakService/hooks/useCreateMarket';
import { Market } from '../../../../_services/GeotrakService/Models';
import '../markets.css';

const createButton = ({ market, isValid }) => {
    const {
        createMarket, error, isLoading, isSuccessful,
    } = useCreateMarket();

    if (market.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/markets" />;
    }

    return (
        <div className="market-toolbar market-button">
            {error && <div className="text-danger market-error" id="create-market-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-market-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createMarket(market)}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    market: PropTypes.instanceOf(Market),
    isValid: PropTypes.func,
};

createButton.defaultProps = {
    market: new Market(),
    isValid: () => null,
};
