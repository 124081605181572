import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class History {
    constructor(checkRequest) {
        if (!checkRequest) {
            return;
        }

        // TODO Moley (2023-02-14)  These date properties should be a date not a string
        //                          with PropTypes.instanceOf(Date).
        //                          I tried to correct them but then check request blew up
        //                          because it wants all proeprty values as a primitive
        this.CurrentStage = checkRequest.CurrentStage;
        this.DeletedBy = checkRequest.DeletedBy;
        this.DeletedDate = datetimes.toLocal(checkRequest.DeletedDate);
        this.RejectionStatus = checkRequest.RejectionStatus;
        this.UpdatedBy = checkRequest.UpdatedBy;
        this.UpdatedOn = datetimes.toLocal(checkRequest.UpdatedOn);
    }
}

History.propTypes = {
    CurrentStage: PropTypes.string,
    DeletedBy: PropTypes.string,
    DeletedDate: PropTypes.string,
    RejectionStatus: PropTypes.string,
    UpdatedBy: PropTypes.string,
    UpdatedOn: PropTypes.string,
};
