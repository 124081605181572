import PropTypes from 'prop-types';

const PaginationType = PropTypes.shape({
    totalItems: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    totalPages: PropTypes.number,
    startPage: PropTypes.number,
    endPage: PropTypes.number,
    startIndex: PropTypes.number,
    endIndex: PropTypes.number,
    pages: PropTypes.instanceOf(Array),
    recordCount: PropTypes.number,
});

const CheckRequestsType = PropTypes.array;

const SelectedCheckRequestType = PropTypes.shape({
    Id: PropTypes.string,
    CheckRequestId: PropTypes.number,
    ProjectTag: PropTypes.string,
    MarketTag: PropTypes.string,
    Amount: PropTypes.number,
    APProcessingRecordNo: PropTypes.string,
    Attention: PropTypes.string,
    BottomTaskNo: PropTypes.string,
    CheckCreatedBy: PropTypes.string,
    CheckCreatedDate: PropTypes.date,
    CheckIssuedDate: PropTypes.date,
    CheckNo: PropTypes.string,
    CheckStatus: PropTypes.string,
    CostCode: PropTypes.string,
    DeletedDate: PropTypes.date,
    DeptNo: PropTypes.string,
    FALocationCode: PropTypes.string,
    FedexCostToProject: PropTypes.string,
    FieldApproverApprovedDate: PropTypes.date,
    FieldCheckReason: PropTypes.string,
    InternalNotes: PropTypes.string,
    InvoiceNo: PropTypes.string,
    InvoiceSource: PropTypes.string,
    JobName: PropTypes.string,
    JobNumber: PropTypes.string,
    NationalProgram: PropTypes.string,
    PayableTo: PropTypes.string,
    PayeeAddress: PropTypes.string,
    PayeeCity: PropTypes.string,
    PayeeState: PropTypes.string,
    PayeeZip: PropTypes.string,
    PayingBVCorp: PropTypes.string,
    PONo: PropTypes.string,
    ProjectAccountingApprovedDate: PropTypes.date,
    ProjectAccountingRejectedComments: PropTypes.string,
    RightOfWayFirms: PropTypes.string,
    CWPNo: PropTypes.string,
    MasterProject: PropTypes.string,
    CreatedOn: PropTypes.date,
    CreatedBy: PropTypes.instanceOf(Object),
    UpdatedOn: PropTypes.date,
    UpdatedBy: PropTypes.instanceOf(Object),
});

const ConfigType = PropTypes.shape({
    isLoaded: PropTypes.bool,
});

const AppReferenceMarketProjectRoleType = PropTypes.shape({
    AppReference: PropTypes.string,
    Market: PropTypes.string,
    Project: PropTypes.string,
    Role: PropTypes.string,
    Title: PropTypes.string,
});

const UserType = PropTypes.shape({
    Admin: PropTypes.string,
    CheckRequest: PropTypes.arrayOf(AppReferenceMarketProjectRoleType),
    emailAddress: PropTypes.string,
    FullName: PropTypes.string,
    phoneNumber: PropTypes.string,
    Procurement: PropTypes.arrayOf(PropTypes.any),
    Structural: PropTypes.arrayOf(AppReferenceMarketProjectRoleType),
    dashboards: PropTypes.arrayOf(PropTypes.any),
    landbase: PropTypes.arrayOf(AppReferenceMarketProjectRoleType),
    manager: PropTypes.arrayOf(AppReferenceMarketProjectRoleType),
    userid: PropTypes.string,
    construction: PropTypes.arrayOf(AppReferenceMarketProjectRoleType),
    Id: PropTypes.string,
});

const LocationType = PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.instanceOf(Object),
});

const HistoryType = PropTypes.shape({
    action: PropTypes.string,
    length: PropTypes.number,
    location: LocationType,
});

const AdminNotificationType = PropTypes.shape({
    changeFieldName: PropTypes.string,
    databaseName: PropTypes.string,
    isActive: PropTypes.bool,
    notificationId: PropTypes.number,
    tableName: PropTypes.string,
    template: PropTypes.string,
    uniqueFieldName: PropTypes.string,
    whereClause: PropTypes.string,
    schemaName: PropTypes.string,
});

export {
    ConfigType,
    UserType,
    LocationType,
    HistoryType,
    SelectedCheckRequestType,
    CheckRequestsType,
    PaginationType,
    AdminNotificationType,
};
