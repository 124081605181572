import debug from 'debug';

const BASE = 'geotrak-react';
const LOGLEVEL_TRACE = 'trace';
const LOGLEVEL_DEBUG = 'debug';
const LOGLEVEL_INFO = 'info';
const LOGLEVEL_WARN = 'warn';
const LOGLEVEL_ERROR = 'error';

class Log {
    constructor(envLevel) {
        debug.log = console.info.bind(console); // eslint-disable-line
        this.stack = console; // eslint-disable-line
        this.trace = debug(`${BASE}:${LOGLEVEL_TRACE}`);
        this.debug = debug(`${BASE}:${LOGLEVEL_DEBUG}`);
        this.info = debug(`${BASE}:${LOGLEVEL_INFO}`);
        this.warn = debug(`${BASE}:${LOGLEVEL_WARN}`);
        this.error = debug(`${BASE}:${LOGLEVEL_ERROR}`);

        Log.enable(envLevel);
    }

    static enable(envLevel) {
        const priority = [
            LOGLEVEL_ERROR,
            LOGLEVEL_WARN,
            LOGLEVEL_INFO,
            LOGLEVEL_TRACE,
            LOGLEVEL_DEBUG,
        ];
        const enabled = priority.slice(0, priority.indexOf(envLevel) + 1);
        const formattedStr = enabled.join(`,${BASE}:`);
        debug.enable(`${BASE}:${formattedStr}`);
    }

    debug(message, source) {
        this.debug(message, source);
    }

    trace(message, source) {
        this.trace(message, source);
    }

    info(message, source) {
        this.info(message, source);
    }

    warn(message, source) {
        this.warn(message, source);
    }

    error(message, source) {
        this.error(message, source);
    }

    dump() {
        this.stack.trace();
    }
}

export default new Log(process.env.REACT_APP_LOG_LEVEL);
