import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckRequestActionService } from '../../../_services';
import LoaderButton from '../../../utilComponents/LoaderButton';

class Resubmit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isWorking: false,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  resubmit = () => {
      this.setState({ isWorking: true });
      const {
          onError, requestForm, checkRequestId, refreshQueues,
      } = this.props;
      const form = {};
      requestForm.map((field) => {
          form[field.Name] = field.value;
          return field;
      });
      this.CheckRequestActionService.resubmitRequest(checkRequestId, form).then(() => {
          this.setState({ isWorking: false });
          refreshQueues();
      }).catch((err) => {
          this.setState({ isWorking: false });
          onError(err.message || 'An error occurred resubmitting request.');
      });
  }

  render() {
      const { isWorking } = this.state;
      const { isReadyToSubmit, filesReady } = this.props;
      return (
          <LoaderButton
              onClick={this.resubmit}
              isLoading={isWorking}
              disabled={isReadyToSubmit() || !filesReady}
              text="Resubmit Check Request"
              loadingText="Resubmitting..."
          />
      );
  }
}

export default Resubmit;

Resubmit.propTypes = {
    isReadyToSubmit: PropTypes.func,
    filesReady: PropTypes.bool,
    requestForm: PropTypes.instanceOf(Array),
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
    onError: PropTypes.func,
};

Resubmit.defaultProps = {
    isReadyToSubmit: () => null,
    requestForm: [],
    filesReady: false,
    checkRequestId: 0,
    refreshQueues: () => null,
    onError: () => null,
};
