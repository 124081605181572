import React from 'react';
import LoadingFullScreen from '../../../../utilComponents/LoadingFullScreen';
import { Office } from '../../../../_services/CheckRequestService/Models';
import useOfficesForm from './useOfficeForm';
import { Create, Update, Delete } from './Actions';
import './offices.css';
import Breadcrumbs from '../../../../utilComponents/Breadcrumbs';
import { STATES } from '../../../../CONSTANTS/global';
import AdminContainer from '../../AdminContainer';

const adminCheckRequestOfficesForm = ({ match }) => {
    const {
        values, isValid, onFormUpdate, isLoading,
    } = useOfficesForm(match.params.id || null);

    if (isLoading) {
        return <LoadingFullScreen id="office-form-loader" />;
    }

    const office = new Office();
    office.id = values.id;
    office.name = values.name;
    office.address = values.address;
    office.city = values.city;
    office.state = values.state;
    office.zipCode = values.zipCode;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Office">
                <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="input-office-name" className="form-label">
                                Name
                                {' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                id="input-office-name"
                                value={values.name}
                                onChange={(e) => onFormUpdate('name', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="input-office-address" className="form-label">
                                Address
                            </label>
                            <input
                                type="text"
                                id="input-office-address"
                                value={values.address}
                                onChange={(e) => onFormUpdate('address', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="input-office-city" className="form-label">
                                City
                            </label>
                            <input
                                type="text"
                                id="input-office-city"
                                value={values.city}
                                onChange={(e) => onFormUpdate('city', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="select-state" className="form-label">
                                State
                            </label>
                            <select
                                id="select-state"
                                value={values.state}
                                onChange={(e) => onFormUpdate('state', e.target.value)}
                            >
                                <option disabled value="">--Select a state--</option>
                                {STATES.map((state) => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="input-office-zip-code" className="form-label">
                                Zip Code
                            </label>
                            <input
                                type="text"
                                id="input-office-zip-code"
                                value={values.zipCode}
                                onChange={(e) => onFormUpdate('zipCode', e.target.value)}
                            />
                        </div>
                    </form>
                    <div className="office-toolbar">
                        <Create office={office} isValid={isValid} />
                        <Update office={office} isValid={isValid} />
                        <Delete office={office} isValid={isValid} />
                    </div>
                </div>
            </AdminContainer>
        </>
    );
};

export default adminCheckRequestOfficesForm;
