import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import NotificationService from '../NotificationService';

const useDeleteNotification = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteNotification = async (notification) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new NotificationService().deleteNotification(notification.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteNotification, error, isLoading, isSuccessful,
    };
};

export default useDeleteNotification;
