import React, { Component } from 'react';
import { BsFileText, BsX } from 'react-icons/bs';
import { MdFileDownload } from 'react-icons/md';
import PropTypes from 'prop-types';
import { CheckRequestService, DocumentService } from '../../_services';
import Log from '../../utilComponents/Log';
import Modal from '../Modals/Modal';
import { ButtonDanger } from '../../utilComponents/styledComponents';
import ErrorMessage from '../../utilComponents/ErrorMessage';
import IDownloadFileRequest from '../../_services/DocumentService/interfaces/IDownloadFileRequest';

class FileList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            showModal: false,
            fileToDelete: null,
            error: null,
        };

        this.checkRequestService = new CheckRequestService();
        this.documentService = new DocumentService();
    }

  componentDidMount = () => {
      this.loadFiles();
  }

  loadFiles = async () => {
      const {
          checkRequest,
          checkRequest: {
              summary: {
                  CheckRequestId,
              },
          },
          onFileStatusUpdate,
          minimumRequiredDocuments,
      } = this.props;
      Log.info(checkRequest);
      try {
          const result = await this.checkRequestService.getCheckWithDocuments(CheckRequestId);
          const { documents } = result;
          this.setState({ files: documents });
          onFileStatusUpdate(documents.length >= minimumRequiredDocuments, documents);
      } catch (err) {
          this.setState({ error: `Error loading files. ${err.message || err}` });
      }
  };

  showConfirmationPopup = (file) => {
      this.setState({
          showModal: true,
          fileToDelete: file,
      });
  }

  hideConfirmationPopup = () => {
      this.setState({
          showModal: false,
          fileToDelete: null,
      });
  }

  deleteFile = () => {
      const { files, fileToDelete } = this.state;
      const { onFileStatusUpdate, minimumRequiredDocuments } = this.props;
      this.documentService.deleteFile(fileToDelete.id);
      const newFiles = files.filter((file) => file.id !== fileToDelete.id);

      this.setState({
          files: newFiles,
          showModal: false,
          fileToDelete: null,
      });
      onFileStatusUpdate(newFiles.length >= minimumRequiredDocuments, newFiles);
  };

  renderFiles = () => {
      const { files } = this.state;
      const { allowFileDeletes } = this.props;
      return files.map((file) => (
          <div key={file.id} className="ms-1">
              <div className="row">
                  <div className="col-md-6 p-3 show-file d-flex flex-row justify-content-between">
                      <div>
                          <BsFileText className="me-2" size={20} />
                          <span>{file.fileName}</span>
                      </div>
                      <span
                          role="button"
                          tabIndex={0}
                          className="download-file"
                          onClick={() => this.documentService.downloadFile(
                              IDownloadFileRequest.load(file.fileName, file.s3Key)
                          )}
                          onKeyDown={() => this.documentService.downloadFile(
                              IDownloadFileRequest.load(file.fileName, file.s3Key)
                          )}
                      >
                          <MdFileDownload size={20} />
                      </span>
                      {allowFileDeletes
                && (
                    <span
                        className="remove-file"
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => { this.showConfirmationPopup(file); }}
                        onClick={() => { this.showConfirmationPopup(file); }}
                    >
                        <BsX size={20} />
                    </span>
                )}
                  </div>
              </div>
          </div>
      ));
  }

  render() {
      const { showModal, error } = this.state;
      return (
          <div className="container check-request-documents">
              <div>
                  <h3 className="fw-bold">
                      Files
                  </h3>
                  {this.renderFiles()}
              </div>
              {error && (
                  <ErrorMessage
                      message={error}
                      onDismiss={() => this.setState({ error: null })}
                  />
              )}
              <Modal
                  showModal={showModal}
                  header="Wait!"
                  onCancel={this.hideConfirmationPopup}
                  component={
                      <h5 className="text-center">Are you sure you want to delete this file?</h5>
                  }
                  footer={
                      <ButtonDanger onClick={this.deleteFile}>Delete</ButtonDanger>
                  }
                  displayFullScreen={false}
              />
          </div>
      );
  }
}

export default FileList;

FileList.propTypes = {
    checkRequest: PropTypes.shape({
        summary: PropTypes.shape({
            CheckRequestId: PropTypes.number.isRequired,
        }),
    }),
    allowFileDeletes: PropTypes.bool,
    onFileStatusUpdate: PropTypes.func,
    minimumRequiredDocuments: PropTypes.number,
};

FileList.defaultProps = {
    checkRequest: {},
    allowFileDeletes: false,
    onFileStatusUpdate: () => null,
    minimumRequiredDocuments: 0,
};
