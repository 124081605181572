import {
    SET_LAYER_VISIBILITY
} from '../CONSTANTS/types';

const mapInterfaceInitialState = { visibleLayers: {} };

const mapUIReducer = (state = mapInterfaceInitialState, action = { type: '', payload: {} }) => {
    switch (action.type) {
    case SET_LAYER_VISIBILITY:
        return {
            ...state,
            visibleLayers: { ...state.visibleLayers, ...action.payload },
        };
    default:
        return state;
    }
};

export default mapUIReducer;
