import React from 'react';
import '../stylesheets/RecordPlaceHolder.css';

const recordPlaceholder = () => (
    <div className="record-place-holder">
        <h3>No Records Found</h3>
    </div>
);

export default recordPlaceholder;
