import React from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_URI } from '../../../helpers/routing';

const loginLink = () => (
    <nav className="navbar-nav navbar-text">
        <Link to={LOGIN_URI} className="text-white">Login</Link>
    </nav>
);

export default loginLink;
