import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/InputCurrency.css';

const sanitizeMoneyInput = (input) => {
    const regex = /^[a-zA-Z]+$/;
    if (!input || input.toString().match(regex)) {
        return '';
    }
    const decimalIndex = input.toString().indexOf('.');
    const FIXED_NUM = 2;
    if (decimalIndex > -1 && input.length - decimalIndex > FIXED_NUM) {
        return parseFloat(input).toFixed(FIXED_NUM);
    }
    return input;
};

const inputCurrency = (props) => {
    const {
        id, label, required,
        handleChange,
        value, isReadOnly,
    } = props;

    return (
        <div className="mb-3">
            <label htmlFor={id} className="fw-bold form-label" label={label}>{label}</label>
            <span className="input-symbol-dollar" id="input-currency">
                <input
                    className={`${required && !value && 'border border-danger'}`}
                    disabled={isReadOnly}
                    type="number"
                    id={id}
                    value={sanitizeMoneyInput(value) || ''}
                    name={label}
                    placeholder={label}
                    step="0.01"
                    onChange={(e) => handleChange(e)}
                />
            </span>

        </div>
    );
};

inputCurrency.propTypes = {
    id: PropTypes.string,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isReadOnly: PropTypes.bool,
};

inputCurrency.defaultProps = {
    id: '',
    handleChange: () => null,
    label: '',
    required: false,
    value: '',
    isReadOnly: false,
};

export default inputCurrency;
