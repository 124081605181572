import PropTypes from 'prop-types';
import { Role } from '../Models';

class ICreateRoleRequest {
    constructor() {
        this.data = {};
    }

    static load(role) {
        if (!role) {
            throw Error('role is required');
        }
        if (!(role instanceof Role)) {
            throw Error('Unexpected type for role');
        }

        const request = new ICreateRoleRequest();
        request.data = role;
        return request;
    }
}

export default ICreateRoleRequest;

ICreateRoleRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
