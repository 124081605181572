import { flaskEndpoint, handleFetchErrors, getFlaskHeaders } from './APIUtils';

const baseURL = flaskEndpoint;

const putRequest = async (url, data, options = {}) => fetch(baseURL + url, {
    method: 'PUT',
    headers: await getFlaskHeaders(),
    body: JSON.stringify(data),
})
    .then(handleFetchErrors)
    .then((responseData) => {
        if (options.skipJsonParse) {
            return responseData;
        }
        return responseData.json();
    });

export default putRequest;
