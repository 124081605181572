import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import RoleService from '../RoleService';

const useDeleteRole = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteRole = async (role) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new RoleService().deleteRole(role.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteRole, error, isLoading, isSuccessful,
    };
};

export default useDeleteRole;
