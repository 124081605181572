import PropTypes from 'prop-types';

class IUpdateViewDetailsRequest {
    constructor() {
        this.existingViewName = null;
        this.newViewName = null;
        this.query = null;
        this.dataSourceId = null;
    }

    static load(existingViewName, newViewName, query, dataSourceId) {
        if (!existingViewName) {
            throw Error('existingViewName is required');
        }
        if (!newViewName) {
            throw Error('newViewName is required');
        }
        if (!dataSourceId) {
            throw Error('dataSource id is required');
        }

        const request = new IUpdateViewDetailsRequest();
        request.existingViewName = existingViewName;
        request.newViewName = newViewName;
        request.query = query === '' ? null : query;
        request.dataSourceId = dataSourceId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.existingViewName) {
            throw Error('existingViewName is required');
        }
        if (!this.newViewName) {
            throw Error('newViewName is required');
        }
        if (!this.dataSourceId) {
            throw Error('dataSource id is required');
        }
    }
}

export default IUpdateViewDetailsRequest;

IUpdateViewDetailsRequest.propTypes = {
    existingViewName: PropTypes.string,
    newViewName: PropTypes.string,
    query: PropTypes.string,
    dataSourceId: PropTypes.string,
};
