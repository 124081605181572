import BaseCheckRequestService from './BaseCheckRequestService';
import CheckRequest from './Models/CheckRequest';

export default class CheckRequestService extends BaseCheckRequestService {
    async getCheckRequest(checkRequestId) {
        return this.fetch(`/check-requests/${checkRequestId}`).then((result) => new CheckRequest(result));
    }

    async getCheckRequests(body) {
        return this.post('/check-requests', body);
    }

    getCheckWithDocuments(checkRequestId) {
        const uri = `/check-requests/${checkRequestId}/documents`;
        return this.get(uri);
    }
}
