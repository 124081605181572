import React from 'react';
import PropTypes from 'prop-types';

class FormControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidated: false,
        };
    }

    getClassNames() {
        let classNames = [];
        const { className } = this.props;
        if (className) {
            classNames = [...className];
        }

        const { isValidated } = this.state;
        if (isValidated) {
            classNames.push('was-validated');
        }

        return classNames;
    }

  validate = () => {
      const { formEl } = this;
      const formLength = formEl.length;

      if (formEl.checkValidity() === false) {
          for (let i = 0; i < formLength; i += 1) {
              const elem = formEl[i];
              const errorLabel = elem.parentNode.querySelector('.invalid-feedback');

              if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
                  if (!elem.validity.valid) {
                      errorLabel.textContent = elem.validationMessage;
                  } else {
                      errorLabel.textContent = '';
                  }
              }
          }

          return false;
      }
      for (let i = 0; i < formLength; i += 1) {
          const elem = formEl[i];
          const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
          if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
              errorLabel.textContent = '';
          }
      }

      return true;
  };

  submitHandler = (event) => {
      event.preventDefault();

      const { onSubmit } = this.props;
      if (this.validate()) {
          onSubmit(event, this.toJson());
      }

      this.setState({ isValidated: true });
  };

  toJson() {
      const result = {};
      Object.keys(this.formEl).forEach((key) => {
          const input = this.formEl[key];

          try {
              result[input.id] = input.value;
          } catch {
              // do nothing.  must not be a valid input field
          }
      });
      return result;
  }

  render() {
      const { children } = this.props;
      return (
          <div className="row">
              <form
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...children.forEach((child) => child)}
                  className={this.getClassNames()}
                  noValidate
                  ref={(form) => { this.formEl = form; }}
                  onSubmit={this.submitHandler}
              >
                  {children}
              </form>
          </div>
      );
  }
}

export default FormControl;

FormControl.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};

FormControl.defaultProps = {
    children: null,
    className: '',
};
