import React from 'react';
import datetimes from '../../../helpers/datetimehelper';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetUserHistory from '../../../_services/UserService/hooks/useGetUserHistory';
import useGetModules from '../../../_services/GeotrakService/hooks/useGetModules';
import './users.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import useGetRoles from '../../../_services/GeotrakService/hooks/useGetRoles';

const adminUserHistory = ({ match }) => {
    const { params: { id } } = match;
    const { userHistory, isLoading } = useGetUserHistory(id);
    const { modules, isLoading: isLoadingModules } = useGetModules();
    const { roles, isLoading: isLoadingRoles } = useGetRoles();

    if (isLoading || isLoadingModules || isLoadingRoles) {
        return <LoadingFullScreen id="user-history-loader" />;
    }

    const getModule = (url) => {
        const urlRouteParameters = url.split('/');
        const moduleIndex = urlRouteParameters.indexOf('modules');
        const moduleId = urlRouteParameters[moduleIndex + 1];
        const moduleRecord = modules.find((module) => module.id === moduleId);
        return moduleRecord ? moduleRecord.name : '';
    };

    const getRole = (url) => {
        const urlRouteParameters = url.split('/');
        const roleIndex = urlRouteParameters.indexOf('roles');
        const roleId = urlRouteParameters[roleIndex + 1];
        const roleRecord = roles.find((role) => role.id === roleId);
        return roleRecord ? roleRecord.name : '';
    };

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="User History">
                <table className="user-history-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>URL</th>
                            <th>Module</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userHistory.map((historyRecord) => (
                            <tr key={historyRecord.id} id="user-history-record">
                                <td>{datetimes.toLocal(historyRecord.createdOn)}</td>
                                <td>{historyRecord.url}</td>
                                <td>{getModule(historyRecord.url)}</td>
                                <td>{getRole(historyRecord.url)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </AdminContainer>
        </>
    );
};

export default adminUserHistory;
