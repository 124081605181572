import PropTypes from 'prop-types';
import { Renderer } from '../Models';

class ICreateRendererRequest {
    constructor() {
        this.dataSourceId = null;
        this.data = {};
    }

    static load(renderer) {
        if (!renderer) {
            throw Error('renderer is required');
        }
        if (!(renderer instanceof Renderer)) {
            throw Error('Unexpected type for renderer');
        }
        if (!renderer.dataSourceId) {
            throw Error('dataSourceId is required');
        }

        const request = new ICreateRendererRequest();
        request.dataSourceId = renderer.dataSourceId;
        request.data = renderer;
        request.validate();
        return request;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('dataSourceId is required');
        }
    }
}

export default ICreateRendererRequest;

ICreateRendererRequest.propTypes = {
    dataSourceId: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
