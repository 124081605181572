import PropTypes from 'prop-types';
import { View } from '../Models';

class IUpdateUserViewFieldsRequest {
    constructor() {
        this.dataSourceId = null;
        this.view = new View();
    }

    static load(view, dataSourceId) {
        if (!view) {
            throw Error('view is required');
        }
        if (!(view instanceof View)) {
            throw Error('Unexpected type for view');
        }
        if (!dataSourceId) {
            throw Error('dataSource id is required');
        }

        const request = new IUpdateUserViewFieldsRequest();
        request.view = view;
        request.dataSourceId = dataSourceId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.view.name) {
            throw Error('viewName is required');
        }
        if (this.view.fields.length < 1) {
            throw Error('at least one field is required');
        }
    }
}

export default IUpdateUserViewFieldsRequest;

IUpdateUserViewFieldsRequest.propTypes = {
    dataSourceId: PropTypes.string,
    view: PropTypes.instanceOf(View),
};
