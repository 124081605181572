import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdWarning } from 'react-icons/md';
import EtlService from '../_services/EtlService/EtlService';
import './stylesheets/EtlStatusIndicator.css';

const etlStatusIndicator = ({ moduleRoleId }) => {
    const etlService = new EtlService();
    const [etlStatus, setEtlStatus] = useState(null);
    const [error, setError] = useState(null);

    const getEtlStatus = async () => {
        try {
            const etlJobStatus = await etlService.getEtlStatus(moduleRoleId);
            if (etlJobStatus.lastUpdatedDate) {
                etlJobStatus.formatDate();
                setEtlStatus(etlJobStatus.lastUpdatedDate);
            }
            setError(null);
        } catch (err) {
            setError(err.message || 'An error occurred while getting ETL status information');
        }
    };

    useEffect(() => {
        getEtlStatus();
    }, [moduleRoleId]);

    if (error) {
        return (
            <span id="etl-status-error" className="d-flex">
                <MdWarning size={20} className="text-danger etl-status-error-symbol" />
                <div className="etl-status-text">{error}</div>
            </span>
        );
    }

    if (!etlStatus) {
        return null;
    }

    return (
        <div id="etl-status" className="etl-status-text">{`Last Successful ETL Update: ${etlStatus}`}</div>
    );
};

export default etlStatusIndicator;

etlStatusIndicator.propTypes = {
    moduleRoleId: PropTypes.string,
};

etlStatusIndicator.defaultProps = {
    moduleRoleId: null,
};
