import { SET_ID_COLLECTION, CLEAR_ID_COLLECTION } from '../CONSTANTS/types';

export default (state = [], action = { type: '', selectedFeatureCollection: [] }) => {
    switch (action.type) {
    case SET_ID_COLLECTION:
        return action.selectedFeatureCollection;
    case CLEAR_ID_COLLECTION:
        return [];
    default:
        return state;
    }
};
