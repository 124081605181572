import React from 'react';
import { useHistory } from 'react-router';
import { ButtonPrimary, ButtonContainer } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { Update, Create, Delete } from './Actions';
import { Role } from '../../../_services/GeotrakService/Models';
import useRoleForm from './useRoleForm';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminRolesForm = ({ match }) => {
    const {
        values, isValid, onFormUpdate, isLoading, module,
    } = useRoleForm(match.params.roleId || null, match.params.moduleId);
    const history = useHistory();

    if (isLoading) {
        return <LoadingFullScreen id="role-form-loader" />;
    }

    const role = new Role(values.id, values.name, values.alias, module);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Role">
                <form>
                    <div className="role-toolbar">
                        {match.params.roleId && (
                            <ButtonContainer>
                                <ButtonPrimary
                                    id="configure-map-button"
                                    onClick={() => history.push(`/admin/modules/${match.params.moduleId}/roles/${match.params.roleId}/map`)}
                                >
                                    Configure Map
                                </ButtonPrimary>
                                <ButtonPrimary
                                    id="configure-data-sources-button"
                                    onClick={() => history.push(`/admin/modules/${match.params.moduleId}/roles/${match.params.roleId}/data-sources`)}
                                >
                                    Configure Data Sources
                                </ButtonPrimary>
                                <ButtonPrimary
                                    id="configure-etls-button"
                                    onClick={() => history.push(`/admin/modules/${match.params.moduleId}/roles/${match.params.roleId}/etls`)}
                                >
                                    Configure ETLs
                                </ButtonPrimary>
                            </ButtonContainer>
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-role-name" className="form-label">
                            Role Name
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-role-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="role-description" className="form-label">
                            Description
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-role-description"
                            value={values.alias}
                            onChange={(e) => onFormUpdate('alias', e.target.value)}
                        />
                    </div>
                </form>
                <div className="role-toolbar">
                    <Create role={role} isValid={isValid} moduleId={match.params.moduleId} />
                    <Update role={role} isValid={isValid} moduleId={match.params.moduleId} />
                    <Delete role={role} moduleId={match.params.moduleId} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminRolesForm;
