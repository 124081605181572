import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import CheckRequestAdminService from '../CheckRequestAdminService';

const useDeleteOffice = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteOffice = async (office) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new CheckRequestAdminService().deleteOffice(office.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteOffice, error, isLoading, isSuccessful,
    };
};

export default useDeleteOffice;
