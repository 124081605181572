import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';

export default class GeotrakCookies {
    constructor() {
        this.cookies = new Cookies();
        this.isLocal = process.env.NODE_ENV === 'development';
        this.options = {};
        this.key = 'cs';

        if (!this.isLocal) {
            this.options.secure = true;
        }
    }

    getSecret() {
        return this.cookies.get(this.key);
    }

    setSecret(secret) {
        return this.cookies.set(this.key, secret);
    }

    destroy() {
        return this.cookies.remove(this.key);
    }

    get(key, options) {
        const result = this.cookies.get(key, { ...options, ...this.options });
        if (!result || result === '') {
            return null;
        }

        try {
            return this.decrypt(result);
        } catch (err) {
            this.remove(key, options);
            throw err;
        }
    }

    set(key, value, options) {
        const encyptedValue = this.encrypt(value);
        this.cookies.set(key, encyptedValue, { ...options, ...this.options });
    }

    getAll(options) {
        const results = this.getAll({ ...options, ...this.options });
        return results.map((result) => this.decrypt(result));
    }

    remove(key, options) {
        this.cookies.set(key, '');
        this.cookies.remove(key, options);
    }

    addChangeListener(callback) {
        this.cookies.removeChangeListener(callback);
    }

    removeChangeListener(callback) {
        this.cookies.removeChangeListener(callback);
    }

    encrypt(cookieValue) {
        if (!cookieValue) {
            return cookieValue;
        }

        if (typeof cookieValue === 'object') {
            return this.encryptObject(cookieValue);
        }

        if (typeof cookieValue === 'string') {
            return this.encryptString(cookieValue);
        }

        return cookieValue;
    }

    decrypt(cookieValue) {
        if (!cookieValue || !this.getSecret()) {
            return cookieValue;
        }
        const bytes = CryptoJS.AES.decrypt(cookieValue, this.getSecret());
        const result = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(result);
    }

    encryptObject(objValue) {
        return this.encryptString(JSON.stringify(objValue));
    }

    encryptString(strValue) {
        if (!this.getSecret()) {
            return strValue;
        }
        return CryptoJS.AES.encrypt(strValue, this.getSecret()).toString();
    }
}
