import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetProjects from '../../../_services/GeotrakService/hooks/useGetProjects';
import './projects.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminProjects = () => {
    const history = useHistory();
    const { projects, isLoading } = useGetProjects();

    if (isLoading) {
        return <LoadingFullScreen id="project-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Projects">
                <ButtonContainer>
                    <ButtonPrimary id="new-project-button" onClick={() => history.push('/admin/projects/new')}>
                        New Project
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group project-container">
                    {projects.map((project) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action text-start"
                            id={project.name}
                            key={project.id}
                            onClick={() => history.push(`/admin/projects/${project.id}`)}
                        >
                            <span id="project-name">{project.name}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminProjects;
