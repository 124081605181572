import PropTypes from 'prop-types';
import Option from './Option';

export default class Field {
    constructor(name, alias, options = [], id = null) {
        this.name = name;
        this.alias = alias;
        this.options = options;
        this.id = id;

        this.loadOptions();
    }

    static load(response) {
        return new Field(
            response.Name,
            response.DisplayName,
            response.Options,
            response.Id
        );
    }

    loadOptions() {
        this.options = this.options.map((option) => Option.load(option));
    }

    validate() {
        if (!this.name || this.name === '') {
            throw Error('Name is required');
        }

        if (!this.alias || this.alias === '') {
            throw Error('Alias is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            Name: this.name,
            DisplayName: this.alias,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

Field.propTypes = {
    name: PropTypes.string,
    alias: PropTypes.string,
    id: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
};
