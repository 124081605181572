import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import '../notifications.css';
import { NotificationDomain } from '../../../../_services/NotificationService/Models';
import useCreateNotificationDomain from '../../../../_services/NotificationService/hooks/domain/useCreateNotificationDomain';

const createDomainButton = ({ notificationDomain, isValid }) => {
    const {
        createNotificationDomain, error, isLoading, isSuccessful,
    } = useCreateNotificationDomain();

    if (notificationDomain.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/notifications/${notificationDomain.notificationId}/domains`} />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="create-notification-domain-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-notification-domain-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createNotificationDomain(notificationDomain)}
            />
        </div>
    );
};

export default createDomainButton;

createDomainButton.propTypes = {
    isValid: PropTypes.func,
    notificationDomain: PropTypes.instanceOf(NotificationDomain),
};

createDomainButton.defaultProps = {
    isValid: () => null,
    notificationDomain: new NotificationDomain(),
};
