/*
 * These routes are thin wrappers around page level components.
 * The page level components themselves do not have similar APIs, thus prop-spreading is more ergonomic here
 * than attempting to define a union of all page props.
 */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { UserType } from '../types';
import AzureLogin from '../containers/AzureLogin';
import Home from '../containers/Home';
import Login from '../containers/Login';
import NotFound from '../containers/NotFound';
import Unauthorized from '../containers/Unauthorized';
import ExpiredToken from '../containers/ExpiredToken';
import ServiceCenterRouter from '../containers/ServiceCenter/ServiceCenterRouter';
import AdminRouter from '../containers/AdminRouter';
import GeoTRAK from '../containers/GeoTRAK';
import UserProfile from '../containers/UserProfile/UserProfile';
import DatabasePasswordForm from '../containers/UserProfile/DatabaseAccess/DatabasePasswordForm';
import { UserService } from '../_services';
import { LOGIN_URI } from '../helpers/routing';
import Loading from './LoaderSpinner';

const auth = new UserService();

const AuthorizedComponent = ({
    component: Component, user, isAuthorized, componentProps,
}) => {
    if (!user) {
        return <Loading />;
    }

    if (isAuthorized) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...componentProps} />;
    }

    return <Redirect to="/unauthorized" />;
};

AuthorizedComponent.defaultProps = {
    user: null,
};

AuthorizedComponent.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]).isRequired,
    user: UserType,
    isAuthorized: PropTypes.bool.isRequired,
    // component props are different per rendered subcomponent.
    // Cannot capture this polymorphism with prop types.
    // eslint-disable-next-line react/forbid-prop-types
    componentProps: PropTypes.object.isRequired,
};

const PrivateRoute = ({
    component: Component,
    user,
    checkAuthorized,
    ...rest
}) => {
    let renderFunction;

    const isAuthenticated = !!user;

    if (isAuthenticated) {
        const isAuthorized = checkAuthorized(rest);

        renderFunction = (otherProps) => (
            <AuthorizedComponent
                isAuthorized={isAuthorized}
                user={user}
                component={Component}
                componentProps={otherProps}
            />
        );
    } else {
        renderFunction = () => (<Redirect to={LOGIN_URI} />);
    }
    return (
        <Route
            {...rest}
            render={renderFunction}
        />
    );
};

const defaultAuthorizationCheck = () => true;
const applicationAuthorizationCheck = (props) => auth.hasAppReferenceRole(props.computedMatch.params.moduleRoleId);

PrivateRoute.defaultProps = {
    component: 'div',
    checkAuthorized: defaultAuthorizationCheck,
    user: null,
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]),
    checkAuthorized: PropTypes.func,
    user: UserType,
};

const Routes = () => {
    const user = new UserService().getCurrentUser();

    return (
        <Switch>
            <PrivateRoute exact path="/" component={Home} user={user} />
            <Route
                path="/service-center"
                user={user}
                render={(otherProps) => <ServiceCenterRouter {...otherProps} user={user} />}
            />
            <Route path="/admin" component={AdminRouter} />
            <Route path="/login" component={Login} />
            <Route path="/azure-login" component={AzureLogin} />
            <Route path="/unauthorized" component={Unauthorized} />
            <Route path="/expiredToken" component={ExpiredToken} />
            <Redirect exact from="/.auth/login/aad/callback" to="/" />
            <PrivateRoute
                path="/modules/:moduleId/roles/:moduleRoleId"
                component={GeoTRAK}
                user={user}
                checkAuthorized={applicationAuthorizationCheck}
            />
            <PrivateRoute
                path="/user/:userId/profile"
                component={UserProfile}
                user={user}
            />
            <PrivateRoute
                path="/user/:userId/change-database-password"
                component={DatabasePasswordForm}
                user={user}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

Routes.defaultProps = {
    component: 'div',
};

Routes.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]),
};

export default Routes;
