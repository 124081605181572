import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import { CheckRequestActionService } from '../../../_services';
import LoaderButton from '../../../utilComponents/LoaderButton';
import Modal from '../../Modals/Modal';
import ErrorMessage from '../../../utilComponents/ErrorMessage';

class RequestVoid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            isWorking: false,
            comments: '',
            reasonForVoid: '',
            error: null,
        };

        this.CheckRequestActionService = new CheckRequestActionService();
    }

  requestVoid = () => {
      this.setState({ isWorking: true });
      const { checkRequestId, refreshQueues } = this.props;
      const { comments, reasonForVoid } = this.state;
      const form = {
          ReasonForVoid: reasonForVoid || null,
          VoidComments: comments || null,
      };
      this.CheckRequestActionService.requestVoid(checkRequestId, form).then(() => {
          this.setState({
              showModal: false,
              isWorking: false,
          });
          refreshQueues();
      }).catch((err) => {
          this.setState({
              isWorking: false,
              error: err.message || 'An error occurred requesting void.',
          });
      });
  }

  render() {
      const {
          showModal, isWorking, reasonForVoid, error,
      } = this.state;
      return (
          <>
              <ButtonPrimary onClick={() => this.setState({ showModal: true })}>
                  Request Void
              </ButtonPrimary>
              <Modal
                  showModal={showModal}
                  header="Request Void"
                  onCancel={() => this.setState({ showModal: false })}
                  component={(
                      <form>
                          <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="void-comments" className="form-label">
                                      Void Comments
                                  </label>
                                  <textarea
                                      rows="2"
                                      id="void-comments"
                                      onChange={(e) => this.setState({ comments: e.target.value })}
                                  />
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="reason-for-void" className="form-label">
                                      Reason for Void
                                  </label>
                                  <select
                                      value={reasonForVoid}
                                      onChange={(e) => this.setState({ reasonForVoid: e.target.value })}
                                  >
                                      <option value="">--Select a reason for void---</option>
                                      <option value="Lost Check">Lost Check</option>
                                      <option value="Error on Original Check">Error on Original Check</option>
                                      <option value="Check Was Duplicate">Check Was Duplicate</option>
                                      <option value="Other">Other</option>
                                  </select>
                              </div>
                          </div>
                      </form>
                  )}
                  footer={(
                      <>
                          {error && (
                              <div>
                                  <ErrorMessage message={error} onDismiss={() => this.setState({ error: null })} />
                              </div>
                          )}
                          <LoaderButton
                              onClick={this.requestVoid}
                              isLoading={isWorking}
                              text="Request Void"
                              loadingText="Requesting…"
                          />
                      </>
                  )}
                  displayFullScreen={false}
              />
          </>
      );
  }
}

export default RequestVoid;

RequestVoid.propTypes = {
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
};

RequestVoid.defaultProps = {
    checkRequestId: 0,
    refreshQueues: () => null,
};
