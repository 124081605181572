import PropTypes from 'prop-types';
import { DocumentType } from '../Models';

class ICreateDocumentTypeRequest {
    constructor() {
        this.data = {};
    }

    static load(documentType) {
        if (!documentType) {
            throw Error('document type is required');
        }
        if (!(documentType instanceof DocumentType)) {
            throw Error('Unexpected data type for document type');
        }

        const request = new ICreateDocumentTypeRequest();
        request.data = documentType;
        return request;
    }
}

export default ICreateDocumentTypeRequest;

ICreateDocumentTypeRequest.propTypes = {
    data: PropTypes.instanceOf(DocumentType),
};
