import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaFilter } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setQuery } from '../actions/queryActions';
import LoaderButton from '../utilComponents/LoaderButton';
import Loading from '../utilComponents/LoaderSpinner';
import MapDataSourceChanger from './MapDataSourceChanger';
import { ButtonContainer, ButtonDanger, ButtonPrimary } from '../utilComponents/styledComponents';
import QueryBuilder from './QueryBuilder/QueryBuilder';
import SearchId from './Search/SearchId';
import SearchLocation from './Search/SearchLocation';
import SearchMap from './Search/SearchMap';
import IGetViewsRequest from '../_services/GeotrakService/interfaces/IGetViewsRequest';
import { DomainService, ViewService } from '../_services/GeotrakService';
import './stylesheets/Search.css';
import { areObjectsEqual } from '../helpers/utilities';
import IGetViewRequest from '../_services/GeotrakService/interfaces/IGetViewRequest';
import { DataSource, View } from '../_services/GeotrakService/Models';

const tabs = {
    ID: 'with-id',
    LOCATION: 'with-location',
    MAP: 'with-map',
    ATTRIBUTES: 'with-attributes',
};

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchActive: false,
            isLoading: true,
            isWorking: false,
            query: null,
            views: [],
            domains: {},
            masterView: new View(),
            activeSearchTab: tabs.ID,
            error: null,
            open: false,
        };

        this.viewService = new ViewService();
        this.domainService = new DomainService();
    }

  componentDidMount = () => {
      this.initQuery();
      this.getViews();
      this.getDomains();
      this.getMasterView();
  };

  componentDidUpdate = async (prevProps, prevState) => {
      const {
          dataSource,
          activeView,
          activeSchema,
      } = this.props;
      const { searchActive, isLoading } = this.state;

      if (searchActive && !prevState.searchActive) {
          this.reset();
      }

      if (
          (dataSource.id !== prevProps.dataSource.id
        || activeView !== prevProps.activeView || !areObjectsEqual(activeSchema, prevProps.activeSchema))
      && !isLoading
      ) {
          this.start();
      }
  };

  reset = () => {
      this.setState({ searchActive: false, isWorking: false });
  }

  start = () => {
      this.setState({ isLoading: true }, this.initQuery);
      this.getViews();
      this.getMasterView();
      this.getDomains();
  }

  getDomains = () => {
      const { dataSource } = this.props;
      this.domainService.getDomainValues(dataSource.databaseReference, dataSource.tableReference)
          .then((domains) => {
              this.setState({ domains });
          }).catch((err) => {
              this.setState({ error: err.message || err });
          });
  }

  getViews = async () => {
      const { dataSource } = this.props;
      const request = IGetViewsRequest.load(dataSource.id);
      const views = await this.viewService.getViews(request);
      this.setState({ views });
  }

  getMasterView = async () => {
      const { dataSource } = this.props;
      const request = IGetViewRequest.load(dataSource.id);
      const view = await this.viewService.getSystemView(request);
      this.setState({ masterView: view });
  }

  initQuery = () => {
      const { activeQuery } = this.props;
      this.setState({
          isLoading: false,
          query: activeQuery || '',
      });
  };

    handleChange = ({ query }) => {
        try {
            if (!query) {
                this.setState({ query: null });
                return;
            }
            let currentQuery = query;
            if (query === '()') {
                currentQuery = '';
            }
            if (currentQuery.includes(' null ')) {
                currentQuery = currentQuery.replace(/null.*?''/g, 'null').trim();
            }
            if (currentQuery.includes(' in ')) {
                currentQuery = currentQuery.replace(/in ('.*?')/g, this.replacer).trim();
            }
            this.setState({ query: currentQuery });
        } catch (err) {
            this.setState({ error: err.message || err });
        }
    };

  replacer = (_match, p1) => {
      const values = p1.replace(/[']+/g, '');
      const splitValues = values.split(',').map((value) => `'${value}'`);
      const finalValues = splitValues.join(',');
      return 'in ('.concat(finalValues).concat(')');
  };

  query = () => {
      this.setState({ isWorking: true });
      const { query, views } = this.state;
      const {
          dataSource,
          activeView,
          setQueryAction,
      } = this.props;
      this.setState({ searchActive: true });
      const view = views.find((viewConfig) => viewConfig.name === activeView);
      setQueryAction(dataSource, view, query);
  };

    clearQuery = () => {
        const { dataSource, activeView, setQueryAction } = this.props;
        const { query, views } = this.state;
        const view = views.find((viewConfig) => viewConfig.name === activeView);
        this.setState({ query }, () => {
            setQueryAction(dataSource, view);
            this.start();
        });
    };

    getTabState = (tab) => {
        const { activeSearchTab } = this.state;
        if (activeSearchTab === tab) {
            return 'active';
        }
        return '';
    }

    render() {
        const { match, dataSource, onMapReposition } = this.props;
        const {
            isLoading,
            isWorking,
            query,
            views,
            domains,
            masterView,
            activeSearchTab,
            error,
            open,
        } = this.state;

        const url = match !== undefined && match.path.split('/');
        if (isLoading) {
            return <Loading />;
        }

        const resetButton = (
            <ButtonDanger onClick={this.clearQuery}>Clear</ButtonDanger>
        );

        const queryBuilder = (
            <QueryBuilder
                onQueryChange={this.handleChange}
                domains={domains}
                masterView={masterView}
                dataSource={dataSource}
            />
        );

        const dataSearch = (
            <>
                <ButtonPrimary
                    data-bs-toggle="collapse"
                    data-bs-target="#search-collapse"
                    aria-expanded="false"
                    aria-controls="search-collapse"
                    onClick={() => { this.setState({ open: !open }); }}
                    className="ms-1"
                >
                    <FaFilter size={20} />
                </ButtonPrimary>
                <div className={`collapse ${open ? 'show' : ''}`} id="search-collapse">
                    <div className="card rounded-0 text-bg-light">
                        <div className="card-body">
                            {queryBuilder}
                            <ButtonContainer
                                error={error}
                                onErrorDismiss={() => this.setState({ error: null })}
                            >
                                <LoaderButton
                                    onClick={this.query}
                                    isLoading={isWorking}
                                    text="Search"
                                    loadingText="Searching..."
                                    disabled={!query}
                                />
                                {resetButton}
                            </ButtonContainer>

                        </div>
                    </div>
                </div>
            </>
        );

        const mapSearch = (
            <>
                <MapDataSourceChanger />
                <ul role="tablist" className="nav nav-tabs">
                    <li role="presentation" className="nav-item">
                        <button
                            role="tab"
                            type="button"
                            className={`nav-link search-tab ${this.getTabState(tabs.ID)}`}
                            aria-controls={tabs.ID}
                            data-toggle="tab"
                            tabIndex={0}
                            data-target={tabs.ID}
                            aria-selected={activeSearchTab === tabs.ID}
                            onClick={() => this.setState({ activeSearchTab: tabs.ID })}
                            onKeyDown={() => this.setState({ activeSearchTab: tabs.ID })}
                        >
                            With ID
                        </button>
                    </li>
                    <li role="presentation" className="nav-item">
                        <button
                            role="tab"
                            type="button"
                            aria-controls={tabs.ATTRIBUTES}
                            data-toggle="tab"
                            className={`nav-link search-tab ${this.getTabState(tabs.ATTRIBUTES)}`}
                            tabIndex={0}
                            data-target={tabs.ATTRIBUTES}
                            aria-selected={activeSearchTab === tabs.ATTRIBUTES}
                            onClick={() => this.setState({ activeSearchTab: tabs.ATTRIBUTES })}
                            onKeyDown={() => this.setState({ activeSearchTab: tabs.ATTRIBUTES })}
                        >
                            With Attributes
                        </button>
                    </li>
                    <li role="presentation" className="nav-item">
                        <button
                            role="tab"
                            type="button"
                            aria-controls={tabs.MAP}
                            className={`nav-link search-tab ${this.getTabState(tabs.MAP)}`}
                            data-toggle="tab"
                            tabIndex={0}
                            data-target={tabs.MAP}
                            aria-selected={activeSearchTab === tabs.MAP}
                            onClick={() => this.setState({ activeSearchTab: tabs.MAP })}
                            onKeyDown={() => this.setState({ activeSearchTab: tabs.MAP })}
                        >
                            With Map
                        </button>
                    </li>
                    <li role="presentation" className="nav-item">
                        <button
                            role="tab"
                            type="button"
                            aria-controls={tabs.LOCATION}
                            data-toggle="tab"
                            className={`nav-link search-tab ${this.getTabState(tabs.LOCATION)}`}
                            tabIndex={0}
                            data-target={tabs.LOCATION}
                            aria-selected={activeSearchTab === tabs.LOCATION}
                            onClick={() => this.setState({ activeSearchTab: tabs.LOCATION })}
                            onKeyDown={() => this.setState({ activeSearchTab: tabs.LOCATION })}
                        >
                            With Location
                        </button>
                    </li>
                </ul>
                <div className="tab-content m-3 p-2 d-flex justify-content-center">
                    <div role="tabpanel" id={tabs.ID} className={`tab-pane ${this.getTabState(tabs.ID)}`}>
                        <SearchId views={views} />
                    </div>
                    <div role="tabpanel" id={tabs.ATTRIBUTES} className={`tab-pane ${this.getTabState(tabs.ATTRIBUTES)}`}>
                        <p className="instructions text-center">Create filters below to search with details.</p>
                        {queryBuilder}
                        <ButtonContainer
                            error={error}
                            onErrorDismiss={() => this.setState({ error: null })}
                        >
                            <LoaderButton
                                onClick={this.query}
                                isLoading={isWorking}
                                text="Search"
                                loadingText="Searching..."
                            />
                            {resetButton}
                        </ButtonContainer>

                    </div>
                    <div role="tabpanel" id={tabs.MAP} className={`tab-pane ${this.getTabState(tabs.MAP)}`}>
                        <SearchMap views={views} />
                    </div>
                    <div role="tabpanel" id={tabs.LOCATION} className={`tab-pane ${this.getTabState(tabs.LOCATION)}`}>
                        <SearchLocation
                            onMapReposition={onMapReposition}
                            setError={(message) => this.setState({ error: message })}
                        />
                    </div>
                </div>
            </>
        );

        if (url[url.length - 1] === 'data') {
            return dataSearch;
        }
        return mapSearch;
    }
}

const mapStateToProps = ({
    activeConfiguration,
    drawCompleteController,
}) => ({
    activeQuery: activeConfiguration.query,
    activeView: activeConfiguration.view,
    activeSchema: activeConfiguration.schema,
    drawCompleteController,
});

const mapDispatchToProps = {
    setQueryAction: setQuery,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));

Search.propTypes = {
    dataSource: PropTypes.instanceOf(DataSource),
    activeQuery: PropTypes.string,
    activeView: PropTypes.string,
    activeSchema: PropTypes.instanceOf(Array),
    match: PropTypes.instanceOf(Object),
    setQueryAction: PropTypes.func,
    onMapReposition: PropTypes.func,
};

Search.defaultProps = {
    dataSource: new DataSource(),
    activeQuery: '',
    activeView: '',
    activeSchema: [],
    match: { },
    setQueryAction: () => null,
    onMapReposition: () => null,
};
