/**
 * Determines if the specified flag is enabled for the current environment
 * @param {*} name The name of the feature flag
 */
// eslint-disable-next-line no-unused-vars
const featureFlag = (name) => () => {
    // NOTE: When adding new feature flags to the .env files, they must
    //       be formatted as REACT_APP_FEATURE_FLAG_XXX, where XXX is the
    //       name of the feature.

    const FEATURE_FLAG_PREFIX = 'REACT_APP_FEATURE_FLAG_';
    const environmentVariableName = `${FEATURE_FLAG_PREFIX}${name}`;

    return process.env[environmentVariableName] === 'true';
};

export default class FeatureFlagService {
  flags = {};

  /**
   * Gets the value of the specified feature flag.
   * @param {string} name The name of the feature flag
   */
  get(name) {
      const flag = this.flags[name] || false;

      return flag();
  }
}
