import PropTypes from 'prop-types';

class ICreateFieldRequest {
    constructor() {
        this.database = null;
        this.schema = 'GeoMRE';
        this.table = null;
        this.data = {};
    }

    static load(database, tableName, name, type, length, schema) {
        if (!database) {
            throw Error('database is required');
        }

        if (!tableName) {
            throw Error('Table name is required');
        }

        if (!name) {
            throw Error('Field name is required');
        }

        if (!type) {
            throw Error('Field type is required');
        }

        if (!length) {
            throw Error('Field length is required');
        }

        const request = new ICreateFieldRequest();
        request.database = database;
        request.table = tableName;
        if (schema) {
            request.schema = schema;
        }
        request.data.fieldName = name;
        request.data.fieldType = type;
        request.data.fieldLength = length;

        return request;
    }
}

export default ICreateFieldRequest;

ICreateFieldRequest.propTypes = {
    database: PropTypes.string,
    schema: PropTypes.string,
    table: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
