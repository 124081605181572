import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { ButtonPrimary } from '../../../../utilComponents/styledComponents';
import '../etljobs.css';

const EditButton = ({ row }) => {
    const history = useHistory();
    return (
        <div className="d-grid">
            <ButtonPrimary
                id="etl-geocoder-job-view-edit-button"
                onClick={() => history.push(`/admin/etls/geocoder-jobs/${row.id}`)}
            >
                Edit
            </ButtonPrimary>
        </div>
    );
};

export default EditButton;

EditButton.propTypes = {
    row: PropTypes.instanceOf(Object).isRequired,
};
