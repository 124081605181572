import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class ProjectManager {
    constructor(checkRequest) {
        this.ProjectManagerApprovedBy = checkRequest.ProjectManagerApprovedBy;
        this.ProjectManagerApprovedDate = datetimes.toLocal(checkRequest.ProjectManagerApprovedDate);
        this.ProjectManagerRejectedBy = checkRequest.ProjectManagerRejectedBy;
        this.ProjectManagerRejectedDate = datetimes.toLocal(checkRequest.ProjectManagerRejectedDate);
        this.ProjectManagerRejectedComments = checkRequest.ProjectManagerRejectedComments;
    }
}

ProjectManager.propTypes = {
    ProjectManagerApprovedBy: PropTypes.string,
    ProjectManagerApprovedDate: PropTypes.datetimes,
    ProjectManagerRejectedBy: PropTypes.string,
    ProjectManagerRejectedDate: PropTypes.datetimes,
    ProjectManagerRejectedComments: PropTypes.string,
};
