import BaseCommentService from './BaseCommentService';
import ApplicationCommentType from './Models/ApplicationCommentType';
import CommentType from './Models/CommentType';

export default class CommentTypeService extends BaseCommentService {
    async postCommentType(body) {
        return this.post('/comment-types', body).then((commentType) => CommentType.load(commentType));
    }

    async getCommentTypeOptions() {
        return this.fetch('/comment-types').then(
            (types) => types.map((type) => CommentType.load(type))
        );
    }

    async getApplicationCommentTypes(applicationId) {
        return this.fetch(`/comment-types/application/${applicationId}`).then(
            (types) => types.map((type) => ApplicationCommentType.load(type))
        );
    }

    async updateApplicationCommentType(request) {
        const { data } = request;
        return this.put('/comment-types/application', data.loadSqlEntity()).then(
            (applicationCommentType) => ApplicationCommentType.load(applicationCommentType)
        );
    }

    async postApplicationCommentType(request) {
        const { data } = request;
        return this.post('/comment-types/application', data).then(
            (applicationCommentType) => ApplicationCommentType.load(applicationCommentType)
        );
    }

    async deleteApplicationCommentType(body) {
        return this.delete('/comment-types/application', body);
    }

    async getFavoriteCommentTypes(applicationId) {
        return this.fetch(`/favorite-comment-types/application/${applicationId}`);
    }

    async postFavoriteCommentType(body) {
        return this.post('/favorite-comment-types', body);
    }

    async deleteFavoriteCommentType(favoriteId) {
        return this.delete(`/favorite-comment-types/${favoriteId}`);
    }
}
