import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useAutopopulationForm from './useAutopopulationForm';
import SelectDatabase from '../../../utilComponents/SelectDatabase';
import SelectTable from '../../../utilComponents/SelectTable';
import SelectField from '../../../utilComponents/SelectField';
import { Autopopulation } from '../../../_services/GeotrakService/Models';
import { Create, Update, Delete } from './Actions';
import './autopopulations.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminAutopopulationsForm = ({ match }) => {
    const { params: { id } } = match;
    const {
        values, isLoading, onFormUpdate, isValid,
    } = useAutopopulationForm(id || null);

    if (isLoading) {
        return <LoadingFullScreen id="autopopulation-form-loader" />;
    }

    const autopopulation = new Autopopulation();
    autopopulation.id = values.id;
    autopopulation.field = values.field;
    autopopulation.value = values.value;
    autopopulation.condition = values.condition;
    autopopulation.database = values.database;
    autopopulation.table = values.table;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Autopopulation">
                <form>
                    <SelectDatabase
                        selectedDatabase={values.database}
                        onDatabaseSelected={(e) => onFormUpdate('database', e)}
                    />
                    <SelectTable
                        selectedDatabase={values.database}
                        selectedTable={values.table}
                        onTableSelected={(e) => onFormUpdate('table', e)}
                    />
                    <SelectField
                        database={values.database}
                        table={values.table}
                        selectedField={values.field}
                        onFieldSelected={(e) => onFormUpdate('field', e)}
                    />
                    <div className="mb-3">
                        <label htmlFor="input-autopopulation-condition" className="form-label">
                            Condition
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-autopopulation-condition"
                            value={values.condition}
                            onChange={(e) => onFormUpdate('condition', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-autopopulation-value" className="form-label">
                            Value
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-autopopulation-value"
                            value={values.value}
                            onChange={(e) => onFormUpdate('value', e.target.value)}
                        />
                    </div>
                </form>
                <div className="autopopulation-toolbar">
                    <Create autopopulation={autopopulation} isValid={isValid} />
                    <Update autopopulation={autopopulation} isValid={isValid} />
                    <Delete autopopulation={autopopulation} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminAutopopulationsForm;
