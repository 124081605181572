import PropTypes from 'prop-types';
import Project from './Project';
import Unit from './Unit';
import Market from './Market';

export default class Module {
    constructor() {
        this.id = null;
        this.name = null;
        this.alias = null;
        this.project = new Project();
        this.unit = new Unit();
        this.market = new Market();
    }

    static load(response) {
        const module = new Module();

        if (response) {
            module.id = response.id;
            module.name = response.name;
            module.alias = response.alias;
            module.project = Project.load(response.project);
            module.unit = Unit.load(response.unit);
            module.market = Market.load(response.market);
        }

        return module;
    }

    validate() {
        if (!this.project.id) {
            throw Error('projectId is required');
        }
        if (!this.unit.id) {
            throw Error('unitId is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            projectId: this.project.id,
            unitTypeId: this.unit.id,
            name: this.name,
            alias: this.alias,
            marketId: this.market.id,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

Module.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    alias: PropTypes.string,
    project: PropTypes.instanceOf(Project),
    unit: PropTypes.instanceOf(Unit),
    market: PropTypes.instanceOf(Market),
};
