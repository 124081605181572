import React from 'react';
import { Link } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import './views.css';

const success = () => (
    <div className="col-md-12 text-center view-form-success-container">
        <BsCheckCircle size="100" className="view-form-valid" />
        <h4>Success!  Your view was saved.</h4>
        <h5>Have more?</h5>
        <ButtonContainer className="view-form-success-button-container">
            <ButtonPrimary>
                <Link to="/admin/views" className="view-form-success-button">
                    Yes
                </Link>
            </ButtonPrimary>
            <ButtonPrimary>
                <Link to="/admin/home" className="view-form-success-button">
                    Nope
                </Link>
            </ButtonPrimary>
        </ButtonContainer>
    </div>
);
export default success;
