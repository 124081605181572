import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Alert } from 'react-bs-notifier';
import generateInputTag from '../../helpers/inputTagFactory';
import RequesterDetails from './RequesterDetails';
import { ButtonPrimary } from '../../utilComponents/styledComponents';
import { CheckRequestConfigService, UserService } from '../../_services';

export default class InitialRequestForm extends Component {
    constructor(props) {
        super(props);
        const { selectedProject, selectedRecordId, selectedMarket } = this.props;
        this.state = {
            selectedProject,
            selectedMarket,
            selectedRecordId,
            projects: [],
            requestTypes: [],
            markets: [],
            selectedRequestType: null,
            initialRequestFormError: null,
        };
        this.CheckRequestConfigService = new CheckRequestConfigService();
        this.UserService = new UserService();
    }

  componentDidMount = () => {
      const { selectedProject } = this.state;
      let project;
      const projects = this.UserService.getCheckRequestProjects();
      // format project appropriately if coming from URL parameters
      if (projects.length === 1) {
          [project] = projects;
      } else {
          project = selectedProject
              ? projects.filter((p) => p.project === selectedProject.project)[0] : selectedProject;
      }
      if (project) {
          this.getRequestTypes(project.project);
          this.getDefaultMarket(project);
      }
      this.setState({ projects, selectedProject: project });
  };

  getDefaultMarket = (project) => {
      if (project === null || project.markets === null) {
          return;
      }
      const { selectedMarket } = this.state;

      let defaultMarket = selectedMarket;
      if (project.markets.length === 1) {
          [defaultMarket] = project.markets;
      }
      this.setState({ markets: project.markets, selectedMarket: defaultMarket });
  };

  getRequestTypes = (projectTag) => {
      this.CheckRequestConfigService.getRequestTypes(projectTag)
          .then((requestTypes) => {
              const newRequestType = this.getUpdatedRequestType(requestTypes);
              this.setState({
                  requestTypes,
                  selectedRequestType: newRequestType,
              });
          })
          .catch((err) => {
              this.setState({ initialRequestFormError: err });
          });
  };

  getUpdatedRequestType = (requestTypes) => {
      const { selectedRequestType } = this.state;
      if (!Array.isArray(requestTypes)) {
          return selectedRequestType;
      }

      if (!requestTypes.length) {
          return selectedRequestType;
      }

      if (requestTypes.length === 1) {
          return requestTypes[0].RequestType.Name;
      }

      if (requestTypes.filter((type) => type.RequestType.Name === selectedRequestType).length > 0) {
          return selectedRequestType;
      }

      return null;
  };

  resetFormStates = (selectedProject) => {
      if (selectedProject === null || selectedProject.markets === null) {
          return;
      }
      const isMultiMarketProject = selectedProject.markets.length > 1;
      this.setState({
          selectedProject,
          selectedMarket: !isMultiMarketProject ? selectedProject.markets[0] : null,
          selectedRecordId: null,
          selectedRequestType: null,
          markets: [],
          requestTypes: [],
      }, () => {
          this.setState({
              markets: selectedProject.markets,
          });
      });
  };

  handleProjectSelection = (event) => {
      const { projects } = this.state;
      const selectedProject = projects.filter((proj) => proj.project === event.target.value)[0];
      this.resetFormStates(selectedProject);
      this.getRequestTypes(selectedProject.project);
  };

  handleMarketSelection = (event) => {
      const selectedMarket = event.target.value;
      this.setState({ selectedMarket });
  };

  handleRequestTypeSelection = (event) => {
      const selectedRequestType = event.target.value;
      this.setState({ selectedRequestType });
  };

  handleRecordIdUpdate = (event) => {
      const selectedRecordId = event.target.value;
      this.setState({ selectedRecordId });
  };

  onRequestStart = () => {
      const { handleStartRequest } = this.props;
      const {
          selectedProject,
          selectedMarket,
          selectedRecordId,
          selectedRequestType,
      } = this.state;
      handleStartRequest(
          'start',
          selectedProject.project,
          selectedMarket,
          selectedRecordId,
          selectedRequestType
      );
  };

  projectMarketsRequired = () => {
      const {
          markets,
          selectedMarket,
      } = this.state;
      return !markets.length || (markets.length && selectedMarket);
  };

isFormValid = () => {
    const {
        selectedProject,
        selectedRequestType,
        selectedRecordId,
    } = this.state;
    return !!(selectedProject && this.projectMarketsRequired() && selectedRequestType && selectedRecordId);
};

renderAlert = (err) => (
    <Alert
        id="error"
        type="danger"
        headline="Sorry, an error has occurred:"
        onDismiss={() => { this.setState({ initialRequestFormError: null }); }}
        dismissTitle="close alert"
    >
        {err.message}

    </Alert>
);

render() {
    const {
        projects,
        selectedProject,
        selectedMarket,
        selectedRequestType,
        requestTypes,
        markets,
        selectedRecordId,
        initialRequestFormError,
    } = this.state;

    return (
        <div className="container card rounded-0">
            <h2 className="text-center m-3">Create New Check Request</h2>
            <div className="row">
                <RequesterDetails />
                <div className="col-md-3">
                    {generateInputTag({
                        type: 'select',
                        id: 'project',
                        options: projects.map((value) => value.project),
                        label: 'Project',
                        handler: this.handleProjectSelection,
                        required: true,
                        value: selectedProject ? selectedProject.project : null,
                    })}
                </div>
                {(selectedProject && markets.length > 0)
            && (
                <div className="col-md-3">
                    {generateInputTag({
                        type: 'select',
                        id: 'market',
                        options: [...new Set(markets)],
                        label: 'Market',
                        handler: this.handleMarketSelection,
                        required: true,
                        value: selectedMarket,
                    })}
                </div>
            )}
                {selectedProject
                && (
                    <div className="col-md-3">
                        {generateInputTag({
                            type: 'input',
                            id: 'record',
                            label: 'Record Id',
                            handler: this.handleRecordIdUpdate,
                            required: true,
                            value: selectedRecordId,
                        })}
                    </div>
                )}
                {requestTypes && requestTypes.length > 0
                    && (
                        <div className="col-md-3">
                            {generateInputTag({
                                type: 'select',
                                id: 'type',
                                options: requestTypes.map((value) => value.RequestType.Name),
                                label: 'Request Type',
                                handler: this.handleRequestTypeSelection,
                                required: true,
                                value: selectedRequestType,
                            })}
                        </div>
                    )}
                { initialRequestFormError && this.renderAlert(initialRequestFormError) }
            </div>
            <div className="text-center m-3">
                <ButtonPrimary disabled={!this.isFormValid()} onClick={this.onRequestStart} id="startCheckRequest">
                    Start Request
                </ButtonPrimary>
            </div>
        </div>
    );
}
}

InitialRequestForm.propTypes = {
    handleStartRequest: propTypes.func,
    selectedProject: propTypes.instanceOf(Object),
    selectedRecordId: propTypes.string,
    selectedMarket: propTypes.string,
};

InitialRequestForm.defaultProps = {
    handleStartRequest: () => null,
    selectedProject: { },
    selectedRecordId: '',
    selectedMarket: '',
};
