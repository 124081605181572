import BaseGeoTrakService from './BaseGeoTrakService';
import { Unit } from './Models';

export default class UnitService extends BaseGeoTrakService {
    async getUnits() {
        return this.fetch('/units').then((units) => units.map((unit) => Unit.load(unit)));
    }

    async createUnit(body) {
        return this.post('/units', body).then((unit) => Unit.load(unit));
    }

    async updateUnit(request) {
        return this.put(`/units/${request.id}`, request.data).then((unit) => Unit.load(unit));
    }

    async deleteUnit(id) {
        return this.delete(`/units/${id}`);
    }
}
