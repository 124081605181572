import React from 'react';
import ViewBuilder from './ViewBuilder';
import AdminContainer from '../AdminContainer';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';

const adminViews = () => (
    <div className="admin-views">
        <Breadcrumbs />
        <AdminContainer title="Views">
            <ViewBuilder />
        </AdminContainer>
    </div>
);

export default adminViews;
