import PropTypes from 'prop-types';

export default class FavoriteView {
    constructor() {
        this.id = null;
        this.dataSourceId = null;
        this.viewName = null;
    }

    static load(response) {
        const favoriteView = new FavoriteView();

        if (response) {
            favoriteView.id = response.id;
            favoriteView.dataSourceId = response.dataSourceId;
            favoriteView.viewName = response.viewName;
        }

        return favoriteView;
    }
}

FavoriteView.propTypes = {
    id: PropTypes.string,
    dataSourceId: PropTypes.string,
    viewName: PropTypes.string,
};
