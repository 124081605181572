import PropTypes from 'prop-types';
import { EtlJob } from '../Models';

class ICreateEtlJobRequest {
    constructor() {
        this.moduleRoleId = null;
        this.data = {};
    }

    static load(etlJob) {
        if (!etlJob) {
            throw Error('etl job is required');
        }
        if (!(etlJob instanceof EtlJob)) {
            throw Error('Unexpected type for etl job');
        }

        const request = new ICreateEtlJobRequest();
        request.moduleRoleId = etlJob.moduleRoleId;
        request.data = {
            jobTypeId: etlJob.jobType.id,
        };
        request.validate();
        return request;
    }

    validate() {
        if (!this.moduleRoleId) {
            throw Error('moduleRoleId is required');
        }
    }
}

export default ICreateEtlJobRequest;

ICreateEtlJobRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
    moduleRoleId: PropTypes.string,
};
