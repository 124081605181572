import PropTypes from 'prop-types';
import { Office } from '../Models';

class IUpdateOfficeRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(office) {
        if (!office) {
            throw Error('office is required');
        }
        if (!(office instanceof Office)) {
            throw Error('Unexpected type for office');
        }

        const request = new IUpdateOfficeRequest();
        request.id = office.id;
        request.data = office;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateOfficeRequest;

IUpdateOfficeRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(Office),
};
