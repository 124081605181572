import { useEffect, useReducer } from 'react';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';
import WidgetService from '../WidgetService';

const useGetDataSourceWidgets = (dataSourceId) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: [] });
    const { data, isLoading, error } = state;

    const getDataSourceWidgets = async () => {
        try {
            const results = await new WidgetService().getDataSourceWidgets(dataSourceId);
            dispatch({ type: ACTIONS.SUCCESS, data: results });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getDataSourceWidgets();
    }, [dataSourceId]);

    return { dataSourceWidgets: data, error, isLoading };
};

export default useGetDataSourceWidgets;
