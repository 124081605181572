import { useEffect, useReducer } from 'react';
import EtlJob from '../Models/EtlJob';
import EtlService from '../EtlService';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';

const useGetEtlJob = (id) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new EtlJob() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const result = await new EtlService().getEtlJob(id);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, [id]);

    return { etlJob: data, error, isLoading };
};

export default useGetEtlJob;
