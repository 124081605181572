import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import InProgress from '../../components/CheckRequest/InProgress';
import NeedsReview from '../../components/CheckRequest/NeedsReview';
import APDailySummary from '../../components/CheckRequest/APDailySummary';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';
import ErrorMessage from '../../utilComponents/ErrorMessage';

const manageCheckRequests = () => {
    const { hash } = useLocation();
    const history = useHistory();
    const [tableWidth, setTableWidth] = useState(window.innerWidth);
    const ref = useRef(null);
    const [error, setError] = useState(null);

    if (!hash) {
        history.push('/service-center/finance/manage-check-requests/#needs-review');
    }

    useEffect(() => {
        setTableWidth(ref.current ? ref.current.offsetWidth : window.innerWidth);
    }, [ref.current]);

    return (
        <>
            <div className="bg-light container-fluid">
                <Breadcrumbs />
                <div className="container-fluid">
                    <div className="float-end mt-3">
                        <APDailySummary
                            onError={setError}
                        />
                    </div>
                    <ul id="check-request-manage-nav" role="tablist" className="nav nav-tabs">
                        <li role="presentation" className="nav-item">
                            <a
                                className={`nav-link ${(hash === '#in-progress' ? 'active' : '')}`}
                                role="tab"
                                aria-controls="in-progress"
                                data-toggle="tab"
                                href="/service-center/finance/manage-check-requests#in-progress"
                            >
                                <h1>In Progress</h1>
                            </a>
                        </li>
                        <li role="presentation" className="nav-item">
                            <a
                                className={`nav-link ${(hash === '#needs-review' ? 'active' : '')}`}
                                role="tab"
                                aria-controls="needs-review"
                                data-toggle="tab"
                                href="/service-center/finance/manage-check-requests#needs-review"
                            >
                                <h1>Needs Review</h1>
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" ref={ref}>
                        <div
                            role="tabpanel"
                            className={`tab-pane ${hash === '#needs-review' ? 'active' : ''}`}
                            id="needs-review"
                        >
                            {error && (
                                <ErrorMessage
                                    message={error}
                                    onDismiss={() => setError(null)}
                                />
                            )}
                            <NeedsReview tableWidth={tableWidth} />
                        </div>
                        <div
                            role="tabpanel"
                            className={`tab-pane ${hash === '#in-progress' ? 'active' : ''}`}
                            id="in-progress"
                        >
                            {error && (
                                <ErrorMessage
                                    message={error}
                                    onDismiss={() => setError(null)}
                                />
                            )}
                            <InProgress tableWidth={tableWidth} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default manageCheckRequests;
