import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetDataSources from '../../../_services/GeotrakService/hooks/useGetDataSources';
import './dataSources.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDataSources = ({ match }) => {
    const history = useHistory();
    const { params: { roleId, moduleId } } = match;
    const { dataSources, isLoading } = useGetDataSources(roleId);

    if (isLoading) {
        return <LoadingFullScreen id="data-source-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Data Sources">
                <ButtonContainer>
                    <ButtonPrimary
                        id="new-data-source-button"
                        onClick={() => history.push(
                            `/admin/modules/${moduleId}/roles/${roleId}/data-sources/new`
                        )}
                    >
                        New Data Source
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group">
                    {dataSources.map((dataSource) => (
                        <button
                            id={dataSource.name}
                            type="button"
                            key={dataSource.id}
                            className="list-group-item list-group-item-action text-start"
                            onClick={() => history.push(
                                `/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSource.id}`
                            )}
                        >
                            <span id="data-source-name">{dataSource.alias}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDataSources;
