import PropTypes from 'prop-types';

class ICreateDatabaseRequest {
    constructor() {
        this.data = {};
    }

    static load(database) {
        if (!database) {
            throw Error('database is required');
        }

        const request = new ICreateDatabaseRequest();
        request.data.database = database;
        return request;
    }
}

export default ICreateDatabaseRequest;

ICreateDatabaseRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
