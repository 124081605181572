import React from 'react';
import { withRouter } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';
import { ButtonPrimary } from '../../utilComponents/styledComponents';
import { HistoryType } from '../../types';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';
import '../stylesheets/ConfirmationPage.css';

const confirmationPage = (props) => {
    const { history } = props;
    return (
        <div className="confirmation-page">
            <Breadcrumbs />
            <div className="d-flex align-items-center flex-column body">
                <div className="d-flex align-items-center icon-container">
                    <p className="success-icon">
                        <MdCheckCircle size={100} />
                    </p>
                </div>
                <p className="message">Thank You!</p>
                <p className="message">Your Check Request is in review.</p>
                <p className="message">You will be notified upon approval.</p>
            </div>
            <div className="d-flex justify-content-evenly footer">
                <a href="/service-center/finance/manage-check-requests#needs-review">Manage Check Requests</a>
                <ButtonPrimary
                    onClick={() => history.push('/service-center/finance/submit-check-request')}
                >
                    Start New Check Request
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default withRouter(confirmationPage);

confirmationPage.propTypes = {
    history: HistoryType.isRequired,
};
