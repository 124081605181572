import PropTypes from 'prop-types';
import PrimaryKey from '../../../helpers/models/PrimaryKey';
import IUploadFileRequest from './IUploadFileRequest';

class IUploadCheckRequestFileRequest extends IUploadFileRequest {
    constructor() {
        super();
        this.databaseName = 'CheckRequest';
        this.tableName = 'CheckRequest';
        this.primaryKey = new PrimaryKey('CheckRequestId');
        this.file = null;
        this.documentType = 'Check Request';
        this.folder = null;
    }

    load(checkRequestId, projectId, file) {
        if (!checkRequestId) {
            throw Error('check request id is required');
        }
        if (!file) {
            throw Error('file is required');
        }

        this.primaryKey.value = projectId;
        this.file = file;
        this.folder = checkRequestId;
    }
}

export default IUploadCheckRequestFileRequest;

IUploadCheckRequestFileRequest.propTypes = {
    databaseName: PropTypes.string,
    tableName: PropTypes.string,
    primaryKey: PropTypes.instanceOf(PrimaryKey),
    file: PropTypes.instanceOf(Object),
    documentType: PropTypes.string,
};
