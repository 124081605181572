import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/ErrorMessage.css';

const errorMessage = ({ onDismiss, message }) => (
    <div className="error-message alert alert-danger alert-dismissable d-flex align-items-center justify-content-between" role="alert">
        <span>
            <strong>Error:</strong>
            {' '}
            <span>
                {message || 'An error occurred.'}
            </span>
        </span>
        <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onDismiss}
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
);

export default errorMessage;

errorMessage.propTypes = {
    message: PropTypes.string,
    onDismiss: PropTypes.func,
};

errorMessage.defaultProps = {
    message: '',
    onDismiss: () => null,
};
