import React, { Component } from 'react';
import { CheckRequestConfigService, UserService } from '../../_services';

export default class RequesterDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requester: {},
        };

        this.CheckRequestConfigService = new CheckRequestConfigService();
        this.userService = new UserService();
    }

  componentDidMount = () => {
      this.CheckRequestConfigService.getRequestAdmin().then((requester) => {
          this.setState({ requester });
      });
  }

  render() {
      const user = this.userService.getCurrentUser();
      const { requester } = this.state;
      return (
          <div className="col-md-12 d-flex flex-wrap justify-content-center">
              <div className="container">
                  <div className="card bg-light rounded-0 mb-2">
                      <h4 className="text-center m-2"><strong>Your Details</strong></h4>
                      <div className="card-body d-flex flex-row justify-content-evenly">
                          <div>
                              <h6><strong>Requested By:</strong></h6>
                              <p>{user.FullName}</p>
                          </div>
                          <div>
                              <h6><strong>Requester Email:</strong></h6>
                              <p>{user.emailAddress}</p>
                          </div>
                          <div>
                              <h6><strong>Requester Phone:</strong></h6>
                              <p>{user.phoneNumber}</p>
                          </div>
                          <div>
                              <h6><strong>Return to Requester:</strong></h6>
                              <p>{requester.Email}</p>
                          </div>
                          <div>
                              <h6><strong>Return to Location:</strong></h6>
                              <p>{requester.Location}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      );
  }
}
