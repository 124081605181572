import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { UserService } from '../../../../_services';
import '../users.css';

const createButton = ({ user, isValid }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userService = new UserService();
    const history = useHistory();

    const createUser = async () => {
        try {
            setIsLoading(true);
            await userService.createUser(user);
            setIsLoading(false);
            setError(false);
            history.push('/admin/users');
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    if (user.Id) {
        return null;
    }

    return (
        <div className="user-toolbar user-button">
            {error && <div className="text-danger user-error" id="create-user-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-user-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={createUser}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    user: PropTypes.instanceOf(Object),
    isValid: PropTypes.func,
};

createButton.defaultProps = {
    user: {},
    isValid: () => null,
};
