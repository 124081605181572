import React from 'react';
import PropTypes from 'prop-types';
import '../stylesheets/ButtonCharcoal.css';

export const ButtonCharcoal = ({ onClick, children }) => (
    <button
        type="button"
        className="btn btn-default text-white charcoal-button"
        onClick={onClick}
    >
        {children}
    </button>
);

ButtonCharcoal.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

ButtonCharcoal.defaultProps = {
    onClick: () => null,
    children: null,
};

export default ButtonCharcoal;
