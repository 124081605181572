import PropTypes from 'prop-types';

class IGetSchemasRequest {
    constructor() {
        this.database = null;
    }

    static load(database) {
        if (!database) {
            throw Error('database is required');
        }

        const request = new IGetSchemasRequest();
        request.database = database;
        return request;
    }
}

export default IGetSchemasRequest;

IGetSchemasRequest.propTypes = {
    database: PropTypes.string,
};
