import PropTypes from 'prop-types';

export default class Admin {
    constructor(email, location, id) {
        this.email = email;
        this.location = location;
        this.id = id;
    }

    static load(response) {
        return new Admin(
            response.Email,
            response.Location,
            response.Id
        );
    }

    validate() {
        if (!this.email || this.email === '') {
            throw Error('Email is required');
        }

        if (!this.location || this.location === '') {
            throw Error('Location is required');
        }

        if (!this.id || this.id === '') {
            throw Error('Id is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        return {
            Email: this.email,
            Location: this.location,
            Id: this.id,
        };
    }
}

Admin.propTypes = {
    email: PropTypes.string,
    location: PropTypes.string,
    id: PropTypes.number,
};
