import React from 'react';
import './stylesheets/LoadingFullScreen.css';

const loadingFullScreen = () => (
    <div className="loading-full-screen">
        <div className="spinner-border full-screen-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>

    </div>
);

export default loadingFullScreen;
