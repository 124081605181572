/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import propTypes from 'prop-types';
import InputPrimary from '../utilComponents/InputPrimary';
import InputCurrency from '../utilComponents/InputCurrency';
import SelectPrimary from '../components/SelectPrimary';

const generateInputTag = ({
    type,
    id,
    label = '',
    placeholder = '',
    required = false,
    handler = () => {},
    isReadOnly = false,
    ...props
}) => {
    const { options } = props;
    if (!type || !id) {
        throw new Error('Input tag requires type and id');
    }

    switch (type) {
    case 'currency':
        return (
            <InputCurrency
                id={id}
                key={id}
                label={label}
                handleChange={handler}
                required={required}
                {...props}
            />
        );
    case 'select':
        if (!options) {
            throw new Error('Select tag requires options');
        }
        return (
            <SelectPrimary
                id={id}
                key={id}
                label={label}
                placeholder={placeholder || `--Select a ${label}--`}
                handleChange={handler}
                required={required}
                {...props}
            />
        );
    case 'date':
        return (
            <InputPrimary
                id={id}
                key={id}
                label={label}
                type="date"
                handleChange={handler}
                required={required}
                {...props}
            />
        );
    case 'text':
    default:
        return (
            <InputPrimary
                id={id}
                key={id}
                label={label}
                type="text"
                handleChange={handler}
                required={required}
                isReadOnly={isReadOnly}
                {...props}
            />
        );
    }
};

generateInputTag.propTypes = {
    handler: propTypes.func,
    id: propTypes.string,
    label: propTypes.string,
    placeholder: propTypes.string,
    options: propTypes.instanceOf(Array),
    required: propTypes.bool,
    type: propTypes.string,
};

export default generateInputTag;
