import { useReducer } from 'react';
import WidgetService from '../WidgetService';
import ICreateModuleWidgetRequest from '../interfaces/ICreateModuleWidgetRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useCreateModuleWidget = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const createModuleWidget = async (moduleWidgetObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = ICreateModuleWidgetRequest.load(moduleWidgetObject);
            const result = await new WidgetService().createModuleWidget(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        createModuleWidget, error, isLoading, isSuccessful, data,
    };
};

export default useCreateModuleWidget;
