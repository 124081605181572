import { Edit } from './Actions';

const columns = [
    {
        key: 'id',
        name: 'Id',
        editable: false,
        width: 350,
        resizable: true,
        filterable: true,
    },
    {
        key: 'taskType',
        name: 'Task Type',
        editable: false,
        width: 150,
        resizable: true,
        filterable: true,
    },
    {
        key: 'database',
        name: 'Database',
        editable: false,
        width: 150,
        filterable: true,
        resizable: true,
    },
    {
        key: 'schema',
        name: 'Schema',
        editable: false,
        width: 100,
        filterable: true,
        resizable: true,
    },
    {
        key: 'table',
        name: 'Table',
        editable: false,
        width: 100,
        filterable: true,
        resizable: true,
    },
    {
        key: 'primaryKeyColumn',
        name: 'Field Name',
        editable: false,
        width: 120,
        resizable: true,
    },
    {
        key: 'createdBy',
        name: 'Created By',
        editable: false,
        width: 150,
        filterable: true,
        resizable: true,
    },
    {
        key: 'Edit',
        name: 'Edit',
        editable: false,
        width: 120,
        resizable: true,
        formatter: Edit,
    },
];

export default columns;
