import PropTypes from 'prop-types';
import { Notification } from '../Models';

class IUpdateNotificationRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(notification) {
        if (!notification) {
            throw Error('notification is required');
        }
        if (!(notification instanceof Notification)) {
            throw Error('Unexpected type for notification');
        }

        const request = new IUpdateNotificationRequest();
        request.id = notification.id;
        request.data = notification;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateNotificationRequest;

IUpdateNotificationRequest.propTypes = {
    id: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
