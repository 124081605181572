import PropTypes from 'prop-types';
import { Autopopulation } from '../Models';

class ICreateAutopopulationRequest {
    constructor() {
        this.data = {};
    }

    static load(autopopulation) {
        if (!autopopulation) {
            throw Error('autopopulation is required');
        }
        if (!(autopopulation instanceof Autopopulation)) {
            throw Error('Unexpected type for autopopulation');
        }

        const request = new ICreateAutopopulationRequest();
        request.data = autopopulation;
        return request;
    }
}

export default ICreateAutopopulationRequest;

ICreateAutopopulationRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
