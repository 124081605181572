import React from 'react';
import { useHistory } from 'react-router';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useDataSourceForm from './useDataSourceForm';
import SelectDatabase from '../../../utilComponents/SelectDatabase';
import SelectTable from '../../../utilComponents/SelectTable';
import SelectField from '../../../utilComponents/SelectField';
import SelectSchema from '../../../utilComponents/SelectSchema';
import { Create, Update, Delete } from './Actions';
import './dataSources.css';
import { DataSource } from '../../../_services/GeotrakService/Models';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDataSourcesForm = ({ match }) => {
    const { params: { dataSourceId, roleId, moduleId } } = match;
    const {
        values, isLoading, onFormUpdate, isValid, role,
    } = useDataSourceForm(dataSourceId || null, roleId);
    const history = useHistory();

    if (isLoading) {
        return <LoadingFullScreen id="data-source-form-loader" />;
    }

    const dataSource = new DataSource();
    dataSource.id = values.id;
    dataSource.moduleRoleId = roleId;
    dataSource.name = values.name;
    dataSource.alias = values.alias;
    dataSource.app = role.module ? role.module.name : null;
    dataSource.role = role.name;
    dataSource.url = values.url || null;
    dataSource.isInitialDataSource = values.isInitialDataSource;
    dataSource.uniqueIdField = values.uniqueIdField;
    dataSource.searchFieldName = values.searchFieldName;
    dataSource.searchFieldAlias = values.searchFieldAlias;
    dataSource.isMapDefaultOn = values.isMapDefaultOn;
    dataSource.dataType = values.dataType;
    dataSource.isSecure = values.isSecure;
    dataSource.category = values.category;
    dataSource.serviceType = values.serviceType || null;
    dataSource.databaseReference = values.databaseReference;
    dataSource.tableReference = values.tableReference;
    dataSource.schemaReference = values.schemaReference;
    dataSource.isHiddenOnMap = values.isHiddenOnMap;
    dataSource.isHiddenOnData = values.isHiddenOnData;
    dataSource.query = values.query || null;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Data Source">
                {dataSource.id && (
                    <div className="data-source-option-toolbar">
                        <ButtonPrimary
                            id="configure-widgets-button"
                            className="data-source-option-button"
                            onClick={() => history.push(`/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSource.id}/widgets`)}
                        >
                            Configure Widgets
                        </ButtonPrimary>
                        <ButtonPrimary
                            id="configure-renderers-button"
                            className="data-source-option-button"
                            onClick={() => history.push(`/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSource.id}/renderer`)}
                        >
                            Configure Renderers
                        </ButtonPrimary>
                        <ButtonPrimary
                            id="configure-document-types-button"
                            className="data-source-option-button"
                            onClick={() => history.push(`/admin/modules/${moduleId}/roles/${roleId}/data-sources/${dataSource.id}/document-types`)}
                        >
                            Configure Document Types
                        </ButtonPrimary>
                    </div>
                )}
                <form>
                    <div className="mb-3">
                        <label htmlFor="input-name" className="form-label">
                            Name
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-alias" className="form-label">
                            Alias
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-alias"
                            value={values.alias}
                            onChange={(e) => onFormUpdate('alias', e.target.value)}
                        />
                    </div>
                    <SelectDatabase
                        selectedDatabase={values.databaseReference}
                        onDatabaseSelected={(e) => onFormUpdate('databaseReference', e)}
                    />
                    <SelectSchema
                        selectedDatabase={values.databaseReference}
                        selectedSchema={values.schemaReference}
                        onSchemaSelected={(e) => onFormUpdate('schemaReference', e)}
                    />
                    <SelectTable
                        selectedDatabase={values.databaseReference}
                        selectedSchema={values.schemaReference}
                        selectedTable={values.tableReference}
                        onTableSelected={(e) => onFormUpdate('tableReference', e)}
                    />
                    <SelectField
                        database={values.databaseReference}
                        table={values.tableReference}
                        label="Search Field Name"
                        selectedField={values.searchFieldName}
                        onFieldSelected={(e) => onFormUpdate('searchFieldName', e)}
                    />
                    <div className="mb-3">
                        <label htmlFor="input-search-field-alias" className="form-label">
                            Search Field Alias
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="input-search-field-alias"
                            value={values.searchFieldAlias}
                            onChange={(e) => onFormUpdate('searchFieldAlias', e.target.value)}
                        />
                    </div>
                    <SelectField
                        database={values.databaseReference}
                        table={values.tableReference}
                        label="Primary Key Field Name (Unique ID Field)"
                        selectedField={values.uniqueIdField}
                        onFieldSelected={(e) => onFormUpdate('uniqueIdField', e)}
                    />
                    <div className="mb-3">
                        <label htmlFor="select-category" className="form-label">
                            Category
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-category"
                            value={values.category}
                            onChange={(e) => onFormUpdate('category', e.target.value)}
                        >
                            <option disabled value="">--Select a category--</option>
                            <option value="Design Data">Design Data</option>
                            <option value="Permitting Data">Permitting Data</option>
                            <option value="Weather Data">Weather Data</option>
                            <option value="Crew Data">Crew Data</option>
                            <option value="Project Data">Project Data</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="select-data-type" className="form-label">
                            Data Type
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-data-type"
                            value={values.dataType}
                            onChange={(e) => onFormUpdate('dataType', e.target.value)}
                        >
                            <option disabled value="">--Select a data type--</option>
                            <option value="table">Tabular</option>
                            <option value="point">Point</option>
                            <option value="line">Line</option>
                            <option value="polygon">Polygon</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="input-query" className="form-label">
                            Query
                        </label>
                        <textarea
                            id="input-query"
                            value={values.query}
                            rows={4}
                            onChange={(e) => onFormUpdate('query', e.target.value)}
                        />
                    </div>
                    <div className="form-check mb-3">
                        <label htmlFor="check-map-default" className="form-check-label">
                            This data source should be turned on on the map by default (map layers only)
                            <input
                                type="checkbox"
                                id="check-map-default"
                                className="form-check-input"
                                checked={values.isMapDefaultOn}
                                onChange={(e) => onFormUpdate('isMapDefaultOn', e.target.checked)}
                            />
                        </label>
                    </div>
                    <div className="form-check mb-3">
                        <label htmlFor="check-default" className="form-check-label">
                            This data source should be the default table tab on load
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="check-default"
                                checked={values.isInitialDataSource}
                                onChange={(e) => onFormUpdate('isInitialDataSource', e.target.checked)}
                            />
                        </label>
                    </div>
                    <div className="form-check mb-3">
                        <label htmlFor="check-map-hidden" className="form-check-label">
                            This data source should be hidden on the map
                            <input
                                type="checkbox"
                                id="check-map-hidden"
                                className="form-check-input"
                                checked={values.isHiddenOnMap}
                                onChange={(e) => onFormUpdate('isHiddenOnMap', e.target.checked)}
                            />
                        </label>
                    </div>
                    <div className="form-check mb-3">
                        <label htmlFor="check-data-hidden" className="form-check-label">
                            This data source should be hidden in the grid
                            <input
                                type="checkbox"
                                id="check-data-hidden"
                                className="form-check-input"
                                checked={values.isHiddenOnData}
                                onChange={(e) => onFormUpdate('isHiddenOnData', e.target.checked)}
                            />
                        </label>
                    </div>
                    <h3 className="text-center">Service Details</h3>
                    <h6>
                        <em>
                            Fill out this section only if this data source is dependent on a 3-GIS or Esri map layer.
                        </em>
                    </h6>
                    <div className="mb-3">
                        <label htmlFor="input-url" className="form-label">
                            Feature/Map Service URL
                        </label>
                        <input
                            type="text"
                            id="input-url"
                            value={values.url}
                            onChange={(e) => onFormUpdate('url', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="select-service-type" className="form-label">
                            Service Type
                        </label>
                        <select
                            id="select-service-type"
                            value={values.serviceType}
                            onChange={(e) => onFormUpdate('serviceType', e.target.value)}
                        >
                            <option disabled value="">--Select a service type--</option>
                            <option value="map">map</option>
                            <option value="feature">feature</option>
                        </select>
                    </div>
                    <div className="form-check mb-3">
                        <label htmlFor="check-secure" className="form-check-label">
                            Requires a 3-GIS or Esri token to access
                            <input
                                type="checkbox"
                                id="check-secure"
                                className="form-check-input"
                                checked={values.isSecure}
                                onChange={(e) => onFormUpdate('isSecure', e.target.checked)}
                            />
                        </label>
                    </div>
                </form>
                <div className="data-source-toolbar">
                    <Create dataSource={dataSource} isValid={isValid} />
                    <Update dataSource={dataSource} isValid={isValid} moduleId={moduleId} />
                    <Delete dataSource={dataSource} moduleId={moduleId} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDataSourcesForm;
