import React from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'react-datetime/css/react-datetime.css';

class DateEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: this.setDefaultValue(),
        };
    }

  getInputNode = () => this.node;

  getValue = () => {
      const { column } = this.props;
      const { date } = this.state;
      const updated = {};
      updated[column.key] = date;
      return updated;
  };

  handleDate = (input) => {
      const date = (typeof input === 'object') ? input.toDate() : input;
      this.setState({ date });
  };

  setDefaultValue = () => {
      const { value } = this.props;

      const date = moment(value).isValid() && moment(value).toDate();
      return date || value;
  };

  setNode = (node) => {
      this.node = node;
  };

  render() {
      const { date } = this.state;
      return (
          <div
              ref={this.setNode}
          >
              <div tabIndex="-1">
                  <Datetime
                      id="date-editor-test"
                      timeFormat={false}
                      dateFormat="MM/DD/YYYY"
                      inputProps={{ autoFocus: true }}
                      onChange={this.handleDate}
                      value={date}
                  />
              </div>
          </div>
      );
  }
}

export default DateEditor;

DateEditor.propTypes = {
    column: PropTypes.instanceOf(Object),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateEditor.defaultProps = {
    column: {},
    value: '',
};
