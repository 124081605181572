import React, { useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../../utilComponents/LoaderButton';
import { ButtonContainer } from '../../../../../utilComponents/styledComponents';
import { DatabaseService } from '../../../../../_services/GeotrakService';
import ICreateTableRequest from '../../../../../_services/GeotrakService/interfaces/ICreateTableRequest';

const createButton = ({
    table, isSpatial, disabled, setIsTableCreated,
}) => {
    const [isWorking, setIsWorking] = useState(false);
    const [error, setError] = useState(null);
    const params = useParams();
    const { database } = params;

    const databaseService = new DatabaseService();

    const createNewTable = async () => {
        setIsWorking(true);
        try {
            const request = ICreateTableRequest.load(
                database,
                table,
                isSpatial
            );

            await databaseService.createTable(request);

            setIsWorking(false);
            setIsTableCreated(true);
        } catch (err) {
            setError(`Error creating the table. ${err.message || err}`);
            setIsWorking(false);
        }
    };

    return (
        <>
            <ButtonContainer
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <LoaderButton
                    type="submit"
                    disabled={(disabled || error)}
                    isLoading={isWorking}
                    onClick={createNewTable}
                    text="Create Table"
                    loadingText="Submitting..."
                />
            </ButtonContainer>
        </>
    );
};

export default createButton;

createButton.propTypes = {
    fields: PropTypes.instanceOf(Array),
    table: PropTypes.string,
    disabled: PropTypes.bool,
    isSpatial: PropTypes.bool,
};

createButton.defaultProps = {
    fields: [],
    table: '',
    disabled: true,
    isSpatial: false,
};
