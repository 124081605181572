import PropTypes from 'prop-types';

export default class DataSource {
    constructor() {
        this.id = null;
        this.moduleRoleId = null;
        this.name = null;
        this.alias = null;
        this.app = null;
        this.role = null;
        this.url = null;
        this.isInitialDataSource = false;
        this.uniqueIdField = null;
        this.searchFieldName = null;
        this.searchFieldAlias = null;
        this.isMapDefaultOn = false;
        this.dataType = null;
        this.isSecure = false;
        this.category = null;
        this.serviceType = null;
        this.databaseReference = null;
        this.schemaReference = null;
        this.tableReference = null;
        this.isHiddenOnMap = false;
        this.isHiddenOnData = false;
        this.query = null;
    }

    static load(response) {
        const dataSource = new DataSource();

        if (response) {
            dataSource.id = response.id;
            dataSource.moduleRoleId = response.moduleRoleId;
            dataSource.name = response.LayerID;
            dataSource.alias = response.TableOfContents;
            dataSource.role = response.Role;
            dataSource.app = response.App;
            dataSource.url = response.URL;
            dataSource.isInitialDataSource = response.IsInitialDataSource;
            dataSource.uniqueIdField = response.UniqueID;
            dataSource.searchFieldName = response.SearchFieldName;
            dataSource.searchFieldAlias = response.SearchFieldAlias;
            dataSource.isMapDefaultOn = response.IsMapDefault;
            dataSource.dataType = response.DataType;
            dataSource.isSecure = response.IsSecure;
            dataSource.category = response.Category;
            dataSource.serviceType = response.ServiceType;
            dataSource.databaseReference = response.databaseReference;
            dataSource.schemaReference = response.schemaReference;
            dataSource.tableReference = response.tableReference;
            dataSource.isHiddenOnMap = response.IsHiddenOnMap;
            dataSource.isHiddenOnData = response.IsHiddenOnData;
            dataSource.query = response.Query;
        }

        return dataSource;
    }

    loadSqlEntity() {
        const sqlEntity = {
            moduleRoleId: this.moduleRoleId,
            LayerID: this.name,
            TableOfContents: this.alias,
            Role: this.role,
            App: this.app,
            URL: this.url,
            IsInitialDataSource: this.isInitialDataSource,
            UniqueID: this.uniqueIdField,
            SearchFieldName: this.searchFieldName,
            SearchFieldAlias: this.searchFieldAlias,
            IsMapDefault: this.isMapDefaultOn,
            DataType: this.dataType,
            IsSecure: this.isSecure,
            Category: this.category,
            ServiceType: this.serviceType,
            databaseReference: this.databaseReference,
            schemaReference: this.schemaReference,
            tableReference: this.tableReference,
            IsHiddenOnMap: this.isHiddenOnMap,
            IsHiddenOnData: this.isHiddenOnData,
            Query: this.query,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

DataSource.propTypes = {
    id: PropTypes.string,
    moduleRoleId: PropTypes.string,
    name: PropTypes.string,
    alias: PropTypes.string,
    role: PropTypes.string,
    app: PropTypes.string,
    url: PropTypes.string,
    isInitialDataSource: PropTypes.bool,
    uniqueIdField: PropTypes.string,
    searchFieldName: PropTypes.string,
    searchFieldAlias: PropTypes.string,
    isMapDefaultOn: PropTypes.bool,
    dataType: PropTypes.string,
    isSecure: PropTypes.bool,
    category: PropTypes.string,
    serviceType: PropTypes.string,
    databaseReference: PropTypes.string,
    schemaReference: PropTypes.string,
    tableReference: PropTypes.string,
    isHiddenOnMap: PropTypes.bool,
    isHiddenOnData: PropTypes.bool,
    query: PropTypes.string,
};
