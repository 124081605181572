import React, { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Data, Toolbar } from 'react-data-grid-addons';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetGeocoderJobs from '../../../_services/EtlService/hooks/useGetGeocoderJobs';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import './etljobs.css';

import columns from './config';

const adminETLGeocoderJobs = () => {
    const selectors = Data.Selectors;
    const [filters, setFilters] = useState({});
    const [jobs, setJobs] = useState([]);
    const history = useHistory();
    const { data, error, isLoading } = useGetGeocoderJobs();
    const getRows = () => selectors.getRows({ rows: jobs, filters });
    const onFilterChange = (filter) => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        setFilters(newFilters);
    };

    useEffect(() => {
        setJobs(data);
    }, [data]);

    if (isLoading) {
        return <LoadingFullScreen id="etl-geocoder-jobs-loader" />;
    }

    if (error) {
        return (
            <div className="text-danger">
                <span>
                    <strong>There was an error loading jobs: </strong>
                    {error.message || error}
                </span>
            </div>
        );
    }
    /* <div className="container-fluid admin-etl-geocoder-jobs"></div> */
    return (
        <div className="admin-etl-geocoder-jobs">
            <Breadcrumbs />
            <AdminContainer title="Geocoder ETL Jobs">
                <ButtonContainer>
                    <ButtonPrimary
                        id="etl-geocoder-job-new-btn"
                        onClick={() => history.push('/admin/etls/geocoder-jobs/new')}
                    >
                        New Job
                    </ButtonPrimary>
                </ButtonContainer>
                <ReactDataGrid
                    columns={columns}
                    toolbar={(<Toolbar enableFilter />)}
                    rowGetter={(i) => getRows()[i]}
                    rowsCount={getRows().length}
                    onAddFilter={onFilterChange}
                    onClearFilters={() => setFilters({})}
                    minHeight={500}
                />
            </AdminContainer>
        </div>
    );
};

export default adminETLGeocoderJobs;
