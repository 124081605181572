import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MdCropSquare, MdPanoramaVertical } from 'react-icons/md';
import { ButtonPrimary, ButtonDanger, ButtonContainer } from '../../utilComponents/styledComponents';
import useGetDataSource from '../../_services/GeotrakService/hooks/useGetDataSource';
import { setQuery } from '../../actions/queryActions';
import IntersectionManager from '../../helpers/spatialUtils/IntersectionManager';

const searchMap = ({ views }) => {
    const history = useHistory();
    const searchParameters = new URLSearchParams(history.location.search);
    const { dataSource } = useGetDataSource(searchParameters.get('data-source'));
    const drawController = useSelector((state) => state.drawController);
    const drawCompleteController = useSelector((state) => state.drawCompleteController);
    const activeView = useSelector((state) => state.activeConfiguration.view);
    const dispatch = useDispatch();

    const startDrawing = (shape) => {
        const { _toolbars: toolbars } = drawController;
        const { _modes: modes } = toolbars.draw;
        modes[shape].handler.enable();
    };

    const resetQuery = () => {
        const view = views.find((viewConfig) => viewConfig.name === activeView);
        dispatch(setQuery(dataSource, view));
        const layerContainer = drawController.options.edit.featureGroup;
        const { _layers: drawLayers } = layerContainer;
        Object.keys(drawLayers).forEach((id) => {
            layerContainer.removeLayer(drawLayers[id]);
        });
    };

    const search = () => {
        const { _map: map } = drawController;
        const { _layers: layers } = map;
        const intersection = new IntersectionManager(layers, dataSource.name, drawCompleteController.layer);
        const pointIds = intersection.getSelectedPointIds();
        if (pointIds.length) {
            const newQuery = `${dataSource.uniqueIdField} IN (${pointIds.join(',')})`;
            const view = views.find((viewConfig) => viewConfig.name === activeView);
            dispatch(setQuery(dataSource, view, newQuery));
        }
    };

    useEffect(() => {
        if (Object.keys(drawCompleteController).length) {
            search();
        }
    }, [dataSource, drawCompleteController]);

    return (
        <div className="container">
            <div className="text-center">
                <p className="instructions text-center">Use the tools below to draw a search radius.</p>
                <div className="row justify-content-center pb-2">
                    <ButtonPrimary onClick={() => startDrawing('rectangle')}>
                        <MdCropSquare size={30} />
                    </ButtonPrimary>
                    <ButtonPrimary onClick={() => startDrawing('polygon')}>
                        <MdPanoramaVertical size={30} />
                    </ButtonPrimary>
                </div>
                <div className="row">
                    <ButtonContainer>
                        <ButtonDanger onClick={resetQuery}>
                            Clear
                        </ButtonDanger>
                    </ButtonContainer>
                </div>
            </div>
        </div>

    );
};

export default searchMap;
