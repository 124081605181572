import BaseGeoTrakService from './BaseGeoTrakService';

export default class FieldService extends BaseGeoTrakService {
    async getFields(dataSourceId) {
        return this.fetch(`/data-sources/${dataSourceId}/fields`);
    }

    async addFields(dataSourceId, body) {
        return this.post(`/data-sources/${dataSourceId}/fields`, body);
    }

    async updateFields(dataSourceId, body) {
        return this.put(`/data-sources/${dataSourceId}/fields`, body);
    }

    async deleteField(id) {
        return this.delete(`/fields/${id}`);
    }

    async deleteFields(dataSourceId) {
        return this.delete(`/data-sources/${dataSourceId}/fields`);
    }
}
