import PropTypes from 'prop-types';

export default class GeocoderTask {
    constructor(name, pythonClassName, description, id = null) {
        this.id = id;
        this.name = name;
        this.pythonClassName = pythonClassName;
        this.description = description;
    }

    static load(response) {
        return new GeocoderTask(
            response.name,
            response.pythonClassName,
            response.description,
            response.id
        );
    }

    validate() {
        if (!this.name) {
            throw Error('Name is required');
        }

        if (!this.pythonClassName) {
            throw Error('PythonClassName is required');
        }

        if (!this.description) {
            throw Error('Description is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const entity = {
            name: this.name,
            pythonClassName: this.pythonClassName,
            description: this.description,
        };

        if (this.id) {
            entity.id = this.id;
        }

        return entity;
    }
}

GeocoderTask.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    pythonClassName: PropTypes.string,
    description: PropTypes.string,
};
