import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './stylesheets/LoaderButton.css';
import { ButtonPrimary, ButtonDanger } from './styledComponents';

const loaderButton = ({
    danger, icon, isLoading, text, loadingText, disabled, onClick, type,
}) => {
    const Button = danger ? ButtonDanger : ButtonPrimary;
    const Icon = icon;
    return (
        <Button
            className="loader-button"
            disabled={disabled || isLoading}
            type={type}
            onClick={onClick}
        >
            {isLoading && <span className="glyphicon glyphicon-refresh spinning" aria-hidden="true" />}
            {isLoading && <FaSpinner className="loader-button-spinner" />}
            {(!isLoading && text) ? text : loadingText}
            {(!isLoading && !text) ? <Icon /> : null}
        </Button>
    );
};

export default loaderButton;

loaderButton.propTypes = {
    danger: PropTypes.bool,
    loadingText: PropTypes.string,
    icon: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button']),
};

loaderButton.defaultProps = {
    danger: false,
    loadingText: 'Loading...',
    icon: () => null,
    isLoading: false,
    disabled: false,
    text: '',
    type: 'button',
};
