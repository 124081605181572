import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import EtlService from '../EtlService';

const useDeleteEtlJob = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteEtlJob = async (etlJob) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new EtlService().deleteEtlJob(etlJob.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteEtlJob, error, isLoading, isSuccessful,
    };
};

export default useDeleteEtlJob;
