import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer } from '../../../../../utilComponents/styledComponents';
import LoaderButton from '../../../../../utilComponents/LoaderButton';
import { Domain } from '../../../../../_services/GeotrakService/Models';
import { DomainService } from '../../../../../_services/GeotrakService';
import ICreateDomainRequest from '../../../../../_services/GeotrakService/interfaces/ICreateDomainRequest';

const createButton = ({ domain, isDisabled, onCreate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const domainService = new DomainService();

    const createDomain = () => {
        setIsLoading(true);
        const request = ICreateDomainRequest.load(domain);
        domainService.createDomainValue(request).then(() => {
            onCreate();
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            setError(`Error creating domain. ${err.message || err}`);
        });
    };

    return (
        <div className="d-flex justify-content-end row">
            <ButtonContainer
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <LoaderButton
                    type="submit"
                    isLoading={isLoading}
                    disabled={isDisabled}
                    text="Submit"
                    loadingText="Submitting..."
                    onClick={createDomain}
                />
            </ButtonContainer>
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    domain: PropTypes.instanceOf(Domain),
    isDisabled: PropTypes.bool,
    onCreate: PropTypes.func,
};

createButton.defaultProps = {
    domain: new Domain(),
    isDisabled: true,
    onCreate: () => null,
};
