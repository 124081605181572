import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import { RequestFormRole } from '../../../_services/CheckRequestService/Models';
import AdminContainer from '../AdminContainer';

const checkRequestAdminService = new CheckRequestAdminService();

const rolePicker = ({ jobProperties: { requestFormId }, nextStep }) => {
    const [requestForm, setRequestForm] = useState(null);
    const [requestRoles, setRequestRoles] = useState([]);
    const [stages, setStages] = useState([]);
    const [stageCount, setStageCount] = useState(0);
    const [error, setError] = useState(null);

    const getRequestRoles = () => {
        checkRequestAdminService.getRequestRoles().then((result) => {
            setRequestRoles(result);
            setStageCount(result.length);
            setStages(result.map(() => 'N/A'));
        }).catch((err) => {
            setError(`Error Getting Request Roles. ${err.message || err}`);
        });
    };

    const getRequestFormRoles = () => {
        checkRequestAdminService.getRequestFormRoles(requestFormId).then((result) => {
            setStages(result.map((role) => role.requestRole.name));
        }).catch((err) => {
            setError(`Error Getting Roles. ${err.message || err}`);
        });
    };

    const getRequestForm = () => {
        checkRequestAdminService.getRequestForm(requestFormId).then((result) => {
            setRequestForm(result);
            getRequestFormRoles();
        }).catch((err) => {
            setError(`Error Getting Request Form. ${err.message || err}`);
        });
    };

    const addRole = (role, index) => {
        const selectedRole = requestRoles.filter((requestRole) => requestRole.name === role)[0];
        const newRequestRole = new RequestFormRole(requestForm, selectedRole, index + 1);
        const newStages = [...stages];
        if (newStages[index]) {
            newStages[index] = role;
        } else {
            newStages.splice(index, 0, role);
        }
        checkRequestAdminService.createRequestFormRole(newRequestRole).then(() => {
            setStages(newStages);
        }).catch((err) => {
            setError(`Error Updating Roles. ${err.message || err}`);
        });
    };

    const removeRole = (index) => {
        const newStages = [...stages];
        newStages.splice(index, 1);
        setStageCount(stageCount - 1);
        setStages(newStages);
    };

    const isRoleOrderValid = () => (new Set(stages)).size !== stages.length || stages.includes('N/A');

    const renderPickers = () => requestRoles.map((_role, index) => {
        if (index < stageCount) {
            return (
                <div key={_role.id}>
                    <div className="mb-3">
                        <label htmlFor={`stage-${index}-input`} className="form-label">
                            {`Stage ${index + 1}`}
                        </label>
                        <select
                            value={stages[index]}
                            onChange={(e) => addRole(e.target.value, index)}
                            id={`stage-${index}-input`}
                        >
                            <option value="N/A">N/A</option>
                            {requestRoles.map((requestRole) => (
                                <option
                                    value={requestRole.name.toString()}
                                    key={requestRole.id}
                                >
                                    {`${requestRole.name} (${requestRole.alias})`}
                                </option>
                            ))}
                        </select>
                    </div>
                    {(stages[index] === 'N/A' || !stages[index]) && (
                        <ButtonContainer>
                            <ButtonPrimary onClick={() => removeRole(index)}>
                                Remove Stage
                            </ButtonPrimary>
                        </ButtonContainer>
                    )}
                </div>
            );
        }
        return null;
    });

    useEffect(() => {
        getRequestRoles();
        if (requestFormId) {
            getRequestForm();
        }
    }, []);

    return (
        <AdminContainer title="Request Roles">
            <h6>Arrange stages in order.  Remove any N/A stages from the end of the list.</h6>
            <h6><em>Tip: Creator should start, Administrator should finish.</em></h6>
            <form>
                {renderPickers()}
                <ButtonContainer
                    error={error}
                    onErrorDismiss={() => setError(null)}
                >
                    <LoaderButton
                        disabled={isRoleOrderValid()}
                        text="Next"
                        onClick={() => nextStep()}
                    />
                </ButtonContainer>
            </form>
        </AdminContainer>
    );
};

export default rolePicker;

rolePicker.propTypes = {
    jobProperties: PropTypes.instanceOf(Object),
    nextStep: PropTypes.func,
};

rolePicker.defaultProps = {
    jobProperties: {
        requestFormId: null,
    },
    nextStep: () => null,
};
