import PropTypes from 'prop-types';
import RequestForm from './RequestForm';

export default class RequestFormNotification {
    constructor(requestForm, isEnabled, email, id = null) {
        this.requestForm = requestForm;
        this.isEnabled = isEnabled;
        this.email = email;
        this.id = id;
    }

    static load(response) {
        return new RequestFormNotification(
            RequestForm.load(response.RequestForm),
            response.NotifyDepartment,
            response.DepartmentEmailAddress,
            response.Id
        );
    }

    validate() {
        if (!this.email || this.email === '') {
            throw Error('Department email is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            RequestFormId: this.requestForm.id,
            NotifyDepartment: this.isEnabled,
            DepartmentEmailAddress: this.email,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

RequestFormNotification.propTypes = {
    requestForm: PropTypes.instanceOf(RequestForm),
    isEnabled: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.number,
};
