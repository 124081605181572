import PropTypes from 'prop-types';
import APProcessing from './APProcessing';
import CancellationReason from './CancellationReason';
import FieldApprover from './FieldApprover';
import History from './History';
import ProjectControls from './ProjectControls';
import ProjectManager from './ProjectManager';
import Summary from './Summary';
import VoidFields from './VoidFields';

export default class CheckRequest {
    constructor(checkRequest) {
        this.summary = new Summary(checkRequest);
        this.projectControls = new ProjectControls(checkRequest);
        this.fieldApprover = new FieldApprover(checkRequest);
        this.projectManager = new ProjectManager(checkRequest);
        this.APProcessing = new APProcessing(checkRequest);
        this.history = new History(checkRequest);
        this.voidFields = new VoidFields(checkRequest);
        this.cancellationReason = new CancellationReason(checkRequest);
    }
}

CheckRequest.propTypes = {
    summary: PropTypes.instanceOf(Summary),
    projectControls: PropTypes.instanceOf(ProjectControls),
    fieldApprover: PropTypes.instanceOf(FieldApprover),
    projectManager: PropTypes.instanceOf(ProjectManager),
    APProcessing: PropTypes.instanceOf(APProcessing),
    history: PropTypes.instanceOf(History),
    voidFields: PropTypes.instanceOf(VoidFields),
    cancellationReason: PropTypes.instanceOf(CancellationReason),
};
