import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { ButtonDanger } from '../../../../utilComponents/styledComponents';
import Modal from '../../../../components/Modals/Modal';
import { UserService } from '../../../../_services';
import '../users.css';

const deleteButton = ({ user }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const userService = new UserService();

    const deleteUser = async () => {
        try {
            setIsLoading(true);
            await userService.deleteUser(user.Id);
            setIsLoading(false);
            history.push('/admin/users');
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    if (!user.Id || user.IsDeleted) {
        return null;
    }

    if (isOpen) {
        return (
            <>
                <Modal
                    showModal
                    header="Delete User"
                    onCancel={() => setIsOpen(false)}
                    component={(
                        <h5 className="text-center">
                            {`Are you sure you want to delete ${user.FullName}?`}
                        </h5>
                    )}
                    footer={(
                        <LoaderButton
                            danger
                            id="delete-user-button"
                            text="Delete"
                            loadingText="Deleting..."
                            isLoading={isLoading}
                            onClick={deleteUser}
                        />
                    )}
                    displayFullScreen={false}
                />
                {error && <div className="text-danger user-error" id="delete-user-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            </>
        );
    }

    return (
        <div className="user-toolbar user-button">
            <ButtonDanger onClick={() => setIsOpen(true)}>Delete</ButtonDanger>
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    user: PropTypes.instanceOf(Object),
};

deleteButton.defaultProps = {
    user: {},
};
