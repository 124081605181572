import Column from '../components/DataGrid/Models/Column';
import Log from '../utilComponents/Log';

class TableBuilder {
    constructor(fields, schema, domains) {
        this.fields = fields;
        this.schema = schema;
        this.domains = domains;
    }

    getColumns() {
        try {
            this.validateFieldList();
            if (!this.isSchemaValid()) {
                return this.getBasicColumns();
            }
            const sortedColumns = this.sortColumns();
            return sortedColumns
                .map((column) => {
                    const keyName = column.name;
                    const schemaMatch = this.findObjectInSchema(keyName);

                    if (!schemaMatch) {
                        const e = `view has a column ${keyName} but it does not exist in the schema`;
                        Log.error(e);
                        Log.error(this.schema);
                        throw new Error(e);
                    }

                    return new Column(
                        column.name,
                        column.alias,
                        this.schema[schemaMatch].type,
                        this.schema[schemaMatch].length,
                        column.isEditable,
                        this.domains[column.name]
                    );
                }).filter((entry) => !!entry);
        } catch (err) {
            Log.error('bad schema');
            Log.error(this.schema);
            throw new Error(err);
        }
    }

    findObjectInSchema(keyName) {
        if (!keyName) {
            throw new Error(`cannot find schema properties for field name: ${keyName}`);
        }
        if (!this.isSchemaValid()) {
            throw new Error('cannot read schema');
        }
        return Object.keys(this.schema).find((k) => k.toLowerCase() === keyName.toLowerCase());
    }

    getBasicColumns() {
        this.validateFieldList();
        return this.fields.map((field) => new Column(field.name, field.alias));
    }

    isSchemaValid() {
        return this.schema === Object(this.schema) && !Array.isArray(this.schema) && this.schema !== null;
    }

    validateFieldList() {
        const isValid = Array.isArray(this.fields);
        if (!isValid) {
            throw new Error('cannot read field list');
        }
    }

    sortColumns() {
        this.validateFieldList();
        return this.fields.sort((a, b) => a.order - b.order);
    }
}

export default TableBuilder;
