import React from 'react';
import PropTypes from 'prop-types';

const viewBuilderSummary = ({
    application, role, dataSource, view,
}) => (
    <div className="text-start">
        {application && (
            <div>
                <b>Application: </b>
                <span>{application}</span>
            </div>
        )}
        {role && (
            <div>
                <b>Role: </b>
                <span>{role}</span>
            </div>
        )}
        {dataSource && (
            <div>
                <b>Data Source: </b>
                <span>{dataSource}</span>
            </div>
        )}
        {view && (
            <div>
                <b>View: </b>
                <span>{view}</span>
            </div>
        )}
    </div>
);

export default viewBuilderSummary;

viewBuilderSummary.propTypes = {
    application: PropTypes.string,
    role: PropTypes.string,
    dataSource: PropTypes.string,
    view: PropTypes.string,
};

viewBuilderSummary.defaultProps = {
    application: null,
    role: null,
    dataSource: null,
    view: null,
};
