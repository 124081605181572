import PropTypes from 'prop-types';

class IGetRendererRequest {
    constructor() {
        this.dataSourceId = null;
    }

    static load(dataSourceId) {
        if (!dataSourceId) {
            throw Error('dataSourceId is required');
        }

        const request = new IGetRendererRequest();
        request.dataSourceId = dataSourceId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('dataSourceId is required');
        }
    }
}

export default IGetRendererRequest;

IGetRendererRequest.propTypes = {
    dataSourceId: PropTypes.number,
};
