import PropTypes from 'prop-types';

class ICreateTableRequest {
    constructor() {
        this.database = null;
        this.schema = 'GeoMRE';
        this.data = {
            table: null,
            isSpatial: false,
        };
    }

    static load(database, table, isSpatial, schema) {
        if (!database) {
            throw Error('database is required');
        }

        if (!table) {
            throw Error('table is required');
        }

        const request = new ICreateTableRequest();
        request.database = database;
        if (schema) {
            request.schema = schema;
        }
        request.data.table = table;
        request.data.isSpatial = isSpatial;

        request.validate();
        return request;
    }

    validate() {
        if (!this.schema) {
            throw Error('schema is required');
        }

        if (!this.database) {
            throw Error('database is required');
        }

        if (!this.data.table) {
            throw Error('table is required');
        }

        if (this.data.isSpatial === null) {
            throw Error('isSpatial is required');
        }
    }
}

export default ICreateTableRequest;

ICreateTableRequest.PropTypes = {
    database: PropTypes.string,
    schema: PropTypes.string,
    data: PropTypes.shape({
        table: PropTypes.string,
        isSpatial: PropTypes.bool,
    }),
};
