import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Notification } from '../../../../_services/NotificationService/Models';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useUpdateNotification from '../../../../_services/NotificationService/hooks/useUpdateNotification';
import '../notifications.css';

const updateButton = ({ isValid, notification }) => {
    const {
        updateNotification, error, isLoading, isSuccessful,
    } = useUpdateNotification();

    if (!notification.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/notifications" />;
    }
    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="update-notification-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-notification-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateNotification(notification)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    notification: PropTypes.instanceOf(Notification),
    isValid: PropTypes.func,
};

updateButton.defaultProps = {
    notification: new Notification(),
    isValid: () => null,
};
