import PropTypes from 'prop-types';
import { Project } from '../Models';

class IUpdateProjectRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(project) {
        if (!project) {
            throw Error('project is required');
        }
        if (!(project instanceof Project)) {
            throw Error('Unexpected type for project');
        }

        const request = new IUpdateProjectRequest();
        request.id = project.id;
        request.data = project;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateProjectRequest;

IUpdateProjectRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(Project),
};
