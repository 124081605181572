import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { resetActiveViewThunk } from '../actions/viewActions';
import useGetDataSources from '../_services/GeotrakService/hooks/useGetDataSources';
import { DataSource } from '../_services/GeotrakService/Models';

const mapDataSourceChanger = () => {
    const history = useHistory();
    const params = useParams();
    const searchParameters = new URLSearchParams(history.location.search);
    const { dataSources } = useGetDataSources(params.moduleRoleId);
    const dataSource = dataSources.find((source) => source.id === searchParameters.get('data-source')) || new DataSource();
    const dispatch = useDispatch();

    return (
        <div className="container-fluid">
            <h6>Active Data Source</h6>
            <p>
                <select
                    value={dataSource.name || ''}
                    onChange={(evt) => {
                        const selectedDataSource = dataSources.find((source) => source.name === evt.target.value);
                        dispatch(resetActiveViewThunk(selectedDataSource));
                        history.push(`${history.location.pathname}?data-source=${selectedDataSource.id}`);
                    }}
                >
                    {dataSources.map((source) => (
                        <option key={source.alias} value={source.name}>{source.alias}</option>
                    ))}
                </select>
            </p>
        </div>
    );
};

export default mapDataSourceChanger;
