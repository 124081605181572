import PropTypes from 'prop-types';
import { DataSource } from '../Models';

class ICreateDataSourceRequest {
    constructor() {
        this.moduleRoleId = null;
        this.data = {};
    }

    static load(dataSource) {
        if (!dataSource) {
            throw Error('dataSource is required');
        }
        if (!(dataSource instanceof DataSource)) {
            throw Error('Unexpected type for dataSource');
        }

        const request = new ICreateDataSourceRequest();
        request.moduleRoleId = dataSource.moduleRoleId;
        request.data = dataSource;
        request.validate();
        return request;
    }

    validate() {
        if (!this.moduleRoleId) {
            throw Error('moduleRoleId is required');
        }
    }
}

export default ICreateDataSourceRequest;

ICreateDataSourceRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
    moduleRoleId: PropTypes.string,
};
