import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class FieldApprover {
    constructor(checkRequest) {
        this.FieldApproverApprovedBy = checkRequest.FieldApproverApprovedBy;
        this.FieldApproverApprovedDate = datetimes.toLocal(checkRequest.FieldApproverApprovedDate);
        this.FieldCheckReason = checkRequest.FieldCheckReason;
    }
}

FieldApprover.propTypes = {
    FieldApproverApprovedBy: PropTypes.string,
    FieldApproverApprovedDate: PropTypes.datetimes,
    FieldCheckReason: PropTypes.string,
};
