import { useEffect, useReducer } from 'react';
import { Project } from '../Models';
import ProjectService from '../ProjectService';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';

const useGetProject = (id) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new Project() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const result = await new ProjectService().getProject(id);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, []);

    return { project: data, error, isLoading };
};

export default useGetProject;
