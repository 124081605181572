import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetMarkets from '../../../_services/GeotrakService/hooks/useGetMarkets';
import './markets.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminMarkets = () => {
    const history = useHistory();
    const { markets, isLoading } = useGetMarkets();

    if (isLoading) {
        return <LoadingFullScreen id="market-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Markets">
                <ButtonContainer>
                    <ButtonPrimary id="new-market-button" onClick={() => history.push('/admin/markets/new')}>
                        New Market
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group market-container">
                    {markets.map((market) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action text-start"
                            id={market.name}
                            key={market.id}
                            onClick={() => history.push(`/admin/markets/${market.id}`)}
                        >
                            <span id="market-name">{market.name}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminMarkets;
