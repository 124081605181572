import { SET_ACTIVE_VIEW, GET_ACTIVE_MODULE_RECEIVED } from '../CONSTANTS/types';
import { setQuery } from './queryActions';
import fetchSchemaThunk from './schemaActions';
import refreshTableData from './dataRefreshAction';
import { addAlertThunk } from './errorActions';
import { ViewService } from '../_services/GeotrakService';
import IGetViewRequest from '../_services/GeotrakService/interfaces/IGetViewRequest';
import IGetViewsRequest from '../_services/GeotrakService/interfaces/IGetViewsRequest';

const activateModuleReceived = () => ({
    type: GET_ACTIVE_MODULE_RECEIVED,
});

export const setActiveView = (activeView) => ({
    type: SET_ACTIVE_VIEW,
    activeView,
});

export const saveView = (view, dataSource) => async (dispatch) => {
    dispatch(setActiveView(view.name));
    dispatch(fetchSchemaThunk(dataSource, view.fields));
    dispatch(refreshTableData());
};

export const setActiveViewThunk = (dataSource, viewSummary) => async (dispatch) => {
    try {
        const request = IGetViewRequest.load(dataSource.id, viewSummary.name);
        const view = await new ViewService().getView(request);

        const uniqueIdField = view.fields.find(
            (field) => field.name.toLowerCase() === dataSource.uniqueIdField.toLowerCase()
        );
        if (!uniqueIdField) {
            dispatch(addAlertThunk('danger', 'This View Has No Unique Identifier', 'Edits will not be saved.'));
        }

        dispatch(setActiveView(viewSummary.name));
        fetchSchemaThunk(dataSource, view.fields)(dispatch);
        dispatch(setQuery(dataSource, viewSummary));
        dispatch(refreshTableData());
    } catch (err) {
        dispatch(addAlertThunk('danger', 'Error Getting View', 'Error changing view'));
    }
};

export const setActiveDefaultViewThunk = (dataSource) => async (dispatch) => {
    const request = IGetViewsRequest.load(dataSource.id);
    const viewsList = await new ViewService().getViews(request);
    if (viewsList.length < 1) {
        dispatch(addAlertThunk('danger', 'This Data Source Has No Views', 'No views are configured for this data source.'));
    }
    const defaultViews = viewsList.filter((viewOption) => viewOption.isDefault);
    if (defaultViews.length > 1) {
        dispatch(addAlertThunk('danger', 'More Than One Default View', 'More than one default view is not allowed.'));
    }
    let defaultView = viewsList.find((viewOption) => viewOption.isDefault);
    if (!defaultView) {
        defaultView = viewsList.find((viewOption) => viewOption.name === 'All');
    }
    const viewRequest = IGetViewRequest.load(dataSource.id, defaultView.name);
    const view = await new ViewService().getView(viewRequest);
    dispatch(setActiveView(view.name));
    fetchSchemaThunk(dataSource, view.fields)(dispatch);
    dispatch(setQuery(dataSource, view));
    dispatch(activateModuleReceived());
};

export const resetActiveViewThunk = (dataSource) => (dispatch) => {
    dispatch(setActiveView('All'));
    setActiveDefaultViewThunk(dataSource)(dispatch);
};
