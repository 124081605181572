import React, { useState, useEffect } from 'react';
import { MdFolder } from 'react-icons/md';
import { useHistory } from 'react-router';
import { LogService } from '../../../_services';
import datetimes from '../../../helpers/datetimehelper';
import SortableHeader from '../../../utilComponents/SortableHeader';
import useSortableData from '../../../helpers/hooks/useSortableData';
import AdminLogsError from './AdminLogsError';
import './logs.css';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminLogsFolderDirectory = () => {
    const logService = new LogService();
    const [isLoading, setIsLoading] = useState(true);
    const [folders, setFolders] = useState([]);
    const [error, setError] = useState(null);
    const { items, requestSort, sortConfig } = useSortableData(folders);
    const history = useHistory();

    const getFolders = async () => {
        try {
            const results = await logService.getLogFiles(logService.rootDirectory);
            setFolders(results);
            setIsLoading(false);
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getFolders();
    }, []);

    if (isLoading) {
        return <LoadingFullScreen id="log-directory-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Logs">
                <div className="row">
                    <div className="col-md-9">
                        <SortableHeader
                            onSort={requestSort}
                            sortKey="name"
                            sortConfig={sortConfig}
                            title="Service"
                        />
                    </div>
                    <div className="col-md-3 text-center logs-right-header">
                        <SortableHeader
                            onSort={requestSort}
                            sortKey="modifiedOn"
                            sortConfig={sortConfig}
                            title="Date Modified"
                        />
                    </div>
                    <AdminLogsError error={error} id="logs-folder-error" />
                    <div className="list-group">
                        {items.map((folder) => (
                            <button
                                type="button"
                                className="list-group-item list-group-item-action logs-folder"
                                key={folder.name}
                                onClick={() => history.push(`/admin/logs/${folder.name}`)}
                            >
                                <div className="col-md-9">
                                    <span className="logs-folder-title">
                                        <MdFolder size={20} />
                                        <span id="logs-folder-name" className="logs-folder-name">{folder.name}</span>
                                    </span>
                                </div>
                                <div className="col-md-3 text-center">
                                    <span>
                                        {datetimes.toLocal(
                                            datetimes.toUTC(folder.modifiedOn).format(), '', 'MM/DD/YYYY HH:mm a z'
                                        )}
                                    </span>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </AdminContainer>
        </>
    );
};

export default adminLogsFolderDirectory;
