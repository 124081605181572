import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { Role } from '../../../../_services/GeotrakService/Models';
import useCreateRole from '../../../../_services/GeotrakService/hooks/useCreateRole';
import '../roles.css';

const createButton = ({ role, isValid, moduleId }) => {
    const {
        createRole, error, isLoading, isSuccessful,
    } = useCreateRole();

    if (role.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles`} />;
    }

    return (
        <div className="role-toolbar role-button">
            {error && <div className="text-danger role-error" id="create-role-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-role-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createRole(role)}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    role: PropTypes.instanceOf(Role),
    isValid: PropTypes.func,
    moduleId: PropTypes.string,
};

createButton.defaultProps = {
    role: new Role(),
    isValid: () => null,
    moduleId: null,
};
