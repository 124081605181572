import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setQuery } from '../../actions/queryActions';
import { ButtonContainer, ButtonDanger, ButtonPrimary } from '../../utilComponents/styledComponents';
import { View } from '../../_services/GeotrakService/Models';
import useGetDataSource from '../../_services/GeotrakService/hooks/useGetDataSource';

const searchId = ({ views }) => {
    const activeView = useSelector((state) => state.activeConfiguration.view);
    const view = views.find((viewConfig) => viewConfig.name === activeView);
    const history = useHistory();
    const searchParameters = new URLSearchParams(history.location.search);
    const [searchIdValue, setSearchIdValue] = useState('');
    const { dataSource } = useGetDataSource(searchParameters.get('data-source'));
    const dispatch = useDispatch();

    const resetQuery = () => {
        setSearchIdValue('');
        dispatch(setQuery(dataSource, view));
    };

    const search = () => {
        const searchField = dataSource.searchFieldName;
        const query = `${searchField} = '${searchIdValue}'`;
        dispatch(setQuery(dataSource, view, query));
    };

    return (
        <div className="container">
            <p className="instructions text-center">
                {`Enter a site's ${dataSource.searchFieldAlias} to search.`}
            </p>
            <div className="row col-md-8 offset-md-2">
                <form className="pb-2">
                    <input
                        type="text"
                        value={searchIdValue}
                        onChange={(e) => setSearchIdValue(e.target.value)}
                    />
                </form>
                <ButtonContainer>
                    <ButtonPrimary onClick={search}>Search</ButtonPrimary>
                    <ButtonDanger onClick={resetQuery}>Clear</ButtonDanger>
                </ButtonContainer>
            </div>
        </div>
    );
};

export default searchId;

searchId.propTypes = {
    views: PropTypes.arrayOf(PropTypes.instanceOf(View)),
};

searchId.defaultProps = {
    views: [],
};
