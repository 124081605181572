import React from 'react';
import { MdLibraryBooks } from 'react-icons/md';
import NavIconContainer from '../../utilComponents/NavIconContainer';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';

const reports = () => (
    <div className="container-fluid">
        <Breadcrumbs />
        <div className="col-md-12 d-flex flex-wrap justify-content-center">
            <NavIconContainer
                link="/service-center/finance/check-request-reports/report?type=site"
                icon={MdLibraryBooks}
                title="Site Report"
            />
        </div>
    </div>
);

export default reports;
