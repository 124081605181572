import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Menu } from 'react-data-grid-addons';
import { DataSource } from '../../../_services/GeotrakService/Models';

const { MenuItem } = Menu;

const createCheck = ({
    row, dataSource, activeRole,
}) => {
    const history = useHistory();

    const onCreateCheck = async () => {
        const { searchFieldName } = dataSource;
        const recordId = row[searchFieldName];

        const params = {
            recordId,
            project: activeRole.module.project.name,
        };

        if (activeRole.module.market) {
            params.market = activeRole.module.market.name;
        }

        const urlParams = new URLSearchParams(params);

        history.push(`/service-center/finance/submit-check-request?${urlParams}`);
    };

    return (
        <MenuItem id="create-check" onClick={onCreateCheck}>
            Create Check Request
        </MenuItem>
    );
};

createCheck.propTypes = {
    row: PropTypes.instanceOf(Object),
    dataSource: PropTypes.instanceOf(DataSource),
    activeRole: PropTypes.instanceOf(Object),
};

createCheck.defaultProps = {
    row: null,
    dataSource: new DataSource(),
    activeRole: null,
};

export default createCheck;
