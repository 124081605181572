import { useEffect, useReducer } from 'react';
import GeocoderJob from '../Models/GeocoderJob';
import GeocoderJobService from '../GeocoderJobService';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';

const useGetGeocoderJob = (id) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: new GeocoderJob() });
    const { data, isLoading, error } = state;

    const getResult = async () => {
        try {
            const result = await new GeocoderJobService().getGeocoderJob(id);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResult();
    }, []);

    return { geocoderJob: data, error, isLoading };
};

export default useGetGeocoderJob;
