import PropTypes from 'prop-types';

export default class WidgetType {
    constructor() {
        this.id = null;
        this.name = null;
        this.isMapWidget = false;
        this.isTableWidget = false;
        this.description = null;
        this.displayOrder = null;
    }

    static load(response) {
        const widgetType = new WidgetType();

        if (response) {
            widgetType.id = response.id;
            widgetType.name = response.name;
            widgetType.isMapWidget = response.isMapWidget;
            widgetType.isTableWidget = response.isTableWidget;
            widgetType.description = response.description;
            widgetType.displayOrder = response.displayOrder;
        }

        return widgetType;
    }
}

WidgetType.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    isMapWidget: PropTypes.bool,
    isTableWidget: PropTypes.bool,
    description: PropTypes.string,
};
