import PropTypes from 'prop-types';
import { Market } from '../Models';

class IUpdateMarketRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(market) {
        if (!market) {
            throw Error('market is required');
        }
        if (!(market instanceof Market)) {
            throw Error('Unexpected type for market');
        }

        const request = new IUpdateMarketRequest();
        request.id = market.id;
        request.data = market;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateMarketRequest;

IUpdateMarketRequest.propTypes = {
    id: PropTypes.number,
    data: PropTypes.instanceOf(Market),
};
