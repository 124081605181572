import { useEffect, useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import { EtlService } from '../index';

const useGetEtlJobTypes = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: [] });
    const { data, isLoading, error } = state;

    const getResults = async () => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const results = await new EtlService().getEtlJobTypes();
            dispatch({ type: ACTIONS.SUCCESS, data: results });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getResults();
    }, []);

    return { etlJobTypes: data, error, isLoading };
};

export default useGetEtlJobTypes;
