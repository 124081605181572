import PropTypes from 'prop-types';

export default class Unit {
    constructor() {
        this.id = null;
        this.name = null;
    }

    static load(response) {
        const unit = new Unit();
        unit.id = response.Id;
        unit.name = response.Name;
        unit.validate();
        return unit;
    }

    static create(name) {
        if (!name) {
            throw Error('name is required');
        }
        const unit = new Unit();
        unit.name = name;
        return unit;
    }

    validate() {
        if (!this.name) {
            throw Error('name is undefined');
        }
        if (!this.id) {
            throw Error('id is undefined');
        }
    }
}

Unit.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
};
