import PropTypes from 'prop-types';

class TableField {
    constructor() {
        this.name = null;
        this.type = null;
        this.length = null;
        this.constraint = null;
        this.isNullAllowed = true;
    }

    static load(name, type, length, isNullAllowed = true, constraint = null) {
        if (!name) {
            throw Error('Field name is required');
        }

        if (!type) {
            throw Error('Field type is required');
        }

        const field = new TableField();
        field.name = name;
        field.type = type;
        if (field.type.toLowerCase() === 'varchar') {
            field.length = length;
        }
        field.constraint = constraint;
        field.isNullAllowed = isNullAllowed;

        return field;
    }
}

export default TableField;

TableField.propTypes = {
    name: PropTypes.string,
    type: PropTypes.arrayOf(PropTypes.oneOf(['varchar', 'decimal', 'integer', 'date', 'datetime', 'numeric'])),
    length: PropTypes.number,
    constraint: PropTypes.string,
    isNullAllowed: PropTypes.bool,
};
