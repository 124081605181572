import PropTypes from 'prop-types';

export default class CommentApplication {
    constructor() {
        this.id = null;
        this.parentApplicationId = null;
        this.appKey = null;
        this.appValue = null;
    }

    static load(response) {
        const app = new CommentApplication();

        if (response) {
            app.id = response.Id;
            app.parentApplicationId = response.ParentApplicationId;
            app.appKey = response.AppKey;
            app.appValue = response.AppValue;
        }

        return app;
    }

    validate() {
        if (!this.parentApplicationId) {
            throw Error('ParentApplicationId is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const entity = {
            ParentApplicationId: this.parentApplicationId,
            AppKey: this.appKey,
            AppValue: this.appValue,
        };

        if (this.id) {
            entity.Id = this.id;
        }

        return entity;
    }
}

CommentApplication.propTypes = {
    id: PropTypes.string,
    parentApplicationId: PropTypes.string,
    appKey: PropTypes.string,
    appValue: PropTypes.string,
};
