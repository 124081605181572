import BaseFormService from './BaseFormService';
import UserService from '../UserService/UserService';

export default class FormService {
    constructor() {
        const us = new UserService();
        const cred = us.getUserClaim('forms');
        const { clientId, secret } = cred;
        this.service = new BaseFormService(clientId, secret);
    }

    get() {
        return this.service
            .fetch(`${this.service.domain}/api/forms`, {
                method: 'GET',
            })
            .then((res) => Promise.resolve(res));
    }

    getById(id) {
        return this.service
            .fetch(`${this.service.domain}/api/forms/${id}`, {
                method: 'GET',
            })
            .then((res) => {
                res.formFields = this.sortFormFields(res.formFields);
                return Promise.resolve(res);
            });
    }

    delete(id) {
        return this.service.delete(`${this.service.domain}/api/forms/${id}`);
    }

    sortFormFields = (fields) => fields.sort((a, b) => {
        const x = a.orderIndex;
        const y = b.orderIndex;
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    })

    /**
   * getIndexes
   * @method
   * @summary gets form indexes
   * @param {string} moduleName
   * @param {string} roleId
   * @return {Array.<Form>} the indexes
   * */
    getFormsByModuleRole(moduleName, roleId) {
        if (!moduleName) {
            return Promise.resolve([]);
        }

        let url = `${this.service.domain}/api/forms/moduleName/${moduleName}`;
        if (roleId) {
            url = `${url}/roleId/${roleId}`;
        }

        return this.service
            .fetch(url, {
                method: 'GET',
            })
            .then((res) => Promise.resolve(res));
    }

    /**
   * getIndexes
   * @method
   * @summary gets form indexes
   * @param {string} moduleName
   * @param {string} projectName
   * @param {string} roleId
   * @return {Array.<Form>} the indexes
   * */
    getFormsByModuleProjectRole(moduleName, projectName, roleId) {
        if (!moduleName || !projectName || !roleId) {
            return Promise.resolve([]);
        }

        const url = `${this.service.domain}/api/forms/find`;

        const request = {
            moduleName,
            projectName,
            roleId,
        };

        return this.service.post(url, request);
    }

    /**
   * getIndexedByModuleProjectRoles
   * @method
   * @summary gets form by module and roles
   * @param {string} moduleName
   * @param {string} projectName
   * @param {Array.<Role>} roles
   * @return {Array.<Form>} the indexes
   * */
    getIndexedByModuleProjectRoles(moduleName, projectName, roles) {
        const promises = [];

        roles.forEach((role) => promises.push(this.getFormsByModuleProjectRole(moduleName, projectName, role.name)));

        const forms = [];
        return Promise.all(promises).then((results) => {
            results.forEach((result) => result.forEach((form) => {
                if (forms.filter((f) => f.id === form.id).length === 0) {
                    forms.push(form);
                }
            }));
            return forms;
        });
    }

    /**
   * create
   * @method
   * @summary creates a form
   * @param {Form} form
   * @return {Form} form
   */
    insert(form) {
        return this.service.post(`${this.service.domain}/api/forms`, form);
    }
}
