import DataSource from '../_services/GeotrakService/Models/DataSource';

export const alphabetize = (array, key) => array.sort((a, b) => {
    const textA = a[key].toUpperCase();
    const textB = b[key].toUpperCase();
    if (textA < textB) {
        return -1;
    } if (textA > textB) {
        return 1;
    }
    return 0;
});

export const padDate = (datePart) => {
    const DATE_LENGTH = 2;
    if (datePart.length < DATE_LENGTH) {
        return `0${datePart}`;
    }
    return datePart;
};

export const maxDate = (dates) => Math.max.apply(null, dates);

export const formatQuery = (dataSource) => {
    let query = null;
    if (dataSource instanceof DataSource) {
        query = dataSource.query;
    }
    if (dataSource.Query) {
        query = dataSource.Query;
    }
    return query;
};

export const isValidDate = (value) => {
    if (Number.isNaN(Date.parse(value)) && value !== '') {
        return false;
    }
    return true;
};

export const isValidString = (length, value) => {
    if (value.length > length) {
        return false;
    }
    return true;
};

export const isValidInteger = (value) => {
    if (Number.isNaN(value) || value % 1 !== 0) {
        return false;
    }
    return true;
};

export const isValidFloat = (value) => {
    const numericValue = parseFloat(value);
    if (Number.isNaN(numericValue) && value !== '') {
        return false;
    }
    return true;
};

// "this_example_string" -> "thisExampleString"
export const snakeCaseToCamelCase = (s) => s.replace(/([-_][a-z])/gi, ($1) => $1
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));
// "thisExampleString" -> "this Example String"
export const camelCaseToSpaced = (text) => String(text)
    .replace(/([A-Z][a-z])/g, ' $1')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (char) => char.toUpperCase());

// "this Example String" -> "thisExampleString"
export const removeAllSpaces = (text) => String(text).replace(/[ \t]/g, '');

// "this Example String" -> "This example string"
export const capitalizeFirstLetter = (text) => text.toLowerCase().charAt(0).toUpperCase() + text.slice(1)
    .replace(/-/g, ' ')
    .replace(/_/g, ' ');

export const formatPermissions = (permissionArray) => {
    let permissionString = '';
    if (permissionArray !== undefined && permissionArray.length > 0) {
        permissionArray.map((role) => {
            permissionString = `${permissionString + role},`;
            return permissionString;
        });
        return permissionString.slice(0, -1);
    }
    return null;
};

export const unformatPermissions = (permissionString) => {
    if (permissionString !== null) {
        return permissionString.split(',');
    }
    return [];
};

export const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === null && obj2 === null) {
        return true;
    }
    if (obj1 === null) {
        return false;
    }
    if (obj2 === null) {
        return false;
    }
    if (obj1 === undefined && obj2 === undefined) {
        return true;
    }
    if (obj1 === undefined) {
        return false;
    }
    if (obj2 === undefined) {
        return false;
    }
    // Obvious case
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 === obj2;
    }
    // Check the content
    return Object.entries(obj1).every(([key, value]) => {
    // If both elements are objects, call this function again
        if (typeof value === 'object' && typeof obj2[key] === 'object') {
            return areObjectsEqual(value, obj2[key]);
        }

        return value === obj2[key];
    });
};

export const filterEmptyElements = (elem) => Boolean(elem);

/* @source underscore.js
 * @see http://unscriptable.com/2009/03/20/debouncing-javascript-methods/
 * @param {Function} function to wrap
 * @param {Number} timeout in ms (`100`)
 * @param {Boolean} whether to execute at the beginning (`false`)
 * @api public
 */
export const debounce = (func, wait, immediate) => {
    let timeout;
    let args;
    let context;
    let timestamp;
    let result;
    let waitTime = wait;
    const DEFAULT_WAIT_MS = 100;

    if (waitTime == null) {
        waitTime = DEFAULT_WAIT_MS;
    }

    function later() {
        const last = Date.now() - timestamp;

        if (last < waitTime && last >= 0) {
            timeout = setTimeout(later, waitTime - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                args = null;
                context = null;
            }
        }
    }

    const debounced = (...argument) => {
        context = this;
        args = argument;
        timestamp = Date.now();
        const callNow = immediate && !timeout;
        if (!timeout) {
            timeout = setTimeout(later, waitTime);
        }
        if (callNow) {
            result = func.apply(context, args);
            args = null;
            context = null;
        }

        return result;
    };

    debounced.clear = () => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    debounced.flush = () => {
        if (timeout) {
            result = func.apply(context, args);
            args = null;
            context = null;

            clearTimeout(timeout);
            timeout = null;
        }
    };

    return debounced;
};

export const returnCurrentLastPageNumber = (totalRecords, totalRecordsPerPage) => {
    const pageCountIsEven = totalRecords % totalRecordsPerPage === 0;
    const actualRecordsPerPage = totalRecords / totalRecordsPerPage;
    const lastPageNumber = (pageCountIsEven && totalRecords !== 0) ? actualRecordsPerPage : actualRecordsPerPage + 1;
    return parseInt(lastPageNumber, 10);
};

export const getEnvironment = (uri, port, service = '') => {
    if (process.env.REACT_APP_ENV_PREFIX === 'local') {
        return `http://localhost:${port}${service}`;
    }
    return uri;
};

export const validateEmails = (userInput) => {
    /* eslint-disable-next-line */
    const emailFormatting = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailAddresses = userInput ? userInput.split(',') : [];
    const everyEmailIsValid = emailAddresses.every((emailAddress) => emailFormatting.test(emailAddress));
    return (emailAddresses.length && everyEmailIsValid);
};
