import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../../utilComponents/LoaderButton';
import ErrorMessage from '../../../../../utilComponents/ErrorMessage';
import { DatabaseService } from '../../../../../_services/GeotrakService';
import ICreateDatabaseRequest from '../../../../../_services/GeotrakService/interfaces/ICreateDatabaseRequest';
import ICreateSchemaRequest from '../../../../../_services/GeotrakService/interfaces/ICreateSchemaRequest';

const createButton = ({ database, isValid, databases }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const history = useHistory();
    const databaseName = `V3_${database}`;
    const databaseService = new DatabaseService();

    const createNewDatabase = async () => {
        setIsLoading(true);
        try {
            const databaseRequest = ICreateDatabaseRequest.load(databaseName);
            await databaseService.createDatabase(databaseRequest);
            const defaultSchemaRequest = ICreateSchemaRequest.load(databaseName);
            await databaseService.createSchema(defaultSchemaRequest);
            setIsLoading(false);
            history.push('/admin/databases');
        } catch (err) {
            setError(err.message || err);
            setIsLoading(false);
        }
    };

    const isNameValid = () => isValid()
    && !databases.find((existingDatabase) => existingDatabase.toUpperCase() === databaseName.toUpperCase());

    return (
        <div className="d-flex justify-content-end">
            {error && (
                <ErrorMessage
                    message={error}
                    onDismiss={() => setError(null)}
                />
            )}
            <LoaderButton
                id="create-database-button"
                text={database ? `Create ${databaseName}` : 'Create'}
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isNameValid()}
                onClick={createNewDatabase}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    database: PropTypes.string,
    isValid: PropTypes.func,
    databases: PropTypes.instanceOf(Array),
};

createButton.defaultProps = {
    database: '',
    isValid: () => null,
    databases: [],
};
