import React, { useState } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { Notification } from '../../../../_services/NotificationService/Models';
import useDeleteNotification from '../../../../_services/NotificationService/hooks/useDeleteNotification';
import '../notifications.css';
import Modal from '../../../../components/Modals/Modal';
import { ButtonDanger } from '../../../../utilComponents/styledComponents';

const DeleteButton = ({ notification }) => {
    const {
        deleteNotification, error, isLoading, isSuccessful,
    } = useDeleteNotification();

    const [showConfirmation, setShowConfirmation] = useState(false);
    if (!notification.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/notifications" />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="delete-notification-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-notification-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => setShowConfirmation(true)}
            />
            <Modal
                showModal={showConfirmation}
                header="Wait!"
                onCancel={() => setShowConfirmation(false)}
                component={
                    <h5 className="text-center">Are you sure you want to delete this notification?</h5>
                }
                footer={(
                    <ButtonDanger onClick={() => deleteNotification(notification)}>Delete</ButtonDanger>
                )}
                displayFullScreen={false}
            />
        </div>
    );
};

export default DeleteButton;

DeleteButton.propTypes = {
    notification: PropTypes.instanceOf(Notification),
};

DeleteButton.defaultProps = {
    notification: new Notification(),
};
