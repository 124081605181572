import React from 'react';
import FormBuilder from '../../components/Forms/FormBuilder';
import AdminContainer from './AdminContainer';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';

const adminForms = () => (
    <div className="admin-forms">
        <Breadcrumbs />
        <AdminContainer title="Forms">
            <FormBuilder />
        </AdminContainer>
    </div>
);

export default adminForms;
