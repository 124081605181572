import { useReducer } from 'react';
import { GeocoderJobService } from '../index';
import IUpdateGeocoderJob from '../interfaces/IUpdateGeocoderJob';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateGeocoderJob = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, isSuccessful, error,
    } = state;

    const updateJob = async (job) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = IUpdateGeocoderJob.load(job);
            const result = await new GeocoderJobService().updateGeocoderJob(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateJob,
        data,
        isLoading,
        isSuccessful,
        error,
    };
};

export default useUpdateGeocoderJob;
