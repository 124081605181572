import PropTypes from 'prop-types';
import EtlJobType from './EtlJobType';

export default class EtlJob {
    constructor() {
        this.id = null;
        this.jobType = new EtlJobType();
        this.moduleRoleId = null;
    }

    static load(response) {
        const etlJob = new EtlJob();

        if (response) {
            etlJob.id = response.id;
            etlJob.jobType = EtlJobType.load(response.jobType);
            etlJob.moduleRoleId = response.moduleRoleId;
        }

        return etlJob;
    }
}

EtlJob.propTypes = {
    id: PropTypes.string,
    jobType: PropTypes.instanceOf(EtlJobType),
    moduleRoleId: PropTypes.string,
};
