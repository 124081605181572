import React from 'react';
import PropTypes from 'prop-types';
import { MdLock } from 'react-icons/md';
import './stylesheets/NavIconContainer.css';

const navIconContainer = ({
    link, isAccessible, title, icon, isNewTab,
}) => {
    const Icon = icon;
    return (
        <div className="d-flex flex-column justify-content-center text-center nav-icon-container">
            <a
                href={link}
                target={isNewTab ? '_blank' : '_self'}
                rel="noopener noreferrer"
                className={isAccessible ? '' : 'disabled'}
            >
                <h1>
                    {isAccessible ? <Icon size={70} /> : <MdLock size={70} className="locked" />}
                </h1>
                <h5 className="text-dark">{title}</h5>
            </a>
        </div>
    );
};

export default navIconContainer;

navIconContainer.propTypes = {
    isAccessible: PropTypes.bool,
    isNewTab: PropTypes.bool,
    link: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.instanceOf(Object),
};

navIconContainer.defaultProps = {
    isAccessible: true,
    isNewTab: false,
    link: '',
    title: '',
    icon: null,
};
