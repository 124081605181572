import PropTypes from 'prop-types';

export default class LookupValue {
    constructor(name, value) {
        this.id = name;
        this.value = value;
    }
}

LookupValue.propTypes = {
    id: PropTypes.number,
    value: PropTypes.string,
};
