import PropTypes from 'prop-types';

class IGetFavoriteDataSourceRequest {
    constructor() {
        this.moduleRoleId = null;
    }

    static load(moduleRoleId) {
        if (!moduleRoleId) {
            throw Error('role is required to get data source favorites');
        }

        const request = new IGetFavoriteDataSourceRequest();
        request.moduleRoleId = moduleRoleId;
        return request;
    }
}

export default IGetFavoriteDataSourceRequest;

IGetFavoriteDataSourceRequest.propTypes = {
    moduleRoleId: PropTypes.string,
};
