import PropTypes from 'prop-types';
import { formatQuery } from '../../../helpers/utilities';
import ViewField from './ViewField';

export default class View {
    constructor() {
        this.name = null;
        this.query = null;
        this.owner = null;
        this.isDefault = false;
        this.fields = [];
    }

    static load(response) {
        const view = new View();
        view.name = response.name;
        view.query = response.query;
        view.owner = response.owner;
        view.isDefault = response.isDefault;
        view.fields = response.fields.map((field) => ViewField.load(field));
        return view;
    }

    static create(name, query, isDefault, fields = []) {
        const view = new View();
        view.name = name;
        view.query = query;
        view.isDefault = isDefault;
        view.fields = fields;
        return view;
    }

    loadFields(fields) {
        this.fields = fields;
    }

    setQuery(query) {
        if (typeof query === 'string' && query.trim() === '') {
            this.query = null;
        } else {
            this.query = query;
        }
    }

    setName(name) {
        this.name = name;
    }

    stripDataSourceQuery(dataSource) {
        const defaultQuery = formatQuery(dataSource);
        let strippedQuery = (defaultQuery)
            ? this.query.replace(`${defaultQuery} AND `, '').replace(defaultQuery, '')
            : this.query;

        if (!strippedQuery) {
            strippedQuery = null;
        }
        this.query = strippedQuery;
    }

    isRootView() {
        return this.name === 'All';
    }
}

View.propTypes = {
    name: PropTypes.string,
    query: PropTypes.string,
    owner: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.instanceOf(ViewField)),
};
