import React from 'react';
import PropTypes from 'prop-types';

const hyperlinkFormatter = ({ value, onRecordSelection }) => (
    <button
        type="button"
        id={`hyperlink-button-${value}`}
        className="btn-link text-primary bg-transparent border-0"
        onClick={() => onRecordSelection(value)}
        title="View Details"
    >
        {value}
    </button>
);

export default hyperlinkFormatter;

hyperlinkFormatter.propTypes = {
    value: PropTypes.number,
    onRecordSelection: PropTypes.func,
};

hyperlinkFormatter.defaultProps = {
    value: null,
    onRecordSelection: () => null,
};
