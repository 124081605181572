import { useReducer } from 'react';
import NotificationUserService from '../../NotificationUserService';
import ICreateNotificationUserRequest from '../../interfaces/ICreateNotificationUserRequest';
import { ACTIONS, useApiReducer } from '../../../../helpers/hooks/useApiReducer';

const useCreateNotificationUser = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const createNotificationUser = async (notificationUserObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = ICreateNotificationUserRequest.load(notificationUserObject);
            const result = await new NotificationUserService().createNotificationUser(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        createNotificationUser, error, isLoading, isSuccessful, data,
    };
};

export default useCreateNotificationUser;
