import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Rnd } from 'react-rnd';
import { BsX } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { toggleWidget } from '../actions/widgetActions';
import styles from './stylesheets/Widget.module.css';
import { DataSource } from '../_services/GeotrakService/Models';
import CoordinatePair from '../helpers/spatialUtils/CoordinatePair';

const ZSHIFT = 900;
const XDEFAULTPOS = 50;
const YDEFAULTPOS = 50;

const getClassName = (widgetTitle) => {
    if (widgetTitle) {
        const scrubbed = widgetTitle.replace(/[\W_]+/g, '').toLowerCase();
        return `${scrubbed}-widget-container`;
    }
    return '';
};

export const Widget = (props) => {
    const {
        widgets, toggleWidgetAction, onMapReposition, mapPosition,
    } = props;
    const [selectedWidget, setSelectedWidget] = useState(null);
    const { current: widgetStack } = useRef([]);

    useEffect(() => () => {
        // this implicit return is the 'unmount' steps
        if (!Object.values(widgets).some((w) => w.isOpen)) {
            setSelectedWidget(null); // reset if all closed
            widgetStack.length = 0;
        }
    }, [selectedWidget]);

    const addWidget = (widgetTitle) => {
        if (!widgetStack.includes(widgetTitle)) {
            widgetStack.push(widgetTitle);
        }
    };

    const removeWidget = (widgetTitle) => {
        if (widgetStack.includes(widgetTitle)) {
            widgetStack.splice(widgetStack.indexOf(widgetTitle), 1);
        }
    };

    const shuffleWidgetToTop = (widgetRef) => {
        widgetStack.splice(widgetStack.indexOf(widgetRef), 1);
        widgetStack.push(widgetRef);
        setSelectedWidget(widgetRef);
        return widgetStack.length - 1;
    };

    return (
        <>
            {Object.entries(widgets).map((currentWidget, index) => {
                const widget = { ...currentWidget };
                const { 0: widgetKey, 1: widgetObj } = widget;
                const widgetTitle = widgetObj.title;
                const Tool = widgetObj.tool;
                if (!widgets[widgetKey].isOpen) {
                    widgetObj.zIndex = 0;
                    removeWidget(widgetTitle);
                    return null;
                }

                addWidget(widgetTitle);
                widgetObj.zIndex = widgetStack.indexOf(widgetTitle);

                return (
                    <Rnd
                        onClick={() => {
                            widgetObj.zIndex = shuffleWidgetToTop(widgetTitle);
                        }}
                        style={{
                            zIndex: widgetObj.zIndex + ZSHIFT,
                            display: 'flex',
                        }}
                        className={`modal-content border shadow hideMeInMapPrint ${getClassName(widgetTitle)} ${styles['geotrak-widget']}`}
                        key={`widget${index.toString()}`}
                        dragHandleClassName="widget-container-header"
                        default={{
                            x: props.widgets[widgetKey].x || XDEFAULTPOS,
                            y: props.widgets[widgetKey].y || YDEFAULTPOS,
                            width: props.widgets[widgetKey].width,
                            height: props.widgets[widgetKey].height,
                        }}
                    >
                        <React.Suspense fallback={<></>}>
                            <div className={styles.body}>
                                <div className={`${styles.title} widget-container-header`}>
                                    {widgetTitle}
                                    <BsX
                                        size="1.25em"
                                        color="white"
                                        className={`${styles.widgetClose} float-end`}
                                        onClick={() => toggleWidgetAction(widgetKey)}
                                    />
                                </div>
                                <Tool
                                    match={props.match}
                                    dataSource={props.dataSource}
                                    onMapReposition={onMapReposition}
                                    mapPosition={mapPosition}
                                />
                            </div>
                        </React.Suspense>
                    </Rnd>
                );
            })}
        </>
    );
};

const mapStateToProps = ({ widgets }) => ({
    widgets,
});

const mapDispatchToProps = {
    toggleWidgetAction: toggleWidget,
};

export const WidgetApp = connect(mapStateToProps, mapDispatchToProps)(Widget);

Widget.propTypes = {
    widgets: PropTypes.instanceOf(Object),
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
    }),
    toggleWidgetAction: PropTypes.func,
    dataSource: PropTypes.instanceOf(DataSource),
    onMapReposition: PropTypes.func,
    mapPosition: PropTypes.instanceOf(CoordinatePair),
};

Widget.defaultProps = {
    widgets: {},
    match: {},
    toggleWidgetAction: () => null,
    dataSource: new DataSource(),
    onMapReposition: () => null,
    mapPosition: new CoordinatePair(0, 0),
};
