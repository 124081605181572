import BaseGeoTrakService from './BaseGeoTrakService';

export default class DatabaseService extends BaseGeoTrakService {
    async getDatabases() {
        return this.fetch('/databases');
    }

    async createDatabase(request) {
        return this.post('/databases', request.data);
    }

    async getSchemas(request) {
        return this.fetch(`/databases/${request.database}/schemas`);
    }

    async createSchema(request) {
        return this.post(`/databases/${request.database}/schemas`, request.data);
    }

    async getTables(request) {
        return this.fetch(`/databases/${request.database}/schemas/${request.schema}/tables`);
    }

    async createTable(request) {
        return this.post(`/databases/${request.database}/schemas/${request.schema}/tables`, request.data);
    }

    async getFields(request) {
        return this.fetch(`/databases/${request.database}/schemas/${request.schema}/tables/${request.table}/fields`);
    }

    async createField(request) {
        return this.post(`/databases/${request.database}/schemas/${request.schema}/tables/${request.table}/fields`, request.data);
    }
}
