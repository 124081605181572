import PropTypes from 'prop-types';
import { DataSourceWidget } from '../Models';

class ICreateDataSourceWidgetRequest {
    constructor() {
        this.data = {};
        this.dataSourceId = null;
    }

    static load(dataSourceWidget) {
        if (!dataSourceWidget) {
            throw Error('widget is required');
        }
        if (!(dataSourceWidget instanceof DataSourceWidget)) {
            throw Error('Unexpected data type for widget');
        }

        const request = new ICreateDataSourceWidgetRequest();
        request.data = dataSourceWidget;
        request.dataSourceId = dataSourceWidget.dataSourceId;
        request.validate();
        return request;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('data source id is required');
        }
    }
}

export default ICreateDataSourceWidgetRequest;

ICreateDataSourceWidgetRequest.propTypes = {
    data: PropTypes.instanceOf(DataSourceWidget),
    dataSourceId: PropTypes.string,
};
