import PropTypes from 'prop-types';
import datetimes from '../../../helpers/datetimehelper';

export default class CheckRequestReport {
    constructor(report) {
        const utcCreatedOnTime = datetimes.toUTC(report['Created On']);
        this.CheckRequestId = report['Check Request Id'];
        this.ProjectSiteId = report['Project Site Id'];
        this.PayingBVCorp = report['Paying BV Corp'];
        this.ProjectNo = report['Project No'];
        this.TaskNo = report['Task No'];
        this.BottomTask = report['Bottom Task'];
        this.PayableTo = report['Payable To'];
        this.Amount = report.Amount;
        this.Purpose = report.Purpose;
        this.CheckNo = report['Check No'];
        this.CheckStatus = report['Check Status'];
        this.CheckClearedDate = report['Check Cleared Date'];
        this.CreatedOn = datetimes.toLocal(utcCreatedOnTime.format(), '', 'MM/DD/YYYY hh:mm a z');
        this.CreatedBy = report['Created By'];
    }
}

CheckRequestReport.propTypes = {
    Amount: PropTypes.string,
    'Bottom Task': PropTypes.string,
    'Check No': PropTypes.string,
    'Check Request Id': PropTypes.string,
    'Check Status': PropTypes.string,
    'Created By': PropTypes.string,
    'Created On': PropTypes.datetimes,
    'Check Cleared Date': PropTypes.datetimes,
    'Payable To': PropTypes.string,
    'Paying BV Corp': PropTypes.string,
    'Project No': PropTypes.string,
    'Project Site Id': PropTypes.string,
    Purpose: PropTypes.string,
    'Task No': PropTypes.string,
};
