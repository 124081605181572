import React, { useEffect, useState } from 'react';
import { MdFileDownload } from 'react-icons/md';
import FileSaver from 'file-saver';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { LogService } from '../../../_services';
import AdminLogsError from './AdminLogsError';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminLogsFile = ({ match }) => {
    const { params: { directory, file } } = match;
    const logService = new LogService();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [fileContents, setFileContents] = useState(null);

    const getFile = async () => {
        try {
            const results = await logService.getFile(`${logService.rootDirectory}/${directory}/${file}`);
            const fileBytes = new Uint8Array(results);
            const blob = new Blob([fileBytes], { type: 'application/octet-stream' });
            blob.text().then((readableFile) => setFileContents(readableFile));
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    };

    const downloadFile = async () => {
        try {
            const results = await logService.getFile(`${logService.rootDirectory}/${directory}/${file}`);
            const fileBytes = new Uint8Array(results);
            const blob = new Blob([fileBytes], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, file);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        getFile();
    }, []);

    if (isLoading) {
        return <LoadingFullScreen id="log-file-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Logs">
                <h4 className="text-center">{directory}</h4>
                <h6 className="text-center">{file}</h6>
                <div>
                    <ButtonContainer>
                        <ButtonPrimary>
                            <MdFileDownload onClick={downloadFile} />
                        </ButtonPrimary>
                    </ButtonContainer>
                    <AdminLogsError error={error} />
                    <pre className="p-4 border bg-light" id="log-file">
                        {fileContents}
                    </pre>
                </div>
            </AdminContainer>
        </>
    );
};

export default adminLogsFile;
