import {
    DRAW_CONTROLLER_RECEIVED,
    DRAW_COMPLETE_CONTROLLER_RECEIVED
} from '../CONSTANTS/types';

export const drawControllerReceived = (drawController) => ({
    type: DRAW_CONTROLLER_RECEIVED,
    drawController,
});

export const drawCompleteControllerReceived = (drawCompleteController) => ({
    type: DRAW_COMPLETE_CONTROLLER_RECEIVED,
    drawCompleteController,
});
