import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckRequestConfigService, UserService } from '../../_services';
import * as Actions from './Actions';
import { SelectedCheckRequestType, UserType } from '../../types';
import withCurrentUser from '../../utilComponents/hocs/withCurrentUser';
import { ButtonContainer } from '../../utilComponents/styledComponents';

class CheckRequestActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actions: null,
            voidActions: null,
            error: null,
        };

        this.CheckRequestConfigService = new CheckRequestConfigService();
        this.UserService = new UserService();
    }

  componentDidMount = () => {
      const {
          role, checkRequestId, showVoidOptions,
      } = this.props;
      // get permissions for the current role
      this.CheckRequestConfigService.getRoleActions(role).then((actions) => {
          this.setState({ actions });
      }).catch((err) => {
          this.setState({ error: err.message || 'An error occurred getting role permissions.' });
      });
      // get void permissions for the current check
      if (showVoidOptions) {
          this.CheckRequestConfigService.getVoidActions(checkRequestId).then((voidActions) => {
              this.setState({ voidActions });
          }).catch((err) => {
              this.setState({ error: err.message || 'An error occurred getting void permissions.' });
          });
      }
  }

  isReadyToSubmit = () => {
      const { requestForm } = this.props;
      return requestForm.some(
          (field) => field.IsRequired && (field.value === null || field.value === '')
      );
  }

  render() {
      const { actions, voidActions, error } = this.state;
      const {
          checkRequestId, requestForm, refreshQueues, role, user, checkRequest, filesReady,
      } = this.props;
      if (actions) {
          return (
              <ButtonContainer
                  error={error}
                  onErrorDismiss={() => this.setState({ error: null })}
              >
                  {actions.CanApprove
            && (
                <Actions.Approve
                    checkRequestId={checkRequestId}
                    role={role}
                    refreshQueues={refreshQueues}
                    requestForm={requestForm}
                    isReadyToSubmit={this.isReadyToSubmit}
                    onError={(err) => this.setState({ error: err })}
                />
            )}
                  {actions.CanReject
            && (
                <Actions.Reject
                    checkRequestId={checkRequestId}
                    role={role}
                    refreshQueues={refreshQueues}
                    requestForm={requestForm}
                />
            )}
                  {actions.CanFinalize
            && (
                <Actions.Finalize
                    checkRequestId={checkRequestId}
                    refreshQueues={refreshQueues}
                    requestForm={requestForm}
                    isReadyToSubmit={this.isReadyToSubmit}
                    onError={(err) => this.setState({ error: err })}
                />
            )}
                  {actions.CanCreate
            && (
                <Actions.Create
                    checkRequest={checkRequest}
                    checkRequestId={checkRequestId}
                    refreshQueues={refreshQueues}
                    requestForm={requestForm}
                />
            )}
                  {actions.CanSave
            && (
                <Actions.Save
                    checkRequestId={checkRequestId}
                    refreshQueues={refreshQueues}
                    requestForm={requestForm}
                    onError={(err) => this.setState({ error: err })}
                />
            )}
                  {actions.CanResubmit
            && (
                <Actions.Resubmit
                    checkRequestId={checkRequestId}
                    refreshQueues={refreshQueues}
                    requestForm={requestForm}
                    filesReady={filesReady}
                    isReadyToSubmit={this.isReadyToSubmit}
                    onError={(err) => this.setState({ error: err })}
                />
            )}
                  {actions.CanCancel
                && (
                    <Actions.Cancel
                        checkRequestId={checkRequestId}
                        refreshQueues={refreshQueues}
                        requestForm={requestForm}
                    />
                )}
                  {user && user.Admin === 'DEV'
              && (
                  <Actions.Delete
                      checkRequestId={checkRequestId}
                      refreshQueues={refreshQueues}
                      requestForm={requestForm}
                  />
              )}
              </ButtonContainer>
          );
      } if (voidActions) {
          return (
              <ButtonContainer
                  error={error}
                  onErrorDismiss={() => this.setState({ error: null })}
              >
                  {voidActions.canRequest
            && (
                <Actions.RequestVoid
                    checkRequestId={checkRequestId}
                    refreshQueues={refreshQueues}
                />
            )}
                  {(voidActions.canApprove && this.UserService.hasCheckRequestAdminPermissions())
          && (
              <Actions.ApproveVoid
                  checkRequestId={checkRequestId}
                  refreshQueues={refreshQueues}
                  onError={(err) => this.setState({ error: err })}
              />
          )}
                  {(voidActions.canReject && this.UserService.hasCheckRequestAdminPermissions())
          && (
              <Actions.RejectVoid
                  checkRequestId={checkRequestId}
                  refreshQueues={refreshQueues}
              />
          )}
                  {user && user.Admin === 'DEV'
          && (
              <Actions.Delete
                  checkRequestId={checkRequestId}
                  refreshQueues={refreshQueues}
                  requestForm={requestForm}
              />
          )}
              </ButtonContainer>
          );
      }
      if (error && !actions) {
          return this.renderErrorMessage();
      }
      return null;
  }
}

export default withCurrentUser(CheckRequestActions);

CheckRequestActions.propTypes = {
    requestForm: PropTypes.instanceOf(Array),
    role: PropTypes.string,
    user: UserType,
    showVoidOptions: PropTypes.bool,
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
    checkRequest: SelectedCheckRequestType,
    filesReady: PropTypes.bool,
};

CheckRequestActions.defaultProps = {
    requestForm: [],
    role: '',
    user: {},
    checkRequestId: 0,
    showVoidOptions: false,
    refreshQueues: () => null,
    checkRequest: {},
    filesReady: false,
};
