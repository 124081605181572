import PropTypes from 'prop-types';
import WidgetType from './WidgetType';

export default class ModuleWidget {
    constructor() {
        this.id = null;
        this.moduleRoleId = null;
        this.widgetType = new WidgetType();
    }

    static load(response) {
        const moduleWidget = new ModuleWidget();

        if (response) {
            moduleWidget.id = response.id;
            moduleWidget.moduleRoleId = response.moduleRoleId;
            moduleWidget.widgetType = WidgetType.load(response.widgetType);
        }

        return moduleWidget;
    }

    validate() {
        if (!this.widgetType.id) {
            throw Error('widgetTypeId is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        return {
            moduleRoleId: this.moduleRoleId,
            widgetTypeId: this.widgetType.id,
        };
    }
}

ModuleWidget.propTypes = {
    id: PropTypes.string,
    moduleRoleId: PropTypes.string,
    widgetType: PropTypes.instanceOf(WidgetType),
};
