import BaseGeoTrakService from './BaseGeoTrakService';
import { DataSourceWidget, ModuleWidget, WidgetType } from './Models';

export default class WidgetService extends BaseGeoTrakService {
    async getModuleWidgets(moduleRoleId) {
        return this.fetch(`/module-roles/${moduleRoleId}/widgets`).then((widgets) => widgets.map((widget) => ModuleWidget.load(widget)));
    }

    async getWidgets() {
        return this.fetch('/widgets').then((widgets) => widgets.map((widget) => WidgetType.load(widget)));
    }

    async createModuleWidget(request) {
        const { moduleRoleId, data } = request;
        return this.post(`/module-roles/${moduleRoleId}/widgets`, data.loadSqlEntity()).then((widget) => ModuleWidget.load(widget));
    }

    async deleteModuleWidget(moduleRoleId, widgetTypeId) {
        return this.delete(`/widgets/module-roles/${moduleRoleId}/widget-type/${widgetTypeId}`);
    }

    async getDataSourceWidgets(dataSourceId) {
        return this.fetch(`/data-source/${dataSourceId}/widgets`).then((widgets) => widgets.map((widget) => DataSourceWidget.load(widget)));
    }

    async createDataSourceWidget(request) {
        const { dataSourceId, data } = request;
        return this.post(`/data-source/${dataSourceId}/widgets`, data.loadSqlEntity()).then((widget) => DataSourceWidget.load(widget));
    }

    async deleteDataSourceWidget(dataSourceId, widgetTypeId) {
        return this.delete(`/widgets/data-source/${dataSourceId}/widget-type/${widgetTypeId}`);
    }
}
