import { useEffect, useState } from 'react';
import useGetDataSourceDocumentTypes from '../../../_services/GeotrakService/hooks/useGetDataSourceDocumentTypes';
import useGetDocumentTypes from '../../../_services/GeotrakService/hooks/useGetDocumentTypes';

const useDataSourceDocumentTypeForm = (dataSourceId, dataSourceDocumentTypeId) => {
    const { documentTypes } = useGetDocumentTypes();
    const { dataSourceDocumentTypes, isLoading } = useGetDataSourceDocumentTypes(dataSourceId);
    const [values, setValues] = useState({
        documentTypeId: '',
    });

    const validDocumentTypes = [];
    documentTypes.forEach((documentType) => {
        if (dataSourceDocumentTypes.filter(
            (dataSourceDocumentType) => dataSourceDocumentType.documentType.id === documentType.id
        ).length < 1) {
            validDocumentTypes.push(documentType);
        }
    });

    const isValid = () => values.documentTypeId !== '';

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    useEffect(() => {
        if (dataSourceDocumentTypeId && dataSourceDocumentTypes.length > 0) {
            setValues({
                documentTypeId: dataSourceDocumentTypes.find(
                    (type) => type.id === parseInt(dataSourceDocumentTypeId, 10)
                ).documentType.id,
            });
        }
    }, [dataSourceDocumentTypes]);

    return {
        documentTypes: dataSourceDocumentTypeId ? documentTypes : validDocumentTypes,
        isLoading,
        isValid,
        onFormUpdate,
        values,
    };
};

export default useDataSourceDocumentTypeForm;
