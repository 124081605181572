import PropTypes from 'prop-types';
import { ApplicationCommentType } from '../Models';

class IUpdateApplicationCommentTypeRequest {
    constructor() {
        this.data = {};
    }

    static load(appCommentType) {
        if (!appCommentType) {
            throw Error('ApplicationCommentType is required');
        }

        if (!(appCommentType instanceof ApplicationCommentType)) {
            throw Error('Unexpected type for ApplicationCommentType');
        }

        const request = new IUpdateApplicationCommentTypeRequest();
        request.data = appCommentType;

        request.validate();
        return request;
    }

    validate() {
        if (!this.data.id) {
            throw Error('id is required');
        }

        if (!this.data.commentTypeId) {
            throw Error('commentTypeId is required');
        }
    }
}

export default IUpdateApplicationCommentTypeRequest;

IUpdateApplicationCommentTypeRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
