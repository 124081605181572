import React from 'react';
import PropTypes from 'prop-types';
import './LabelControl.css';

const labelControl = function statelessFunctionComponentClass(props) {
    const { htmlFor, children, style } = props;
    return (
        <div className="label-control-container label-control-tooltip">
            <label
                className="form-label"
                htmlFor={htmlFor}
                data-toggle="tooltip"
                data-placement="top"
                style={style}
            >
                {children}
            </label>
            <span className="label-control-tooltiptext">{children}</span>
        </div>
    );
};

export default labelControl;

labelControl.propTypes = {
    htmlFor: PropTypes.string,
    children: PropTypes.string,
    style: PropTypes.shape({
        display: PropTypes.string,
        marginBottom: PropTypes.string,
    }),
};

labelControl.defaultProps = {
    htmlFor: '',
    children: '',
    style: {
        display: '',
        marginBottom: '',
    },
};
