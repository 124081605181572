import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import { ViewService } from '../../../_services/GeotrakService';
import ViewBuilderSummary from './ViewBuilderSummary';
import { View } from '../../../_services/GeotrakService/Models';
import IGetViewsRequest from '../../../_services/GeotrakService/interfaces/IGetViewsRequest';

const viewPicker = ({ previousStep, nextStep, jobProperties }) => {
    const {
        application, role, dataSource, isImport, onImport, dataSources,
    } = jobProperties;
    const [views, setViews] = useState([]);
    const [selectedView, setSelectedView] = useState('');
    const [error, setError] = useState(false);
    const viewService = new ViewService();

    const getViews = () => {
        const selectedDataSource = dataSources.find((source) => source.name === dataSource);
        const request = IGetViewsRequest.load(selectedDataSource.id);
        viewService.getSystemViews(request).then((viewList) => {
            setViews(viewList);
        }).catch((err) => {
            setError(`Error Getting Views. ${err.message || err}`);
        });
    };

    const selectView = (e) => {
        const selectedDataSource = dataSources.find((source) => source.name === dataSource);
        setSelectedView(e);
        if (isImport) {
            onImport(selectedDataSource.id, e);
        }
    };

    const renderViewPicker = () => (
        <select defaultValue="--Select a view--" onChange={(e) => selectView(e.target.value)} className="mb-3">
            <option disabled value="--Select a view--">--Select a view--</option>
            {views.map((view) => (
                <option key={view.name} value={view.name}>{view.name}</option>
            ))}
        </select>
    );

    useEffect(() => {
        getViews();
    }, []);

    const isValid = () => selectedView !== '';

    return (
        <div className="col-md-8 offset-md-2 text-center">
            <h2 className="Admin">View</h2>
            <ViewBuilderSummary application={application} role={role} dataSource={dataSource} />
            {views.length === 0 && <h5>No views found for this application and layer.</h5>}
            {views.length > 0 && renderViewPicker()}
            {!isImport && (
                <>
                    <ButtonContainer className="mt-2">
                        <ButtonPrimary
                            onClick={() => nextStep({
                                views,
                                view: new View(),
                            })}
                            disabled={selectedView !== ''}
                        >
                            Create New View
                        </ButtonPrimary>
                    </ButtonContainer>
                    <ButtonContainer
                        error={error}
                        onErrorDismiss={() => setError(null)}
                    >
                        <ButtonPrimary
                            onClick={() => nextStep({
                                views,
                                view: views.filter((viewSummary) => viewSummary.name === selectedView)[0],
                            })}
                            disabled={!isValid()}
                        >
                            Next
                        </ButtonPrimary>
                        <ButtonPrimary onClick={previousStep}>
                            Back
                        </ButtonPrimary>
                    </ButtonContainer>
                </>
            )}
        </div>
    );
};

export default viewPicker;

viewPicker.propTypes = {
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    jobProperties: PropTypes.instanceOf(Object),
};

viewPicker.defaultProps = {
    nextStep: () => null,
    previousStep: () => null,
    jobProperties: {
        application: '',
        role: '',
        dataSource: '',
        isImport: false,
        onImport: () => null,
    },
};
