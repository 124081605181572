class IProjectRecordRequest {
    constructor() {
        this.dataSourceId = null;
        this.where = null;
        this.view = null;
        this.orderByColumn = null;
        this.orderByDirection = null;
    }

    static load(dataSourceId, query, activeView, sort) {
        if (!dataSourceId) {
            throw Error('dataSourceId is required');
        }
        if (!activeView) {
            throw Error('activeView is required');
        }

        const request = new IProjectRecordRequest();
        request.dataSourceId = dataSourceId;
        request.where = query;
        request.view = activeView;

        if (sort) {
            request.orderByColumn = sort.column;
            request.orderByDirection = sort.direction;
        }

        request.validate();

        return request;
    }

    static copy(data) {
        const request = new IProjectRecordRequest();
        request.dataSourceId = data.dataSourceId;
        request.where = data.where;
        request.view = data.view;
        request.orderByColumn = data.orderByColumn;
        request.orderByDirection = data.orderByDirection;
        request.validate();
        return request;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('dataSource is required');
        }
        if (!this.view) {
            throw Error('view is required');
        }
    }
}

export default IProjectRecordRequest;
