import React, { useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { UserService } from '../../../../_services';
import '../users.css';

const reactivateButton = ({ user, isValid }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const userService = new UserService();
    const history = useHistory();

    const reactivateUser = async () => {
        try {
            setIsLoading(true);
            const updatedUser = { ...user };
            updatedUser.IsDeleted = false;
            await userService.modifyUser(user.Id, updatedUser);
            setIsLoading(false);
            history.push('/admin/users');
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    };

    if (!user.IsDeleted) {
        return null;
    }

    return (
        <div className="user-toolbar user-button">
            {error && <div className="text-danger user-error" id="reactivate-user-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="reactivate-user-button"
                text="Reactivate User"
                loadingText="Reactivating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={reactivateUser}
            />
        </div>
    );
};

export default reactivateButton;

reactivateButton.propTypes = {
    user: PropTypes.instanceOf(Object),
    isValid: PropTypes.func,
};

reactivateButton.defaultProps = {
    user: {},
    isValid: () => null,
};
