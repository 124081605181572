import PropTypes from 'prop-types';
import GeocoderJob from '../Models/GeocoderJob';

class ICreateGeocoderJob {
    constructor() {
        this.data = {};
    }

    static load(job) {
        if (!job) {
            throw Error('Job is required');
        }

        if (!(job instanceof GeocoderJob)) {
            throw Error('Unexpected type for geocoderJob');
        }

        const request = new ICreateGeocoderJob();
        request.data = job;
        return request;
    }
}

export default ICreateGeocoderJob;

ICreateGeocoderJob.propTypes = {
    data: PropTypes.instanceOf(Object),
};
