import React from 'react';
import { CgPassword } from 'react-icons/cg';
import { UserService } from '../../_services';
import NavIconContainer from '../../utilComponents/NavIconContainer';

const userProfile = () => {
    const auth = new UserService();
    const user = auth.getCurrentUser();
    const hasDatabasePermissions = auth.hasDatabasePermissions();

    return (
        <div className="container">
            <div className="text-center">
                <h1 className="mt-3 mb-3">{user.FullName}</h1>
                <NavIconContainer
                    isAccessible={hasDatabasePermissions}
                    icon={CgPassword}
                    link={`/user/${user.Id}/change-database-password`}
                    title="Change Database Password"
                />
            </div>
        </div>
    );
};

export default userProfile;
