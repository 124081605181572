import PropTypes from 'prop-types';

class IGetViewsRequest {
    constructor() {
        this.dataSourceId = null;
    }

    static load(dataSourceId) {
        if (!dataSourceId) {
            throw Error('dataSourceId is required');
        }
        const request = new IGetViewsRequest();
        request.dataSourceId = dataSourceId;
        return request;
    }
}

export default IGetViewsRequest;

IGetViewsRequest.propTypes = {
    dataSourceId: PropTypes.string,
};
