import { useState } from 'react';
import useGetDatabases from '../../../_services/GeotrakService/hooks/useGetDatabases';

const useDatabaseForm = () => {
    const { databases } = useGetDatabases();
    const [values, setValues] = useState({
        name: '',
    });

    const isValid = () => values.name !== '' && values.name;

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    return {
        values, isValid, onFormUpdate, databases,
    };
};

export default useDatabaseForm;
