import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import TwoWayQueryBuilder from 'react-two-way-querybuilder';
import QueryParser from 'react-two-way-querybuilder/dist/helpers/QueryParser';
import {
    supportedCombinators, supportedOperators, getTokensArray, setCombinatorValues
} from './query-helpers';
import '../stylesheets/Search.css';
import ErrorMessage from '../../utilComponents/ErrorMessage';

const styleClassNames = {
    primaryBtn: 'query-builder-btn custom-primary-btn',
    deleteBtn: 'query-builder-btn custom-delete-btn',
    rule: 'rule',
    condition: 'condition',
    select: 'custom-select',
    input: 'text-center custom-input-text',
    txtArea: 'queryText',
};

QueryParser.getTokensArray = getTokensArray;

class TwoWayQueryBuilderWrapper extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    componentDidCatch = () => {
        this.setState({ error: 'There was an error with Two Way QueryBuilder' });
    };

    componentDidMount = () => {
        const { query } = this.props;
        setCombinatorValues(query);
    };

    renderQueryBuilder = () => {
        const { fields, onChange, query } = this.props;
        return (
            <div className="row align-items-center pb-2">
                <TwoWayQueryBuilder
                    fields={fields}
                    onChange={onChange}
                    config={{
                        query,
                        styles: styleClassNames,
                        operators: supportedOperators,
                        combinators: supportedCombinators,
                    }}
                />
            </div>
        );
    };

    render() {
        const { error } = this.state;
        if (error) {
            return (
                <ErrorMessage
                    message={error}
                    onDismiss={() => this.setState({ error: null })}
                />
            );
        }
        return this.renderQueryBuilder();
    }
}

const optionShape = PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
const inputShape = PropTypes.shape({ type: PropTypes.string, options: PropTypes.arrayOf(optionShape) });
const fieldShape = PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    operators: PropTypes.string,
    input: inputShape,
});

TwoWayQueryBuilderWrapper.defaultProps = {
    query: null,
};

TwoWayQueryBuilderWrapper.propTypes = {
    query: PropTypes.string,
    fields: PropTypes.arrayOf(fieldShape).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TwoWayQueryBuilderWrapper;
