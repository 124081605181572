import React from 'react';
import { useHistory } from 'react-router';
import useGetEtlJobs from '../../../_services/EtlService/hooks/useGetEtlJobs';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import AdminContainer from '../AdminContainer';

const adminRoleEtls = ({ match }) => {
    const history = useHistory();
    const { params: { moduleId, roleId } } = match;
    const { etlJobs, isLoading } = useGetEtlJobs(roleId);

    if (isLoading) {
        return <LoadingFullScreen id="etl-job-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="ETLs">
                <ButtonContainer>
                    <ButtonPrimary
                        id="new-etl-button"
                        onClick={() => history.push(
                            `/admin/modules/${moduleId}/roles/${roleId}/etls/new`
                        )}
                    >
                        New ETL
                    </ButtonPrimary>
                </ButtonContainer>
                {etlJobs.length < 1 && (
                    <div className="card bg-light rounded-0 text-center">
                        <div className="card-body">
                            <h6>No ETLs found.</h6>
                        </div>
                    </div>
                )}
                <div className="list-group">
                    {etlJobs.map((etlJob) => (
                        <button
                            id={etlJob.id}
                            type="button"
                            key={etlJob.id}
                            className="list-group-item list-group-item-action text-start"
                            onClick={() => history.push(
                                `/admin/modules/${moduleId}/roles/${roleId}/etls/${etlJob.id}`
                            )}
                        >
                            <span id="etl-job-name">{etlJob.jobType.name}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminRoleEtls;
