import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdSettings } from 'react-icons/md';
import { useHistory } from 'react-router';
import { ViewService } from '../../_services/GeotrakService';
import { addAlertThunk } from '../../actions/errorActions';
import { ButtonContainer } from '../../utilComponents/styledComponents';
import FieldShuffler from './FieldShuffler';
import { View } from '../../_services/GeotrakService/Models';
import { Create, Delete, Update } from './Actions';
import IGetViewsRequest from '../../_services/GeotrakService/interfaces/IGetViewsRequest';
import IGetViewRequest from '../../_services/GeotrakService/interfaces/IGetViewRequest';
import useGetDataSource from '../../_services/GeotrakService/hooks/useGetDataSource';

const viewsDrawer = () => {
    const [open, setIsOpen] = useState(false);
    const [fields, setFields] = useState([]);
    const [fieldPickList, setFieldPickList] = useState([]);
    const [views, setViews] = useState([]);
    const query = useSelector((state) => state.activeConfiguration.query);
    const view = useSelector((state) => state.activeConfiguration.view);
    const [viewName, setViewName] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const history = useHistory();
    const searchParameters = new URLSearchParams(history.location.search);
    const { dataSource } = useGetDataSource(searchParameters.get('data-source'));
    const viewService = new ViewService();
    const dispatch = useDispatch();
    const drawerCloseRef = useRef(null);

    const getViewFields = () => {
        const request = IGetViewRequest.load(dataSource.id, view);
        viewService.getView(request)
            .then((viewRecord) => {
                setIsDefault(viewRecord.isDefault);
                setFields(viewRecord.fields);
            }).catch((err) => {
                dispatch(addAlertThunk('danger', 'Error Getting View', err));
            });
    };

    const getFieldPickList = () => {
        const pickListRequest = IGetViewRequest.load(dataSource.id, 'All');
        return viewService.getSystemView(pickListRequest).then(
            (viewRecord) => setFieldPickList(viewRecord.fields)
        ).catch((err) => {
            dispatch(addAlertThunk('danger', 'Error Getting Field Options', err));
        });
    };

    const getViewsList = () => {
        const request = IGetViewsRequest.load(dataSource.id);
        viewService.getViews(request)
            .then((records) => {
                setViews(records);
            }).catch((err) => {
                dispatch(addAlertThunk('danger', 'Error Getting View List', err));
            });
    };

    const isViewNameUsed = () => views.some(
        (existingView) => existingView.name.toLowerCase() === viewName.toLowerCase()
    );

    const getDefaultView = () => views.find((existingView) => existingView.isDefault).name;

    const isUserViewOwner = () => {
        const selectedViews = views.filter(
            (existingView) => existingView.name.toLowerCase() === viewName.toLowerCase()
        );
        if (selectedViews.length > 0) {
            return selectedViews[0].owner !== null;
        }
        return true;
    };

    const isViewDefaultView = () => {
        if (views.find((existingView) => existingView.name === viewName && existingView.isDefault)) {
            return true;
        }
        return false;
    };

    const isDefaultAllowed = () => (isUserViewOwner()
    && !views.some((existingView) => existingView.isDefault))
    || isViewDefaultView();

    useEffect(() => {
        if (dataSource.id) {
            setViewName(view);
            getViewFields();
            getViewsList();
            getFieldPickList();
        }
    }, [view, dataSource, open]);

    return (
        <>
            <button
                type="button"
                className="btn bg-transparent border-0"
                data-bs-toggle="offcanvas"
                data-bs-target="#views-drawer"
                title="View Settings"
                ref={drawerCloseRef}
                onClick={() => setIsOpen(!open)}
            >
                <MdSettings size={30} />
            </button>
            <div
                className="offcanvas offcanvas-end w-50"
                data-bs-scroll="true"
                tabIndex="-1"
                id="views-drawer"
            >
                <div className="offcanvas-header">
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body">
                    <div className="container col-md-12">
                        <h2 className="buffer">View Settings</h2>
                        <h5>Current Query:</h5>
                        <p>{query}</p>
                        <form className="text-start">
                            <div className="mb-3">
                                <label htmlFor="view-name-input" className="form-label">
                                    Title
                                </label>
                                <input
                                    value={viewName}
                                    onChange={(e) => setViewName(e.target.value)}
                                    id="view-name-input"
                                />
                            </div>
                            <div className="form-check mb-3">
                                <label htmlFor="default-view-input" className="form-check-label">
                                    <input
                                        id="default-view-input"
                                        name="defaultViewInput"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={isDefault}
                                        onChange={() => setIsDefault(!isDefault)}
                                        disabled={!isDefaultAllowed()}
                                    />
                                    Default View
                                </label>
                            </div>
                            {!isViewDefaultView() && !isDefaultAllowed() && (
                                <div className="text-danger">
                                    <i>
                                        Saving default view not allowed.
                                    </i>
                                    <i>
                                        {isUserViewOwner()
                                            ? `  "${getDefaultView()}" is the current default.`
                                            : '  You do not own this view.'}
                                    </i>
                                </div>
                            )}
                        </form>
                        <ButtonContainer className="justify-content-center">
                            <Delete
                                view={View.create(viewName, query, isDefault)}
                                closeDrawer={() => {
                                    drawerCloseRef.current.click();
                                    setIsOpen(false);
                                }}
                                disabled={!(isViewNameUsed() && isUserViewOwner())}
                                dataSource={dataSource}
                            />
                            <Create
                                disabled={isViewNameUsed() || viewName === ''}
                                view={View.create(viewName, query, isDefault, fields)}
                                closeDrawer={() => {
                                    drawerCloseRef.current.click();
                                    setIsOpen(false);
                                }}
                                dataSource={dataSource}
                            />
                            <Update
                                closeDrawer={() => {
                                    drawerCloseRef.current.click();
                                    setIsOpen(false);
                                }}
                                disabled={!(isViewNameUsed() && isUserViewOwner())}
                                view={View.create(viewName, query, isDefault, fields)}
                                dataSource={dataSource}

                            />
                        </ButtonContainer>
                        <FieldShuffler
                            usedFields={fields}
                            pickListFields={fieldPickList}
                            dataSource={dataSource}
                            onFieldsUpdate={(fieldList) => setFields(fieldList)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default viewsDrawer;
