import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import '../dataSources.css';
import { DataSource } from '../../../../_services/GeotrakService/Models';
import useDeleteDataSource from '../../../../_services/GeotrakService/hooks/useDeleteDataSource';

const deleteButton = ({ dataSource, moduleId }) => {
    const {
        deleteDataSource, error, isLoading, isSuccessful,
    } = useDeleteDataSource();

    if (!dataSource.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${dataSource.moduleRoleId}/data-sources`} />;
    }

    return (
        <div className="data-source-toolbar data-source-button">
            {error && <div className="text-danger data-source-error" id="delete-data-source-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-data-source-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteDataSource(dataSource)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    dataSource: PropTypes.instanceOf(DataSource),
    moduleId: PropTypes.string,
};

deleteButton.defaultProps = {
    dataSource: new DataSource(),
    moduleId: null,
};
