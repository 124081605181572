import PropTypes from 'prop-types';

export default class Project {
    constructor(name, id = null) {
        this.name = name;
        this.id = id;
    }

    static load(response) {
        return new Project(
            response.ProjectTag,
            response.Id
        );
    }

    validate() {
        if (!this.name || this.name === '') {
            throw Error('Name is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            ProjectTag: this.name,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

Project.propTypes = {
    name: PropTypes.string,
    id: PropTypes.number,
};
