import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoaderButton from '../../../utilComponents/LoaderButton';
import CommentApplicationService from '../../../_services/CommentService/CommentApplicationService';
import { addAlertThunk } from '../../../actions/errorActions';
import Modal from '../../../components/Modals/Modal';

class NewCommentApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            isWorking: false,
            isValid: false,
            appName: '',
        };

        this.CommentApplicationService = new CommentApplicationService();
    }

    createApplication = () => {
        const {
            appTier, parentAppId, updateApplicationOptions, addAlertAction,
        } = this.props;
        const { appName } = this.state;
        this.setState({ isWorking: true });
        const body = {
            ParentApplicationId: parentAppId,
            AppKey: appTier,
            AppValue: appName,
        };
        this.CommentApplicationService.postApplication(body).then((result) => {
            this.setState({
                showModal: false,
                isWorking: false,
                isValid: false,
                appName: '',
            });
            updateApplicationOptions(result);
        }).catch((err) => {
            addAlertAction('danger', 'Error Saving', err.message || err);
        });
    }

    validate = (e) => {
        const appName = e.target.value;
        this.setState({
            appName,
            isValid: appName.length,
        });
    }

    renderModal = () => {
        const {
            showModal, isWorking, isValid, appName,
        } = this.state;
        const { appTier } = this.props;
        return (
            <Modal
                showModal={showModal}
                header={`Add new ${appTier}`}
                onCancel={() => this.setState({ showModal: false })}
                component={(
                    <input name="appName" onChange={this.validate} value={appName} />
                )}
                footer={(
                    <LoaderButton
                        type="submit"
                        disabled={!isValid}
                        isLoading={isWorking}
                        text="Create"
                        loadingText="Creating..."
                        onClick={this.createApplication}
                    />
                )}
            />
        );
    }

    render() {
        const { appTier } = this.props;
        return (
            <>
                <ButtonPrimary onClick={() => this.setState({ showModal: true })}>{`Add new ${appTier}`}</ButtonPrimary>
                {this.renderModal()}
            </>
        );
    }
}

const mapDispatchToProps = ({
    addAlertAction: addAlertThunk,
});

export default connect(null, mapDispatchToProps)(NewCommentApplication);

NewCommentApplication.propTypes = {
    appTier: PropTypes.string,
    parentAppId: PropTypes.string,
    updateApplicationOptions: PropTypes.func,
    addAlertAction: PropTypes.func,
};

NewCommentApplication.defaultProps = {
    appTier: '',
    parentAppId: '',
    updateApplicationOptions: () => null,
    addAlertAction: () => null,
};
