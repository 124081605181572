import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchConfig from '../utilComponents/SearchConfig';
import { DataSourceService } from '../_services/GeotrakService';

export default class DataSourceSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSources: [],
            selectedDataSource: '',
        };

        this.dataSourceService = new DataSourceService();
    }

  onConfigSelected = async (role) => {
      const dataSourceList = await this.dataSourceService.getDataSources(role.id);
      const { onDataSourceSelected } = this.props;

      this.setState({
          dataSources: dataSourceList,
          selectedDataSource: '',
      });
      onDataSourceSelected(null);
  };

  handleDataSourceSelect = (evt) => {
      const { onDataSourceSelected } = this.props;
      const { dataSources } = this.state;
      const selectedDataSource = dataSources.find((dataSource) => dataSource.name === evt.target.value);
      if (selectedDataSource) {
          this.setState({ selectedDataSource: evt.target.value });
          onDataSourceSelected(selectedDataSource);
      } else {
          onDataSourceSelected(null);
      }
  };

  render() {
      const { dataSources, selectedDataSource } = this.state;
      return (
          <div id="datasource-selector">
              <h4>1. Find an app.</h4>
              <SearchConfig onAppConfigSelected={this.onConfigSelected} />
              {dataSources.length > 0 && (
                  <div className="datasource-selector-options">
                      <h4>2. Choose a data source.</h4>
                      <select
                          value={selectedDataSource}
                          onChange={this.handleDataSourceSelect}
                      >
                          <option disabled value="">--Select a data source--</option>
                          {dataSources.map((dataSource) => (
                              <option key={dataSource.id} value={dataSource.name}>{dataSource.alias}</option>
                          ))}
                      </select>
                  </div>
              )}
          </div>
      );
  }
}

DataSourceSelector.propTypes = {
    onDataSourceSelected: PropTypes.func,
};

DataSourceSelector.defaultProps = {
    onDataSourceSelected: () => null,
};
