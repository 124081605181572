import PropTypes from 'prop-types';
import PrimaryKey from '../../../helpers/models/PrimaryKey';

class IUploadFileRequest {
    constructor() {
        this.databaseName = null;
        this.tableName = null;
        this.primaryKey = new PrimaryKey();
        this.file = null;
        this.documentType = null;
        this.folder = null;
    }

    static load(databaseName, tableName, primaryKey, file, documentType, folder = null) {
        if (!databaseName) {
            throw Error('database name is required');
        }
        if (!primaryKey) {
            throw Error('key is required');
        }
        if (!(primaryKey instanceof PrimaryKey)) {
            throw Error('unexpected data type for key');
        }
        if (!file) {
            throw Error('file is required');
        }
        if (!documentType) {
            throw Error('document type is required');
        }

        const request = new IUploadFileRequest();
        request.databaseName = databaseName;
        request.tableName = tableName;
        request.primaryKey = primaryKey;
        request.file = file;
        request.folder = folder;
        request.documentType = documentType;
        return request;
    }

    toFormData() {
        const data = new FormData();
        data.append('file', this.file);
        data.append('databaseName', this.databaseName);
        data.append('tableName', this.tableName);
        data.append('externalKeyColumnName', this.primaryKey.key);
        data.append('externalKey', this.primaryKey.value);
        data.append('documentType', this.documentType);
        data.append('folder', this.folder);
        data.append('fileName', this.file.name);

        return data;
    }
}

export default IUploadFileRequest;

IUploadFileRequest.propTypes = {
    databaseName: PropTypes.string,
    tableName: PropTypes.string,
    primaryKey: PropTypes.instanceOf(PrimaryKey),
    file: PropTypes.instanceOf(Object),
    folder: PropTypes.string,
    documentType: PropTypes.string,
};
