import PropTypes from 'prop-types';
import { DataSource } from '../Models';

class IGetSpatialRecordsRequest {
    constructor() {
        this.data = {
            dataSourceId: null,
            where: null,
        };
    }

    static load(dataSource, where) {
        if (!dataSource) {
            throw Error('data source is required');
        }
        if (!(dataSource instanceof DataSource)) {
            throw Error('invalid type for data source');
        }

        const request = new IGetSpatialRecordsRequest();
        request.data.dataSourceId = dataSource.id;
        if (where) {
            request.data.where = where;
        }
        request.validate();
        return request;
    }

    validate() {
        if (!this.data.dataSourceId) {
            throw Error('data source id is required');
        }
    }
}

export default IGetSpatialRecordsRequest;

IGetSpatialRecordsRequest.propTypes = {
    data: PropTypes.instanceOf(Object),
};
