import PropTypes from 'prop-types';

export default class RequestType {
    constructor(name, alias, id = null) {
        this.name = name;
        this.alias = alias;
        this.id = id;
    }

    static load(response) {
        return new RequestType(
            response.Code,
            response.Name,
            response.Id
        );
    }

    validate() {
        if (!this.name || this.name === '') {
            throw Error('Name is required');
        }

        if (!this.alias || this.alias === '') {
            throw Error('Alias is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            Code: this.name,
            Name: this.alias,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }
}

RequestType.propTypes = {
    name: PropTypes.string,
    alias: PropTypes.string,
    id: PropTypes.number,
};
