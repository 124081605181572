import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetNotificationFields from '../../../_services/NotificationService/hooks/field/useGetNotificationFields';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminNotificationsFields = ({ match }) => {
    const { params: { id } } = match;
    const history = useHistory();
    const { notificationFields, isLoading } = useGetNotificationFields(id);

    if (isLoading) {
        return <LoadingFullScreen id="notification-field-form" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Notification Fields">
                <ButtonContainer>
                    <ButtonPrimary id="new-notification-field-button" onClick={() => history.push(`/admin/notifications/${id}/fields/new`)}>
                        New Field
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group">
                    {notificationFields.map((field) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action text-start"
                            key={field.id}
                            onClick={() => history.push(`/admin/notifications/${id}/fields/${field.id}`)}
                        >
                            {field.name}
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminNotificationsFields;
