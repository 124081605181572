import PropTypes from 'prop-types';

export default class CommentType {
    constructor() {
        this.id = null;
        this.name = null;
    }

    static load(response) {
        const type = new CommentType();

        if (response) {
            type.id = response.Id;
            type.name = response.Name;
        }

        return type;
    }

    validate() {
        if (!this.id) {
            throw Error('Id is required');
        }

        if (!this.name) {
            throw Error('Name is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const entity = {
            Name: this.name,
        };

        if (this.id) {
            entity.Id = this.id;
        }

        return entity;
    }
}

CommentType.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
};
