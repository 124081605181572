import uuidv4 from 'uuid/v4';

export default class Notification {
    constructor() {
        this.alertLevels = {
            SUCCESS: 'success',
            DANGER: 'danger',
            WARNING: 'warning',
            INFO: 'info',
            NONE: 'none',
        };
        this.alertTypes = {
            GENERIC: {
                name: 'generic',
                isUnique: false,
            },
            DATABASE_PASSWORD: {
                name: 'databasePassword',
                isUnique: true,
            },
        };
        this.id = uuidv4();
        this.alertLevel = null;
        this.message = null;
        this.navigation = null;
        this.alertType = null;
    }

    static load(alertLevel, message, navigation, alertType) {
        const notification = new Notification();

        notification.alertLevel = alertLevel;
        notification.message = message;
        notification.navigation = navigation;
        notification.alertType = alertType;

        return notification;
    }

    static loadObject(object) {
        const notification = new Notification();

        notification.id = object.id;
        notification.alertLevel = object.alertLevel;
        notification.message = object.message;
        notification.navigation = object.navigation;
        notification.alertType = object.alertType;

        return notification;
    }
}
