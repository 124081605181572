import React from 'react';
import PropTypes from 'prop-types';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import './stylesheets/SortableHeader.css';

const sortableHeader = ({
    onSort, sortKey, sortConfig, title,
}) => (
    <span
        className="sort-header"
        role="button"
        tabIndex={0}
        onClick={() => onSort(sortKey)}
        onKeyDown={() => onSort(sortKey)}
    >
        {(sortConfig.key === sortKey && sortConfig.direction === 'descending') && (
            <BsArrowUp size={20} className="sort-button" />
        )}
        {(sortConfig.key === sortKey && sortConfig.direction === 'ascending') && (
            <BsArrowDown size={20} className="sort-button" />
        )}
        <span>{title}</span>
    </span>
);

export default sortableHeader;

sortableHeader.propTypes = {
    onSort: PropTypes.func,
    sortKey: PropTypes.string,
    sortConfig: PropTypes.instanceOf(Object),
    title: PropTypes.string,
};

sortableHeader.defaultProps = {
    onSort: () => null,
    sortKey: null,
    sortConfig: {},
    title: null,
};
