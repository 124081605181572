import PropTypes from 'prop-types';

export default class Renderer {
    constructor() {
        this.id = null;
        this.dataSourceId = null;
        this.size = null;
        this.color = null;
    }

    static load(response) {
        const renderer = new Renderer();

        if (response) {
            renderer.id = response.id;
            renderer.dataSourceId = response.dataSourceId;
            renderer.size = response.size;
            renderer.color = response.color;
        }

        return renderer;
    }

    validate() {
        if (!this.dataSourceId) {
            throw Error('dataSourceId is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            dataSourceId: this.dataSourceId,
            size: this.size,
            color: this.color,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

Renderer.propTypes = {
    id: PropTypes.number,
    dataSourceId: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
};
