import { ADD_ALERT, REMOVE_ALERT } from '../CONSTANTS/types';

export default (state = [], action = { type: '', payload: {} }) => {
    switch (action.type) {
    case ADD_ALERT:
        return state.concat(action.payload);
    case REMOVE_ALERT:
        return state.filter((alert) => alert.id !== action.payload);
    default:
        return state;
    }
};
