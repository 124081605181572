import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetDocumentTypes from '../../../_services/GeotrakService/hooks/useGetDocumentTypes';
import './documentTypes.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDocumentTypes = () => {
    const history = useHistory();
    const { documentTypes, isLoading } = useGetDocumentTypes();

    if (isLoading) {
        return <LoadingFullScreen id="document-type-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Document Types">
                <ButtonContainer>
                    <ButtonPrimary id="new-document-type-button" onClick={() => history.push('/admin/document-types/new')}>
                        New Document Type
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group document-type-container">
                    {documentTypes.map((documentType) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action text-start document-type-link"
                            key={documentType.id}
                            onClick={() => history.push(`/admin/document-types/${documentType.id}`)}
                        >
                            <span id="document-type-name">{documentType.name}</span>
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDocumentTypes;
