import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import { Project } from '../../../_services/CheckRequestService/Models';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import Modal from '../../../components/Modals/Modal';
import SuccessMessage from '../../../utilComponents/SuccessMessage';
import AdminContainer from '../AdminContainer';

const checkRequestAdminService = new CheckRequestAdminService();

const projectPicker = ({ jobProperties: { requestFormId }, nextStep }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setProject] = useState('');
    const [projectName, setProjectName] = useState('');
    const [showNewProject, setNewProject] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const getProjects = () => {
        checkRequestAdminService.getProjects().then((projectsResult) => {
            setProjects(projectsResult);
        }).catch((err) => {
            setError(`Error Getting Projects. ${err.message || err}`);
        });
    };

    const getRequestForm = () => {
        checkRequestAdminService.getRequestForm(requestFormId).then((requestForm) => {
            setProject(requestForm.project.name);
        }).catch((err) => {
            setError(`Error Getting Request Form. ${err.message || err}`);
        });
    };

    const createProject = () => {
        const newProject = new Project(projectName);
        checkRequestAdminService.createProject(newProject).then(() => {
            setNewProject(false);
            setProjectName('');
            getProjects();
            setSuccess('Project Created Successfully');
        }).catch((err) => {
            setError(`Error Creating Project. ${err.message || err}`);
        });
    };

    const startRequestForm = () => {
        const project = projects.find((projectResult) => projectResult.name === selectedProject);
        nextStep({ project });
    };

    const isProjectValid = () => projects.filter((project) => project.name === selectedProject).length > 0;

    const isNewProjectValid = () => projectName !== '';

    useEffect(() => {
        getProjects();
        if (requestFormId) {
            getRequestForm();
        }
    }, []);

    return (
        <AdminContainer title="Project">
            <form>
                <div className="mb-3">
                    <label htmlFor="request-project" className="form-label">
                        Project
                        {' '}
                        <span className="text-danger">*</span>
                    </label>
                    <select
                        value={selectedProject}
                        id="request-project"
                        onChange={(e) => setProject(e.target.value)}
                    >
                        <option disabled value="">--Select a project--</option>
                        {projects.map((project) => (
                            <option key={project.name} value={project.name}>{project.name}</option>
                        ))}
                    </select>
                </div>
                <ButtonContainer
                    error={error}
                    onErrorDismiss={() => setError(null)}
                >
                    <LoaderButton
                        text="Save"
                        disabled={!isProjectValid()}
                        onClick={startRequestForm}
                    />
                    {!requestFormId
                    && (
                        <ButtonPrimary onClick={() => setNewProject(true)}>
                            New Project
                        </ButtonPrimary>
                    )}
                </ButtonContainer>
                {success && (
                    <SuccessMessage
                        message={success}
                        onDismiss={() => setSuccess(null)}
                    />
                )}
            </form>
            <Modal
                showModal={showNewProject}
                header="New Project"
                onCancel={() => setNewProject(false)}
                component={(
                    <form>
                        <div className="mb-3">
                            <label htmlFor="project-name" className="form-label">
                                Name
                                {' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                value={projectName}
                                id="project-name"
                                onChange={(e) => setProjectName(e.target.value)}
                                placeholder="Name"
                            />
                        </div>
                    </form>
                )}
                footer={(
                    <LoaderButton
                        text="Save"
                        disabled={!isNewProjectValid()}
                        onClick={createProject}
                    />
                )}
            />
        </AdminContainer>
    );
};

export default projectPicker;

projectPicker.propTypes = {
    jobProperties: PropTypes.instanceOf(Object),
    nextStep: PropTypes.func,
};

projectPicker.defaultProps = {
    jobProperties: {
        requestFormId: null,
    },
    nextStep: () => null,
};
