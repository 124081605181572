/* eslint no-underscore-dangle: ["error", { "allow": ["_dismissAlerts"] }] */
import Log from '../Log';

const LEFT_BUTTON = 0;
const CLICKABLE_TARGETS = ['a', 'button', 'svg'];
const EVENT_MOUSE_DOWN = 'mousedown';
const EVENT_DOUBLE_CLICK = 'dblclick';

// close all alerts
const _dismissAlerts = () => {
    Log.debug('dismiss all alerts');
    const eleAlerts = document.getElementsByClassName('alert-dismissable');
    Array.prototype.filter.call(eleAlerts, (a) => a.getElementsByClassName('close')[0].click());
};

// this will close all open alerts if the user clicks any button, anchor or svg
const dismissAlertsEffect = () => {
    const isDecendentOfAlert = (evt) => {
        const { target } = evt;
        const isDecendent = target.closest('.alert');
        Log.debug(`isDecendentOfAlert ${isDecendent}`);
        return isDecendent;
    };

    // LEFT CLICK is only valid, if it's not in an alert box
    const onLeftClick = (evt) => {
        const { button, target: { tagName: targetTagName } } = evt;
        if (button === LEFT_BUTTON
            && CLICKABLE_TARGETS.includes(targetTagName.toLowerCase())
            && !isDecendentOfAlert(evt)) {
            _dismissAlerts();
        }
    };

    // DOUBLE CLICK is only valid, if it IS in an alert box
    const onDoubleClick = (evt) => {
        if (evt.type === EVENT_DOUBLE_CLICK && isDecendentOfAlert(evt)) {
            _dismissAlerts();
        }
    };

    // bindings
    document.addEventListener(EVENT_MOUSE_DOWN, onLeftClick);
    document.addEventListener(EVENT_DOUBLE_CLICK, onDoubleClick);

    return () => {
        // remove listeners so they dont stack
        document.removeEventListener(EVENT_MOUSE_DOWN, onLeftClick);
        document.removeEventListener(EVENT_DOUBLE_CLICK, onDoubleClick);
    };
};

export { _dismissAlerts as dismissAlerts, dismissAlertsEffect };
