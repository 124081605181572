import React, { Children } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';

const ButtonContainer = ({
    className, children, error, onErrorDismiss,
}) => {
    const childNodes = Children.toArray(children);

    return (
        <div className="container">
            <div className={`d-flex flex-row-reverse pb-2 ${className}`}>
                {childNodes.map((child) => (
                    <div key={child.key} className="pe-0 ps-2">
                        {child}
                    </div>
                ))}
            </div>
            {error && (
                <ErrorMessage
                    message={error}
                    onDismiss={onErrorDismiss}
                />
            )}
        </div>
    );
};

ButtonContainer.propTypes = {
    children: PropTypes.instanceOf(Object),
    className: PropTypes.string,
    error: PropTypes.string,
    onErrorDismiss: PropTypes.func,
};

ButtonContainer.defaultProps = {
    children: null,
    className: '',
    error: '',
    onErrorDismiss: () => null,
};

export default ButtonContainer;
