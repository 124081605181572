import BaseCommentService from './BaseCommentService';
import Comment from './Models/Comment';

export default class CommentService extends BaseCommentService {
    async getCommentsByCompositeKey(applicationId, externalKey) {
        return this.fetch(`/application/${applicationId}/externalKey/${externalKey}`).then(
            (comments) => comments.map((comment) => Comment.load(comment))
        );
    }

    async getComment(commentId) {
        return this.fetch(`/${commentId}`).then((result) => Comment.load(result));
    }

    async postComment(request) {
        const { data } = request;
        return this.post('/', data.loadSqlEntity()).then((result) => Comment.load(result));
    }

    async updateComment(request) {
        const { id, data } = request;
        return this.put(`/${id}`, data.loadSqlEntity()).then((comment) => Comment.load(comment));
    }

    async deleteComment(commentId) {
        return this.delete(`/${commentId}`);
    }

    async downloadComments(externalKey, params) {
        return this.download(`/download/${externalKey}?${params}`, `Comments_${externalKey}.xlsx`)
            .catch((err) => Promise.reject(err));
    }

    async uploadComments(data) {
        return this.uploadFile('upload', data);
    }
}
