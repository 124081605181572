import PropTypes from 'prop-types';
import { DataSource } from '../../GeotrakService/Models';

export default class SiteKey {
    constructor() {
        this.module = null;
        this.project = null;
    }

    static load(dataSource) {
        const site = new SiteKey();
        site.module = SiteKey.parseModule(dataSource);
        site.project = SiteKey.parseProject(dataSource);
        return site;
    }

    static parseModule(dataSource) {
        if (!dataSource || !(dataSource instanceof DataSource)) {
            return null;
        }
        const { app } = dataSource;
        if (!app) {
            return null;
        }
        if (app.startsWith('landbase')) {
            return 'landbase';
        }
        if (app.startsWith('manager')) {
            return 'manager';
        }
        return null;
    }

    static parseProject(dataSource) {
        if (!dataSource || !(dataSource instanceof DataSource)) {
            return null;
        }
        const { databaseReference } = dataSource;
        if (!databaseReference) {
            return null;
        }

        return databaseReference.replace('V3_', '').toLowerCase();
    }
}

SiteKey.propTypes = {
    module: PropTypes.string,
    project: PropTypes.string.isRequired,
};
