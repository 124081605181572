import { isFileNameValid } from '../../../helpers/stringUtils';

const validateFileNames = (files) => {
    files.forEach((file) => {
        const isValid = isFileNameValid(file.name);

        if (!isValid) {
            throw new Error('Attached file name(s) are invalid. Only special characters ".", "_", "-", "()", "#" are supported.');
        }
    });

    return true;
};

export default validateFileNames;
