import PropTypes from 'prop-types';

export default class NotificationDomain {
    constructor() {
        this.id = null;
        this.name = null;
        this.notificationId = null;
    }

    static load(response) {
        const notificationDomain = new NotificationDomain();

        if (response) {
            notificationDomain.id = response.NotificationsGeoTrakDomainId;
            notificationDomain.name = response.DomainFieldName;
            notificationDomain.notificationId = response.NotificationId;
        }

        return notificationDomain;
    }

    loadSqlEntity() {
        const sqlEntity = {
            DomainFieldName: this.name,
            NotificationId: this.notificationId,
        };

        if (this.id) {
            sqlEntity.NotificationsGeoTrakDomainId = this.id;
        }

        return sqlEntity;
    }
}

NotificationDomain.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    notificationId: PropTypes.number,
};
