import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import LoaderButton from './LoaderButton';
import { ButtonContainer } from './styledComponents';
import { RoleService } from '../_services/GeotrakService';
import SelectUnit from './SelectUnit';

export default class SearchConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isWorking: false,
            appOptions: [],
            roleOptions: [],
            roles: [],
            module: null,
            error: null,
        };

        this.RoleService = new RoleService();
    }

    async componentDidMount() {
        try {
            const roles = await this.RoleService.getRoles();
            this.setState({
                roles,
            });
        } catch (error) {
            this.setState({ error });
        }
    }

  onFormChange = (stateProp) => (evt) => this.setState({ [stateProp]: evt.target.value }, this.filterOptions);

  onUnitChange = (unit) => this.setState({ module: unit }, this.filterOptions);

  filterProjects = (module = '') => {
      const { roles } = this.state;
      const projects = [];
      roles.forEach((role) => {
          if (role.module.unit.name === module && !projects.includes(role.module.project.name)) {
              projects.push(role.module.project.name);
          }
      });
      return projects;
  };

  filterRoles = (module = '', project = '') => {
      const { roles } = this.state;
      return roles.filter(
          (role) => role.module.unit.name === module && role.module.project.name === project
      );
  };

  fetchAppConfig = () => {
      const {
          roles, module, project, role,
      } = this.state;
      const { onAppConfigSelected } = this.props;
      const selectedRole = roles.find(
          (eachRole) => eachRole.module.unit.name === module
        && eachRole.module.project.name === project
        && eachRole.name === role
      );
      if (selectedRole) {
          return onAppConfigSelected(selectedRole);
      }
      return null;
  };

  filterOptions = () => {
      const { module, project } = this.state;
      this.setState({
          appOptions: this.filterProjects(module),
          roleOptions: this.filterRoles(module, project),
      });
  };

  canSubmit = () => {
      const { project, module, role } = this.state;
      return project && module && role;
  }

  render() {
      const {
          appOptions, roleOptions, isWorking, error,
      } = this.state;
      return (
          <div id="search-config" className="text-center">
              <SelectUnit onSelected={this.onUnitChange} />
              <select id="select-project" defaultValue="--Select a project--" onChange={this.onFormChange('project')}>
                  <option disabled value="--Select a project--">--Select a project--</option>
                  {appOptions.map((app) => <option key={app} value={app}>{app}</option>)}
              </select>
              <select id="select-role" defaultValue="--Select a role--" onChange={this.onFormChange('role')}>
                  <option disabled value="--Select a role--">--Select a role--</option>
                  {roleOptions.map((role) => <option key={role.id} value={role.name}>{role.alias}</option>)}
              </select>
              <ButtonContainer id="search-config-button-container" className="mt-2">
                  <LoaderButton
                      onClick={this.fetchAppConfig}
                      disabled={!this.canSubmit()}
                      isLoading={isWorking}
                      text="Go"
                      loadingText="Searching..."
                  />
              </ButtonContainer>
              {error && <div className="text-danger">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
          </div>
      );
  }
}

SearchConfig.propTypes = {
    onAppConfigSelected: PropTypes.func,
};

SearchConfig.defaultProps = {
    onAppConfigSelected: () => null,
};
