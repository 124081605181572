import BaseNodeService from '../BaseNodeService';

export default class LogService extends BaseNodeService {
    constructor() {
        const url = '/log-service';
        const port = 3012;
        super(url, port);
        this.rootDirectory = 'J://logs';
    }

    async getLogFiles(path) {
        const body = {
            path,
        };
        return this.post('/crawl', body);
    }

    async getFile(path) {
        const body = {
            path,
        };
        return this.post('/download', body);
    }
}
