import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { WidgetService } from '../_services/GeotrakService';
import ViewsDrawer from '../components/ViewsDrawer/ViewsDrawer';
import useGetDataSource from '../_services/GeotrakService/hooks/useGetDataSource';
import { toggleWidget } from '../actions/widgetActions';
import './stylesheets/Toolbar.css';

const toolbar = ({ match }) => {
    const [error, setError] = useState(null);
    const [mapWidgets, setMapWidgets] = useState([]);
    const [tableWidgets, setTableWidgets] = useState([]);
    const widgets = useSelector((state) => state.widgets);
    const history = useHistory();
    const dispatch = useDispatch();
    const url = Boolean(match) && match.path.split('/');
    const widgetService = new WidgetService();
    const searchParameters = new URLSearchParams(history.location.search);
    const { isLoading, dataSource } = useGetDataSource(searchParameters.get('data-source'));
    const ACTIVE_WIDGET_CLASS = 'toolbar-widget-enabled';
    const INACTIVE_WIDGET_CLASS = 'toolbar-widget-disabled';

    const getMapWidgets = async () => {
        try {
            const result = await widgetService.getModuleWidgets(dataSource.moduleRoleId);
            setMapWidgets(result.filter((widget) => widget.widgetType.isMapWidget));
        } catch (err) {
            setError('Error loading map widgets');
        }
    };

    const getTableWidgets = async () => {
        try {
            const result = await widgetService.getDataSourceWidgets(dataSource.id);
            setTableWidgets(result.filter((widget) => widget.widgetType.isTableWidget));
            setError(null);
        } catch (err) {
            setError('Error loading table widgets');
        }
    };

    const renderTooltip = (widget) => {
        const widgetName = widget.widgetType ? widget.widgetType.name : widget.title;
        const WidgetIcon = widgets[widgetName].icon;
        const widgetClickHandler = () => dispatch(toggleWidget(widgetName));
        return (
            <button
                type="button"
                key={`${widgetName}-btn`}
                className="btn text-white bg-transparent border-0"
                data-bs-toggle="tooltip"
                title={widgetName}
                onClick={widgetClickHandler}
            >
                <WidgetIcon
                    key={widgetName}
                    data-tip
                    data-for={widget.title}
                    size={30}
                    className={widget.isOpen ? ACTIVE_WIDGET_CLASS : INACTIVE_WIDGET_CLASS}
                />
            </button>
        );
    };

    const renderSearchWidget = () => renderTooltip(widgets.Search);

    const renderMapWidgets = () => mapWidgets.map((widget) => renderTooltip(widget));

    const renderTableWidgets = () => tableWidgets.map((widget) => {
        if (!widgets[widget.widgetType.name]) {
            return null;
        }

        return (
            renderTooltip(widget)
        );
    });

    useEffect(() => {
        if (!isLoading && dataSource.id) {
            getMapWidgets();
            getTableWidgets();
        }
    }, [dataSource, isLoading]);

    return (
        <nav className="text-center toolbar-container navbar navbar-default fixed-bottom">
            <div className="container justify-content-center">
                <li>
                    {renderTableWidgets()}
                    {url[url.length - 1] === 'map' && renderMapWidgets()}
                    {url[url.length - 1] === 'map' && renderSearchWidget()}
                    {url[url.length - 1] === 'data' && <ViewsDrawer />}
                </li>
                {error && <div className="text-danger">{error}</div>}
            </div>
        </nav>
    );
};

export default toolbar;
