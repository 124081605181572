import PropTypes from 'prop-types';

export default class NotificationUser {
    constructor() {
        this.id = null;
        this.username = null;
        this.notificationId = null;
    }

    static load(response) {
        const notificationUser = new NotificationUser();

        if (response) {
            notificationUser.id = response.NotificationsGeoTrakUserId;
            notificationUser.username = response.UserId;
            notificationUser.notificationId = response.NotificationId;
        }

        return notificationUser;
    }

    loadSqlEntity() {
        const sqlEntity = {
            UserId: this.username,
            NotificationId: this.notificationId,
        };

        if (this.id) {
            sqlEntity.NotificationsGeoTrakUserId = this.id;
        }

        return sqlEntity;
    }
}

NotificationUser.propTypes = {
    id: PropTypes.number,
    username: PropTypes.string,
    notificationId: PropTypes.number,
};
