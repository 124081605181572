import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { Autopopulation } from '../../../../_services/GeotrakService/Models';
import useUpdateAutopopulation from '../../../../_services/GeotrakService/hooks/useUpdateAutopopulation';
import '../autopopulations.css';

const updateButton = ({ autopopulation, isValid }) => {
    const {
        updateAutopopulation, error, isLoading, isSuccessful,
    } = useUpdateAutopopulation();

    if (!autopopulation.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/autopopulations" />;
    }

    return (
        <div className="autopopulation-toolbar autopopulation-button">
            {error && <div className="text-danger autopopulation-error" id="update-autopopulation-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-autopopulation-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateAutopopulation(autopopulation)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    autopopulation: PropTypes.instanceOf(Autopopulation),
    isValid: PropTypes.func,
};

updateButton.defaultProps = {
    autopopulation: new Autopopulation(),
    isValid: () => null,
};
