import React from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import { changePageThunk } from '../actions/moduleActions';
import './stylesheets/Pager.css';

const PAGE_RANGE = 10;
const PAGE_SIZE = 50;

class Pager extends React.Component {
  handlePageChange = (pageNumber) => {
      const { changePageThunk: pageThunk } = this.props;
      pageThunk(pageNumber);
  };

  render() {
      const { page, recordCount } = this.props;
      return (
          <div className="mt-2">
              <Pagination
                  activePage={page}
                  itemsCountPerPage={PAGE_SIZE}
                  totalItemsCount={recordCount}
                  pageRangeDisplayed={PAGE_RANGE}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  className="bg-primary"
              />
          </div>
      );
  }
}

const mapStateToProps = (state) => ({
    recordCount: state.tableData.recordCount,
    page: state.page,
});

const mapDispatchToProps = {
    changePageThunk,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pager);

// These should be more specific, but I'm just trying to get a clean lint
Pager.propTypes = {
    changePageThunk: PropTypes.func,
    page: PropTypes.number,
    recordCount: PropTypes.number,
};

Pager.defaultProps = {
    changePageThunk: () => null,
    page: 0,
    recordCount: 0,
};
