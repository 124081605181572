import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { DocumentType } from '../../../_services/GeotrakService/Models';
import { Create, Update, Delete } from './DocumentTypeActions';
import useDocumentTypeForm from './useDocumentTypeForm';
import './documentTypes.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminDocumentTypesForm = ({ match }) => {
    const { params: { id } } = match;
    const {
        values, isValid, onFormUpdate, isLoading,
    } = useDocumentTypeForm(id || null);

    if (isLoading) {
        return <LoadingFullScreen id="document-type-form-loader" />;
    }

    const documentType = new DocumentType();
    documentType.id = values.id;
    documentType.name = values.name;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Document Type">
                <form>
                    <div className="mb-3">
                        <label htmlFor="input-document-type-name" className="form-label">
                            Document Type Name
                        </label>
                        <input
                            type="text"
                            id="input-document-type-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                </form>
                <div className="document-type-toolbar">
                    <Create documentType={documentType} isValid={isValid} />
                    <Update documentType={documentType} isValid={isValid} />
                    <Delete documentType={documentType} isValid={isValid} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminDocumentTypesForm;
