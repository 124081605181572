import React from 'react';
import PropTypes from 'prop-types';

class BasicCheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.checked == null) {
            return { checked: nextProps.checked };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        const { checked } = this.props;
        if (prevProps.checked !== checked) {
            this.update(checked);
        }
    }

    update = (checked) => {
        this.setState({ checked });
    }

  handleChange = () => {
      const { checked } = this.state;
      const { onChange } = this.props;
      this.setState({ checked: !checked });
      onChange();
  };

  render() {
      const { checked } = this.state;
      const { id, name, label } = this.props;
      return (
          <div className="form-check">
              <label className="form-check-label" htmlFor={id}>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      checked={checked}
                      onChange={this.handleChange}
                      id={id}
                      name={name}
                  />
                  {' '}
                  {label}
              </label>
          </div>
      );
  }
}

export default BasicCheckBox;

BasicCheckBox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

BasicCheckBox.defaultProps = {
    checked: false,
    onChange: () => null,
};
