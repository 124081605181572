import PropTypes from 'prop-types';
import CommentType from './CommentType';

export default class Comment {
    constructor() {
        this.id = null;
        this.key = null;
        this.note = null;
        this.isDeleted = false;
        this.createdOn = null;
        this.createdBy = null;
        this.updatedOn = null;
        this.updatedBy = null;
        this.createdByFullName = null;
        this.updatedByFullName = null;
        this.isVisible = null;
        this.commentType = new CommentType();
    }

    static load(response) {
        const comment = new Comment();

        if (response) {
            comment.id = response.Id;
            comment.key = response.Key;
            comment.note = response.Note;
            comment.isDeleted = response.IsDeleted;
            comment.createdOn = response.CreatedOn;
            comment.createdBy = response.CreatedBy;
            comment.updatedOn = response.UpdatedOn;
            comment.updatedBy = response.UpdatedBy;
            comment.createdByFullName = response.CreatedByFullName;
            comment.updatedByFullName = response.UpdatedByFullName;
            comment.isVisible = true;
            comment.commentType = CommentType.load(response.CommentType);
        }

        return comment;
    }

    loadSqlEntity() {
        const entity = {
            CommentTypeId: this.commentType.id,
            ApplicationId: this.applicationId,
            Key: this.key,
            Note: this.note,
            IsDeleted: this.isDeleted,
            CreatedOn: this.createdOn,
        };

        if (this.id) {
            entity.Id = this.id;
        }

        if (this.updatedOn) {
            entity.UpdatedOn = this.updatedOn;
        }

        return entity;
    }
}

Comment.propTypes = {
    id: PropTypes.string,
    key: PropTypes.string,
    note: PropTypes.string,
    isDeleted: PropTypes.bool,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    createdByFullName: PropTypes.string,
    updatedOn: PropTypes.string,
    updatedBy: PropTypes.string,
    updatedByFullName: PropTypes.string,
    commentType: PropTypes.instanceOf(CommentType),
};
