import PropTypes from 'prop-types';

export default class Project {
    constructor() {
        this.id = null;
        this.name = null;
    }

    static load(response) {
        const project = new Project();

        if (response) {
            project.id = response.id;
            project.name = response.name;
        }

        return project;
    }

    validate() {
        if (!this.name) {
            throw Error('name is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        const sqlEntity = {
            name: this.name,
        };

        if (this.id) {
            sqlEntity.id = this.id;
        }

        return sqlEntity;
    }
}

Project.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
};
