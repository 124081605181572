import { useReducer } from 'react';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';
import ModuleService from '../ModuleService';

const useDeleteModule = () => {
    const [state, dispatch] = useReducer(useApiReducer, {});
    const { isLoading, error, isSuccessful } = state;

    const deleteModule = async (module) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            await new ModuleService().deleteModule(module.id);
            dispatch({ type: ACTIONS.SUCCESS });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        deleteModule, error, isLoading, isSuccessful,
    };
};

export default useDeleteModule;
