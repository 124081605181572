import React from 'react';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import { Project } from '../../../_services/GeotrakService/Models';
import { Create, Update, Delete } from './Actions';
import useProjectForm from './useProjectForm';
import './projects.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminProjectsForm = ({ match }) => {
    const {
        values, isValid, onFormUpdate, isLoading,
    } = useProjectForm(match.params.id || null);

    if (isLoading) {
        return <LoadingFullScreen id="project-form-loader" />;
    }

    const project = new Project();
    project.id = values.id;
    project.name = values.name;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Project">
                <form>
                    <div className="mb-3">
                        <label htmlFor="input-project-name" className="form-label">
                            Project Name
                        </label>
                        <input
                            type="text"
                            id="input-project-name"
                            value={values.name}
                            onChange={(e) => onFormUpdate('name', e.target.value)}
                        />
                    </div>
                </form>
                <div className="project-toolbar">
                    <Create project={project} isValid={isValid} />
                    <Update project={project} isValid={isValid} />
                    <Delete project={project} isValid={isValid} />
                </div>
            </AdminContainer>
        </>
    );
};

export default adminProjectsForm;
