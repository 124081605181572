import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetNotificationDomains from '../../../_services/NotificationService/hooks/domain/useGetNotificationDomains';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminNotificationsDomains = ({ match }) => {
    const { params: { id } } = match;
    const history = useHistory();
    const { notificationDomains, isLoading } = useGetNotificationDomains(id);

    if (isLoading) {
        return <LoadingFullScreen id="notification-domain-form" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Notification Domains">
                <ButtonContainer>
                    <ButtonPrimary id="new-notification-domain-button" onClick={() => history.push(`/admin/notifications/${id}/domains/new`)}>
                        New Domain
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group">
                    {notificationDomains.map((domain) => (
                        <button
                            type="button"
                            className="list-group-item list-group-item-action text-start"
                            key={domain.id}
                            onClick={() => history.push(`/admin/notifications/${id}/domains/${domain.id}`)}
                        >
                            {domain.name}
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminNotificationsDomains;
