import React from 'react';
import { Switch } from 'react-router-dom';
import AdminHome from './AdminComponents/AdminHome';
import AdminUsers from './AdminComponents/Users/AdminUsers';
import AdminUserHistory from './AdminComponents/Users/AdminUserHistory';
import AdminNotifications from './AdminComponents/Notifications/AdminNotifications';
import AdminNotificationsForm from './AdminComponents/Notifications/AdminNotificationsForm';
import AdminNotificationsUsers from './AdminComponents/Notifications/AdminNotificationsUsers';
import AdminNotificationsFields from './AdminComponents/Notifications/AdminNotificationsFields';
import AdminNotificationsDomains from './AdminComponents/Notifications/AdminNotificationsDomains';
import AdminNotificationsUsersForm from './AdminComponents/Notifications/AdminNotificationsUsersForm';
import AdminNotificationsDomainsForm from './AdminComponents/Notifications/AdminNotificationsDomainsForm';
import AdminNotificationsFieldsForm from './AdminComponents/Notifications/AdminNotificationsFieldsForm';
import AdminProjects from './AdminComponents/Projects/AdminProjects';
import AdminProjectsForm from './AdminComponents/Projects/AdminProjectsForm';
import AdminMarkets from './AdminComponents/Markets/AdminMarkets';
import AdminMarketsForm from './AdminComponents/Markets/AdminMarketsForm';
import AdminModules from './AdminComponents/Modules/AdminModules';
import AdminModulesForm from './AdminComponents/Modules/AdminModulesForm';
import AdminRoles from './AdminComponents/Roles/AdminRoles';
import AdminRolesForm from './AdminComponents/Roles/AdminRolesForm';
import AdminMapsForm from './AdminComponents/Maps/AdminMapsForm';
import AdminDataSources from './AdminComponents/DataSources/AdminDataSources';
import AdminDataSourcesForm from './AdminComponents/DataSources/AdminDataSourcesForm';
import AdminDataSourceWidgetsForm from './AdminComponents/DataSources/AdminDataSourceWidgetsForm';
import AdminDatabases from './AdminComponents/Databases/AdminDatabases';
import AdminDatabasesForm from './AdminComponents/Databases/AdminDatabasesForm';
import AdminFields from './AdminComponents/AdminFields';
import AdminViews from './AdminComponents/Views/AdminViews';
import AdminForms from './AdminComponents/AdminForms';
import {
    UserAdminPrivateRoute,
    ITAdminPrivateRoute,
    DevAdminPrivateRoute
} from '../utilComponents/privateRoutes';
import AdminAutoPopulations from './AdminComponents/Autopopulations/AdminAutopopulations';
import AdminAutopopulationsForm from './AdminComponents/Autopopulations/AdminAutopopulationsForm';
import AdminLogsFolderDirectory from './AdminComponents/Log/AdminLogsFolderDirectory';
import AdminLogsFileList from './AdminComponents/Log/AdminLogsFileList';
import AdminLogsFile from './AdminComponents/Log/AdminLogsFile';
import AdminComments from './AdminComponents/Comments/AdminComments';
import AdminCheckRequest from './AdminComponents/CheckRequest/AdminCheckRequest';
import AdminCheckRequestFields from './AdminComponents/CheckRequest/AdminCheckRequestFields';
import AdminCheckRequestRequester from './AdminComponents/CheckRequest/AdminCheckRequestRequester';
import AdminCheckRequestOffices from './AdminComponents/CheckRequest/Offices/AdminCheckRequestOffices';
import AdminCheckRequestRequests from './AdminComponents/CheckRequest/AdminCheckRequestRequests';
import AdminRenderers from './AdminComponents/Renderers/AdminRenderers';
import AdminDocumentTypes from './AdminComponents/Documents/AdminDocumentTypes';
import AdminDocumentTypesForm from './AdminComponents/Documents/AdminDocumentTypesForm';
import AdminDataSourceDocumentTypes from './AdminComponents/Documents/AdminDataSourceDocumentTypes';
import AdminDataSourceDocumentTypesForm from './AdminComponents/Documents/AdminDataSourceDocumentTypesForm';
import { UserService } from '../_services';
import AdminUsersForm from './AdminComponents/Users/AdminUsersForm';
import AdminETLs from './AdminComponents/ETLs/AdminETLs';
import AdminRoleETLs from './AdminComponents/ETLs/AdminRoleETLs';
import AdminRoleETLsForm from './AdminComponents/ETLs/AdminRoleETLsForm';
import AdminETLGeocoderJobs from './AdminComponents/ETLs/AdminETLGeocoderJobs';
import AdminETLGeocoderJobForm from './AdminComponents/ETLs/AdminETLGeocoderJobForm';
import AdminCheckRequestOfficesForm from './AdminComponents/CheckRequest/Offices/AdminCheckRequestOfficesForm';
import AdminTables from './AdminComponents/Databases/AdminTables';
import AdminTablesForm from './AdminComponents/Databases/AdminTablesForm';
import TableFields from './AdminComponents/Databases/TableFields';
import AdminDomains from './AdminComponents/Databases/AdminDomains';
import AdminSchemas from './AdminComponents/Databases/AdminSchemas';

const adminRouter = () => {
    const user = new UserService().getCurrentUser();
    return (
        <Switch>
            <UserAdminPrivateRoute
                path="/admin/home"
                component={AdminHome}
                user={user}
            />
            <UserAdminPrivateRoute
                path="/admin/users/:id/history"
                component={AdminUserHistory}
                user={user}
            />
            <UserAdminPrivateRoute
                path="/admin/users/new"
                component={AdminUsersForm}
                user={user}
            />
            <UserAdminPrivateRoute
                path="/admin/users/:id"
                component={AdminUsersForm}
                user={user}
            />
            <UserAdminPrivateRoute
                path="/admin/users"
                component={AdminUsers}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/databases/:database/schemas/:schema/tables/:table/fields/:field/domains"
                component={AdminDomains}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/databases/:database/schemas/:schema/tables/:table/fields"
                component={TableFields}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/databases/:database/schemas/:schema/tables/new"
                component={AdminTablesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/databases/:database/schemas/:schema/tables"
                component={AdminTables}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/databases/:database/schemas/"
                component={AdminSchemas}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/databases/new"
                component={AdminDatabasesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/databases"
                component={AdminDatabases}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/fields"
                component={AdminFields}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/views"
                component={AdminViews}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/forms"
                component={AdminForms}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/new"
                component={AdminNotificationsForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/users/new"
                component={AdminNotificationsUsersForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/users/:userId"
                component={AdminNotificationsUsersForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/users"
                component={AdminNotificationsUsers}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/fields/new"
                user={user}
                component={AdminNotificationsFieldsForm}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/fields/:fieldId"
                component={AdminNotificationsFieldsForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/fields"
                component={AdminNotificationsFields}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/domains/new"
                component={AdminNotificationsDomainsForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/domains/:domainId"
                component={AdminNotificationsDomainsForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id/domains"
                component={AdminNotificationsDomains}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications/:id"
                component={AdminNotificationsForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/notifications"
                component={AdminNotifications}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/projects/new"
                component={AdminProjectsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/projects/:id"
                component={AdminProjectsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/projects"
                component={AdminProjects}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/document-types/new"
                component={AdminDocumentTypesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/document-types/:id"
                component={AdminDocumentTypesForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/document-types"
                component={AdminDocumentTypes}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/markets/new"
                component={AdminMarketsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/markets/:id"
                component={AdminMarketsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/markets"
                component={AdminMarkets}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/map"
                component={AdminMapsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources/:dataSourceId/widgets"
                component={AdminDataSourceWidgetsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources/:dataSourceId/renderer"
                component={AdminRenderers}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources/:dataSourceId/document-types/new"
                component={AdminDataSourceDocumentTypesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources/:dataSourceId/document-types/:documentTypeId"
                component={AdminDataSourceDocumentTypesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources/:dataSourceId/document-types"
                component={AdminDataSourceDocumentTypes}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources/new"
                component={AdminDataSourcesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources/:dataSourceId"
                component={AdminDataSourcesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/data-sources"
                component={AdminDataSources}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/etls/new"
                component={AdminRoleETLsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/etls/:etlId"
                component={AdminRoleETLsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId/etls"
                component={AdminRoleETLs}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/new"
                component={AdminRolesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles/:roleId"
                component={AdminRolesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:moduleId/roles"
                component={AdminRoles}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/new"
                component={AdminModulesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules/:id"
                component={AdminModulesForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/modules"
                component={AdminModules}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/roles"
                component={AdminRoles}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/autopopulations/new"
                component={AdminAutopopulationsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/autopopulations/:id"
                component={AdminAutopopulationsForm}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/autopopulations"
                component={AdminAutoPopulations}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/logs/:directory/:file"
                component={AdminLogsFile}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/logs/:directory"
                component={AdminLogsFileList}
                user={user}
            />
            <DevAdminPrivateRoute
                path="/admin/logs"
                component={AdminLogsFolderDirectory}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/comments"
                component={AdminComments}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/check-request/check-request-admin-requester"
                component={AdminCheckRequestRequester}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/check-request/check-request-fields"
                component={AdminCheckRequestFields}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/check-request/check-request-offices/new"
                component={AdminCheckRequestOfficesForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/check-request/check-request-offices/:id"
                component={AdminCheckRequestOfficesForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/check-request/check-request-offices"
                component={AdminCheckRequestOffices}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/check-request/check-request-requests"
                component={AdminCheckRequestRequests}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/check-request"
                component={AdminCheckRequest}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/etls/geocoder-jobs/new"
                component={AdminETLGeocoderJobForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/etls/geocoder-jobs/:id"
                component={AdminETLGeocoderJobForm}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/etls/geocoder-jobs"
                component={AdminETLGeocoderJobs}
                user={user}
            />
            <ITAdminPrivateRoute
                path="/admin/etls"
                component={AdminETLs}
                user={user}
            />
        </Switch>
    );
};

export default adminRouter;
