/**
 * Checks whether any object key contains an empty or null value
 */
exports.isNullOrEmpty = (data) => {
    const isNull = (value) => !value;
    const isEmpty = (value) => value && !value.trim();
    const hasNullValues = Object.values(data).some(isNull);
    const hasWhiteSpace = Object.values(data).some(isEmpty);

    return !hasNullValues && !hasWhiteSpace;
};

/**
 * Checks a filename string against a regex to validate string contents for irregular special
 * characters. This regex currently recognizes ".", "_", "-", "()", and "#" as allowed characters.
 * @param {string} fileName
 */
exports.isFileNameValid = (fileName) => {
    const validCharactersRegex = /[^a-zA-Z0-9._\-()# ]/;
    return !validCharactersRegex.test(fileName);
};
