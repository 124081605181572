import React from 'react';
import { Link } from 'react-router-dom';
import {
    MdLock,
    MdLocationOn
} from 'react-icons/md';
import { UserService } from '../../../_services';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import '../../stylesheets/AdminETLs.css';

const adminETLs = () => {
    const auth = new UserService();

    const renderLink = (route, Icon, adminTypes, label) => {
        const userHasPermission = auth.hasAdminPermission(adminTypes);
        return (
            <div>
                <Link to={route} className="link-style">
                    <div className="render-link" to={route}>
                        {userHasPermission ? (
                            <h1>
                                <Icon size={100} />
                            </h1>
                        ) : (
                            <h1>
                                <MdLock size={100} className="text-danger" />
                            </h1>
                        )}
                        <p className="text-center text-dark h5">{label}</p>
                    </div>
                </Link>
            </div>
        );
    };

    return (
        <>
            <Breadcrumbs />
            <div className="admin-etls">
                {renderLink('/admin/etls/geocoder-jobs', MdLocationOn, ['IT', 'DEV'], 'Geocoder')}
            </div>

        </>
    );
};

export default adminETLs;
