import BaseNodeService from '../BaseNodeService';
import S3File from './Models/S3File';

export default class DocumentService extends BaseNodeService {
    constructor() {
        const url = '/document-service';
        const port = 3010;
        super(url, port);
    }

    getByExternalKey(externalKey) {
        return this.fetch(`/externalKey/${externalKey}`).then((files) => files.map((file) => S3File.load(file)));
    }

    getByGeoTrakValues(request) {
        const link = `databaseName/${request.databaseName}/tableName/${request.tableName}`
      + `/externalKeyColumnName/${request.primaryKey.key}/externalKey/${request.primaryKey.value}`;
        return this.fetch(`/${link}`).then((files) => files.map((file) => S3File.load(file)));
    }

    downloadFile(request) {
        return this.download(
            `/download/s3/${request.s3Key}`,
            request.fileName,
            {
                method: 'GET',
            }
        ).then((response) => response);
    }

    deleteFile(id) {
        return this.delete(`/${id}`);
    }

    upload(request) {
        return this.uploadFile('/', request.toFormData());
    }
}
