import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchConfig from '../../../utilComponents/SearchConfig';
import { DataSourceService } from '../../../_services/GeotrakService';
import ErrorMessage from '../../../utilComponents/ErrorMessage';

const appPicker = ({ nextStep }) => {
    const [error, setError] = useState(false);
    const dataSourceService = new DataSourceService();

    const onConfigSelected = async (role) => {
        try {
            const dataSources = await dataSourceService.getDataSources(role.id);
            nextStep({
                application: role.module.name,
                role: role.name,
                dataSources,
            });
        } catch (err) {
            setError(`Error Getting Data Sources. ${err.message || err}`);
        }
    };

    return (
        <div className="col-md-8 offset-md-2 text-center">
            <h2 className="Admin">Application</h2>
            <SearchConfig onAppConfigSelected={onConfigSelected} />
            {error && (
                <ErrorMessage
                    message={error}
                    onDismiss={() => setError(null)}
                />
            )}
        </div>
    );
};

export default appPicker;

appPicker.propTypes = {
    nextStep: PropTypes.func,
};

appPicker.propTypes = {
    nextStep: () => null,
};
