import PropTypes from 'prop-types';
import Notification from './Models/Notification';

export default class GeotrakNotificationManager {
    constructor() {
        this.NOTIFICATION_STORAGE_KEY = 'notifications';
        this.notifications = this.parseNotifications();
    }

    parseNotifications() {
        const notificationJson = JSON.parse(localStorage.getItem(this.NOTIFICATION_STORAGE_KEY));
        if (!notificationJson) {
            return [];
        }
        return notificationJson.map((notification) => Notification.loadObject(notification));
    }

    refreshNotifications() {
        this.notifications = this.parseNotifications();
    }

    createNotification(notification) {
        if (!notification) {
            return;
        }

        if (!(notification instanceof Notification)) {
            throw Error('Unexpected type for notification');
        }
        const updatedNotifications = this.removeDuplicateNotifications(notification);
        updatedNotifications.push(notification);
        localStorage.setItem(this.NOTIFICATION_STORAGE_KEY, JSON.stringify(updatedNotifications));
        this.refreshNotifications();
    }

    dismissNotification(notification) {
        if (!notification) {
            return;
        }

        if (!(notification instanceof Notification)) {
            throw Error('Unexpected type for notification');
        }
        const notifications = this.parseNotifications();
        const updatedNotifications = notifications.filter((e) => e.id !== notification.id);
        localStorage.setItem(this.NOTIFICATION_STORAGE_KEY, JSON.stringify(updatedNotifications));
        this.refreshNotifications();
    }

    isDuplicateNotification(notification) {
        const notifications = this.parseNotifications();
        const { isUnique } = notification.alertType;
        const existingNotifications = notifications.filter((e) => e.alertType.name === notification.alertType.name);
        return existingNotifications.length > 0 && isUnique;
    }

    removeDuplicateNotifications(notification) {
        const notifications = this.parseNotifications();
        const { isUnique } = notification.alertType;
        const filteredNotifications = notifications.filter(
            (e) => e.alertType.name !== notification.alertType.name
        );
        return isUnique ? filteredNotifications : notifications;
    }
}

GeotrakNotificationManager.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.instanceOf(Notification)),
};
