import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import { ButtonContainer } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import AdminContainer from '../AdminContainer';
import '../../stylesheets/AdminTables.css';
import { DatabaseService } from '../../../_services/GeotrakService';
import IGetSchemasRequest from '../../../_services/GeotrakService/interfaces/IGetSchemasRequest';

const adminSchemas = ({ match }) => {
    const history = useHistory();
    const { database } = match.params;
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [schemas, setSchemas] = useState([]);

    const getSchemas = async () => {
        try {
            const tableSchemas = await new DatabaseService().getSchemas(IGetSchemasRequest.load(database));
            setSchemas(tableSchemas);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(`An error occurred while getting schemas. ${err.message || err}`);
        }
    };

    useEffect(() => {
        getSchemas();
    }, []);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Schemas">
                {isLoading && (
                    <LoadingFullScreen />
                )}
                <ButtonContainer
                    error={error}
                    onErrorDismiss={() => setError(null)}
                />
                <div className="list-group tables-list">
                    {schemas.map((currentSchema) => (
                        <button
                            type="button"
                            id="schemas-list"
                            className="list-group-item list-group-item-action text-left"
                            key={currentSchema}
                            onClick={() => history.push(`/admin/databases/${database}/schemas/${currentSchema}/tables`)}
                        >
                            {currentSchema}
                        </button>
                    ))}
                </div>

            </AdminContainer>
        </>
    );
};

export default adminSchemas;
