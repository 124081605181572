import PropTypes from 'prop-types';

class IGetFieldsRequest {
    constructor() {
        this.database = null;
        this.schema = 'GeoMRE';
        this.table = null;
    }

    static load(database, table, schema) {
        if (!database) {
            throw Error('database is required');
        }

        if (!table) {
            throw Error('table is required');
        }

        const request = new IGetFieldsRequest();
        request.database = database;
        request.table = table;

        if (schema) {
            request.schema = schema;
        }

        request.validate();
        return request;
    }

    validate() {
        if (!this.database) {
            throw Error('database is required');
        }
        if (!this.schema) {
            throw Error('schema is required');
        }
        if (!this.table) {
            throw Error('table is required');
        }
    }
}

export default IGetFieldsRequest;

IGetFieldsRequest.propTypes = {
    database: PropTypes.string,
    schema: PropTypes.string,
    table: PropTypes.string,
};
