import BaseNotificationService from './BaseNotificationService';
import { NotificationField } from './Models';

export default class NotificationFieldService extends BaseNotificationService {
    async getNotificationFields(notificationId) {
        return this.fetch(`/notification/${notificationId}/fields`)
            .then((fields) => fields.map((field) => NotificationField.load(field)));
    }

    async getNotificationField(id) {
        return this.fetch(`/notification-fields/${id}`).then((field) => NotificationField.load(field));
    }

    async createNotificationField(request) {
        const { notificationId, data } = request;
        return this.post(`/notification/${notificationId}/fields`, data.loadSqlEntity())
            .then((field) => NotificationField.load(field));
    }

    async deleteNotificationField(id) {
        return this.delete(`/notification-fields/${id}`);
    }
}
