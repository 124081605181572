import {
    RESET_TABLE_DATA,
    LOAD_TABLE_DATA_PENDING,
    LOAD_TABLE_DATA_SUCCESS,
    LOAD_TABLE_DATA_ERROR
} from '../CONSTANTS/types';

const initialState = {
    isTableLoading: false,
    data: [],
    recordCount: 0,
};

export default (state = initialState, action = { type: '', tableData: [], recordCount: 0 }) => {
    switch (action.type) {
    case RESET_TABLE_DATA:
    case LOAD_TABLE_DATA_ERROR:
        return {
            isTableLoading: false,
            data: [],
            recordCount: 0,
        };
    case LOAD_TABLE_DATA_PENDING:
        return {
            isTableLoading: true,
            data: [],
            recordCount: 0,
        };
    case LOAD_TABLE_DATA_SUCCESS:
        return {
            isTableLoading: false,
            data: action.tableData,
            recordCount: action.recordCount,
        };
    default:
        return state;
    }
};
