import { useEffect, useReducer } from 'react';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';
import DataSourceService from '../DataSourceService';

const useGetDataSources = (moduleRoleId) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: [] });
    const { data, isLoading, error } = state;

    const getDataSources = async () => {
        try {
            const results = await new DataSourceService().getDataSources(moduleRoleId);
            dispatch({ type: ACTIONS.SUCCESS, data: results });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getDataSources();
    }, []);

    return { dataSources: data, error, isLoading };
};

export default useGetDataSources;
