import { SET_TABLE_DATA_COLUMNS_FILTER, RESET_TABLE_DATA_COLUMNS_FILTER } from '../CONSTANTS/types';

export const setTableDataColumnsFilter = (filters) => ({
    type: SET_TABLE_DATA_COLUMNS_FILTER,
    payload: filters,
});

export const resetTableDataColumnsFilter = () => ({
    type: RESET_TABLE_DATA_COLUMNS_FILTER,
});
